import { ReportContextEnum } from '@cohesity/api/reporting';
import { reportLayoutMap } from '../config/index';
import { reportContextMap } from '../filters/filters-maps';

/**
 * Report item title.
 */
interface ReportItemTitle {
  itemId: string;
  titleKey: string;
  columnSpan?: number;
}

/**
 * Report item list.
 */
export interface ReportItemList {
  reportId: string;
  items: ReportItemTitle[];
}

/**
 * List of report items that are wider.
 */
const wideReportItems = [ '604', '802', '1601' ];

/**
 * Get the list of report item (only id and title) grouped by report.
 *
 * @param scope App scope.
 * @returns list of report items.
 */
export function getReportItems(context?: ReportContextEnum): ReportItemList[] {
  return Object.entries(reportLayoutMap).filter(
    report => !context || reportContextMap[report[0]]?.includes(context)
  ).map(([reportId, report]) => ({
    reportId,
    items: report
      .map(section =>
        section.items.map(item => {
          if (item.componentIds?.length && !['statlistItem', 'tableItem'].includes(item.id)) {
            const result: ReportItemTitle = {
              itemId: item.componentIds[0],
              titleKey: item.title,
            };

            if (wideReportItems.includes(result.itemId)) {
              result.columnSpan = 2;
            }
            return result;
          }
          return null;
        }).filter(item => !!item)
      )
      .flat(1)
  })).filter(report => report?.items?.length);
}

/**
 * Item ID to report ID map.
 */
export const itemReportMap: Record<string, string> = {};

Object.entries(reportLayoutMap)
  .forEach(([reportId, report]) =>
    report.forEach(section =>
      section.items.forEach(item => {
        if (item.componentIds?.length && !['statlistItem', 'tableItem'].includes(item.id)) {
          itemReportMap[item.componentIds[0]] = reportId;
        }
      })
    )
  );
