import { Injectable } from '@angular/core';
import { ProtectionSourceNode } from '@cohesity/api/v1';
import { ProtectdObjectsActionRequest } from '@cohesity/api/v2';
import { DataTreeNode, DataTreeSelection, NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { StateParams, StateService, UIRouterGlobals } from '@uirouter/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { StateManagementService } from 'src/app/core/services';
import { geO365RecoveryActions } from 'src/app/modules/object-details-shared/o365-object-action-utils';
import { RestoreConfigService } from 'src/app/modules/restore/restore-shared';
import {
  Environment,
  OFFICE365_GROUPS,
  Office365ActionKeyProtectionTypeMap,
  Office365BackupType,
  Office365CSMBackupTypes,
  Office365LeafNodeType,
  Office365ObjectTypeToActionKey,
  Office365ProtectionGroupEnvMap,
  RouterTab,
} from 'src/app/shared';
import { Office365UtilityService } from 'src/app/shared/office365/services/office365-utility.service';
import {
  Office365SourceDataNode,
} from 'src/app/shared/source-tree/protection-source/office365/office365-source-data-node';

import { Office365SourceDetailsService } from '../sources/office365/services/office365-source-details.service';
import { O365ObjectActionCreator } from './o365-object-action-creator';
import { ObjectActionCreator } from './object-action-creator';
import { ObjectActionOptions } from './object-action-options.model';
import { ObjectActionProvider } from './object-action-provider';
import { checkObjectsForBulkAction, externalEnvBackupTypeUnsupportedActions, externalEnvBackupTypes } from './object-action-utils';
import { ObjectInfoService, ObjectInfoServiceOptions } from './object-info.service';
import { SimpleObjectInfo } from './object-menu-provider';
import { ObjectProtectAction } from './object-protect-action.constants';

/**
 * This is a simple implementation for construction object menu actions for Office 365.
 */
@Injectable()
export class O365ObjectActionProvider extends ObjectActionProvider {
  /**
   * The provider for this service is manually set up in object-actions-menu.service
   * which must provide the list of providers as an array in the correct order.
   * In order to maintain some kind of sanity, the providers are listed here,
   * they should match the order of the constructor args.
   */
  static o365ObjectActionProviderDependencies = [
    ObjectInfoService,
    RestoreConfigService,
    StateManagementService,
    StateService,
    ObjectActionCreator,
    IrisContextService,
    UIRouterGlobals,
    O365ObjectActionCreator,
    Office365SourceDetailsService,
    Office365UtilityService,
  ];

  exclusiveProtection = false;

  supportsBulkActions = true;

  /**
   * Specifies whether the objects are in global search context.
   */
  get isGlobalSearchContext() {
    return this.uiRouterGlobals?.current?.name === 'ng-search';
  }

  constructor(
    readonly objectStore: ObjectInfoService,
    readonly restoreConfig: RestoreConfigService,
    readonly stateManagementService: StateManagementService,
    readonly stateService: StateService,
    readonly actionCreator: ObjectActionCreator,
    readonly irisContextService: IrisContextService,
    private uiRouterGlobals: UIRouterGlobals,
    private o365ActionCreator: O365ObjectActionCreator,
    private o365SourceDetailsService: Office365SourceDetailsService,
    private office365UtilityService: Office365UtilityService,
  ) {
    super(objectStore, restoreConfig, stateManagementService, stateService, actionCreator, irisContextService);
  }

  /**
   * Determines the backup type for the given object.
   * There are 3 scenarios from where the actions can be triggered:
   *
   * 1. GlobalSearch    - Assumption is to capture customer's intent within the
   *                      object.(TODO(tauseef): Enhance GlobalSearch workflow
   *                      for O365).
   *
   * 2. SourceDetails   - The context is driven through the selected workload.
   * 3. ActivityDetails - The context is driven through the backupType already
   *                      present within the object.
   *
   * @param     object   Specifies the object on which action is to be
   *                     performed.
   * @returns   Returns the object action key.
   */
  private getObjectActionKey(object: SimpleObjectInfo): ProtectdObjectsActionRequest['objectActionKey'] {
    if (!object) {
      return;
    }

    if (object.objectActionKey) {
      return object.objectActionKey;
    }

    // For differentiating between the action on the 'kUser' entity, the object
    // action key is passed. This is only applicable where a 1:1 mapping
    // doesn't exist between entity & its backup within Magneto.
    let objectActionKey: ProtectdObjectsActionRequest['objectActionKey'] = null;

    const workload = this.o365SourceDetailsService.getWorkload();
    const isCSMWorkload = Office365CSMBackupTypes.includes(workload);

    if (!this.isGlobalSearchContext &&
        this.office365UtilityService.isMailboxOrOneDriveWorkload(workload) &&
        this.office365UtilityService.isMailboxOrOneDriveWorkloadSupported(object.objectType as Office365LeafNodeType)) {
      objectActionKey =
        Office365ProtectionGroupEnvMap[workload] as ProtectdObjectsActionRequest['objectActionKey'];
    } else {
      if ((object?.workloadType || (object?.v1Object as Office365SourceDataNode)?.workloadType) &&
          this.office365UtilityService.isMailboxOrOneDriveWorkload(workload)) {
        const currentWorkloadType = object?.workloadType ||
          (object?.v1Object as Office365SourceDataNode)?.workloadType;
        objectActionKey =
          Office365ObjectTypeToActionKey[currentWorkloadType] as ProtectdObjectsActionRequest['objectActionKey'];
      } else if (isCSMWorkload) {
        objectActionKey =
          Office365ProtectionGroupEnvMap[workload] as ProtectdObjectsActionRequest['objectActionKey'];
      } else {
        objectActionKey =
          Office365ObjectTypeToActionKey[object.objectType] as ProtectdObjectsActionRequest['objectActionKey'];
      }
    }

    return objectActionKey;
  }

  getProtectAction(): Observable<NavItem> {
    // Override inherited getProtectAction method to always return null as
    // this class defines custom protection methods.
    return of(null);
  }

  getBulkProtectAction(): Observable<NavItem> {
    // Override inherited getBulkProtectAction method to always return null as
    // this class defines custom protection methods.
    return of(null);
  }

  /**
   * Get list of actions available for an Office365 object.
   *
   * @param object  The protected object.
   * @returns List of available actions.
   */
  getObjectActions(object: SimpleObjectInfo): Observable<NavItem[]> {
    // Through ActivityDetails 'objectActionKey' will always be present.
    if (!object?.objectActionKey) {
      object.objectActionKey = this.getObjectActionKey(object);
    }

    return combineLatest([
      super.getObjectActions(object).pipe(
        map(actions => actions.filter(action => action.displayName !== 'downloadFiles')),
      ),
      of(super.canProtect(object)).pipe(map(canProtect => canProtect
        ? this.o365ActionCreator.createO365ProtectActions([object], undefined, this.isGlobalSearchContext)
        : [])),
      this.getO365RecoverActions([object]),
    ]).pipe(
      map(([baseActions, o365ProtectAction, o365RecoverActions]) =>
        [...baseActions, ...o365ProtectAction, ...o365RecoverActions]),
      map(actions => actions.filter(action => this.isActionSupportedForObjectActionKey(
        action, object.objectActionKey))),
      map(actions => actions.filter(action => super.filterActionByAccess(action))),
      map(actions => this.sortActions(actions))
    );
  }

  /**
   * Determines whether the given action is supported for the corresponding
   * object action key.
   *
   * @param action Specifies the object action type
   * @param objectActionKey Specifies the object action key(backup type)
   * @returns True, iff the action is supported by the backup type.
   */
  isActionSupportedForObjectActionKey(action: NavItem, objectActionKey: string): boolean {
    if (!!action && !!objectActionKey &&
        externalEnvBackupTypes.has(objectActionKey as Environment) &&
        externalEnvBackupTypeUnsupportedActions.has(action?.displayName)) {
      return false;
    }
    return true;
  }

  /**
   * Creates and returns the edit protection action for protected Office365 object.
   *
   * @param object The object to edit protection settings for.
   * @returns An observable, which yields the NavItem or Null.
   */
  getEditObjectProtectionAction(object: SimpleObjectInfo): Observable<NavItem> {
    if (!this.objectStore.getObjectInfo || !object.isProtected) {
      return of(null);
    }

    // Through ActivityDetails 'objectActionKey' will always be present.
    if (!object?.objectActionKey) {
      object.objectActionKey = this.getObjectActionKey(object);
    }

    return this.objectStore
      .getObjectInfo(
        object.id,
        this.getObjectInfoOptions(object)
      ).pipe(
        filter(entry => !entry.loading),
        map(entry => entry.item),
        map(item => {
          if (!item || !item.objectBackupConfiguration) {
            return null;
          }
          const isAutoProtect = item.objectBackupConfiguration.isAutoProtectConfig;
          const autoProtectId = item.objectBackupConfiguration.autoProtectParentId;
          const id = (isAutoProtect && autoProtectId) ? autoProtectId : item.id;

          // Create NavItem based on the Office365BackupType.
          return this.o365ActionCreator.createEditO365ObjectProtectionAction(
            item.objectBackupConfiguration?.office365Params?.objectProtectionType as Office365BackupType,
            id,
            { accessClusterId: object.accessClusterId, regionId: object.regionId },
            !!isAutoProtect
          );
        })
      );
  }

  /**
   * Gets a list of nav item options available for one or more O365 objects.
   *
   * @param object  The protected object.
   * @returns List of available actions.
   */
  getBulkObjectActions(objects: SimpleObjectInfo[]): Observable<NavItem[]> {
    return combineLatest([
      super.getBulkObjectActions(objects).pipe(
        // Filter out the default protect action to switch with block and file
        // based protection.
        map(actions => actions.filter(action => action.displayName !== 'protect')),
      ),
      super.canProtectAll(objects).pipe(map(canProtect => canProtect
        ? this.o365ActionCreator.createO365ProtectActions(objects, undefined, this.isGlobalSearchContext)
        : []),
      ),
      this.getO365RecoverActions(objects),
    ]).pipe(
      map(([baseActions, o365ProtectAction, o365RecoverActions]) =>
        [...baseActions, ...o365ProtectAction, ...o365RecoverActions]),
      map(actions => actions.filter(action => super.filterActionByAccess(action))),
      map(actions => this.sortActions(actions)),
    );
  }

  /**
   * Gets a list of nav item options available for one or more O365 objects.
   *
   * @param   objects          The object to get actions for.
   * @param   sourceSelection  The transformed api tree selection including .
   *                           excluded and special params.
   * @param   objectOptions    The object's action options
   * @returns Any applicable actions that can be run for all items.
   */
  getBulkTreeObjectActions(
    selection: DataTreeSelection<DataTreeNode<ProtectionSourceNode>>,
    sourceSelection: SourceSelection,
    objectOptions: ObjectActionOptions = {}
  ): Observable<NavItem[]> {
    if (!selection || (!selection.selected.length && !selection.autoSelected.length)) {
      return of([]);
    }

    const selected = selection.selected.filter(node =>
      // Filter out container nodes if they are marked for autoprotection.
      (selection.autoSelected.length && !selection.autoSelected.includes(node)) ||

      // Filter out nodes which are container type.
      !OFFICE365_GROUPS.office365EntityContainers.includes((node as Office365SourceDataNode).type));

    let objects = [...selected, ...selection.autoSelected];

    // For O365, there is only a single container node at level 0.
    const containerNode = selection.selected.find(node => node.level === 0);

    // If container node is selected
    if (containerNode) {
      const { sourceIds, sourceSpecialParameters } = sourceSelection;
      const isContainerAutoSelected =
        sourceIds?.length === 1 &&
        sourceIds?.[0] === containerNode.id &&
        Boolean(sourceSpecialParameters?.find(node => node.id === containerNode.id)?.shouldAutoProtectObject);

      // If the source selection has single selection with the container node selected.
      // Get the object actions based on the container node instead of its child nodes.
      if (isContainerAutoSelected) {
        objects = [containerNode];
      }
    }

    let objectInfos: SimpleObjectInfo[] = objects.map((object: Office365SourceDataNode) => ({
      id: object.protectionSource.id,
      environment: object.environment,
      sourceId: object.protectionSource.parentId,
      isProtected: object.protected,
      isObjectProtected: Boolean(object.objectProtected),
      objectType: object.type,
      accessClusterId: objectOptions.accessClusterId,
      regionId: objectOptions.regionId,
      v1Object: object
    }));

    objectInfos = objectInfos.map(object => ({
      ...object,
      objectActionKey: this.getObjectActionKey(object),
    }));

    return combineLatest([
      this.canProtectAll(objectInfos).pipe(map(canProtect => canProtect
        ? this.o365ActionCreator.createO365ProtectActions(objectInfos, sourceSelection)
        : []),
      ),
      this.getO365RecoverActions(objectInfos),
      this.getProtectedObjectAction(objectInfos, ObjectProtectAction.ProtectNow),
      this.getProtectedObjectAction(objectInfos, ObjectProtectAction.UnProtect),
      this.getProtectedObjectAction(objectInfos, ObjectProtectAction.Resume),

      // TODO(tauseef): Add the 'objectActionKey' within Cancel once iris_exec
      // supports the same.
      this.getProtectedObjectAction(objectInfos, ObjectProtectAction.CancelRun),
    ]).pipe(
      map(([o365ProtectAction, o365RecoverActions, ...baseActions]) =>
        [...(o365ProtectAction as NavItem[]), ...(o365RecoverActions as NavItem[]), ...baseActions]),
      map((actions: NavItem[]) => actions.filter(action => this.filterActionByAccess(action))),
      map(actions => actions.filter(action => this.isActionSupportedForObjectActionKey(
        action, objectInfos?.[0]?.objectActionKey))),
      map(actions => this.sortActions(actions))
    );
  }

  /**
   * Creates and returns the Office365 Recover action for the specified object.
   *
   * @param    objects  The protected objects.
   * @returns  An observable, which yields the NavItem or null.
   */
  getO365RecoverActions(objects: SimpleObjectInfo[]): Observable<NavItem[]> {
    if (!this.objectStore.getProtectedObject || !objects?.length) {
      return of([]);
    }

    // True if the object(s) selected is of type kUser.
    const iskUserObjectType = objects[0].objectType === Office365LeafNodeType.kUser;

    // In Global Search, since we do not tie to any workload, get recovery actions
    // based on the restorePoint snapshots. Otherwise, get recovery actions
    // for both Mailbox and OneDrive workloads if it's a kUser object.
    if (iskUserObjectType && this.isGlobalSearchContext) {
      let supportedEnv = ['kO365Exchange', 'kO365OneDrive'];

      if (this.isCsmBasedBackupEnabled()) {
        supportedEnv.push(
          Office365ProtectionGroupEnvMap[Office365BackupType.kMailboxCSM],
          Office365ProtectionGroupEnvMap[Office365BackupType.kOneDriveCSM]);
      }
      // Override the supportedEnv only incase where the object type is set to
      // kO365 instead of kO365Exchange/kO365OneDrive. This is the case for
      // OnPrem where we are not able to segregate the User entity based on
      // the response obtained from GlobalSearch.
      const objectEnvironment = objects[0]?.restorePointSelection?.objectInfo?.environment;
      if (!!objectEnvironment && objectEnvironment !== Environment.kO365) {
        supportedEnv = [objectEnvironment];
      }

      return combineLatest(supportedEnv.map(actionKey =>
        this.getGlobalSearchO365UserRecoverActions(objects, actionKey))
      ).pipe(map(actions => actions.flat()));
    }

    // Only looking up first object is okay as we only need to pull the workload type.
    return this.objectStore.getObjectInfo(
      objects[0].id,
      this.getObjectInfoOptions(objects[0])
    ).pipe(
      filter(entry => !entry.loading),
      map(entry => entry.item),
      switchMap(item => {
        let office365WorkloadType =
          (item?.objectBackupConfiguration?.office365Params?.objectProtectionType
          ?? objects[0].workloadType) as Office365BackupType;

        if (!office365WorkloadType) {
          // For kUser, currently the due to the API limitation, if both mailbox
          // & onedrive are backed the 'objectBackupConfiguration' will contain
          // the latest one. Hence, rely on the tab selection for kUser.
          // TODO(tauseef): Remove this one the API limitation is fixed.
          if (iskUserObjectType && !this.isGlobalSearchContext) {
            office365WorkloadType = this.o365SourceDetailsService.getWorkload();
          }

          // For non-kUser, there is always a 1:1 mapping between objectType and workload.
          if (!iskUserObjectType) {
            office365WorkloadType =
              Office365ActionKeyProtectionTypeMap[this.getObjectActionKey(objects[0])] as Office365BackupType;
          }
        }
        return this.processO365RecoveryActions(objects, undefined, office365WorkloadType);
      })
    );
  }

  /**
   * Sort array of actions to a specific order.
   *
   * @param actions The actions to sort.
   * @return Array of sorted actions.
   */
  sortActions(actions: NavItem[]): NavItem[] {
    // Generic action items sorting logic
    actions = super.sortActions(actions);

    // Additional M365-specific sorting logic based on the priority option of the actions.
    // This is to sort the action of object recovery before granular recovery
    // regardless of the action's display name.
    return actions.sort((a, b) => {
      if (a.options?.priority && b.options?.priority) {
        return b.options.priority - a.options.priority;
      } else {
        // Keep original order
        return 0;
      }
    });
  }

  /**
   * Gets a list of tabs for different type of Storages.
   *
   * @param   stateParams   The router state paramaters
   * @param   newWorkload   The new workload
   * @returns Any applicable tab that is supported
   */
  getParentTabs(stateParams: StateParams, newWorkload?: string): RouterTab[] {

    const cohWorkload = stateParams.tabType !== 'o365' ?
      (newWorkload ? newWorkload : Office365BackupType.kMailbox) : Office365BackupType.kMailbox;

    const csmWorkload = stateParams.tabType === 'o365' ?
      (newWorkload ? newWorkload : Office365BackupType.kMailboxCSM) : Office365BackupType.kMailboxCSM;


    return [
      {
        displayName: 'microsoftBackupApis',
        routeName: this.uiRouterGlobals.current as string,
        routeParams: {
          ...stateParams,
          workload: csmWorkload,
          tabType: 'o365'
        },
        routeOptions: { reload: true, notify: true },
      },
      {
      displayName: 'importExportApis',
      routeName: this.uiRouterGlobals.current as string,
      routeParams: {
        ...stateParams,
        workload: cohWorkload,
        tabType: ''
      },
      routeOptions: { reload: true, notify: true },
    }];
  }

  /**
   * Determines if Csm based backup is supported.
   *
   * @returns  True if Csm based backup is supported.
   */
  isCsmBasedBackupEnabled(): boolean {
    return flagEnabled(this.irisContextService.irisContext, 'office365CsmBasedBackupEnabled');
  }

  /**
   * Extract the additional lookup options for an object from the simple object
   *
   * @param object The current object
   * @returns An options object with the accessClusster or regionId set and the action key if any.
   */
  protected getObjectInfoOptions(object: SimpleObjectInfo): ObjectInfoServiceOptions {
    return {
      accessClusterId: object.accessClusterId,
      regionId: object.regionId,
      actionKey: this.getObjectActionKey(object)
    };
  }

  /**
   * Gets the recovery actions for User objects in Global Search.
   *
   * @param    objects    List of user objects.
   * @param    actionKey  The object action key for Exchange/OneDrive.
   * @returns  Observable of recovery actions.
   */
  private getGlobalSearchO365UserRecoverActions(
    objects: SimpleObjectInfo[],
    actionKey: string
  ): Observable<NavItem[]> {
    const objectInfos = objects.map(object => ({
      id: object.id,
      options: {
        accessClusterId: object.accessClusterId,
        regionId: object.regionId,
        actionKey,
      }
    }));

    const office365WorkloadType = Office365ActionKeyProtectionTypeMap[actionKey] as Office365BackupType;
    return this.processO365RecoveryActions(objects, objectInfos, office365WorkloadType, true);
  }

  /**
   * Helper method to process O365 recovery actions based on the workload type passed in the parameter.
   *
   * @param objects                Array of selected objects.
   * @param office365WorkloadType  Workload type.
   * @returns   Observable of NavItems[]
   */
  private processO365RecoveryActions(
    objects: SimpleObjectInfo[],
    objectInfos: {id: number; options: ObjectInfoServiceOptions}[],
    office365WorkloadType: Office365BackupType,
    isGlobalSearchContext?: boolean
  ): Observable<NavItem[]> {
    // If objectInfos with actionKeys are not passed, get them from simple object info.
    if (!objectInfos) {
      objectInfos = objects.map(object => ({
        id: object.id,
        options: this.getObjectInfoOptions(object),
      }));
    }

    if (objects.length === 1 && objects[0].useRestorePointSelection && objects[0].restorePointSelection) {
      // Do not fetch protected object if the object only wants
      // recovery actions created using restore point selection.
      const recoveryActions = geO365RecoveryActions(
        this.irisContextService.irisContext,
        office365WorkloadType) ?? [];
      const compatibleActions =
        recoveryActions.filter(action => checkObjectsForBulkAction(objects, action));

      return of(compatibleActions.map(action =>
        this.o365ActionCreator.createO365RecoverAction(
          objectInfos,
          action,
          office365WorkloadType,
          objects[0].restorePointSelection,
          { accessClusterId: objects[0].accessClusterId, regionId: objects[0].regionId },
          isGlobalSearchContext
        )
      ));
    }

    return this.objectStore.getProtectedObjects(objectInfos).pipe(
      filter(entries => entries.every(entry => !entry.loading)),
      map(entries => {
        const infos = entries.map(entry => entry.item);

        if (!infos.filter(Boolean).length) {
          return [];
        }

        // Non bulk actions.
        if (objects.length === 1) {
          const recoveryActions = geO365RecoveryActions(
            this.irisContextService.irisContext,
            office365WorkloadType) ?? [];
          const compatibleActions =
            recoveryActions.filter(action => checkObjectsForBulkAction(objects, action));

          return compatibleActions.map(action =>
            this.o365ActionCreator.createO365RecoverAction(
              infos,
              action,
              office365WorkloadType,
              null,
              { accessClusterId: objects[0].accessClusterId, regionId: objects[0].regionId },
              isGlobalSearchContext
            )
          );
        }

        // Bulk actions.
        // All objects have to be of the same workload type and be from the same source, so there will
        // be only one bulk recovery action returned.
        if (!infos.every(info => info &&
          this.canRecover(this.office365UtilityService.getRecoveryAction(office365WorkloadType), info, null) &&
          info.sourceId === infos[0].sourceId)) {
          // Make sure all objects can perform the bulk recovery action.
          return [];
        }

        return [
          this.o365ActionCreator.createO365BulkRecoverAction(
            infos,
            office365WorkloadType,
            null,
            { accessClusterId: objects[0].accessClusterId, regionId: objects[0].regionId }
          ),
        ];
      })
    );
  }

  /**
   * Determines if action DownloadFiles is supported for the current object.
   *
   * @param    object   O365 simple object info.
   * @returns  True if the object supports DownloadFiles action.
   */
  private isDownloadFileSupported(object: SimpleObjectInfo): boolean {
    const workloadType = Office365ActionKeyProtectionTypeMap[object.objectActionKey];

    switch (workloadType) {
      case Office365BackupType.kOneDrive:
        return flagEnabled(this.irisContextService.irisContext, 'office365NgRecoveryOneDrivesDownload');
      case Office365BackupType.kGroups:
      case Office365BackupType.kMailbox:
      case Office365BackupType.kPublicFolders:
      case Office365BackupType.kSharePoint:
        return flagEnabled(this.irisContextService.irisContext, 'office365NgRecoverySharePointDownload');
      case Office365BackupType.kTeams:
      default:
        return false;
    }
  }
}
