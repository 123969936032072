import { AggregatedSubtreeInfo, ProtectionSourceNode, Office365ProtectionSource } from '@cohesity/api/v1';
import { DataTreeControl } from '@cohesity/helix';
import { Memoize } from '@cohesity/utils';
import { get } from 'lodash';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import {
  Environment,
  OFFICE365_GROUPS,
  Office365BackupType,
  Office365ContainerNodeType,
  Office365LeafNodeType,
  ObjectTypeToIconMap,
  Office365CSMBackupTypes,
} from 'src/app/shared/constants';

import { ProtectionSourceDataNode } from '../shared/protection-source-data-node';
import { SourceNodeMetadata } from '../shared/protection-source-metadata/source-node-metadata';

export class Office365SourceDataNode extends ProtectionSourceDataNode<Office365ProtectionSource, Office365BackupType> {
  /**
   * This indicates whether the node is part of the job currently being edited or viewed.
   */
  inCurrentJob = false;

  /**
   * Indicates whether the node is  excluded from auto protection.
   */
  isExcluded = false;

  /**
   * Indicates whether the node is fully protected.
   *
   * Refer exceptions within calculateProtectedLeafCount(...)
   */
  isFullyProtected = false;

  /**
   * Office365 node meta data.
   */
  readonly metadata: SourceNodeMetadata;

  /**
   * Map of Environment to the count of protected leaves for this node.
   */
  private protectedLeavesCountMap = new Map<string, number>();

  /**
   * Map of Environment to the count of unprotected leaves for this node.
   */
  private unprotectedLeavesCountMap = new Map<string, number>();

  /**
   * ctor
   */
  constructor(
    data: ProtectionSourceNode,
    readonly level: number,
    readonly treeControl: DataTreeControl<Office365SourceDataNode>,
    readonly workloadTypeObservable: Observable<Office365BackupType>,
  ) {
    super(Environment.kO365, data, level);

    // Set the workload type.
    this.workloadTypeObservable.pipe(first()).subscribe(workloadType => this.workloadType = workloadType);

    if (this.data.protectedSourcesSummary) {
      this.data.protectedSourcesSummary.forEach((summary: AggregatedSubtreeInfo) => {
        this.protectedLeavesCountMap.set(summary.environment, summary.leavesCount);
      });

      this.data.unprotectedSourcesSummary.forEach((summary: AggregatedSubtreeInfo) => {
        this.unprotectedLeavesCountMap.set(summary.environment, summary.leavesCount);
      });
    }

    this.metadata = {
      logicalSize: this.logicalSize,
      leafCount: this.expandable ? this.children.length : undefined,
      channelCount: this.getChannelCount(data),
    };

    this.isFullyProtected =
      this.calculateUnprotectedLeafCount(data) === 0 &&
      this.calculateProtectedLeafCount(data) > 0;
  }

  /**
   * Determines if the nodes can be selected.
   */
  canSelect(): boolean {
    return true;
  }

  /**
   * Determines the icon for the given entity.
   */
  get icon(): string {
    const envIconMap = ObjectTypeToIconMap[this.protectionSource.environment];

    if (!envIconMap) {
      return null;
    }

    // The icon depends on the workload for the o365 entity.
    let iconName = envIconMap[this.workloadType];

    // Override the icon type if the entity is kGroup with security-enabled.
    if (this.protectionSource?.office365ProtectionSource?.groupInfo?.isSecurityEnabled) {
      iconName = envIconMap['kSecurityGroup'];
    }

    if (!iconName) {
      return null;
    }

    const suffix = (this.isAutoProtectedByObject || this.isAutoProtectionParent) ? '-auto' :
      this.protected ? '-protected' :
      this.partialProtected ? '-partial' : '';

    this._icon = iconName.concat(suffix);
    return this._icon;
  }

  /**
   * Determines whether an object is autoprotected through its parent.
   */
  get isAutoProtectedByObject(): boolean {
    return this.protected &&
      this.isObjectProtected &&
      !!this.data.objectProtectionInfo?.autoProtectParentId;
  }

  /**
   * Determines whether an object is providing auto protection to its children.
   * This will only apply to root nodes that are not of kSites type.
   */
  get isAutoProtectionParent(): boolean {
    return !this.isLeaf && this.type !== Office365ContainerNodeType.kSites && this.objectProtected;
  }

  /**
   * Fetches the protection summary.
   */
  get protectedSummary(): AggregatedSubtreeInfo {
    return (this.data.protectedSourcesSummary || []).find(sum => {
      if (this.workloadType === Office365BackupType.kMailbox) {
        return sum.environment === Environment.kO365Exchange;
      } else if (this.workloadType === Office365BackupType.kOneDrive) {
        return sum.environment === Environment.kO365OneDrive;
      } else if (this.workloadType === Office365BackupType.kMailboxCSM) {
        return sum.environment === Environment.kO365ExchangeCSM;
      } else if (this.workloadType === Office365BackupType.kOneDriveCSM) {
        return sum.environment === Environment.kO365OneDriveCSM;
      } else if (this.workloadType === Office365BackupType.kSharePoint) {
        return sum.environment === Environment.kO365Sharepoint;
      } else if (this.workloadType === Office365BackupType.kSharePointCSM) {
        return sum.environment === Environment.kO365SharepointCSM;
      } else {
        return sum.environment === this.environment;
      }
    }) || {};
  }

  /**
   * Fetches the unprotected summary.
   */
  get unprotectedSummary(): AggregatedSubtreeInfo {
    return (this.data.unprotectedSourcesSummary || []).find(sum => {
      if (this.workloadType === Office365BackupType.kMailbox) {
        return sum.environment === Environment.kO365Exchange;
      } else if (this.workloadType === Office365BackupType.kOneDrive) {
        return sum.environment === Environment.kO365OneDrive;
      } else if (this.workloadType === Office365BackupType.kMailboxCSM) {
        return sum.environment === Environment.kO365ExchangeCSM;
      } else if (this.workloadType === Office365BackupType.kOneDriveCSM) {
        return sum.environment === Environment.kO365OneDriveCSM;
      } else if (this.workloadType === Office365BackupType.kSharePoint) {
        return sum.environment === Environment.kO365Sharepoint;
      } else if (this.workloadType === Office365BackupType.kSharePointCSM) {
        return sum.environment === Environment.kO365SharepointCSM;
      } else {
        return sum.environment === this.environment;
      }
    }) || {};
  }

  /**
   * Gets the logical size of all leaf children by summing the protected and unprotected
   * logical size, except for kMailbox and kOneDrive, which it gets directly.
   */
  get logicalSize(): number {
    return (this.protectedSummary?.totalLogicalSize || 0) + (this.unprotectedSummary?.totalLogicalSize || 0);
  }

  /**
   * Specifies whether the current node is of type 'kGroup' and has security
   * enabled attribute set to true.
   */
  get isSecurityGroupNode(): boolean {
    return this.protectionSource?.office365ProtectionSource?.groupInfo?.isSecurityEnabled;
  }

  /**
   * Specifies whether the node supports viewing the last run status within
   * the source tree. All leaf entities currently show the backup run status
   * except Security Group entities.
   */
  get shouldShowLastRunStatus(): boolean {
    return this.isLeaf && !this.isSecurityGroupNode;
  }

  /**
   * Specifies whether the node supports viewing the last run SLA within the
   * source tree. All leaf entities currently show the backup run SLA except
   * Security Group entities.
   */
  get shouldShowLastRunSla(): boolean {
    return this.isLeaf && !this.isSecurityGroupNode;;
  }

  /**
   * Deternines if the node can be excluded.
   *
   * @returns   True if this node can be excluded.
   */
  canExclude(): boolean {
    return true;
  }

  /**
   * Determines if the node can be auto protected.
   * AutoProtect is not supported for CSM based workloads.
   */
  canAutoSelect(): boolean {
    return !Office365CSMBackupTypes.includes(this.workloadType) && (
      OFFICE365_GROUPS.office365EntityContainers.includes(this.type) ||
      !!this.expandable);
  }

  /**
   * True if current node has object protection.
   */
  get objectProtected(): boolean {
    return Boolean(this.data.objectProtectionInfo);
  }

  /**
   * True if the entire tree is protected for the given node.
   */
  get protected(): boolean {
    switch (this.workloadType) {
      // Handle Office365 User workload scenario.
      case Office365BackupType.kMailbox:
        return (this.protectedLeavesCountMap.get(Environment.kO365Exchange) > 0 &&
          !this.unprotectedLeavesCountMap.get(Environment.kO365Exchange));
      case Office365BackupType.kMailboxCSM:
        return (this.protectedLeavesCountMap.get(Environment.kO365ExchangeCSM) > 0 &&
          !this.unprotectedLeavesCountMap.get(Environment.kO365ExchangeCSM));
      case Office365BackupType.kOneDrive:
        return this.protectedLeavesCountMap.get(Environment.kO365OneDrive) > 0 &&
          !this.unprotectedLeavesCountMap.get(Environment.kO365OneDrive);
      case Office365BackupType.kOneDriveCSM:
        return this.protectedLeavesCountMap.get(Environment.kO365OneDriveCSM) > 0 &&
          !this.unprotectedLeavesCountMap.get(Environment.kO365OneDriveCSM);

      // Handle Office365 Sharepoint workload scenario.
      case Office365BackupType.kSharePoint:
        return this.protectedLeavesCountMap.get(Environment.kO365Sharepoint) > 0 &&
          !this.unprotectedLeavesCountMap.get(Environment.kO365Sharepoint);
      case Office365BackupType.kSharePointCSM:
        return this.protectedLeavesCountMap.get(Environment.kO365SharepointCSM) > 0 &&
          !this.unprotectedLeavesCountMap.get(Environment.kO365SharepointCSM);
      default:

        // Handle other container entities.
        return !!this.protectedCount && !this.unprotectedCount;
    }
  }

  /**
   * Specifies whether the node it partially protected.
   *
   * For all Office365 entity types, this state of partial protection is only
   * applicable to container entities where out of all children. some may not
   * be protected.
   *
   * (Refer Office365ContainerNodeType within office365.constants.ts)
   *
   * @returns   True, if node is partially protected. False, otherwise.
   */
  get partialProtected(): boolean {
    return this.calculateUnprotectedLeafCount(this.data) > 0 &&
      this.calculateProtectedLeafCount(this.data) > 0;
  }

  /**
   * Whether the node is a leaf which can be directly selected or not.
   */
  get isLeaf(): boolean {
    // TODO(tauseef): kSite is both leaf and internal node. Check if this
    // breaks any assumption.
    return OFFICE365_GROUPS.office365Leaves.includes(this.type);
  }

  /**
   * Return the node level in auto protected view.
   */
  get levelInAutoProtectedView() {
    // For SharePoint site, keep the same hierarchy in auto protected view.
    if (this.type === Office365LeafNodeType.kSite) {
      return this.level;
    }
    return super.levelInAutoProtectedView;
  }

  /**
   * Determines if the Node can be expanded.
   */
  @Memoize()
  get expandable(): boolean {
    const children = this.children;
    return Array.isArray(children) && children.length > 0;
  }

  /**
   * Helper to determine if this node is protected by the given environment
   * kValue job type, ie. 'kO365'.
   *
   * @param     environment  The environment to check protection status for.
   * @returns   True if the node is protected for the given Environment.
   */
  @Memoize()
  isEnvironmentProtected(environment: Environment): boolean {
    return !!this.protectedLeavesCountMap.get(environment as string);
  }

  /**
   * Returns the count of leaf entities protected within the given node.
   *
   * For all container entities, this includes the nodes within the subtree of
   * the same which are discovered within EH in Magneto.
   *
   * Exceptions:
   *  -- kPublicFolder hierarchy is discovered only till the root folder level
   *     The 2nd level and beyond are not part of EH, hence not consisdered
   *     for any aggregation.
   *  -- kUsers hierarchy determines the count based on the workload type
   *     which can either be Mailbox/OneDrive.
   *
   * @param     node   Specifies the selected/Deselected node.
   * @returns   Number of protected leaf entities.
   */
  calculateProtectedLeafCount(node: ProtectionSourceNode): number {
    // Handle kUsers.
    if (node.protectionSource?.office365ProtectionSource?.type === Office365ContainerNodeType.kUsers) {
      if (this.workloadType === Office365BackupType.kMailbox) {
        return this.protectedLeavesCountMap.get(Environment.kO365Exchange) || 0;
      }
      if (this.workloadType === Office365BackupType.kMailboxCSM) {
        return this.protectedLeavesCountMap.get(Environment.kO365ExchangeCSM) || 0;
      }
      if (this.workloadType === Office365BackupType.kOneDrive) {
        return this.protectedLeavesCountMap.get(Environment.kO365OneDrive) || 0;
      }
      if (this.workloadType === Office365BackupType.kOneDriveCSM) {
        return this.protectedLeavesCountMap.get(Environment.kO365OneDriveCSM) || 0;
      }
    }

    // Handle kSites.
    if (node.protectionSource?.office365ProtectionSource?.type === Office365ContainerNodeType.kSites) {
      if (this.workloadType === Office365BackupType.kSharePoint) {
        return this.protectedLeavesCountMap.get(Environment.kO365Sharepoint) || 0;
      }
      if (this.workloadType === Office365BackupType.kSharePointCSM) {
        return this.protectedLeavesCountMap.get(Environment.kO365SharepointCSM) || 0;
      }
    }

    // Handle other container entities.
    return get(node, 'protectedSourcesSummary[0].leavesCount', 0) || 0;
  }

  /**
   * Returns leaf entities unprotected within the given node.
   *
   * For all container entities, this includes the nodes within the subtree of
   * the same which are discovered within EH in Magneto.
   *
   * Exceptions:
   *  -- kPublicFolder hierarchy is discovered only till the root folder level
   *     The 2nd level and beyond are not part of EH, hence not consisdered
   *     for any aggregation.
   *  -- kUsers hierarchy determines the count based on the workload type
   *     which can either be Mailbox/OneDrive.
   *
   * @param     node   Specifies the selected/Deselected node.
   * @returns   Number of unprotected leaf entities.
   */
  calculateUnprotectedLeafCount(node: ProtectionSourceNode): number {
    // Handle kUsers.
    if (node.protectionSource?.office365ProtectionSource?.type === Office365ContainerNodeType.kUsers) {
      if (this.workloadType === Office365BackupType.kMailbox) {
        return this.unprotectedLeavesCountMap.get(Environment.kO365Exchange) || 0;
      }
      if (this.workloadType === Office365BackupType.kMailboxCSM) {
        return this.unprotectedLeavesCountMap.get(Environment.kO365ExchangeCSM) || 0;
      }
      if (this.workloadType === Office365BackupType.kOneDrive) {
        return this.unprotectedLeavesCountMap.get(Environment.kO365OneDrive) || 0;
      }
      if (this.workloadType === Office365BackupType.kOneDriveCSM) {
        return this.unprotectedLeavesCountMap.get(Environment.kO365OneDriveCSM) || 0;
      }
    }

    // Handle kSites.
    if (node.protectionSource?.office365ProtectionSource?.type === Office365ContainerNodeType.kSites) {
      if (this.workloadType === Office365BackupType.kSharePoint) {
        return this.unprotectedLeavesCountMap.get(Environment.kO365Sharepoint) || 0;
      }
      if (this.workloadType === Office365BackupType.kSharePointCSM) {
        return this.unprotectedLeavesCountMap.get(Environment.kO365SharepointCSM) || 0;
      }
    }

    // Handle other container entities.
    return get(node, 'unprotectedSourcesSummary[0].leavesCount', 0) || 0;
  }

  /**
   * Get the channel count associated with office 365 team ie. 'kTeam'.
   *
   * @returns   Number of Channels associated with the specified MS Team.
   */
  getChannelCount(node: ProtectionSourceNode): number {
    if (node.protectionSource?.office365ProtectionSource?.type === Office365LeafNodeType.kTeam) {
      return node.protectionSource?.office365ProtectionSource?.teamInfo?.channelCount;
    }
    return undefined;
  }

  /**
   * Query all of the current node's descendants.
   *
   * @returns  The list of all descendants of the current node.
   */
  @Memoize()
  getDescendants(): Office365SourceDataNode[] {
    if (!this.expandable) {
      return [];
    }
    return this.treeControl.getDescendants(this);
  }
}
