<ng-container *ngIf="chartConfig?.chartType && chartConfig?.measurements.length">
  <iris-rpt-bubble-chart-item
    *ngIf="chartConfig.chartType === 'bubble'"
    [measurements]="chartConfig.measurements"
    [dimensions]="chartConfig.dimensions"
    [components]="components"
    [translate]="translate"
    [isPrintMedia]="isPrintMedia"
    (itemFilterChange)="onFilterChange($event)">
  </iris-rpt-bubble-chart-item>
  <iris-rpt-donut-chart-item
    *ngIf="chartConfig.chartType === 'donut'"
    [measurements]="chartConfig.measurements"
    [dimensions]="chartConfig.dimensions"
    [components]="components"
    [translate]="translate"
    [isPrintMedia]="isPrintMedia"
    (itemFilterChange)="onFilterChange($event)">
  </iris-rpt-donut-chart-item>
  <iris-rpt-column-chart-item
    *ngIf="chartConfig.chartType === 'column'"
    [measurements]="chartConfig.measurements"
    [dimensions]="chartConfig.dimensions"
    [components]="components"
    [translate]="translate"
    [isPrintMedia]="isPrintMedia"
    (itemFilterChange)="onFilterChange($event)">
  </iris-rpt-column-chart-item>
  <iris-rpt-stacked-column-chart-item
    *ngIf="chartConfig.chartType === 'stacked'"
    [measurements]="chartConfig.measurements"
    [dimensions]="chartConfig.dimensions"
    [components]="components"
    [translate]="translate"
    [isPrintMedia]="isPrintMedia"
    (itemFilterChange)="onFilterChange($event)">
  </iris-rpt-stacked-column-chart-item>
  <iris-rpt-line-chart-item
    *ngIf="chartConfig.chartType === 'line'"
    [measurements]="chartConfig.measurements"
    [dimensions]="chartConfig.dimensions"
    [components]="components"
    [translate]="translate"
    [isPrintMedia]="isPrintMedia"
    (itemFilterChange)="onFilterChange($event)">
  </iris-rpt-line-chart-item>
  <iris-rpt-pie-chart-item
  *ngIf="chartConfig.chartType === 'pie'"
  [measurements]="chartConfig.measurements"
  [dimensions]="chartConfig.dimensions"
  [components]="components"
  [translate]="translate"
  [isPrintMedia]="isPrintMedia"
  (itemFilterChange)="onFilterChange($event)">
</iris-rpt-pie-chart-item>
</ng-container>

<ng-template #editTemplate>
  <div class="settings-menu" (click)="$event.stopPropagation()">
    <iris-rpt-chart-config-form [translate]="translate"
      [chartConfig]="chartConfig"
      (chartConfigChange)="onConfigChange($event)"
      [component]="components[0]">
    </iris-rpt-chart-config-form>
  </div>
</ng-template>
