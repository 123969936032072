<!-- Since this feature is advanced and not needed as of now, hence commenting from product -->
<!-- <ng-container *ngIf="showScanOnReplicaEnabled">
  <div class="margin-bottom-lg flex-row-lg">
    <span class="active-text" cogDataId="always-scan-replication-label">
      {{ 'dg.clusterReplication.scanReplicatedSnapshot' | translate }}
    </span>
    <span cogDataId="always-scan-replication-value">{{ scanOnReplicaEnabled | yesNo | translate }}</span>
  </div>

  <mat-divider *ngIf="!isMinimalMode" class="margin-bottom-lg"></mat-divider>
</ng-container> -->

<div *ngIf="!isMinimalMode">
  <dg-cr-cluster-config-filters
    [softwareVersionFilterValues]="softwareVersionOptions"
    [locationFilterValues]="locationOptions"
    (valueChanges)="applyFilters($event)">
  </dg-cr-cluster-config-filters>
</div>

<cog-table cogDataId="cluster-list" [source]="filteredOrder">
  <table mat-table>
    <!-- table footer with loader spinner -->
    <tr
      mat-footer-row
      class="loading-row"
      [class.hidden]="!loading"
      *matFooterRowDef="['loading']"
      cogDataId="scan-order-loading-row"></tr>
    <ng-container matColumnDef="loading">
      <td colspan="50" mat-footer-cell *matFooterCellDef>
        <cog-spinner cogDataId="scan-order-spinner"></cog-spinner>
      </td>
    </ng-container>

    <!-- table footer with no data found -->
    <tr
      mat-footer-row
      [class.hidden]="loading || filteredOrder?.length > 0"
      *matFooterRowDef="['noData']"
      cogDataId="scan-order-footer-row"></tr>
    <ng-container matColumnDef="noData">
      <td colspan="50" mat-footer-cell *matFooterCellDef cogDataId="scan-order-no-data">
        <cog-blank-card message="{{ 'dg.clusterConfig.noClustersIncludedInScanOrder' | translate }}"></cog-blank-card>
      </td>
    </ng-container>

    <ng-container matColumnDef="scanOrder">
      <th mat-header-cell *matHeaderCellDef cogDataId="scan-order-header">
        {{ 'dg.clusterConfig.scanOrder' | translate }}
      </th>
      <td mat-cell *matCellDef="let cluster; let i = index" cogDataId="scan-order-in-row-{{ i }}">
        {{ cluster.clusterScanOrder | naLabel }}
      </td>
    </ng-container>

    <ng-container matColumnDef="clusterName">
      <th mat-header-cell *matHeaderCellDef cogDataId="cluster-name-header">
        {{ 'dg.clusterConfig.clusterName' | translate }}
      </th>
      <td mat-cell *matCellDef="let cluster; let i = index">
        <div class="flex-column">
          <span cogDataId="cluster-name-in-row-{{ i }}">{{ cluster.clusterName }}</span>
          <ul *ngIf="!isMinimalMode" class="flex-row c-ul-inline c-ul-dot-separator no-margin">
            <li class="no-padding-right">
              <small class="item-label" cogDataId="cluster-version-in-row-{{ i }}">
                {{ 'dg.clusterConfig.versionCol' | translate: { version: cluster.currentVersion | naLabel } }}
              </small>
            </li>
            <li>
              <small class="item-label" cogDataId="cluster-location-in-row-{{ i }}">
                {{ 'dg.clusterConfig.locationCol' | translate: { location: cluster.location | naLabel } }}
              </small>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="connectionStatus">
      <th mat-header-cell *matHeaderCellDef cogDataId="cluster-connection-status-header">
        {{ 'dg.clusterConfig.connectionStatus' | translate }}
      </th>
      <td mat-cell *matCellDef="let cluster; let i = index" cogDataId="connection-status-in-row-{{ i }}">
        <div class="flex-row-sm vertical-align">
          <cog-icon shape="{{ cluster.isConnectedToHelios | clusterHealthStatusIcon }}" size="md"> </cog-icon>
          <span>{{ (cluster.isConnectedToHelios ? 'connected' : 'disconnected') | translate }}</span>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="datahawkAppStatus">
      <th mat-header-cell *matHeaderCellDef cogDataId="cluster-health-status-header">
        {{ 'dg.clusterConfig.datahawkAppStatus' | translate }}
      </th>
      <td mat-cell *matCellDef="let cluster; let i = index" cogDataId="health-status-in-row-{{ i }}">
        <div class="flex-row-sm vertical-align">
          <cog-icon shape="{{ cluster.isDataHawkAppStatusHealthy | clusterHealthStatusIcon }}" size="md"> </cog-icon>
          <span>{{
            (cluster.isDataHawkAppStatusHealthy ? 'healthy' : 'dg.clusterReplication.status.unhealthy') | translate
          }}</span>
        </div>
      </td>
    </ng-container>

    <!-- Header and row definition -->
    <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
    <tr mat-row *matRowDef="let cluster; columns: displayColumns" [class.hidden]="loading"></tr>
  </table>
</cog-table>
