import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {
  LegacyDateAdapter as DateAdapter,
  MAT_LEGACY_DATE_FORMATS as MAT_DATE_FORMATS,
  MAT_LEGACY_DATE_LOCALE as MAT_DATE_LOCALE,
} from '@angular/material/legacy-core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { CohesityHelixModule } from '@cohesity/helix';

import { ActivityTypeFilterComponent } from './activity-type-filter/activity-type-filter.component';
import {
  BackupStatusFilterComponent,
  ObjectStatusFilterComponent,
} from './backup-status-filter/backup-status-filter.component';
import { ConnectionStatusFilterComponent } from './connection-status-filter/connection-status-filter.component';
import { DateRangeFilterComponent } from './date-range-filter/date-range-filter.component';
import {
  EnvGroupFilterComponent,
  GroupEnvGroupFilterComponent,
  SourceEnvGroupFilterComponent,
} from './env-group-filter/env-group-filter.component';
import {
  EnvironmentFilterComponent,
  GroupEnvironmentFilterComponent,
} from './environment-filter/environment-filter.component';
import { MessageCodeFilterComponent } from './message-code-filter/message-code-filter.component';
import { ObjectSnapshotStatusFilterComponent } from './object-snapshot-status-filter/object-snapshot-status-filter.component';
import { ObjectSearchFilterComponent } from './object-search-filter/object-search-filter.component';
import {
  M365AppFilterComponent,
  M365FilterComponent,
  ObjectTypeFilterComponent
} from './object-type-filter/object-type-filter.component';
import { OwnershipFilterComponent } from './ownership-filter/ownership-filter.component';
import { PolicyFilterComponent } from './policy-filter/policy-filter.component';
import { ProtectionGroupFilterComponent } from './protection-group-filter/protection-group-filter.component';
import { ProtectionStatusFilterComponent } from './protection-status-filter/protection-status-filter.component';
import { ReportFiltersComponent } from './report-filters/report-filters.component';
import {
  LastRunStatusFilterComponent,
  LastRunStatusLabelFilterComponent,
  RunStatusFilterComponent,
  TaskStatusFilterComponent,
} from './run-status-filter/run-status-filter.component';
import { SlaStatusFilterComponent } from './sla-status-filter/sla-status-filter.component';
import { SnapshotStatusFilterComponent } from './snapshot-status-filter/snapshot-status-filter.component';
import { SnapshotTypeFilterComponent } from './snapshot-type-filter/snapshot-type-filter.component';
import { SourceFilterComponent, SourceListFilterComponent } from './source-filter/source-filter.component';
import { SystemFilterComponent } from './system-filter/system-filter.component';
import { TargetNameFilterComponent } from './target-name-filter/target-name-filter.component';
import { TargetTypeFilterComponent } from './target-type-filter/target-type-filter.component';
import {
  TenantsFilterComponent,
  TenantsFilterSingleSelectionComponent,
} from './tenants-filter/tenants-filter.component';

@NgModule({
  declarations: [
    ActivityTypeFilterComponent,
    BackupStatusFilterComponent,
    ConnectionStatusFilterComponent,
    DateRangeFilterComponent,
    EnvGroupFilterComponent,
    EnvironmentFilterComponent,
    GroupEnvGroupFilterComponent,
    GroupEnvironmentFilterComponent,
    LastRunStatusFilterComponent,
    LastRunStatusLabelFilterComponent,
    M365AppFilterComponent,
    M365FilterComponent,
    MessageCodeFilterComponent,
    ObjectSearchFilterComponent,
    ObjectSnapshotStatusFilterComponent,
    ObjectStatusFilterComponent,
    ObjectTypeFilterComponent,
    OwnershipFilterComponent,
    PolicyFilterComponent,
    ProtectionGroupFilterComponent,
    ProtectionStatusFilterComponent,
    ReportFiltersComponent,
    RunStatusFilterComponent,
    SlaStatusFilterComponent,
    SnapshotStatusFilterComponent,
    SnapshotTypeFilterComponent,
    SourceEnvGroupFilterComponent,
    SourceFilterComponent,
    SourceListFilterComponent,
    SystemFilterComponent,
    TaskStatusFilterComponent,
    TargetNameFilterComponent,
    TargetTypeFilterComponent,
    TenantsFilterComponent,
    TenantsFilterSingleSelectionComponent,
  ],
  imports: [CommonModule, CohesityHelixModule],
  exports: [ReportFiltersComponent],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },
  ],
})
export class FiltersModule {}
