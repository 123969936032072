import { ProtectionSource } from '@cohesity/api/v1';

/**
 * Check if the object is RDS PostgreSQL.
 *
 * @param object The object
 * @returns true, if RDS PostgresSQL.
 */
export const isRdsPostgres = (object: ProtectionSource): boolean => {
  const awsProtectionSource = object?.awsProtectionSource;
  const { dbEngineId = '', type } = awsProtectionSource ?? {};

  // The object DB is an postgres DB if the dbEngineId starts with 'postgres'.
  // Or if the type is 'kRDSPostgresDb'.
  // TODO: Need YAML for adding 'kRDSPostgresDb' to the type.
  return dbEngineId.startsWith('postgres') || type as any === 'kRDSPostgresDb';
};

/**
 * Checks if the object is RDS PostgreSQL DB.
 *
 * @param object The object
 * @returns true, if RDS PostgreSQL db.
 */
export const isRdsPostgresDb = (object: ProtectionSource): boolean => {
  const awsProtectionSource = object?.awsProtectionSource;
  const type = awsProtectionSource?.type;

  // TODO: Need YAML for adding 'kRDSPostgresDb' to the type.
  return type === 'kRDSPostgresDb' as any && isRdsPostgres(object);
};

/**
 * Check if the object is RDS PostgresSQL DB instance. Used to add credentials.
 *
 * @param object The object
 * @returns true, if RDS PostgreSQL db instance.
 */
export const isRdsPostgresDbInstance = (object: ProtectionSource): boolean => {
  const awsProtectionSource = object?.awsProtectionSource;
  const type = awsProtectionSource?.type;

  return type === 'kRDSInstance' && isRdsPostgres(object);
};

/**
 * Check if the object is Aurora PostgreSQL.
 *
 * @param object The object
 * @returns true, if Aurora PostgresSQL.
 */
export const isAuroraPostgres = (object: ProtectionSource): boolean => {
  const awsProtectionSource = object?.awsProtectionSource;
  const { dbEngineId = '', type } = awsProtectionSource ?? {};

  // The object DB is an postgres DB if the dbEngineId starts with
  // 'aurora-postgresql' Or if the type is 'kAuroraClusterPostgresDb'.
  // TODO: Need YAML for adding 'kAuroraClusterPostgresDb' to the type.
  return dbEngineId.startsWith('aurora-postgresql') || type as any === 'kAuroraClusterPostgresDb';
};

/**
 * Checks if the object is Aurora PostgreSQL DB.
 *
 * @param object The object
 * @returns true, if Aurora PostgreSQL db.
 */
export const isAuroraPostgresDb = (object: ProtectionSource): boolean => {
  const awsProtectionSource = object?.awsProtectionSource;
  const type = awsProtectionSource?.type;

  // TODO: Need YAML for adding 'kAuroraClusterPostgresDb' to the type.
  return type === 'kAuroraClusterPostgresDb' as any && isAuroraPostgres(object);
};

/**
 * Check if the object is Aurora PostgresSQL DB instance.
 *
 * @param object The object
 * @returns true, if Aurora PostgreSQL db instance.
 */
export const isAuroraPostgresDbInstance = (object: ProtectionSource): boolean => {
  const awsProtectionSource = object?.awsProtectionSource;
  const type = awsProtectionSource?.type;

  return type === 'kAuroraCluster' && isAuroraPostgres(object);
};

/**
 * Check if the object is PostgreSQL (RDS or Aurora).
 *
 * @param object The object
 * @returns true, if RDS or Aurora PostgresSQL.
 */
export const isPostgres = (object: ProtectionSource): boolean => isRdsPostgres(object) || isAuroraPostgres(object);

/**
 * Check if the object is PostgreSQL DB (RDS or Aurora).
 *
 * @param object The object
 * @returns true, if RDS or Aurora PostgresSQL DB.
 */
export const isPostgresDb = (object: ProtectionSource): boolean =>
  isRdsPostgresDb(object) || isAuroraPostgresDb(object);

/**
 * Check if the object is PostgreSQL DB Instance(RDS or Aurora).
 *
 * @param object The object
 * @returns true, if RDS or Aurora PostgresSQL DB instance.
 */
export const isPostgresDbInstance = (object: ProtectionSource): boolean =>
  isRdsPostgresDbInstance(object) || isAuroraPostgresDbInstance(object);
