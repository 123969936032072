// Component: Cluster Details of CE to be deployed

;(function(angular) {
  'use strict';

  angular.module('C.cloudEditionManager')
    .component('ceClusterDetails', {
      bindings: {
        /**
         * nodeTypes to be populated in the dropdown
         *
         * @type   {Object}
         */
        nodeTypes: '<',

        /**
         * the model object to be populated by the component
         *
         * @type   {Object}
         */
        deploymentConfig: '=',

        /**
         * the cloud type for CE (aws/azure)
         *
         * @type   {String}
         */
        cloudType: '<',

        /**
         * if available, show a dropdown to select the cluster image version
         *
         * @type   {String[]}
         */
        clusterImages: '<',

        /**
         * The regions available for AWS S3 Bucket
         */
        regions: '<',

        /**
         * whether to show 'Cloud Tier' option or not
         *
         * @type   {Boolean}
         */
        showCloudTier: '<',

        /**
         * whether to show 'Fault Tolerance' option or not
         *
         * @type   {Boolean}
         */
        showFaultToleranceToggle: '<',

        /**
         * whether to show fault tolerance warning or not
         *
         * @type   {Boolean}
         */
        showFaultToleranceWarning: '<',

        /**
         * whether to show availability zone field or not
         *
         * @type   {Boolean}
         */
        showAvailabilityZone: '<',

        /**
         * List of zones for the selected region.
         *
         * @type   {String[]}
         */
        zoneDetails: '<',

        /**
         * Whether to show cluster type radio button or not.
         *
         * @type   {Boolean}
         */
        showClusterType: '<',
      },
      templateUrl: 'app/platform/cloud-edition-manager/ce-cluster-details.html',
      controller: 'ceClusterDetailsCtrl',
    })
    .controller('ceClusterDetailsCtrl', ceClusterDetailsCtrlFn);

  /**
   * @ngdoc component
   * @name C.cloudEditionManager:ceClusterDetails
   * @function
   *
   * @description
   * Specify cluster details of CE to be deployed
   */
  function ceClusterDetailsCtrlFn(_, cUtils, FORMATS, FEATURE_FLAGS, CLUSTER_TYPE, INSTANCE_TYPE,
    AVAILABILITY_ZONE, CloudEditionManagerService) {

    var $ctrl = this;
    var faultToleranceNodesNumber = {
      azure: 3,
      gcp: 3,
    };

    _.assign($ctrl, {
      // properties
      clusterNameFormat: {
        gcp: FORMATS.gcpVMName,
        aws: FORMATS.awsVMName,
        azure: FORMATS.azureVMName,
      },
      FORMATS: FORMATS,
      FEATURE_FLAGS: FEATURE_FLAGS,
      faultToleranceEnabled: true,
      xlNodeSupportEnvs: ['aws', 'azure', 'gcp'],
      clusterType: CLUSTER_TYPE.kLegacy,
      availabilityZones: [],
      imageList: [],

      // methods
      $onInit: $onInit,
      getClusterImages: getClusterImages,
      getSetFaultTolerance: getSetFaultTolerance,
      setClusterImage: setClusterImage,
      updateFaultTolerance: updateFaultTolerance,
      validateDNS: validateDNS,
      isSSDv2NodeType: isSSDv2NodeType,
      filterNodeType: filterNodeType,
      filterNodeTypesForAzure: filterNodeTypesForAzure,
      filterNodeTypesForGcp: filterNodeTypesForGcp,
      filterNodeTypesForAws: filterNodeTypesForAws,
      processPrivateKey: processPrivateKey,
      getClusterImageVersions: getClusterImageVersions,
    });

    /**
     * getter/setter for fault tolerance.
     * Sets a numerical value for toggle on/off
     *
     * @method  getSetFaultTolerance
     * @param   {Boolean}  newVal  The value to be set for faultTolerance
     * @return  {Boolean}  The 'booleanized' value of faultTolerance
     */
    function getSetFaultTolerance(newVal) {
      if (_.isBoolean(newVal)) {
        $ctrl.deploymentConfig.faultTolerance = newVal ? 1 : 0;
      }

      return !!$ctrl.deploymentConfig.faultTolerance;
    }

    /**
     * When the number of nodes is selected, enable/disable fault tolerance
     * toggle accordingly
     *
     * @method  updateFaultTolerance
     */
    function updateFaultTolerance() {
      if ($ctrl.showFaultToleranceToggle) {
        $ctrl.faultToleranceEnabled = $ctrl.deploymentConfig.numInstances
          >= faultToleranceNodesNumber[$ctrl.cloudType];

        $ctrl.deploymentConfig.faultTolerance =
          $ctrl.faultToleranceEnabled ? 1 : 0;
      }
    }

    /**
     * Function to enable free text in image selection dropdown
     *
     * @method  getClusterImages
     * @param   {String}    search  The string typed in dropdown
     * @return  {Object[]}  The list of images to display in dropdown
     */
    function getClusterImages(search) {
      var images = _.map($ctrl.imageList, 'version');
      // If free text is not available in list, add it
      if (search && images.indexOf(search) === -1) {
        images.unshift(search);
      }

      return images;
     }

    /**
     * Function to set clusterImage into deploymentConfig
     *
     * @method  setClusterImage
     * @param   {String}    search  The selected clusterImage
     */
    function setClusterImage(clusterImage) {
      var image = _.find($ctrl.imageList, ['version', clusterImage]);

      if (image) {
        $ctrl.deploymentConfig.clusterImage = angular.copy(image);
      } else {
        $ctrl.deploymentConfig.clusterImage = clusterImage;
      }
    }

    /**
     * Validate the names of dns
     *
     * @method  validateDNS
     */
    function validateDNS() {
      // set the flag if even one invalid dns is found
      $ctrl.invalidDNS =
        !!_.find($ctrl.deploymentConfig.dnsServer, function findDNS(dns) {
          return !FORMATS.IPv4AndIPv6.test(dns);
        });
    }

    /**
     * When SSDv2 node type is selected, the show availability zone
     * fields, else hide it for azure CE.
     *
     * @method  isSSDv2NodeType
     * @param   {String}    selectedNodeType  The selected node type name.

     */
    function isSSDv2NodeType(selectedNodeType) {
      if (selectedNodeType) {
        // Show availabilityZone field only for ssdv2 node types and if zone list is present.
        $ctrl.showAvailabilityZone = $ctrl.availabilityZones.length &&
          (selectedNodeType.SSDTierDiskType === INSTANCE_TYPE.kPremiumV2 ||
          selectedNodeType.HDDTierDiskType === INSTANCE_TYPE.kPremiumV2);

        $ctrl.deploymentConfig.availabilityZone = null;
        }
    }

    /**
     * Filter node type values based on selected cluster type.
     *
     * @method  filterNodeType
     */
    function filterNodeType() {
      switch ($ctrl.cloudType) {
        case 'azure':
          return $ctrl.filterNodeTypesForAzure();
        case 'gcp':
          return $ctrl.filterNodeTypesForGcp();
        case 'aws':
          return $ctrl.filterNodeTypesForAws();
        case 'aws-us-gov':
          return $ctrl.filterNodeTypesForAws();
      }
    }

    /**
     * Filter node type values based on selected cluster type.
     *
     * @method  filterNodeTypesForAzure
     */
    function filterNodeTypesForAzure() {
      if ($ctrl.clusterType === CLUSTER_TYPE.kNextGen) {
        // Set availability zone options from zonedetails.
        _.each($ctrl.zoneDetails, function eachZone(zone) {
          if ($ctrl.deploymentConfig.regionId === zone.regionId && zone.zones) {
            $ctrl.availabilityZones = zone.zones.map(function mapZones(zoneId) {
              return {
                zoneId: zoneId,
                displayName: zoneId === "1" ? AVAILABILITY_ZONE.kZone1 :
                  (zoneId === "2" ? AVAILABILITY_ZONE.kZone2 : AVAILABILITY_ZONE.kZone3),
              };
            });
          }
        });
        // If zone details is unavailable, do not display SSDv2 node types in the list.
        if (!$ctrl.availabilityZones.length) {
          $ctrl.displayNodeTypes = $ctrl.nodeTypes.filter(function getNodeTypes(node) {
            return node.Type.split('_')[0] === INSTANCE_TYPE.kNGCE &&
              node.SSDTierDiskType !== INSTANCE_TYPE.kPremiumV2;
          });
        } else {
          $ctrl.displayNodeTypes = $ctrl.nodeTypes.filter(function getNodeTypes(node) {
            return node.Type.split('_')[0] === INSTANCE_TYPE.kNGCE;
          });
        }
      } else {
        $ctrl.displayNodeTypes = $ctrl.nodeTypes.filter(function getNodeTypes(node) {
          return node.Type.split('_')[0] !== INSTANCE_TYPE.kNGCE;
        });
      }
      // Get images for Azure if flag is enabled.
      if ($ctrl.FEATURE_FLAGS.ngceHeliosDeploymentImageManagement) {
        // Request params for getImageVersions api
        const imageParams = {
          isNgce: $ctrl.clusterType === CLUSTER_TYPE.kNextGen ? true : false,
        }
        $ctrl.getClusterImageVersions('azure', imageParams);
      }
      // Reset node type and availability zone fields on cluster type change.
      $ctrl.deploymentConfig.instanceType = null;
      $ctrl.showAvailabilityZone = false;
      $ctrl.deploymentConfig.availabilityZone = null;
    }


    /**
     * Filter node type values for GCP clusters.
     *
     * @method  filterNodeTypesForGcp
     */
    function filterNodeTypesForGcp() {
      if ($ctrl.clusterType === CLUSTER_TYPE.kNextGen) {
        $ctrl.displayNodeTypes = $ctrl.nodeTypes.filter(function getNodeTypes(node) {
          return node.type.split('_')[0] === INSTANCE_TYPE.kNGCE;
        });
      } else {
        $ctrl.displayNodeTypes = $ctrl.nodeTypes.filter(function getNodeTypes(node) {
          return node.type.split('_')[0] !== INSTANCE_TYPE.kNGCE;
        });
      }
      // Request params for getImageVersions api
      const imageParams = {
        isNgce: $ctrl.clusterType === CLUSTER_TYPE.kNextGen ? true : false,
      }
      // Get images for GCP.
      $ctrl.getClusterImageVersions('gcp', imageParams);
      // Reset node size on cluster type change.
      $ctrl.deploymentConfig.instanceType = null;
    }

    /**
     * Filter node type values for AWS clusters.
     *
     * @method  filterNodeTypesForAws
     */
    function filterNodeTypesForAws() {
      if ($ctrl.clusterType === CLUSTER_TYPE.kNextGen) {
        $ctrl.displayNodeTypes = $ctrl.nodeTypes.filter(function getNodeTypes(node) {
          return node.type.split('_')[0] === INSTANCE_TYPE.kNGCE;
        });
      } else {
        $ctrl.displayNodeTypes = $ctrl.nodeTypes.filter(function getNodeTypes(node) {
          return node.type.split('_')[0] !== INSTANCE_TYPE.kNGCE;
        });
      }
      // Get images for AWS if flag is enabled.
      if ($ctrl.FEATURE_FLAGS.ngceHeliosDeploymentImageManagement) {
        // Request params for getImageVersions api
        const imageParams = {
          isNgce: $ctrl.clusterType === CLUSTER_TYPE.kNextGen ? true : false,
          cloudType: $ctrl.cloudType,
          region: $ctrl.deploymentConfig.region
        }

        $ctrl.getClusterImageVersions('aws', imageParams);
      }
      // Reset node size on cluster type change.
      $ctrl.deploymentConfig.instanceType = null;
    }

    /**
     * Processes private key into a format suitable for backend
     *
     * @method  processPrivateKey
     */
    function processPrivateKey() {
      $ctrl.deploymentConfig.gcpExternalTarget.clientPrivateKey =
        cUtils.normalizeGooglePrivateKey($ctrl.deploymentConfig.gcpExternalTarget.
          clientPrivateKey);
    }

    /**
     * Get image versions for clusters.
     *
     * @method  getClusterImageVersions
     * @param   {String} clusterType  The type of cluster to be deployed(aws/azure/gcp).
     */
    function getClusterImageVersions(clusterType, imageParams) {
      // Check if cluster type is NGCE or not.
      const isNgce = $ctrl.clusterType === CLUSTER_TYPE.kNextGen ? true : false;
      // Empty image list before populating again.
      $ctrl.imageList = [];

      // Get images for cluster deployment depending upon the cluster type.
      CloudEditionManagerService.getClusterImageVersions({
        accessCredentials: $ctrl.deploymentConfig.accessCredentials,
        project: $ctrl.deploymentConfig.projectId,
      }, imageParams, clusterType).then(function gotClusterImageVersions(data) {
        _.each(data.image_details, function eachImage(value, key) {
          // For aws clusters, credate imagelist with amiId.
          if (value.amiId) {
            $ctrl.imageList.push({
              name: value.name,
              version: key,
              type: value.type,
              amiId: value.amiId
            });
          } else if (value.vhdName) { // Imagelist for azure
            $ctrl.imageList.push({
              name: value.name,
              version: key,
              type: value.type,
              vhdName: value.vhdName
            });
          } else { // Imagelist for GCP
            $ctrl.imageList.push({
              name: value.name,
              version: key,
              type: value.type,
            });
          }
        });
      });
    }

    /**
     * Initialize the component
     */
    function $onInit() {
      // Initialize fault tolerance to true
      if ($ctrl.showFaultToleranceToggle) {
        $ctrl.deploymentConfig.faultTolerance = 1;
      }
      // If cloud type is Azure or GCP and if respective feature flags are enabled, show
      // cluster type radio button.
      if (($ctrl.cloudType === 'azure' && $ctrl.FEATURE_FLAGS.enableNgceAzureHeliosDeployment) ||
        ($ctrl.cloudType === 'gcp' && $ctrl.FEATURE_FLAGS.enableNgceGcpHeliosDeployment) ||
        ($ctrl.cloudType === 'aws' && $ctrl.FEATURE_FLAGS.enableNgceAwsHeliosDeployment)) {
          $ctrl.showClusterType = true;
      }
      $ctrl.filterNodeType();
    }
  }

})(angular);