// Component: logout page component
import { environment } from 'src/environments/environment';

(function(angular) {
  'use strict';

  angular.module('C.login')
    .controller('logoutPageCtrl', logoutPageCtrlFn)
    .component('logoutPage', {
      controller: 'logoutPageCtrl',
      templateUrl: 'app/login/logout.html',
    });

  function logoutPageCtrlFn(_, $state, $stateParams, $timeout, UserService, NG_IS_IBM_AQUA_ENV) {
    var $ctrl = this;

    _.assign($ctrl, {
      $onInit: $onInit,
      NG_IS_IBM_AQUA_ENV: NG_IS_IBM_AQUA_ENV,
      copyrightYear: moment().year()
    });

    /**
     * initialization function
     *
     * @method   $onInit
     */
    function $onInit() {
      // Allow showing logout page to IBM Aqua users.
      if (NG_IS_IBM_AQUA_ENV) {

        // IBM Specific referrer and redirect validations.
        const validProductionReferrerDomains = [
          'storage-defender.ibm.com',
          'cohesity.com',
          'cohesitycloud.co'
        ];

        // In the future, these might be different.
        const validProductionRedirectDomains = validProductionReferrerDomains;

        const redirectTimeout = 5000;
        // validate that this request originated from a known referrer.
        const shouldRedirect = environment.production ? validProductionReferrerDomains.some(document.referrer) : true;
        const redirectUrl = $stateParams.redirect;
        if (shouldRedirect && redirectUrl) {
          $timeout(() => {
            const host = new URL(redirectUrl).host;
            if (environment.production ?
              validProductionRedirectDomains.some(domain => domain.startsWith(host)) : true) {
                document.location.href = redirectUrl;
              }
          }, redirectTimeout);
        }
        return;
      }

      // Go to the login page if IDP/SSO login page is not enabled or if the
      // certificate only flow is not enabled. TODO(samual): explain "wny".
      //
      // If login banner is enabled, keep the user on this page as it
      // is undesirable to send them to login immediately as it will
      // pop the banner modal asking user to acknowledge the message,
      // which would be strange coming from a logout attempt.
      if (!(UserService.isIdpLogoutPageEnabled() ||
        UserService.isCertificateOnlyAuthentication() ||
        UserService.isLoginBannerEnabled())) {
        $state.go('login');
      }
    }
  }
})(angular);
