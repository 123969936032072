// MODULE: Cluster Upgrade Page

import { isDodin, isMcm } from "@cohesity/iris-core";

;(function(angular, undefined) {
  'use strict';

  angular.module('C.clusterUpgrade', ['C.connectionStatus', 'C.timelineView'])
    .config(configFn)
    .controller('clusterUpgradeController', clusterUpgradeControllerFn)
    .controller('manualClusterUpgradeController',
      manualClusterUpgradeControllerFn)
    .controller('multipleClusterUpgradeController',
      multipleClusterUpgradeControllerFn)
    .controller('multipleClusterModalUpgradeController',
      multipleClusterModalUpgradeControllerFn)
    .controller('multipleClusterLocationUpdateModalController',
      multipleClusterLocationUpdateModalControllerFn)
    .controller('multipleClusterUnregisterController',
      multipleClusterUnregisterControllerFn)
    .controller('registerClusterController',
      registerClusterControllerFn)
    .controller('SyncDataController',
      SyncDataControllerFn);

  function configFn($stateProvider) {
    $stateProvider
      .state('cluster-upgrade', {
        name: 'Cohesity Cluster Details',
        url: '/admin/cluster-upgrade',
        help: 'admin_cluster_upgrade',
        title: 'Upgrade Cluster',
        canAccess: 'CLUSTER_UPGRADE',
        controller: 'clusterUpgradeController',
        controllerAs: '$ctrl',
        parentState: 'cluster-update',
        templateUrl: 'app/admin/cluster/upgrade/upgrade.html',
        allClustersSupport: {
          singleClusterState: 'cluster-upgrade',
          allClustersState: 'cluster-upgrades',
        },
        resolve: {
          featureFlagEnabled: function(featureFlagsService) {
            return featureFlagsService.enabled('patchV2Enhancements');
          }
        },
        onEnter: function($state, featureFlagEnabled) {
          if (featureFlagEnabled) {
            $state.go('ngCluster-upgrade');
          }
        }
      })
      .state('cluster-upgrades', {
        name: 'Cohesity Helios Cluster Upgrade',
        url: '/platform/clusters-management',
        help: 'helios_cluster_upgrade',
        title: 'Upgrade Clusters',
        canAccess: 'MCM_MODIFY',
        parentState: 'cluster-update',
        redirectTo: function redirector(trans) {
          const clusterManagerUtils = trans.injector().get('NgClusterManagementUtils');
          const rootScope = trans.injector().get('$rootScope')

          // Check if it should show the new page.
          if (clusterManagerUtils.showNgClusterManagementPage()) {
            return 'software-upgrade.cluster-list';
          }

          // Otherwise just continue to the normal route.
          return;
        },
        allClustersSupport: {
          heliosOnly: true,
          singleClusterState: 'cluster.summary',
          allClustersState: 'cluster-upgrades',
        },
        controller: 'multipleClusterUpgradeController as $ctrl',
        templateUrl: 'app/admin/cluster/upgrade/multiple-upgrade.html',
        params: {
          health: { type: 'string', value: '' },
        },
      });
  }

  function clusterUpgradeControllerFn(_, $rootScope, $scope, NODE_REMOVAL_STATE_CLASS,
    NODE_REMOVAL_STATE_LABEL, UpgradeClusterService, featureFlagsService,
    ClusterService, $translate, evalAJAX, cMessage, ngDialogService, PollTaskStatus, NgIrisContextService) {
    var $ctrl = this;
    var upgradePollTimeout;
    var pollingOpts = {
      interval: 10,
      isDoneFn: isPollerDone,
      iteratorFn: function iteratorPoller() {
        return UpgradeClusterService.getPackagesList(false)
      },
      scope: $scope,
    };

    // Will showReviewDialog update after the precheck api call is made
    $rootScope.showReviewDialog = false;

    // setup our tabs
    $ctrl.cTabConfig = [
      {
        name: 'productPatches',
        value: 'cluster-update.maintenance',
      },
      {
        name: 'securityPatches',
        value: 'cluster-update.security-patches',
      },
      {
        name: 'upgrade',
        value: 'cluster-upgrade',
      },
    ];

    _.assign($ctrl, {
      // Vars
      NODE_REMOVAL_STATE_CLASS: NODE_REMOVAL_STATE_CLASS,
      NODE_REMOVAL_STATE_LABEL: NODE_REMOVAL_STATE_LABEL,
      autoAgentUpgradeEnabled: false,
      autoAgentUpgrade: false,

      // Methods
      $onInit: onInit,
      downloadAndInstallPackage: downloadAndInstallPackage,
      manualUpgrade: manualUpgrade,
      confirmPatchUpgrade: confirmPatchUpgrade,
      handleUpgrade: handleUpgrade,
      upgradeCancel: upgradeCancel,
      upgradeConfirm: upgradeConfirm,
      removePackage: removePackage,
      runPrecheck: runPrecheck,
      pollCheckUpgradeStatus: pollCheckUpgradeStatus,
      openCRLUploadDialog: openCRLUploadDialog,
    });

    /**
     * Initialize the upgrade cluster states
     *
     * @method     onInit
     */
    function onInit() {
      $ctrl.showUploadButton = !isMcm(NgIrisContextService.irisContext);
      $ctrl.clusterUpgradeState = UpgradeClusterService.initUpgradeState();
      $ctrl.preUpgradeLoading = true;
      $ctrl.upgradePrecheckFeatureFlag = featureFlagsService.enabled('upgradePrecheck');
      $ctrl.authHeadersFeatureFlag = featureFlagsService.enabled('enableAuthHeadersForClusterUpgrade');
      $ctrl.legacyPatchWithUpgradeFlag = featureFlagsService.enabled('legacyPatchWithUpgrade')
      if($ctrl.upgradePrecheckFeatureFlag) {
        $ctrl.pollCheckUpgradeStatus();
      }
      $ctrl.mcmMode = $rootScope.basicClusterInfo.mcmMode;
      $ctrl.isDodinMode = isDodin(NgIrisContextService.irisContext);
      // Setup the auto agent upgrade flag.
      $ctrl.autoAgentUpgradeEnabled =
        featureFlagsService.enabled('autoAgentUpgrade') &&
        !ClusterService.clusterInfo.multiTenancyEnabled;

        $scope.$watch('$ctrl.clusterUpgradeState.uploadInProgress', function stateUploading(uploadingValue) {
          if (uploadingValue) {
            PollTaskStatus.createPoller(pollingOpts);
          } else {
            $ctrl.pollCheckUpgradeStatus();
          }
        });
    }

    /**
     * Used in createPoller's isDoneFn to determine if the poller is done.
     * Detects if the uploading of a package has completed.
     *
     * @method    isPollerDone
     * @returns   {Boolean}   True if the upgrading is done and the poller
     *                        should stop.
     */
    function isPollerDone() {
      return !$ctrl.clusterUpgradeState.uploadInProgress;
    }

    /**
     * Tells Nexus to start an upgrade operation if all nodes are reachable.
     *
     * @method     upgradeConfirm
     */
    function upgradeConfirm() {
      // Test if atleast one node in cluster is unreachable.
      var unreachable = $ctrl.clusterUpgradeState.nodes.some(function testUnreachability(node) {
        if (!!node._unreachable) {
          return true;
        }
      });

      // If atleast one node unreachable, throw error and cancel upgrade.
      if (unreachable) {
        evalAJAX.errorMessage({
          error: {
            message: $translate.instant('upgradeNodesUnreachableError')
          }
        });
        upgradeCancel();
        return;
      }
      var upgradeParams = {
        clusterId: $ctrl.clusterUpgradeState.clusterInfo.id,
        targetSwVersion: $ctrl.clusterUpgradeState.targetPackage,
      };

      if ($ctrl.autoAgentUpgradeEnabled) {
        upgradeParams.autoAgentUpgrade = $ctrl.autoAgentUpgrade;
      }

      if ($ctrl.manualUpgradeData) {
        upgradeParams.url = $ctrl.manualUpgradeData.url;
      }
      if ($ctrl.upgradePrecheckFeatureFlag) {
        upgradeParams.abortUpgradeOnChecksFailure = $ctrl.abortUpgradeOnChecksFailure;
      }
      if ($ctrl.authHeadersFeatureFlag) {
        upgradeParams.authHeaders = $ctrl.manualUpgradeData.authHeaders
      }
      UpgradeClusterService.upgradeConfirm(upgradeParams);
    }

    /**
     * Transitions the view from package listing to node confirmation.
     *
     * @method     downloadAndInstallPackage
     * @param      {Object}  upgradePackage  Package object
     */
    function downloadAndInstallPackage(upgradePackage) {
      if ($ctrl.upgradePrecheckFeatureFlag && $ctrl.preCheckSupportedOnPlatform) {
        // Open new UI for upgrade review
        openUpgradeReviewDialog(upgradePackage);
      } else {
        $ctrl.isDowntimeRequired = !!upgradePackage.downTime;
        UpgradeClusterService.setTargetPackage(upgradePackage.name);
        UpgradeClusterService.setMode('confirm');
      }
    }

    /**
     * Flow when upload model is closed
     */
    function uploadModalClosedFlow(resp) {
      const showReviewDialog = $ctrl.upgradePrecheckFeatureFlag && $ctrl.preCheckSupportedOnPlatform;
      $ctrl.manualUpgradeData = resp;
      const isValid = resp && !angular.equals(resp, {});
      if (isValid) {
        pollCheckUpgradeStatus();
        if (showReviewDialog) {
          openUpgradeReviewDialog(resp);
        } else {
          UpgradeClusterService.setMode('confirm');
        }
      }
    }

    /**
     * Opens Upgrade Review dialog
     */
    function openUpgradeReviewDialog(upgradePackage) {
      if(upgradePackage?.config?.fileFormDataName !== 'uploadFile') {
        $ctrl.isDowntimeRequired = !!upgradePackage.downTime;
        UpgradeClusterService.setTargetPackage(upgradePackage.targetVersion);
      }

      ngDialogService
        .showDialog('coh-cluster-upgrade-review-dialog', {
          dlgData: {
            clusterUpgradeState: {
              ...$ctrl.clusterUpgradeState,
              isDowntimeRequired: !!upgradePackage.downTime,
            },
          },
        })
        .toPromise()
        .then(function successCloseModal(resp) {
          if (!resp || angular.equals(resp, {})) {
            upgradeCancel();
          } else {
            // Set the auto agent value from checkbox to pass in api param
            $ctrl.autoAgentUpgrade = resp.autoAgentUpgrade;

            // Set the value from radio buttons (continue or stop) to pass in new api param
            $ctrl.abortUpgradeOnChecksFailure = resp.abortUpgradeOnChecksFailure;

            upgradeConfirm();
          }
        }, _.noop);
    }

    /**
     * Delete a given package.
     *
     * @method     removePackage
     * @param      {Object}  upgradePackage  Package object
     */
    function removePackage(upgradePackage) {
      const isPatchPackage = upgradePackage.packageType === 'PATCH';
      const removePackage = isPatchPackage ? ClusterService.removePatch : UpgradeClusterService.removePackage;
      removePackage(upgradePackage.name).then(() => {
        cMessage.success({
          textKey: 'clusterManagement.removePackageSuccessful',
        });
        $ctrl.preUpgradeStatus = 'none';
        UpgradeClusterService.getPackagesList();
      }, evalAJAX.errorMessage);
    }

    /**
     * Opens the CRL Upload Dialog
     */
    function openCRLUploadDialog() {
      ngDialogService.showDialog('upload-crl-file-dialog-component', {
        data: { sourceType: $translate.instant('upgradePackage') },
      });
    }

    /**
     *checks if upgrade patch feature is enabled or not and calls a function accordingly.
     *
     * @method handleUpgrade
     */
    function handleUpgrade() {
      if ($ctrl.legacyPatchWithUpgradeFlag && !$ctrl.mcmMode) {
        confirmPatchUpgrade();
      } else {
        manualUpgrade();
      }
    }

    /**
     * Opens the modal to confirm Patch upgrade with Package upgrade.
     *
     * @method     confirmPatchUpgrade
     */
    function confirmPatchUpgrade() {
      $ctrl.preUpgradeLoading = false;
      ngDialogService
        .showDialog('upgrade-confirmation-dialog', {
          title: $translate.instant('applyPatchWithUpgrade'),
          message: $translate.instant('uploadPatchConfirmation'),
          cancelLabel: $translate.instant('no'),
          confirmLabel: $translate.instant('yes'),
        })
        .toPromise()
        .then(confirmationResponse => {
          if (confirmationResponse) {
            let dialogData = {
              patchUpgrade : true,
              numOfPatches: $ctrl.clusterUpgradeState.numOfPatches
            }
            if ($ctrl.clusterUpgradeState.patchToBeRemoved && $ctrl.clusterUpgradeState.numOfPatches === 1) {
              dialogData = { ...dialogData, existingPatch: $ctrl.clusterUpgradeState.patchToBeRemoved[0] };
            }
            if ($ctrl.clusterUpgradeState.targetPatch) {
              dialogData = { ...dialogData, existingPatch: $ctrl.clusterUpgradeState.targetPatch };
            }
            ngDialogService
              .showDialog('upload-patch-file-dialog', dialogData)
              .toPromise()
              .then(() => {
                UpgradeClusterService.getPackagesList();
                manualUpgrade();
              });
          } else {
            manualUpgrade();
          }
        })
        .catch(_.noop);
    }

    /**
     * Opens the modal to enter cluster manual upgrade details.
     *
     * @method     manualUpgrade
     */
    function manualUpgrade() {
      UpgradeClusterService.getPackagesList();
      if (featureFlagsService.enabled('clusterUpgradeEnhancementsEnabled')) {
        ngDialogService.showGetNewPackageDialog().toPromise().then(
          function successCloseModal(resp){
            if($ctrl.upgradePrecheckFeatureFlag) {
              pollCheckUpgradeStatus();
            }
            uploadModalClosedFlow(resp);
          }, _.noop);
      } else {
        UpgradeClusterService.openUploadModal().then(
          function successCloseModal(resp){
            if($ctrl.upgradePrecheckFeatureFlag) {
              pollCheckUpgradeStatus();
            }
            uploadModalClosedFlow(resp);
          }, _.noop);
      }
    }

    function runPrecheck() {
      $ctrl.preUpgradeStatus = 'running';
      UpgradeClusterService.runUpgradeCheck()
        .then(function (result) {
          $ctrl.testRunInstanceId = result.testRunInstanceId;
          pollCheckUpgradeStatus();
        });
    }

    function pollCheckUpgradeStatus() {
      if (!$ctrl.preUpgradeStatus) {
        $ctrl.preUpgradeStatus = 'none';
      }
      UpgradeClusterService.getUpgradeCheckResults()
        .then(function (result) {
          $ctrl.preCheckSupportedOnPlatform = true;
          $ctrl.preUpgradeLoading = false;
          $ctrl.preUpgradeStatus = result.data.resultStatus?.toLowerCase();
          $ctrl.lastRunTime = result.data.startTimeSecs || null;
          if ($ctrl.preUpgradeStatus === 'running') {
            upgradePollTimeout = setTimeout(() => pollCheckUpgradeStatus(), 30000);
          } else {
            clearTimeout(upgradePollTimeout);
          }
        })
        .catch(err => {
          if (
            err.data?.errorCode === 'KNotImplemented' ||
            (['KInvalidInput', 'KInternalError'].includes(err.data?.errorCode) &&
              err.data?.message.includes('unsupported'))
          ) {
            $ctrl.preCheckSupportedOnPlatform = false;
          } else {
            $ctrl.preCheckSupportedOnPlatform = true;
            // Throw error only if Precheck is supported on platform.
            cMessage.error({
              titleKey: 'clusterUpgrades.precheckError',
              textKey: err?.statusText,
            });
          }
        })
        .finally(() => {
          $ctrl.preUpgradeLoading = false;
          $rootScope.showReviewDialog = $ctrl.upgradePrecheckFeatureFlag && $ctrl.preCheckSupportedOnPlatform;
        });
    }

    /**
     * Resets the view to the listing view
     *
     * @method     upgradeCancel
     */
    function upgradeCancel() {
      UpgradeClusterService.resetUpgrade();
    }

    $scope.$on(
      "$destroy",
      _ => {
        clearTimeout(upgradePollTimeout);
      }
    );
  }

  function manualClusterUpgradeControllerFn(
    _, $rootScope, UpgradeClusterService, $uibModalInstance, cMessage, featureFlagsService, NgIrisContextService) {
    var $ctrl = this;

     _.assign($ctrl, {
      // Vars
      clusterUpgradeState: UpgradeClusterService.getClusterUpgradeState(),
      uploadParams: {
        url: undefined,
        upgradeOptions: 'url',
        authHeaders: [],
      },
      file: undefined,
      upgradePrecheckFeatureFlag: featureFlagsService.enabled('upgradePrecheck'),
      authHeadersFeatureFlag:
        featureFlagsService.enabled('enableAuthHeadersForClusterUpgrade'),
      legacyPatchWithUpgradeFlag: featureFlagsService.enabled('legacyPatchWithUpgrade'),
      authMethods: ['None', 'Basic', 'Custom'],
      selectedAuthMethod: 'None',
      showUploadButton: !isMcm(NgIrisContextService.irisContext),

      basicAuth: {
        username: '',
        password: '',
      },

      customAuths: [{
        key: '',
        value: ''
      }],

      // Methods
      confirmUpgrade: confirmUpgrade,
      upgradeCancel: upgradeCancel,
      downloadPackage: downloadPackage,
      removeAuthHeader: removeAuthHeader,
      addAuthHeader: addAuthHeader,
    });

     /**
     * Closes the manual upgrade cSlider
     *
     * @method     closeUploadSlider
     * @param      {Object}  out   Object containing manual cluster upgrade data
     */
    function closeUploadSlider(out) {
      $uibModalInstance.close(out);
    }

    /**
     * Manual upgrade form submit function. Transitions to node-list
     *
     * @method     confirmUpgrade
     * @param      {Object}  form   The form from the cSlider
     */
    function confirmUpgrade(form) {
      setAuthHeaders();
      if ($ctrl.file && ($ctrl.uploadParams.upgradeOptions === 'file')) {
        UpgradeClusterService.uploadPackage($ctrl.file,$rootScope.showReviewDialog).then(
          closeUploadSlider);
      } else if ($ctrl.uploadParams.upgradeOptions === 'url') {
        if (!$ctrl.uploadParams.url.toLowerCase().endsWith('.tar.gz')) {
          cMessage.error({
            textKey: 'upgradeNodeInvalidTypeError',
          });
        } else {
          getPackage(form);
        }
      }
    }

    /**
     * Gets the package
     *
     * @method     getPackage
     * @param      {Object}  form   The form
     */
    function getPackage(form) {
      var out;
      if ($ctrl.uploadParams.url) {
        out = {
          url: $ctrl.uploadParams.url,
          targetVersion: UpgradeClusterService
            .parseTargetVersion($ctrl.uploadParams.url),
          authHeaders: $ctrl.uploadParams.authHeaders
        };
        UpgradeClusterService.setTargetPackage(out.targetVersion);
        if (!$ctrl.upgradePrecheckFeatureFlag) {
          UpgradeClusterService.setMode('confirm');
        }
        closeUploadSlider(out);
        form.$setPristine();
        form.$setUntouched();
      }
    }

    /**
     * Resets the view to the listing view
     *
     * @method     upgradeCancel
     */
    function upgradeCancel() {
      UpgradeClusterService.resetUpgrade();
      $uibModalInstance.dismiss('user.cancel');
    }

    /**
     * Download package to cluster
     *
     * @method     downloadPackage
     */
    function downloadPackage() {
      setAuthHeaders();
      if ($ctrl.uploadParams.url) {
        UpgradeClusterService.downloadPackage($ctrl.uploadParams.url, $ctrl.uploadParams.authHeaders).then(
          function closeModal() {
            closeUploadSlider();
          }
        );
      }
    }


    /**
     * Add Auth Header
     *
     * @method     addAuthHeader
     */
    function addAuthHeader() {
      $ctrl.customAuths.push({
        key: '',
        value: '',
      })
    }

    /**
     * Remove Auth Header at specified index
     *
     * @method     removeAuthHeader
     */
    function removeAuthHeader(index) {
      $ctrl.customAuths.splice(index,1);
    }

    /**
     * Method to set auth Headers in upload params
     *
     * @method     setAuthHeaders
     */
    function setAuthHeaders() {
      if ($ctrl.selectedAuthMethod === 'Basic') {
        const basicAuthHeaderVal = 'Basic ' +
        window.btoa($ctrl.basicAuth.username + ':' + $ctrl.basicAuth.password);

        $ctrl.uploadParams.authHeaders = [{
          key: 'Authorization',
          value: basicAuthHeaderVal
        }];
      }

      if ($ctrl.selectedAuthMethod === 'Custom') {
        $ctrl.uploadParams.authHeaders = $ctrl.customAuths.filter(
          customAuth => customAuth.key !== '' && customAuth.value !== ''
        ).map((customAuth) => {
          return {
            key: customAuth.key,
            value: customAuth.value,
          };
        });
      }
    }
  }

  function multipleClusterUpgradeControllerFn(_, $rootScope, $timeout,
    $scope, $state, UpgradeClusterService, featureFlagsService, cModal,
    RemoteClusterService, UserService) {

    var $ctrl = this;
    var poller;
    var cleanupRootscopeListener;

    _.assign($ctrl, {
      filters: {
        type: '',
        version: '',
        search: '',
      },
      itemsPerPage: 10,
      pollingInterval: 60000,
      selectAll: false,
      selectedClusters: [],
      types: [],
      unFilteredData: [],
      version: [],
      health: ['healthy', 'critical'],

      $onInit: $onInit,
      filterClusters: filterClusters,
      getClusterUpgradeData: getClusterUpgradeData,
      selectAllCluster: selectAllCluster,
      triggerLocationUpdate: triggerLocationUpdate,
      triggerRegister: triggerRegister,
      triggerSync: triggerSync,
      triggerUnregister: triggerUnregister,
      triggerUpdate: triggerUpdate,
      updateClusterForUpgrade: updateClusterForUpgrade,
      $onDestroy: $onDestroy,
    });

    /**
     * Initialize the multiple upgrade cluster states for Helios
     *
     * @method   $onInit
     */
    function $onInit() {
      // gFlags recipe mangement flag.
      $ctrl.gflagRecipesEnabled = featureFlagsService.enabled('gflagRecipes') &&
        UserService.user.privs.GFLAG_MANAGEMENT;

      getClusterUpgradeData();
    }

    /**
     * Retrieves upgrade information and start the poll
     *
     * @method   getUpgradeInfo
     */
    function getUpgradeInfo() {
      var params = {
        includeUpgradeInfo: true,
      };
      UpgradeClusterService.getMcmClustersForUpgrade(params).then(
        function gotUpgradeInfo(upgradeInfo) {
          $ctrl.selectedClusters = [];
          // Since we poll frequently the selection of the checkboxes needs
          // to be maintained when the new request with the data arrives.
          // Merge the Selection State

          // Loop through the existing clusters.
          $ctrl.clustersInfo.forEach(function mergeInfo(clustr) {
            upgradeInfo.some(function updateCheckbox(clustrInfo) {
              var check = clustr.clusterId === clustrInfo.clusterId;

              // Update only when it is found. Since unregistering might remove
              // the cluster from existing list.
              if (check) {
                clustrInfo._isSelected = clustr._isSelected;

                // If the cluster is selected then add it to the selection list.
                if (clustrInfo._isSelected) {
                  $ctrl.selectedClusters.push(clustrInfo);
                }
              }
              return check;
            });
          });

          $ctrl.clustersInfo = $ctrl.unFilteredData = upgradeInfo;

          // Update with cluster locations
          getClustersLocation($ctrl.clustersInfo, true);
        }
      ).finally(function finishGetCluster() {
        // passing true to force refresh the list.
        RemoteClusterService.broadcastLocalUpdates(true);
        pollForUpgrade();

        // Make sure the cluster list is updated in the context.
        // If the cluster is registered or unregistered, update the user data
        // with the udpated information.
        UserService.updateClusterForUser($ctrl.unFilteredData);
      });
    }

    /**
     * Retrieves the upgrade information of all the clusters
     *
     * @method   getClusterUpgradeData
     */
    function getClusterUpgradeData() {
      $ctrl.loading = true;
      var params = {
        includeUpgradeInfo: true,
      };
      var health = $state.params.health || '';

      if (health) {
        $ctrl.filters.health = health;
      }

      UpgradeClusterService.getMcmClustersForUpgrade(params).then(
        function gotCluster(clustersInfo) {
          $ctrl.clustersInfo = $ctrl.unFilteredData = clustersInfo;

          // if gflag recipe management is enabled
          // we fetch cluster recipes information to show
          // it in the table.
          if ($ctrl.gflagRecipesEnabled) {
            var clusterIdentifiers = clustersInfo.map(function (cluster) {
              return getClusterIdentifiersFromCluster(cluster);
            });

            UpgradeClusterService.getClusterRecipes(clusterIdentifiers)
              .then(function (result) {
                ($ctrl.clustersInfo || []).forEach(function (cluster) {
                  var clusterIdentifier = getClusterIdentifiersFromCluster(cluster);
                  var recipes = (result.data.recipes || []).filter(function (recipe) {
                    return recipe.clusterIdentifiers.includes(clusterIdentifier);
                  });

                  cluster.recipes = recipes.map(function (recipe) {
                    return recipe.name;
                  });
                });
              });
          }

          _getFilters();

          // After clustersInfo is populated, Get locations
          getClustersLocation($ctrl.clustersInfo, Boolean(health));
        }
      ).finally(function finalize() {
        $ctrl.loading = false;
        pollForUpgrade();
      });
    }

    /**
     * Retrieves location information for all clusters
     *
     * @method   getClustersLocation
     */
    function getClustersLocation(clusters, applyFilters) {
      UpgradeClusterService.getClustersLocation().then(
        function clustersLocation(locations) {

          // Group by clusterId for easier lookup
          const locationsById = _.groupBy(locations, 'clusterId');

          // Iterate over clustersInfo and add city to show upfront.
          // This is done instead of direct merge to avoid any potential value overlapping.
          // as the structure is used during upgrade and unregister.
          $ctrl.clustersInfo = clusters.map(function updateLocation(clusterInfo) {
            const locInfo = locationsById[clusterInfo.clusterId];
            if (locInfo) {
              clusterInfo.city = locInfo[0].city;
            };
            return clusterInfo;
          });
        }).finally(function finalize() {
          if(applyFilters) {
            filterClusters();
          }
        });
    }

    /**
     * Poll data if any cluster is scheduled for upgrade.
     *
     * @method   pollForUpgrade
     */
    function pollForUpgrade() {
      var pollForData = $ctrl.unFilteredData.some(function needToPoll(cluster) {
        return cluster.upgradeStatus !== 'kNone';
      });

      // clear any existing poll.
      $timeout.cancel(poller);

      if (pollForData) {
        poller = $timeout(function poll() {
          getUpgradeInfo();
        }, 20000);
      }
    }

    /**
     * Builds the filters based from the cluster data
     *
     * @method   _getFilters
     */
    function _getFilters() {
      $ctrl.types = [];
      $ctrl.version = [];

      $ctrl.types = _.keys(
        _.groupBy($ctrl.clustersInfo, 'type')).filter(function filterType(cl) {
          return cl !== "undefined";
        });
      $ctrl.version = _.keys(
        _.groupBy($ctrl.clustersInfo, '_version')).filter(Boolean);
    }

    /**
     * Filters the search results
     *
     * @method   filterClusters
     */
    function filterClusters() {
      // Do a single level of filter by modifying the filtered data with the
      // filter parameters. This will avoid having seperate method for each
      // filter and avoid complex logic of filter.
      $ctrl.filteringData = true;

      var filterType = $ctrl.filters.type || '';
      var filterVersion = $ctrl.filters.version || '';
      var filterHealth = $ctrl.filters.health || '';

      // Filter based on whether the cluster name is part of the search and
      // cluster type includes filter Type (kCloud, etc) and whether there is a
      // version match.
      // Since array.includes("") is always true, the filter by version needs
      // to have a similar check, if the search is empty then return true.
      $ctrl.clustersInfo =
        $ctrl.unFilteredData.filter(function filterByParams(_cluster) {

          var clusterType = _cluster.type || '';
          var clusterVersion = _cluster._version || '';
          var clusterIsHealthy = _cluster._isHealthy || false;

          return _cluster.clusterName.includes($ctrl.filters.search.trim()) &&
            (filterType === '' || filterType === clusterType) &&
            (filterVersion === '' || filterVersion === clusterVersion) &&
            (filterHealth === '' ||
              (filterHealth === 'healthy' ? clusterIsHealthy : !clusterIsHealthy)
            );
      });

      $ctrl.filteringData = false;
    }

    /**
     * Generates cluster identifier from cluster object
     *
     * @returns string cluster identifier
     */
    function getClusterIdentifiersFromCluster(cluster) {
      return cluster.clusterId + ':' + cluster.clusterIncarnationId;
    }

    /**
     * Selects all upgrable clusters
     *
     * @method   selectAllCluster
     */
    function selectAllCluster() {
      $ctrl.clustersInfo.forEach(function selectAll(cluster) {
        cluster._isSelected = $ctrl.selectAll;
        updateClusterForUpgrade(cluster);
      });
    }

    /**
     * Add or deletes the cluster for upgrade
     *
     * @method  updateClusterForUpgrade
     * @param   {object}   cluster   The cluster object
     */
    function updateClusterForUpgrade(cluster) {
      // As we are merging the state selection, during polling.
      // The ng-model update will trigger this method. We would like to hold
      // only unique elements in the selectedCluster list.
      _.remove($ctrl.selectedClusters, {
        clusterId: cluster.clusterId,
      });
      if (cluster._isSelected) {
        $ctrl.selectedClusters.push(cluster);
      }
    }

    /**
     * Trigger register to the Helios App.
     *
     * @method   triggerRegister
     */
    function triggerRegister() {
      UpgradeClusterService.registerClusterToHeliosApp().finally(getUpgradeInfo);
    }

    /**
     * Triggers a Modal for sync
     *
     * @method   triggerSync
     */
    function triggerSync() {
      var modalConfig = {
        controller: SyncDataControllerFn,
      };

      var options = {
        titleKey: 'clusterManagement.syncTitle',
        contentKey: 'clusterManagement.syncDesc',
      };

      return cModal.standardModal(modalConfig, options);
    }

    /**
     * Triggers a modal for cluster location update
     *
     * @method   triggerLocationUpdate
     */
    function triggerLocationUpdate() {
      UpgradeClusterService.openLocationUpdateModal($ctrl.selectedClusters)
        .finally(getUpgradeInfo);
    }

    /**
     * Triggers a Modal for update
     *
     * @method   triggerUpdate
     */
    function triggerUpdate() {
      UpgradeClusterService.openMultipleUploadModal($ctrl.selectedClusters)
        .finally(getUpgradeInfo);
    }

    /**
     * Triggers a Modal for unregistering the clusters
     *
     * @method   triggerUnregister
     */
    function triggerUnregister() {

      var modalConfig = {
        controller: 'multipleClusterUnregisterController',
        controllerAs: '$ctrl',
        resolve: {
          clusters: function resolveSelectedClusters() {
            return $ctrl.selectedClusters;
          },
        },
      };

      var options = {
        titleKey: 'clusterManagement.unregisterClusters',
        contentKey: 'clusterManagement.unregisterDesc',
        actionButtonKey: 'unregister',
        closeButtonKey: 'cancel',
        buttonIds: {
          ok: 'unregister-cluster-button',
          cancel: 'cancel-unregister-button',
          close: 'close-unregister-modal',
        }
      };

      cModal.standardModal(modalConfig, options).finally(getUpgradeInfo);
    }

    /**
     * Destroy the controller and terminate the poller.
     *
     * @method   $onDestroy
     */
    function $onDestroy() {
      (cleanupRootscopeListener || angular.noop)();
      $timeout.cancel(poller);
    }

    // The alt-cluster selector will trigger the update when data is polled
    cleanupRootscopeListener =
      $rootScope.$on('refreshClusterList', function updateCluster(e, opt) {
        $ctrl.selectedClusters = [];
        // Merge the Selection State.
        // Loop through the existing Clusters.
        ($ctrl.clustersInfo || []).forEach(function mergeInfo(clustr) {
          (opt.clusters || []).some(function updateCheckbox(clustrInfo) {
              var check = clustr.clusterId === clustrInfo.clusterId;
              if(check) {
                clustrInfo._isSelected = clustr._isSelected;
                // Push the updated clusterInfo
                if (clustrInfo._isSelected) {
                  $ctrl.selectedClusters.push(clustrInfo);
                }
              }
              return check;
          });
      });

      $ctrl.clustersInfo = $ctrl.unFilteredData = opt.clusters;
      filterClusters();
      _getFilters();
    });

    // When transistion from state, stop the poller.
    // The angularjs ui-router does not trigger the controller.$onDestroy
    // when $scope is still on.
    // https://github.com/angular/angular.js/issues/15073#issuecomment-245270829
    $scope.$on('$destroy', function stateChangeStarted() {
      $timeout.cancel(poller);
    });
  }

  function registerClusterControllerFn(_, $rootScope, $uibModalInstance,
    evalAJAX, cMessage, HeliosService) {
    var $ctrl = this;
    _.assign($ctrl, {
      close: close,
      config: {},
      frmRegister: {},
      heliosOnPremMode: _.get($rootScope, 'basicClusterInfo.mcmOnPremMode', false),
      ok: registerCluster,
      submitting: false,
      mfa: false,
      otpTypes: [
        {
          label: 'access.mfa.authenticator.otp',
          value: 'Totp'
        },
        {
          label: 'access.mfa.email.otp',
          value: 'Email'
        }
      ]
    });

    /**
     * Triggers a cluster registration with Helios on-prem app.
     *
     * @method   registerCluster
     */
    function registerCluster() {
      if (!$ctrl.frmRegister.$valid) {
        return;
      }

      // special case for heliosOnPrem mode
      if ($ctrl.heliosOnPremMode && $ctrl.config.otpTypeSelected) {
        $ctrl.config.otpType = $ctrl.config.otpTypeSelected.value;
      }

      $ctrl.submitting = true;
      HeliosService.registerClusterToApp($ctrl.config)
        .then(
          function registrationSuccess() {
            cMessage.success({
              textKey: 'clusterManagement.registerClusterSuccessful',
            });
            $ctrl.submitting = false;
            $uibModalInstance.close();
          },
          $ctrl.heliosOnPremMode ? registrationError : evalAJAX.errorMessage
        )
        .finally(function finalize() {
          $ctrl.submitting = false;
        });
    }

    /**
     * Closes the upgrade modal
     *
     * @method   close
     */
    function close() {
      $uibModalInstance.close();
    }

    /**
     * @param error registration error response
     */
    function registrationError(error) {
      // sample mfa error data for reference
      // {
      //   "errorCode": "KInternalError",
      //   "message": "Access denied. [7]:Please specify the mandatory parameters."
      // }
      // checking if registration needs MFA security code
      const hasValidErrorMessage = _.get(error, 'data.message', false);
      if (hasValidErrorMessage && error.data.message.includes('mandatory parameters')) {
        $ctrl.mfa = true;
      } else {
        evalAJAX.errorMessage(error);
      }
    }
  }

  function multipleClusterUnregisterControllerFn($uibModalInstance, cMessage,
    HeliosService, clusters, evalAJAX, RemoteClusterService) {
    var $ctrl = this;

    _.assign($ctrl, {
      ok: unregisterClusters,
    });

    function unregisterClusters() {
      var deleteParams = {
        clusterUids: [],
      };

      clusters.forEach(function prepareDeleteParam(cluster) {
        deleteParams.clusterUids.push({
          clusterId: cluster.clusterId,
          clusterIncarnationId: cluster.clusterIncarnationId,
        });
      });

      $ctrl.submitting = true;
      HeliosService.unregisterMcmCluster(deleteParams).then(
        function unregistered(unregisterInfo) {
          var hasSuccessfulUnregister =
            !!_.get(unregisterInfo, 'successfulClusterIds.length', 0);
          var hasFailedUnregister =
            !!_.get(unregisterInfo, 'failedClusterIds.length', 0);
          var quorumErrorMessage = _.get(unregisterInfo, 'quorumErrorMessage');
          switch (true) {
            // Partially Successful
            case hasSuccessfulUnregister && hasFailedUnregister:
              var clusterName = [];
              unregisterInfo.failedClusterIds.forEach(
                function getClusterName(id) {
                  var currClstr = _.find(clusters, { clusterId: id });
                  if (currClstr) {
                    clusterName.push(currClstr.clusterName);
                  }
              });
              cMessage.warn({
                textKey: 'clusterManagement.unregisterPartial',
                textKeyContext: {
                  clusterNames: clusterName.join(','),
                },
              });
              break;
            // Successfully unregistered
            case hasSuccessfulUnregister:
              cMessage.success({
                textKey: 'clusterManagement.unregisterSuccessful',
              });
              break;
            // Failed to unregister
            case hasFailedUnregister:
              cMessage.error({
                textKey: quorumErrorMessage ? quorumErrorMessage : 'clusterManagement.unregisterFailed',
              });
              break;
          }

          // passing true to force refresh the list.
          RemoteClusterService.broadcastLocalUpdates(true);
        }, evalAJAX.errorMessage).finally(function finalize() {
          $uibModalInstance.close();
          $ctrl.submitting = false;
        });
    }

  }

  function multipleClusterModalUpgradeControllerFn(_, $uibModalInstance, moment,
    ClusterService, UpgradeClusterService, DateTimeService, evalAJAX, cMessage,
    clusters) {
    var $ctrl = this;

    _.assign($ctrl, {
      frmUpload: {},
      loadingData: false,
      preUpgradeLoading: false,
      ltsTimeline: [],
      mcmOnPremMode: ClusterService.basicClusterInfo.mcmOnPremMode,
      minDate: DateTimeService.beginningOfDay(new Date()),
      scheduleUpgradeTypes: ['now', 'later'],
      selectedUpgrade: ClusterService.basicClusterInfo.mcmOnPremMode ?
        'custom' : 'lts',
      selectedVersion: {},
      showMore: false,
      submittingUpgrade: false,
      timeline: [],
      timelineTitle: ['selectUpgrade', 'releaseDate', 'support'],
      upgrade: {
        clusters: [],
        url: '',
      },

      $onInit: $onInit,
      checkScheduleEnabled: checkScheduleEnabled,
      ok: confirmMultipleUpgrade,
      cancel: close,
    });

    /**
     * Init the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      var fromUpgradeVersion = '';
      var param = {};
      clusters.forEach(function getSoftwareVersion(cluster) {
        // Determine upgrade version
        fromUpgradeVersion = fromUpgradeVersion < cluster._version ?
          cluster._version : fromUpgradeVersion;

        // the default values for the cluster type
        cluster._scheduledType = $ctrl.scheduleUpgradeTypes[0];
        cluster._scheduledDate = new Date();
      });

      $ctrl.clusters = clusters;

      // No need to get the cluster upgrade Path as there are not available with
      // Helios OnPrem.
      if (ClusterService.basicClusterInfo.mcmOnPremMode) {
        return;
      }

      param.from = fromUpgradeVersion;
      param.clusterIds = clusters.map(function getClusterId(cluster) {
        return cluster.clusterId;
      });

      $ctrl.loadingData = true;
      UpgradeClusterService.getPossibleUpgradePath(param).then(
        function getPossiblePath(versions) {

          // Get the Upgrade path to be shown and filter only LTS.
          $ctrl.timeline = _.assign([], versions);
          $ctrl.ltsTimeline = _.filter(_.assign([], versions), '_isLts');
        }, evalAJAX.errorMessage
      ).finally(function finalizeVersion() { $ctrl.loadingData = false; });
    }


    /**
     * Return whether scheduled show more needs to be disabled or not.
     *
     * @method   checkScheduleEnabled
     * @return   {boolean}   disable schedule see-more or not
     */
    function checkScheduleEnabled() {
      // 1. If 'All' is selected, there needs to be at least one release
      // 2. If 'LTS' is selected, there needs to be at least one LTS release
      // 3. If 'Custom' enable it
      return ($ctrl.selectedUpgrade === 'all' && !$ctrl.timeline.length) ||
        ($ctrl.selectedUpgrade === 'lts' && !$ctrl.ltsTimeline.length);
    }

    /**
     * Submit the multiple upgrade to the bulletin
     *
     * @method   confirmMultipleUpgrade
     */
    function confirmMultipleUpgrade() {
      if (!$ctrl.frmUpload.$valid) {
        return;
      }

      $ctrl.submitting = true;
      $ctrl.clusters.forEach(function generateClusterRequest(cluster) {
        // Determine the schedule time based on the type, if the scheduleType
        // is now the value is undefined in which case the backend takes care
        // of the logic to determine the upgrade time, else the date to be
        // scheduled is sent.
        var scheduleDateTime;

        if (cluster._scheduledType === 'later') {
          scheduleDateTime =  moment(cluster._scheduledDate)
            .set(cluster._scheduledTime).valueOf();
        }

        $ctrl.upgrade.clusters.push({
          clusterId: cluster.clusterId,
          incarnationId: cluster.clusterIncarnationId,
          timestampMsecs: scheduleDateTime,
        });
      });

      // If it's LTS or All release decorate the upgrade with the correct value
      if ($ctrl.selectedUpgrade !== 'custom') {
        $ctrl.upgrade.targetSwVersion = $ctrl.selectedVersion._version;
        var selectedPackage = _.find($ctrl.selectedVersion.deliverableObjects, {
          deliverableType: 'Upgrade Package',
        });
        $ctrl.upgrade.url = selectedPackage ? selectedPackage.fastLink : '';
      } else {
        $ctrl.upgrade.targetSwVersion = undefined;
      }

      $ctrl.submittingUpgrade = true;

      UpgradeClusterService.upgradeSelectedClusters($ctrl.upgrade)
        .then(
          function successSubmittedUpgrade() {
            cMessage.success({
              textKey: 'upgradeScheduledForClusters',
            });
            $uibModalInstance.close();
          }, evalAJAX.errorMessage
        )
        .finally(function finalizeUpgrade() {
          $ctrl.submitting = false;
        });
    }

    /**
     * Closes the upgrade modal
     *
     * @method   close
     */
    function close() {
      $uibModalInstance.close();
    }
  }

  function multipleClusterLocationUpdateModalControllerFn(_, $uibModalInstance,
    UpgradeClusterService, cMessage, evalAJAX, clusters) {
    var $ctrl = this;

    _.assign($ctrl, {
      frmUpload: {},
      loading: false,
      submitting: false,
      selectedLocation: '',

      $onInit: $onInit,
      searchCities: searchCities,
      ok: confirmLocationUpdate,
      cancel: close,
    });

    /**
     * Init the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      // Get list of all countries
      $ctrl.clusters = clusters;
    }


    /**
     * Gets list of cities as per input
     */
    function searchCities(inputText) {
      $ctrl.loading = true;
      const params = {
        search: inputText.toLowerCase(),
        limit: 50,
      };
      return UpgradeClusterService.searchCities(params).then(
        function getStates(response) {
          return response.cities || [];
        }).finally(function finalizeCities() { $ctrl.loading = false; });
    }

    /**
     * Submit the multiple upgrade to the bulletin
     *
     * @method   confirmLocationUpdate
     */
    function confirmLocationUpdate() {
      if (!$ctrl.frmUpload.$valid) {
        return;
      }

      $ctrl.submitting = true;

      // Map and create clusters object for updating location
      const selectedClusters = $ctrl.clusters.map(function getClusters(cluster) {
        return {
          clusterId: cluster.clusterId,
          clusterIncarnationId: cluster.clusterIncarnationId,
        };
      });

      // Create params
      const params = {
        clusters: selectedClusters,
        locationInfo: {
          country: $ctrl.selectedLocation.country,
          state: $ctrl.selectedLocation.state,
          city: $ctrl.selectedLocation.city,
          lat: $ctrl.selectedLocation.lat,
          lon: $ctrl.selectedLocation.lon,
        }
      };

      // Update the location
      UpgradeClusterService.updateClustersLocation(params).then(
        function updatedLocations() {
          cMessage.success({
            textKey: 'locationUpdatedSuccessfully',
          });
          $uibModalInstance.close();
        }, evalAJAX.errorMessage
      ).finally(function finalizeCities() { $ctrl.submitting = false; });
    }

    /**
     * Closes the upgrade modal
     *
     * @method   close
     */
    function close() {
      $uibModalInstance.close();
    }
  }

  /* @ngInject */
  function SyncDataControllerFn($uibModalInstance, HeliosService, evalAJAX,
    cMessage) {
    var $ctrl = this;

    /**
     * Handles the onclick function of the modal.
     */
    $ctrl.ok = function ok() {
      $ctrl.submitting = true;

      HeliosService.syncState().then(
        function syncSuccess() {
          cMessage.success({
            textKey: 'clusterManagement.syncSuccess',
          });
          $uibModalInstance.close();
        },
        evalAJAX.errorMessage,
      ).finally(function syncFinal() {
        $ctrl.submitting = false;
      });
    }
  }

})(angular);
