<cog-banner *ngIf="limitDataShown" status="warn">
  <p>{{translate(limitedDataShownMessageKey, {
      maxRows: maxPrintableRows
    })}}
  </p>
</cog-banner>

<cog-auto-table [columns]="columns"
  [name]="tableName"
  [columnDate]="columnDate"
  [dateFormat]="dateFormat"
  [data]="tableData"
  [showFilter]="!isPrintMedia && !noHeader"
  [translate]="translate"
  [noHeader]="noHeader"
  [alignHeader]="true"
  [paginate]="!isPrintMedia && !noHeader && tableData?.length > 5"
  [isAsyncData]="isAsyncData"
  (filterChange)="onFilterChange($event)">
</cog-auto-table>

<ng-template #editTemplate>
  <div class="settings-menu" (click)="$event.stopPropagation()">
    <cog-table-config-form [translate]="translate"
      [columns]="columns"
      (columnsChange)="onColumnsChange($event)">
    </cog-table-config-form>
  </div>
</ng-template>
