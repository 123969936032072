import { EventEmitter, InjectionToken, Provider, TemplateRef, Type } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { AttributeFilter, Component, ReportPreview } from '@cohesity/api/reporting';
import { Observable } from 'rxjs';

/**
 * The API doesn't include layout data yet - this is a temporary structure
 * to decorate the response with a layout property.
 */
export interface ReportPreviewWithLayout extends ReportPreview {
  /**
   * The report sections and items
   */
  layout: ReportSection[];

  /**
   * Report title.
   */
  title?: string;

  /**
   * Report Id.
   */
  id?: string;

  /**
   * Report timezone.
   */
  timezone?: string;

  /**
   * Report filters.
   */
  filters?: any;

  /**
   * When present, this is an observable stream that will add udpdate as each component
   * is loaded separately.
   */
  components$?: Observable<Component[]>;
}

/**
 * The export target type for the report - there are slightly different options based on where
 * the report will be rendered.
 */
export type ReportViewType = 'screen' | 'print' | 'email';

/**
 * Measure Filter interface used in CustomChartInput.measures.
 */
export interface MeasurementFilter {
  /**
   * The key for a property to match against.
   */
  filterKey: string;

  /**
   * The expected filter value.
   */
  value: string | number;
}

export interface CustomChartInput {
  /**
   * The main value being measured in the chart. There can potentially be multiple values.
   * In a two dimensional chart, this is the Y Axis.
   */
  measurements: {
    /**
     * The key on the data response containing the value
     */
    valueKey: string;

    /**
     * The key on the data response containing the total value
     * Used for percentage calculation in statlist
     */
    totalKey?: string;

    /**
     * The data type - usually this is a number, but there may be special formatting cases
     * within the charts that need special rendering.
     */
    dataType?: string;

    /**
     * Aggregations are currently only supported, when dimensions are not specified.
     */
    aggregation?: 'sum' | 'count' | 'percent';

    /**
     * Use a simple filter for whether to include the data for a row or not.
     * This will only include if the value of the filterKey matches the specified value.
     */
    filter?: MeasurementFilter;

    /**
     * Supports OR operation on a list of filters defined here.
     * Note that when both filter and filters are specified, it is AND operation between them.
     */
    filters?: MeasurementFilter[];
  }[];

  /**
   * Dimensions to use for showing data. When no dimensions are specified, the measurement
   * will be a single value. If one is specified, it will be treated as an x axis, and if
   * two are specified, it will be used as additional grouping for the data.
   */
  dimensions?: {
    /**
     * The data type - For dimensions, this is usually a string, but this should be set to
     * 'date' for time range charts.
     */
    dataType?: string;

    /**
     * Key too us to reference the dimension
     */
    dimensionKey: string;

    /**
     * If set, this will sort the dimension values and choose the top X number.
     */
    maxValues?: number;
  }[];
}

/**
 * An array of measurement items for chart input
 */
export type Measurements = CustomChartInput['measurements'];

/**
 * A single measurement for chart input
 */
export type Measurement = Measurements[number];

/**
 * An array of dimension values for chart input
 */
export type Dimensions = Required<CustomChartInput>['dimensions'];

/**
 * A single dimension value for chart input.
 */
export type Dimension = Dimensions[number];

/**
 * Each report section consists of a set of items which will be layed out in
 * columns within the report.
 */
export interface ReportSection {
  /**
   * A list of report items that will be rendered in this row.
   */
  items: ReportItem[];

  /**
   * The report section's title
   */
  title?: string;

  /**
   * This flag indicates that the table contains the entire report data set, which could be very large.
   * When exporting, we can accept a param to disable showing the report's full data table.
   */
  isFullData?: boolean;
}

/**
 * A report item represents one column within a row, such as a table or chart.
 * The configuration includes the information needed to configure the widget and
 * map inputs from the report's main data section.
 */
export interface ReportItem {
  /**
   * The id of the report item - this will map to a specific component that will render the item in the report.
   */
  id: string;

  /**
   * The name of a component that contains the item's data
   */
  componentNames: string[];

  /**
   * The id of a component in API.
   */
  componentIds?: string[];

  /**
   * Specific configuration params for the report item. Properties on this value will be set as inputs on the report
   * item.
   */
  params?: any;

  /**
   * The report item's title
   */
  title?: string;
}

/**
 * This stores user action, such as sort, select different table,
 * which causes the report component to reload with new parameters.
 */
export interface ReportItemFilter {
  /**
   * Component ID.
   */
  id?: string;

  /**
   * Sort object.
   */
  sort?: Sort;

  /**
   * Page event object.
   */
  pagination?: PageEvent;

  /**
   * Filters change.
   */
  filters?: AttributeFilter[];

  /**
   * This will affect filters but for the entire page.
   */
  parameters?: Record<string, string>;

  /**
   * Search query string.
   */
  searchQuery?: string;
}

/**
 * All report item components should implement this interface. The components used by the items will
 * be set on the component's instance.
 */
export interface ReportItemRenderer {
  /**
   * Whether the user can edit the report.
   */
  allowEdit: boolean;

  /**
   * Perform server side sort, filter if true.
   */
  isAsyncData: boolean;

  /**
   * List of components that are used for this report item.
   */
  components: Component[];

  /**
   * An optional template ref. If this is pressent, it will contain a menu to edit a report item's contents.
   */
  editTemplate?: TemplateRef<any>;

  /**
   * Whether to show the table pagination controls
   */
  isPrintMedia: boolean;

  /**
   * Report filters
   */
  reportFilters: AttributeFilter[];

  /**
   * An event is emitted whenever an item is edits
   */
  itemEdited?: EventEmitter<any>;

  /**
   * Translate function for displaying strings.
   */
  translate: TranslateFn;

  /**
   * Output filter change including sort, pagination and filter so that report
   * page can make API call to get data. For table only.
   */
  itemFilterChange?: EventEmitter<ReportItemFilter>;
}

/**
 * Define a translate function type, which can be passed as an input to the reporting components.
 */
export type TranslateFn = (key: string, params?: any) => string;

/**
 * A map of dataTypes to their renderer components.
 */
export interface ReportItemRenderers {
  [reportItemId: string]: {
    /**
     * The renderer component
     */
    renderer: Type<any>;

    /**
     * The height to use when showing a loading skeleton div.
     * This should be as close to the normal height of the report item as possible.
     */
    loadingHeight: string;
  };
}

/**
 * Injection token used to look up availabe components to render report items.
 */
export const REPORT_ITEM_RENDERERS = new InjectionToken<ReportItemRenderers>('reportItemRenderers');

/**
 * Provide function to simplify specifying report item implementation. Note that this can be used multiple
 * times to include many report item renderers.
 *
 * @param renderers The renderer map
 * @returns The provider object
 */
export function reportItemRendererProvider(renderers: ReportItemRenderers): Provider {
  return {
    provide: REPORT_ITEM_RENDERERS,
    useValue: renderers,
    multi: true,
  };
}

/**
 * Statlist item configuration
 */
export interface StatlistReportItemInput {
  /**
   * The title value to show
   */
  title: string;

  /**
   * Shows date in title if true.
   */
  showTitleDate?: boolean;

  /**
   * Whether to show a chart (generally pie chart) with the statlist item.
   */
  showChart?: boolean;

  /**
   * Whether to translate the title or not
   */
  translate: boolean;

  /**
   * The measurement config. This is used to calculate the value.
   */
  measurement: Measurement;

  /**
   * The component will calculate the value and add it here.
   */
  value?: number | string;
}
