import { SearchServiceApi } from '@cohesity/api/v2';
import { AzureLeafNodeType, envGroups, Environment, Office365LeafNodeType } from 'src/app/shared/constants';
import { cloneDeep } from 'lodash';

/**
 * Interface for global search environment filters.
 */
interface GlobalSearchEnvironmentFilter {
  /**
   * The title of the environment filter.
   */
  titleKey: string;

  /**
   * The name used in stateParams of the environment filter.
   */
  stateParamName: string;

  /**
   * The array of environments for the environment filter.
   */
  environments: Environment[];

  /**
   * The array of types for kO365 environment filter.
   */
  o365Types?: Office365LeafNodeType[];

  /*
   * The array of types for kAzure environment filter.
   */
  azureObjectTypes?: AzureLeafNodeType[];
}

/**
 * Type options for global search which have sub environment options.
 */
export type GlobalSearchGroupedType = 'vm' | 'applications' | 'm365' | 'db' | 'hadoop'
  | 'nas' | 'physical' | 'san' | 'azure';

/**
 * Type options for global search which do not have any sub options.
 */
export type GlobalSearchSingleType = 'kView' | 'kKubernetes' | 'kSfdc';

/**
 * All available environments type for global search type filter.
 */
export type GlobalSearchType = GlobalSearchGroupedType | GlobalSearchSingleType;

/**
 * Available OS types for global search physical server type filter.
 */
export type GlobalSearchOs = 'kLinux' | 'kWindows' | 'kAix' | 'kSolaris' | 'kSapHana' | 'kOther';

/**
 * Available status filters for global search.
 */
export type GlobalSearchStatus = 'protected' | 'unprotected' | 'unavailable';

/**
 * Available last run type for global search.
 */
export type GlobalSearchLastRun =  SearchServiceApi.SearchObjectsParams['lastRunStatusList'][0];

/**
 * Available protection type for global search.
 */
export type GlobalSearchProtectionType = 'kAgent' | 'kNative' | 'kSnapshotManager'
  | 'kRDSSnapshotManager' | 'kAuroraSnapshotManager' | 'kFile' | 'kVolume' | 'kAzureSQL';

/**
 * Common filter options for O365.
 */
const o365TypesCommon = [
  Office365LeafNodeType.kGroup,
  Office365LeafNodeType.kSite,
  Office365LeafNodeType.kPublicFolder,
  Office365LeafNodeType.kTeam
];

/**
 * Filter options for O365. Still use kUser.
 */
const o365TypesUser = [
  Office365LeafNodeType.kUser,
  ...o365TypesCommon,
];

/**
 * Updated Filter options for O365. Use Exchange and OneDrive instead of User.
 */
const o365TypesExchangeAndOneDrive = [
  Office365LeafNodeType.kO365Exchange,
  Office365LeafNodeType.kO365OneDrive,
  ...o365TypesCommon,
];

/**
 * All global search environment filters which have sub environment filters.
 */
export const GlobalSearchEnvironmentFilters: Record<GlobalSearchGroupedType, GlobalSearchEnvironmentFilter> = {
  vm: {
    titleKey: 'virtualMachines',
    stateParamName: 'vm',
    environments: envGroups.vms.filter(environment => ![
      // These two environments are not supported by v2 global search.
      Environment.kHyperVVSS,
      Environment.kVCD,
    ].includes(environment)),
  },
  applications: {
    titleKey: 'applications',
    stateParamName: 'application',
    environments: envGroups.applications.filter(environment => ![
      // This is displayed as "Microsoft 365" independently.
      Environment.kO365,

      // These two environments are not supported by v2 global search.
      Environment.kO365Outlook,
      Environment.kAgent,
    ].includes(environment)),
  },
  m365: {
    titleKey: 'microsoft365',
    stateParamName: 'm365',
    environments: [Environment.kO365],
    o365Types: o365TypesUser,
  },
  azure: {
    titleKey: 'azure',
    stateParamName: 'azure',
    environments: [Environment.kAzure],
    azureObjectTypes: [
      AzureLeafNodeType.kSQLDatabase,
      AzureLeafNodeType.kVirtualMachine,
    ]
  },
  db: {
    titleKey: 'databases',
    stateParamName: 'db',
    environments: envGroups.dbs.filter(environment => ![
      // These two environments are not supported by v2 global search.
      Environment.kAuroraSnapshotManager,
      Environment.kRDSSnapshotManager,
    ].includes(environment)),
  },
  hadoop: {
    titleKey: 'hadoop',
    stateParamName: 'hadoop',
    environments: envGroups.hadoop,
  },
  nas: {
    titleKey: 'nas',
    stateParamName: 'nas',
    environments: envGroups.nas,
  },
  physical: {
    titleKey: 'physicalServers',
    stateParamName: 'physical',
    environments: envGroups.physical,
  },
  san: {
    titleKey: 'san',
    stateParamName: 'san',
    environments: envGroups.san,
  },
};

/**
 * All global search environment filters which have sub environment filters with
 * updated O365 types such as Exchange and OneDrive.
 * TODO: Replace above constant with this one once backend finish updating O365 types.
 */
export const GlobalSearchEnvironmentFiltersExchangeAndOneDrive:
  Record<GlobalSearchGroupedType, GlobalSearchEnvironmentFilter> =
  cloneDeep(GlobalSearchEnvironmentFilters);
GlobalSearchEnvironmentFiltersExchangeAndOneDrive.m365.o365Types = o365TypesExchangeAndOneDrive;
