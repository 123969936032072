<ng-container *ngIf="data?.length">
  <cog-donut-chart
    [customChartOptions]="options"
    [seriesData]="pieChartSeries"
    class="default-chart-theme">
  </cog-donut-chart>
  <div class="default-chart-theme" *ngFor="let column of legendValues">
    <cog-statlist [vertical]="true">
        <cog-statlist-item *ngFor="let item; of column"
          [label]="item.label || item.name"
          labelLegend="highcharts-color-{{ item.index }}">
          <cog-data-renderer [value]="item.value" [dataType]="item.dataType">
          </cog-data-renderer>
        </cog-statlist-item>
    </cog-statlist>
  </div>
</ng-container>
<div *ngIf="!data?.length">{{translate('reporting.noDataAvailable')}}</div>