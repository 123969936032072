import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AttributeFilter, FiltersServiceApi, InFilterParams } from '@cohesity/api/reporting';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseReportFilterComponent } from '../base-report-filter.component';

class BaseSourceFilterComponent extends BaseReportFilterComponent {
  attributeDataType: InFilterParams['attributeDataType'] = 'String';

  constructor(
    filters: FiltersComponent,
    private api: FiltersServiceApi,
    attributeName: string
  ) {
    super(filters, attributeName);
  }

  getFilterValues(): Observable<ValueFilterSelection[]> {
    return this.api.GetResources({ resourceType: 'RegisteredSources' }).pipe(
      map(response => response?.sources || []),
      map(sources =>
        sources.map(source => ({
          label: source.name,
          value: source.uuid,
        }))
      )
    );
  }

  toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter {
    if (!filterValues?.length) {
      return null;
    }
    return {
      attribute: this.property,
      filterType: 'In',
      inFilterParams: {
        attributeDataType: this.attributeDataType,
        stringFilterValues: filterValues.map(value => value.value as string),
        attributeLabels: filterValues.map(value => value.label),
      },
    };
  }

  fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[] {
    return (apiValue?.inFilterParams.stringFilterValues || []).map((value, index) => ({
      label: apiValue.inFilterParams.attributeLabels[index],
      value: value
    }));
  }
}

@Component({
  selector: 'iris-rpt-source-filter',
  templateUrl: './source-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceFilterComponent extends BaseSourceFilterComponent {
  constructor(filters: FiltersComponent, api: FiltersServiceApi) {
    super(filters, api, 'sourceUuid');
  }
}

@Component({
  selector: 'iris-rpt-source-list-filter',
  templateUrl: './source-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SourceListFilterComponent extends BaseSourceFilterComponent {
  attributeDataType: InFilterParams['attributeDataType'] = 'StringArray';

  constructor(filters: FiltersComponent, api: FiltersServiceApi) {
    super(filters, api, 'sourceUuids');
  }
}
