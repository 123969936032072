// COMPONENT: Name or description of this Component

// TODO: Support for restore of SQL VMs was removed in 6.0. However, because
// that change landed late and with the hightened need to minimize unknown
// regressions, all the code support for that is left in tact. Only the SQL
// search query params were changed to remove them from search results.

(function(angular, undefined) {
  'use strict';

  /**
   * This constant determines the threshold of the number of SQL Hosts after
   * which we switch from ui-select to uib-typeahead because of performance
   * concerns. This performance concern with ui-select came to our attention
   * from FI-6420 (ENG-86972).
   *
   * @type   {integer}
   */
  var ALT_HOST_SELECTOR_THRESHOLD = 60;

  angular
    .module('C.dbRestore')
    .controller('restoreDBOptionsController', restoreDBOptionsControllerFn);

  function restoreDBOptionsControllerFn(
    _, $rootScope, $scope, $q, $stateParams, JobRunsService, ViewService,
    SourceService, DateTimeService, RestoreService, SlideModalService,
    evalAJAX, FEATURE_FLAGS, ENV_TYPE_CONVERSION, moment, ngDialogService,
    REGISTRATION_VERIFICATION_STATUS, CloneService, $translate,
    DB_RECOVER_APP_TYPE, ENUM_HOST_TYPE_CONVERSION, ORACLE_BACKUP_TYPE, NgRecoveryService) {

    var $ctrl = this;

    // The SGA configuration for Oracle DB.
    var oracleSgaLimits = {
      // 1 GiB
      minBytes: 1073741824,

      // 100 GiB
      maxBytes: 107374182400,

      // Translation values.
      minMessage: '1' + $translate.instant('filesizeAbbrevations.gigabytes'),
      maxMessage: '100' + $translate.instant('filesizeAbbrevations.gigabytes'),
    };

    _.assign($ctrl, {
      // Controller lifecycle hooks
      $onInit: restoreDBOptionsInit,
      changeCdcEligible: changeCdcEligible,
      changeOracleRecoveryType: changeOracleRecoveryType,
      dbParamsChanged: dbParamsChanged,
      dbParamsConfigured: dbParamsConfigured,
      ENUM_HOST_TYPE_CONVERSION: ENUM_HOST_TYPE_CONVERSION,
      exposeViewToggleChanged: exposeViewToggleChanged,
      generatePfile: generatePfile,
      getTargetsByQuery: getTargetsByQuery,
      hidePfile: hidePfile,
      isRestrictedParamEntered: isRestrictedParamEntered,
      isShowOracleSettingsBox: isShowOracleSettingsBox,
      showOracleChangeDbidOption: showOracleChangeDbidOption,
      showRecreateBctFileWarning: showRecreateBctFileWarning,
      updatePfile: updatePfile,

      // Specifies whether db Params has been changed or not.
      dbParamsUpdated: false,

      // Specifies the list of duplicate pfile db params entered.
      duplicatePfileValues: [],

      // For Oracle clones, the following params control the details of pre/
      // post scripts to be run.
      hasPreScript: false,
      hasPostScript: false,

      // Oracle Shell Environment
      hasShellEnv: false,

      // Specify whether server type is active/passive or not.
      isActivePassive: false,

      // Determines whether pfile is generated or not.
      isPfileGenerated: false,

      // Determines whether restricted params are selected or not.
      isRestrictedParamSelected: false,

      // Specifies whether pfile is loading or not.
      loadingPfile: false,

      // Oracle backup type. Default to ImageCopy.
      oracleBackupType: ORACLE_BACKUP_TYPE.kImageCopy,

      // Oracle host type.
      oracleHostType: undefined,

      // Expose the Oracle SGA limits to template.
      oracleSgaLimits: oracleSgaLimits,

      // Pfile configuration
      pfileConfig: null,

      // Pfile ace editor configuration
      pfileEditorConfig: {
        mode: 'text',
        theme: 'eclipse',
        useWrapMode : true,
        onLoad: function onPfileLoad(editor) {
          // Remove unwanted tab spaces from wrapped lines inside ace-editor.
          editor.session.setOption("indentedSoftWrap", false);
        },
      },

      // Pfile parameters key pair mapping config.
      pfileParamsMapper: {
        restrictedPfileParamMap: [],
        inheritedPfileParamMap: [],
        cohesityPfileParamMap: [],
      },

      // Pfile param types definition.
      pfileParamTypes: [
        {
          key: 'oracleRestore.pfile.defaultParams',
          type: 'cohesityPfileParamMap'
        },
        {
          key: 'oracleRestore.pfile.inheritedParams',
          type: 'inheritedPfileParamMap'
        }
      ],

      // Specifies the list restricted pfile db params entered.
      restrictedPfileValues: [],

      // Specifies if scn range corresponding to selected snapshot
      // could be used for oracle clone or not.
      useScnForRestore: false,

      // Specifies whether nid step has to be performed for Oracle
      // clone workflow via changing the dbid or not.
      changeDbId: false,

      togglePrePostScript: togglePrePostScript,
      toggleUseScnForRestore: toggleUseScnForRestore,
      toggleShellEnv: toggleShellEnv,
      toggleSkipNidStep: toggleSkipNidStep,

      // Pfile config overrided by user
      userDefinedPfileConfig: [],
    });

    angular.extend($scope, {
      // GENERAL SCOPE VARS
      FEATURE_FLAGS: FEATURE_FLAGS,
      fullDateTimeFormat: DateTimeService.getFullDateTimeFormat(),
      isLogChainBroken: false,
      loadingOracleMnmc: false,
      showCredentials: {},
      selectedParentSource: undefined,
      selectedResourcePool: undefined,
      selectedDatastore: undefined,
      selectedNetworkEntity: undefined,
      selectedRacNode: undefined,
      datastores: [],
      resourcePools: [],
      networkEntities: [],
      NFSViews: [],
      dbRestoreParams: {},

      // TEXT STRINGS
      text: $rootScope.text.protectionRecoverySqlSqlOptionsText,

      // SCOPE METHODS
      combinedFileDestinationGetterSetter: combinedFileDestinationGetterSetter,
      datastoreChanged: datastoreChanged,
      getDatastores: getDatastores,
      getNetworkConfigs: getNetworkConfigs,
      getNFSViews: getNFSViews,
      getResourcePools: getResourcePools,
      getSelectedMultiNodeChannelCount: getSelectedMultiNodeChannelCount,
      isShowExplicitDBOpenWarning: isShowExplicitDBOpenWarning,
      showVlanOptions: showVlanOptions,
      networkConfigChanged: networkConfigChanged,
      NFSViewChanged: NFSViewChanged,
      onSelectRacNode: onSelectRacNode,
      oracleHostChanged: oracleHostChanged,
      overwriteOriginalDbToggleChanged: overwriteOriginalDbToggleChanged,
      parentSourceChanged: parentSourceChanged,
      recoverToOriginalServerToggleChanged:
        recoverToOriginalServerToggleChanged,
      resetOptions: resetOptions,
      resourcePoolChanged: resourcePoolChanged,
      restoreToNewLocation: restoreToNewLocation,
      selectDBRestorePoint: selectDBRestorePoint,
      selectDBNodes: selectDBNodes,
      showAagStepsModal: RestoreService.showAagStepsModal,
      showTaskOptions: showTaskOptions,
      sqlHostChanged: sqlHostChanged,
      toggleAdvancedPaths: toggleAdvancedPaths,
      toggleHostCredentials: toggleHostCredentials,
      toggleRenameOptions: toggleRenameOptions,
      transformViewTagString: transformViewTagString,
    });

    // METHODS
    /**
     * Initialize all the things!
     *
     * @method     restoreDBOptionsInit
     */
    function restoreDBOptionsInit() {
      // Initialize data
      _.assign($ctrl, {
        multiNodeChannelFormData: [],

        // Oracle Horizontal UI specific params.
        targetLocation: 'alternate',
      });

      /**
       * Determines if we've entered the options step with pre-populated job,
       * source, and entity information. Typically for abbreviated flows.
       * @type {boolean}
       */
      var hasState = !!($stateParams.jobId && $stateParams.entityId);
      var promise;

      // Set the restore params for the database.
      $scope.dbRestoreParams = $scope._isOracle ?
        $scope.shared.restoreAppObject.restoreParams.oracleRestoreParams :
        $scope.shared.restoreAppObject.restoreParams.sqlRestoreParams;

      if (hasState) {
        // This is an abbreviated flow. Ensure the abbreviated setup is complete
        // before trying to populate the entity model
        promise = setAbbreviatedFlow();
      } else if ($scope.shared && $scope.shared.taskCart &&
        !$scope.shared.taskCart.length) {

        // Cart is empty, we're starting over
        return $scope.startFlowOver();
      } else {
        // Resolve the empty promise since there is nothing to do in this case.
        promise = $q.resolve(true);
      }

      $scope.updateStep();

      // Since getNFSViews is independent of any preselection of options, lets
      // load it immediately for clone flows.
      if ('clone' === $scope.flowType) {
        getNFSViews();
      }

      // Changing views revokes acceptance of the challenge modal. It's only
      // valid while remaining on this view.
      $scope.shared.challengeAccepted = false;
      $scope.shared.overwriteOriginalDatabase = $scope.copyAttachOpts &&
        $scope.copyAttachOpts.overwriteOriginalDatabase;

      // Update options display vars
      // If it's not in resubmit workflow, give the default settings.
      if (!$stateParams.resubmit) {
        angular.extend($scope, {
          // TODO: determine defaults for these new flags based on
          // $scope.shared.task config?
          copyAttachOpts: {
            isClone: $scope.isClone,

            recoverToOriginalServer: false,
            overwriteOriginalDatabase: !$scope.isClone,
            exposeView: false,


            // indicates "Advanced"/"Basic" handling for dataFileDestination &
            // logFileDestination directory inputs.
            syncFileDestinations: true,
            currentSqlHost: undefined,
            currentOracleHost: undefined,

            // To be updated when getSqlEntities() completes
            originalServerUnavailable: false,
          },
        });

        recoverToOriginalServerToggleChanged();
      } else {
        var sqlRestoreParams = $stateParams.restoreParams.restoreAppObjectVec[0]
          .restoreParams.sqlRestoreParams;
        _.merge($scope, {
          shared: {
            overwriteOriginalDatabase: !sqlRestoreParams.newDatabaseName,
            restoreAppObject: {
              restoreParams: {
                sqlRestoreParams: {
                  dataFileDestination: sqlRestoreParams.dataFileDestination,
                  secondaryDataFileDestinationVec: sqlRestoreParams.secondaryDataFileDestinationVec,
                  logFileDestination: sqlRestoreParams.logFileDestination,
                  newDatabaseName: sqlRestoreParams.newDatabaseName,
                  keepCdc: sqlRestoreParams.keepCdc,
                  dbRestoreOverwritePolicy: sqlRestoreParams.dbRestoreOverwritePolicy ? 1 : 0,
                  captureTailLogs: sqlRestoreParams.captureTailLogs,
                }
              }
            }
          },

          copyAttachOpts: {
            isClone: $scope.isClone,

             // If this is an SQL restore without a targetHost, target is original Server.
            recoverToOriginalServer: !$stateParams.restoreParams.restoreAppObjectVec[0]
              .restoreParams.targetHost,
            currentSqlHost: '',
            overwriteOriginalDatabase: !$scope.isClone,
            exposeView: false,

            // indicates "Advanced"/"Basic" handling for dataFileDestination &
            // logFileDestination directory inputs.
            syncFileDestinations: !sqlRestoreParams.secondaryDataFileDestinationVec,
            currentSqlHost: undefined,
            currentOracleHost: undefined,

            // To be updated when getSqlEntities() completes
            originalServerUnavailable: false,
          },
        });

        overwriteOriginalDbToggleChanged();
      }


      // Pre-populates the db-options page with the task on which re-configure
      // is selected.
      _preConfigureRestoreParams();

      if ($scope._isOracle && !$scope.isClone) {
        changeOracleRecoveryType();
      }

      promise.then(function continueSetup() {
        var getAvailableHostEntitiesFn =
          $scope._isSQL ? getSqlEntities : getOracleEntities;

        // Build hash from the attribute map we get from vmDocument
        // TODO(maulik): Update this to accomodate multi item taskCart.
        $scope.attributeHash = $scope.shared.taskCart[0] &&
          buildAttributeHash($scope.shared.taskCart[0].vmDocument.attributeMap);

        // Set the default snapshot to point in time if it is latest snapshot
        if (FEATURE_FLAGS.sqlRestoreLatestPIT && $scope._isSQL &&

          // In case of abbreviated flow, we select the selected snapshot and
          // not the latest snapshot
          !($stateParams.logTimeMs || $stateParams.jobInstanceId) &&

          // PIT does not apply to DB migration tasks
          !$scope.isDbMigration) {
          defaultToLatestPIT();
        }

        getJobSummaries()
          .then(function summariesReceived(resp) {
            return getAvailableHostEntitiesFn().then(function hostReceived(hosts) {
              checkForOriginalServer(hosts);
              if ($scope.isSQL) {
                $ctrl.useUiSelectAltTargets =
                  $scope.sqlEntities.length <= ALT_HOST_SELECTOR_THRESHOLD;
              }

              var parentId = $scope.shared.taskCart[0]._parentId;

              var host = hosts.find(function findHost(host) {
                return _.get(host, 'appEntity.entity.id') === parentId;
              });

              if ($scope._isOracle) {
                $ctrl.oracleHostType = _.get(host, '_appTypeEntity.hostType');

                // Determine if the parent host is of type Windows. If so,
                // set backupType to SBT and only cohesityView is applicable.
                // TODO (Tung): switch to use rmanBackupType instead of
                // using parent host type when backend supports.
                if (_.get(host, '_appTypeEntity.hostType') ===
                  ENUM_HOST_TYPE_CONVERSION.kWindows) {
                  $ctrl.oracleBackupType = ORACLE_BACKUP_TYPE.kSbt;

                  if (!FEATURE_FLAGS.oracleWindowsRestoreEnabled && !$scope.isClone) {
                    $ctrl.targetLocation = 'cohesityView';

                    // Reset the params
                    changeOracleRecoveryType();
                  }
                }

                // Filtering out oracle host entities based on parent host type.
                $scope.oracleEntities = $scope.oracleEntities.filter(
                  function filterEntitiesBasedOnHostType(hostEntity) {
                    if (hostEntity.browseForHost) {
                      return true;
                    }

                    // Oracle Source Host type.
                    const hostType = _.get(hostEntity, '_appTypeEntity.hostType');

                    // Filtering out non kImageCopy backup sets and windows sbt sources
                    // from clone target selection during oracle clone workflow and
                    // guarded with feature flags
                    switch ($scope.isClone) {
                      // Checking out non kImageCopy Oracle backup sets.
                      case $ctrl.oracleBackupType !== ORACLE_BACKUP_TYPE.kImageCopy &&
                        !FEATURE_FLAGS.allowOracleNonImageCopyBackupSets:

                      // Checking out for windows sbt Oracle sources.
                      case hostType === ENUM_HOST_TYPE_CONVERSION.kWindows &&
                        !FEATURE_FLAGS.enableOracleWindowsClone:
                        return $ctrl.oracleHostType !== undefined ? hostType ===
                          $ctrl.oracleHostType : false;

                      // Checking out for solaris Oracle sources.
                      case hostType === ENUM_HOST_TYPE_CONVERSION.kSolaris &&
                        !FEATURE_FLAGS.enableOracleSolarisClone:
                        return $ctrl.oracleHostType !== undefined ? hostType ===
                          $ctrl.oracleHostType : false;
                    }

                    return $ctrl.oracleHostType !== undefined ? hostType ===
                      $ctrl.oracleHostType : true;
                  }
                );
              }
            });
          })
          .finally(function ready() {
            evaluateAvailableOptions();
            $scope.dataReady = true;

            if (showOracleChangeDbidOption()) {
              $scope.dbRestoreParams.skipCloneNid = !$ctrl.changeDbId;
            }
          }, evalAJAX);
      });

      // Initialize time zone to client's time zone.
      $scope.shared.currentTimezone = moment.tz.guess();

      // DO things on $scope destroy. Details within.
      $scope.$on('$destroy', function cleanup() {
        var dbRestoreParamsPath = $scope._isSQL ?
          'sqlRestoreParams' : 'oracleRestoreParams.alternateLocationParams';

        // When leaving this scope, say, to select a different database, reset
        // the new database name. This clears out User defined and pre-populated
        // defaults alike. The assumption is that if the User is selecting a
        // different database, this option should reset.
        _.set(
          $scope.shared.restoreAppObject.restoreParams,
          dbRestoreParamsPath + '.newDatabaseName',
          undefined
        );

        // Clean secondaryDataFileDestinationVec when leaving.
        if ($scope._isSQL &&
          _.get($scope, 'dbRestoreParams.secondaryDataFileDestinationVec')) {
          $scope.dbRestoreParams.secondaryDataFileDestinationVec = undefined;
        }
      });
    }

    /**
     * Determines whether pfile should be hidden or not.
     */
    function hidePfile() {
      return $ctrl.loadingPfile || !$ctrl.isPfileGenerated;
    }

    /**
     * Specifies whether a restricted param is entered or not.
     */
    function isRestrictedParamEntered() {
      if (!$ctrl.userDefinedPfileConfig || !$ctrl.pfileParamsMapper.restrictedPfileParamMap) {
        $ctrl.isRestrictedParamSelected = false;
        $ctrl.restrictedPfileValues = [];
      }

      // Creating a map of restricted parameters to avoid entering
      // restricted parameter.
      const restrictedParams = $ctrl.pfileParamsMapper.restrictedPfileParamMap
        .map(config => config.key);

      // If a restricted param is selected then show warning to the user.
      $ctrl.restrictedPfileValues = $ctrl.userDefinedPfileConfig.filter(
        val => restrictedParams.includes(val.key));
      $ctrl.isRestrictedParamSelected = $ctrl.restrictedPfileValues.length > 0;
    }

    /**
     * Sets the restore/clone default snapshot to latest Point in Time  snapshot
     *
     * @method   defaultToLatestPIT
     */
    function defaultToLatestPIT() {
      var restoreObject = $scope.shared.restoreObject ||
        $scope.shared.taskCart[0];
      var jobUid;

      if (!restoreObject) { return; }

      jobUid = restoreObject._jobUid;
      const params = {
        protectionGroupIds: [`${jobUid.clusterId}:${jobUid.clusterIncarnationId}:${jobUid.objectId}`],
        jobUids: [{
          "clusterId": jobUid.clusterId,
          "clusterIncarnationId": jobUid.clusterIncarnationId,
          "id": jobUid.objectId
        }],
        environment: 'kSQL',
        protectionSourceId: restoreObject._protectionSourceId,
        startTimeUsecs: restoreObject._snapshot.snapshotTimestampUsecs,
        endTimeUsecs: Date.now() * 1000,
      };

      NgRecoveryService.getPITRestorePointsInTimeRange(params).then(
        function getRanges(ranges) {
          // If there are no PIT ranges default to incremental/full snapshot
          if (!ranges.timeRanges[0]) {
            return;
          }

          var pitUsecs = _.last(ranges?.timeRangeInfo?.timeRanges || ranges?.timeRanges).endTimeUsecs;
          var latestSnapshotUsecs = ranges.fullSnapshotInfo[0] ?
            _.last(ranges.fullSnapshotInfo).restoreInfo.startTimeUsecs : 0;

          // If PIT latest snapshot is newer than incremental snapshot, default
          // to the Point in Time value
          if (pitUsecs > latestSnapshotUsecs) {
            $scope.dbRestoreParams.restoreTimeSecs =
              Math.round(pitUsecs / 1000000) - 1;
          }
        }
      );
    }

    /**
     * Callback for the oracle expose view toggle change
     *
     * @member   exposeViewToggleChanged
     */
    function exposeViewToggleChanged() {
      // In case of expose view we would need an extra parameter
      // 'mountPathIdentifier'. Initilaize it.
      if ($scope.shared.copyAttachOpts.exposeView) {
        _.assign($scope.shared.restoreAppObject.restoreParams
          .oracleRestoreParams, {
            oracleCloneAppViewParamsVec : [{ mountPathIdentifier: '' }],
          });
      }
    }

    /**
     * Builds an attribute hash from the attribute map that magneto provides for
     * the object that is selected for restore.
     *
     * @method   buildAttributeHash
     * @param    {Array}   attributeMap   The attribute array
     * @return   {Object}   The attribute hash.
     */
    function buildAttributeHash(attributeMap) {
      return attributeMap.reduce(
        function loopAttributeMap(accumulator, entry) {
          accumulator[entry.xKey] = entry.xValue;
          return accumulator;
        }, {});
    }

    /**
     * Check if Vlan Options should be displayed on UI.
     *
     * @method   showVlanOptions
     * @returns  {boolean}   Show Vlan Options or not.
     */
    function showVlanOptions() {
      return ($scope._isOracle && FEATURE_FLAGS.oracleRestoreVlanParams &&
        [
          ENUM_HOST_TYPE_CONVERSION.kLinux,
          ENUM_HOST_TYPE_CONVERSION.kWindows,
          ENUM_HOST_TYPE_CONVERSION.kAix
        ].includes($ctrl.oracleHostType)) ||
        ($scope._isSQL && FEATURE_FLAGS.sqlRestoreVlanParam);
    }

    /**
     * Check if change db id option should be displayed on UI.
     *
     * @method   showOracleChangeDbidOption
     * @returns  {boolean}   Show Change Dbid Options or not.
     */
    function showOracleChangeDbidOption() {
      return $scope._isOracle && $scope.isClone && FEATURE_FLAGS.oracleSkipNidStep &&
        ($ctrl.oracleHostType !== undefined ? $ctrl.oracleHostType ===
        ENUM_HOST_TYPE_CONVERSION.kLinux : true);
    }

    /**
     * Method called to determine whether recreate BCT file warning has to be shown or not.
     *
     * @returns True if recreate BCT file warning has to be shown.
     */
    function showRecreateBctFileWarning() {
      const selectedDbObject = $scope.shared.restoreObject.vmDocument.objectId.entity;
      const isCdb = _.get(selectedDbObject,
        'oracleEntity.dbEntityInfo.cdbEntityInfo.pdbEntityInfoVec')?.length > 0;
      const targetHostId = _.get($scope.shared.restoreAppObject, 'restoreParams.targetHost.id');
      const isSameTargetHostSelected = targetHostId === selectedDbObject.parentId &&
        targetHostId !== undefined;
      return $scope._isOracle && $scope.isClone && isCdb && isSameTargetHostSelected;
    }

    /**
     * Sets options visibility bools. Reusable to re-evaluate conditions after
     * changes occur.
     *
     * @method    evaluateAvailableOptions
     */
    function evaluateAvailableOptions() {
      _.assign($scope, {
        showOptions: $scope.taskHasOptions(),
        showLocationOptions: $scope.hasLocationOptions(),
        selectedNFSView: getPreviousViewName(),
        showRenameOptions: $scope.hasRenameOptions(),
      });

      _.assign($ctrl, {
        canUseOverwriteAltDb: _canUseOverwriteAltDb(),
      });
    }

    /**
     * Determines if the target entity is the same as original entity from which
     * it was protected.
     *
     * @method   isOriginalEntity
     * @param    {object}    entity   The entity
     * @return   {boolean}   True if original entity, False otherwise.
     */
    function isOriginalEntity(entity) {
      return !!entity.appEntity &&
        SourceService.isSameEntity(
          entity.appEntity.entity,
          $scope.shared.task.restoreAppParams
            .ownerRestoreInfo.ownerObject.entity
        );
    }

    /**
     * Determines if the original SQL/Oracle server instance is on this cluster.
     * If not, disables and toggles off 'restore to original server' option.
     *
     * @method   checkForOriginalServer
     */
    function checkForOriginalServer() {
      $scope.copyAttachOpts.originalServerUnavailable = $scope._isSQL ?
        !$scope.sqlEntities.some(isOriginalEntity) :
        !$scope.oracleEntities.some(isOriginalEntity);

      if ($scope.copyAttachOpts.originalServerUnavailable) {
        // If we are in a DB restore task and the original server is unavailable
        // we want to set the 'Recover To Original SQL Server' toggle to false
        // so the user can choose a new host to restore too.
        $scope.copyAttachOpts.recoverToOriginalServer =
          // If this !!expression is true, this is a host object restore task
          // and `recoverToOriginalServer` is not applicable (and a false value
          // blocks recovery of a VM in an rx cluster scenario). However, if
          // it's false, it's a DB restore task, for which false also applies
          // when the original server is unavailable.
          !!$scope.shared.task.restoreAppParams.ownerRestoreInfo.performRestore;

        recoverToOriginalServerToggleChanged();
      }
    }

    /**
     * On change Oracle Recovery Type.
     *
     * @method   changeOracleRecoveryType
     */
    function changeOracleRecoveryType() {
      if (!$scope.shared.copyAttachOpts) {
        $scope.shared.copyAttachOpts = {};
      }

      switch($ctrl.targetLocation) {
        case 'overwrite':
          $scope.copyAttachOpts.recoverToOriginalServer = true;
          $scope.shared.copyAttachOpts.exposeView = false;
          $scope.shared.task.action = DB_RECOVER_APP_TYPE.recover;
          recoverToOriginalServerToggleChanged();
          $scope.shared.overwriteOriginalDatabase = true;
          overwriteOriginalDbToggleChanged();

          // Prompt user for confirmation to overwrite Data Guard Primary DB.
          if (SourceService.isOracleDgPrimaryDatabase(_.get($scope.shared,
              'restoreObject.vmDocument.objectId.entity'))) {
            const data = {
              confirmButtonLabel: 'yes',
              declineButtonLabel: 'no',
              copy: 'oracleRestore.dgPrimaryOverwriteWarning',
            };
            ngDialogService.simpleDialog(null, data).subscribe(userChoice => {
              if (!userChoice) {
                $ctrl.targetLocation = 'alternate';
                changeOracleRecoveryType();
                return;
              }
            });
          }
          break;
        case 'alternate':
          $scope.copyAttachOpts.recoverToOriginalServer = false;
          $scope.shared.task.action = DB_RECOVER_APP_TYPE.clone;
          $scope.shared.overwriteOriginalDatabase = false;
          $scope.shared.copyAttachOpts.exposeView = false;
          recoverToOriginalServerToggleChanged();
          break;
        case 'cohesityView':
          $scope.shared.copyAttachOpts.exposeView = true;
          $scope.shared.task.action = DB_RECOVER_APP_TYPE.exposeView;
          $ctrl.exposeViewToggleChanged()
          break;
      }
    }

    /**
     * Hide or show Oracle Settings Container.
     *
     * @method   isShowOracleSettingsBox
     */
    function isShowOracleSettingsBox() {
      if (($ctrl.targetLocation === 'overwrite' &&
        !FEATURE_FLAGS.oracleMultiNodeChannelRecovery) ||
        ($ctrl.oracleBackupType === ORACLE_BACKUP_TYPE.kSbt &&
        !FEATURE_FLAGS.oracleWindowsRestoreEnabled)) {
          return false;
        }
      return true;
    }

    /**
     * Get selected multi node channel count.
     *
     * @method   getSelectedMultiNodeChannelCount
     * @returns  {number}   selected Nodes count.
     */
    function getSelectedMultiNodeChannelCount() {
      // Default count of nodes is one i.e when user doesn't choose the
      // nodes manually magneto selects one node for him automatically.
      // When user selects the node manually he has to select atleast one.
      return _.filter(
        $ctrl.multiNodeChannelFormData, 'isSelected').length || 1;
    }

    /**
     * Set the multi node channel form data to the retore params.
     *
     * @method  _setMultiNodeChannelData
     * @param   {object}     parentEntity    Parent physical source of selected
     *                                       target
     */
    function _setMultiNodeChannelData(parentEntity) {
      var hostInfoVec = [];
      var oracleTargetParams = {};
      var hostAddressToAgentIdMap =
        SourceService.getOracleHostAddressToAgentIdMap(
          parentEntity._appTypeEntity);

      $ctrl.multiNodeChannelFormData.forEach(function forEachNode(node) {
        if (node.isSelected) {
          var hostInfo = {
            host: isNaN(node.ip) ? hostAddressToAgentIdMap[node.ip] : node.ip,
            numChannels: node.channels,
            portNum: node.port,
            sbtHostParams: { sbtLibraryPath : node.sbtLibraryPath }
          };
          hostInfoVec.push(hostInfo);
        }
      });
      const dbCredentials = _.get($scope.shared,
        'dbCredentials.appCredentialsVec') ?
        $scope.shared.dbCredentials.appCredentialsVec[0].credentials : null;
      oracleTargetParams = hostInfoVec.length ? {
        additionalOracleDbParamsVec : [{
          appEntityId:$scope.shared.restoreObject._protectionSourceId,
          dbInfoChannelVec: [{
            hostInfoVec: hostInfoVec,
            dbUuid: $scope.shared.restoreObject
              ._protectionSource.oracleEntity.uuid,
            credentials: FEATURE_FLAGS.oracleDbCredentialsPerHostEnabled &&
              dbCredentials ? dbCredentials : null,
          }],
        }],
      } : undefined;

      _.assign($scope.shared.restoreAppObject.restoreParams
        .oracleRestoreParams,
        {oracleTargetParams: oracleTargetParams});
    }

    /**
     * Gets the job summaries for everything in the cart.
     *
     * @method   getJobSummaries
     * @return   {object}   Promise to resolve with jobSummary data, or server
     *                      response if error.
     */
    function getJobSummaries() {
      return JobRunsService.getJobSummary({
        // Gets list of job Ids for every item in the taskCart
        ids: $scope.shared.taskCart.reduce(
          function promiseMapperFn(ids, entity, ii) {
            if (!ids.includes(entity.vmDocument.objectId.jobId)) {
              ids.push(entity.vmDocument.objectId.jobId);
            }
            return ids;
          }, [])
        })
        .then(function summariesReecivedFn(resp) {
          if (Array.isArray(resp.data)) {
            resp.data.forEach(function eachSummaryFn(job) {
              $scope.shared.taskCart.some(function entityFinderFn(entity) {
                if (job.backupJobSummary.jobDescription.jobId ===
                  entity.vmDocument.objectId.jobId) {
                  angular.extend(entity, {
                    _job: job.backupJobSummary,
                    _isPausedJob:
                      !!job.backupJobSummary.jobDescription.isPaused,
                    _isDeletedJob:
                      !!job.backupJobSummary.jobDescription.isDeleted
                  });
                  return true;
                }
              });
            });
          }
          return resp.data;
        });
    }

    /**
     * callback on selecting a Oracle RAC node
     *
     * @method   onSelectRacNode
     * @param   {string}   ip    node ip
     */
    function onSelectRacNode(ip) {
      $scope.shared.restoreAppObject.restoreParams.oracleRestoreParams
        .alternateLocationParams.nodeIpVec = [ip];
    }

    /**
     * Toggles visibility of the credentials fields for each VM object in the
     * cart. If hiding, this clears any entered values.
     *
     * @method   toggleHostCredentials
     * @param    {object}   entity   The VM entity
     */
    function toggleHostCredentials(entity) {
      if ($scope.showCredentials[entity._id]) {
        $scope.showCredentials[entity._id] = false;
        $scope.shared.task.restoreAppParams.credentials = undefined;
      } else {
        $scope.showCredentials[entity._id] = true;
      }
    }

    /**
     * Transformer for ui-select[tagging] of new View names
     *
     * @method   transformViewTagString
     * @param    {string}   value   The new tag/View name
     * @return   {Object}   New object compatible with the server's response
     */
    function transformViewTagString(value) {
      return {
        name: value
      };
    }

    /**
     * Finds an archiveTarget in the given snapshot by vaultId.
     *
     * @function   _findArchiveTarget
     * @param      {number}   vaultId    The vault ID.
     * @param      {Object}   snapshot   The snapshot to search on.
     * @returns    {Object}   The found archiveTarget, if any.
     */
    function _findArchiveTarget(vaultId, snapshot) {
      var replicaVec = _.get(snapshot, 'replicaInfo.replicaVec');

      if (!vaultId || !replicaVec) { return; }

      return replicaVec.find(function findArchiveTarget(target) {
        return _.get(target.target, 'archivalTarget.vaultId') == vaultId;
      });
    }

    /**
     * Function to make some scope changes for abbreviated flow
     *
     * @method     setAbbreviatedFlow
     */
    function setAbbreviatedFlow() {
      var deferred = $q.defer();

      $scope.shared.isAbbreviatedFlow = true;
      $scope.shared.isFailover = !!$stateParams.failover;
      $scope.taskSteps.shift();
      $scope.shared.task.name = $scope.generateDefaultTaskName();
      $scope.restoreToNewLocation(true);
      $scope.fetchSingleEntity($stateParams, true)
        .then(function singleEntityReceived(entity) {
          if (!entity) {
            deferred.reject(
              'Entity is undefined. Abbreviated flow set up incomplete.'
            );
            return entity;
          }

          // Preselect snapshot?
          if ($stateParams.jobInstanceId) {
            entity.vmDocument.versions.some(
              function findSnapshot(run, ii) {
                if ($stateParams.jobInstanceId ==
                  run.instanceId.jobInstanceId) {
                  entity._snapshotIndex = ii;
                  entity._snapshot = run;
                  entity._archiveTarget = _findArchiveTarget(
                    $stateParams.archiveId,
                    entity._snapshot
                  );

                  return true;
                }
              }
            );
          }

          $scope.addToCart(entity);
          $scope.parentSourceChanged(entity.registeredSource);
          evaluateAvailableOptions();

          // If a log time was provided, and no jobInstanceId, update the task
          // to be PIT.
          if ($stateParams.logTimeMs && !$stateParams.jobInstanceId) {
            $scope.dbRestoreParams.restoreTimeSecs =
              Math.floor($stateParams.logTimeMs/1000);
          }

          deferred.resolve('Abbreviated flow set up is complete.');
        })
        .catch(deferred.reject);

      return deferred.promise;
    }

    /**
     * Get the previous configured view name. ui-select tagging compatible
     *
     * @method   getPreviousViewName
     * @return   {Array}   Previous view name.
     */
    function getPreviousViewName() {
      var taskParams =
        $scope.shared.task.restoreAppParams.ownerRestoreInfo.ownerRestoreParams;
      if (taskParams && taskParams.viewName) {
        return [taskParams.viewName];
      }
      return;
    }

    /**
     * Removes VM rename params when hiding the options.
     *
     * @method     toggleRenameOptions
     * @param      {boolean}  showRename  Show or hide the rename options
     */
    function toggleRenameOptions(showRename) {
      $scope.showRenameOptions = showRename;
      if (!showRename) {
        $scope.shared.task.restoreAppParams.ownerRestoreInfo
          .ownerRestoreParams.renameRestoredObjectParam = undefined;
      }
    }

    /**
     * resets Sql Host related information
     *
     * @method   resetSqlHost
     */
    function resetSqlHost() {
      $scope.copyAttachOpts.currentSqlHost = undefined;
      $scope.sqlInstances = [];
      $scope.shared.restoreAppObject.restoreParams
        .sqlRestoreParams.instanceName = '';
    }

    /**
     * resets Oracle Host related information
     *
     * @method   resetOracleHost
     */
    function resetOracleHost() {
      $scope.copyAttachOpts.currentOracleHost = undefined;
      $scope.oracleInstances = [];
      $scope.shared.restoreAppObject.restoreParams
        .oracleRestoreParams.instanceName = '';
    }

    /**
     * handles object updates when the toggle for
     * "recover to original server?" is changed
     *
     * @method     recoverToOriginalServerToggleChanged
     */
    function recoverToOriginalServerToggleChanged() {
      var restoreAppParams = $scope.shared.task.restoreAppParams;
      var restoreParams = $scope.shared.restoreAppObject.restoreParams;

      // Clear stale overwrite recovery flag if any.
      _.set($scope.shared, 'overwriteOriginalDatabase', false);

      if ($scope._isSQL) {
        // Sync these settings.
        $scope.copyAttachOpts.recoverToOriginalServer =
          // If this is already set, use it, otherwise...
          $scope.copyAttachOpts.recoverToOriginalServer  ||

          // Use this as a default (currently only recover to original server
          // supported in system dbs and is hidden by default)
          $scope.shared.areSystemDbsSelected;
        setNewDatabaseSQLSettings();
      } else {
        var dbRestoreParams = $scope.shared.restoreAppObject.restoreParams.oracleRestoreParams;
        dbRestoreParams.oracleCloneAppViewParamsVec = undefined;
        setNewDatabaseOracleSettings();
      }

      // Trigger overwrite change so it will clean up any path related
      // properties as necessary.
      overwriteOriginalDbToggleChanged();

      if ($scope._isSQL) {
        if ($scope.copyAttachOpts.recoverToOriginalServer) {
          resetSqlHost();

          // Empty properties that may have been added when
          // recoverToOriginalServer was toggled to false.
          restoreParams.sqlRestoreParams.instanceName =
            restoreParams.targetHost =
            restoreParams.targetHostParentSource =
            restoreAppParams.credentials = undefined;
        } else {
          // Recovering to a new Server

          // when recovering to a different server, capture tail logs is
          // irrelevant and will be hidden in the UI, defaulting to false
          restoreParams.sqlRestoreParams.captureTailLogs = false;
          restoreParams.sqlRestoreParams.withNoRecovery = undefined;
        }
      } else {
        if ($scope.copyAttachOpts.recoverToOriginalServer) {
          resetOracleHost();

          // Empty properties that may have been added when
          // recoverToOriginalServer was toggled to false.
          restoreParams.oracleRestoreParams.instanceName =
            restoreParams.targetHost =
            restoreParams.targetHostParentSource =
            restoreAppParams.credentials = undefined;

          // To check the agent verion and set isOlderAgent property
          // which is used in deciding whether agent supports
          // PIT restore or not.
          setIsOlderAgent();
        } else {
          // Recovering to a new Server

          // when recovering to a different server, capture tail logs is
          // irrelevant and will be hidden in the UI, defaulting to false
          restoreParams.oracleRestoreParams.captureTailLogs = false;
          restoreParams.oracleRestoreParams.withNoRecovery = undefined;

          // Setting isOlderAgent to default value (false) which doesnot
          // restrict user in selecting Point-in-time restore.
          $scope.shared.isOlderAgent = false;
        }
      }
    }

    /**
     * Show / Hide explicitly open database warning for backup of recovered
     * database.
     *
     * @method   isShowExplicitDBOpenWarning
     * @return   {boolean}   true if noOpenMode and overwriteOriginalDatabase
     *                       are enabled. false for other cases.
     */
    function isShowExplicitDBOpenWarning() {
      return !!($scope.shared.restoreAppObject.restoreParams.noOpenMode &&
        $scope.shared.overwriteOriginalDatabase);
    }

    /**
     * Handles cleanup of path parameters when "Overwrite Original Database?" is
     * switched to Yes.
     *
     * @method   overwriteOriginalDbToggleChanged
     * @param    {boolean}   [supressModal]   True tells this Fn to supress the
     *                                        AAG Challenge Modal (for
     *                                        self-calling).
     */
    function overwriteOriginalDbToggleChanged(supressModal) {
      var dbRestoreParams = $scope._isSQL ?
        $scope.shared.restoreAppObject.restoreParams.sqlRestoreParams :
        $scope.shared.restoreAppObject.restoreParams.oracleRestoreParams;

      // clear Multi Node Multi Channel formdata
      $ctrl.multiNodeChannelFormData.length = 0;

      var promise = $q.resolve();

      /**
       * Flag indicating 3 conditions as the result of a promise:
       *   1. Supressed: accepted === undefined
       *   2. Closed/Canceled: accepted === false
       *   3. OK: accepted === true
       *
       * @type   {boolean|undefined}
       */
      var accepted;

      if (!supressModal &&
        FEATURE_FLAGS.restoreSqlAag &&
        $scope.shared.overwriteOriginalDatabase &&
        $scope.shared.hasAagMembers) {
        // AAG members are selected, show the modal with next steps.
        promise = RestoreService.showAagStepsModal(
          $scope.shared.taskCart,
          $scope.flowType,
          $scope.shared.challengeAccepted
        );
      }

      promise
        .then(
          function modalClosed(resp) {
            // When no AAG members are selected, or the modal is supressed, resp
            // == undefined. Otherwise it will be accepted == true.
            accepted = resp;
          },
          function modalDismissed() { accepted = false; }
        )
        .finally(function promiseFinally() {
          $scope.shared.challengeAccepted =
            $scope.shared.challengeAccepted || accepted;

          // If the user declined the modal
          if (accepted === false) {
            // Flip the proxy toggle back to it's previous state.
            $scope.shared.overwriteOriginalDatabase =
              !$scope.shared.overwriteOriginalDatabase;

            return;
          }

          // Sync these settings.
          $scope.copyAttachOpts.overwriteOriginalDatabase =
            $scope.shared.overwriteOriginalDatabase =
              // If this is already set, use it, otherwise...
              $scope.shared.overwriteOriginalDatabase ||

              // Use this as a default (overwrite is true and hidden for System
              // DB restores).
              $scope.shared.areSystemDbsSelected;

          if ($scope.shared.overwriteOriginalDatabase) {
            // When overwriting original DB, unset the associated properties.
            dbRestoreParams.logFileDestination =
              dbRestoreParams.secondaryDataFileDestination =
              dbRestoreParams.secondaryDataFileDestinationVec =
              dbRestoreParams.dataFileDestination = undefined;
          } else {
            // If not overwriting database, capture tail logs is irrelevant
            // and will be hidden in the UI, defaulting to false.
            dbRestoreParams.captureTailLogs = false;
          }

          if ($scope._isSQL) {
            setNewDatabaseSQLSettings();
          } else if ($scope._isOracle) {
            setNewDatabaseOracleSettings();

            if ($scope.shared.overwriteOriginalDatabase) {
              // When overwriting original DB, unset the associated properties.
              dbRestoreParams.alternateLocationParams = undefined;
              dbRestoreParams.oracleCloneAppViewParamsVec = undefined;
            }
          }
          if (!dbRestoreParams.secondaryDataFileDestinationVec) {
            dbRestoreParams.secondaryDataFileDestinationVec = [{}];
          }
        });
    }

    /**
     * Sets the newDatabaseName property default according to the choices made
     * in the UI.
     *
     * @method   setNewDatabaseSQLSettings
     */
    function setNewDatabaseSQLSettings() {
      // TODO: Consider moving handling of instanceName in here too. Will
      // require additional testing to ensure it's correct.
      var copyAttachOpts = $scope.copyAttachOpts;
      var firstObject =
        $scope.shared.task.restoreAppParams.restoreAppObjectVec[0];
      var restoreParams = $scope.shared.restoreAppObject.restoreParams;
      var sqlParams = restoreParams.sqlRestoreParams;
      var databaseName = _.get(firstObject, 'appEntity.sqlEntity.databaseName');

      if (!copyAttachOpts.recoverToOriginalServer ||
        !copyAttachOpts.overwriteOriginalDatabase) {
        // When restoring to a new server or a new database name

        // Set a default new DB name.
        sqlParams.newDatabaseName = sqlParams.newDatabaseName || databaseName;
      } else {
        // Clear a configured new DB name.
        sqlParams.newDatabaseName = undefined;
      }

      if ($scope.isDbMigration) {
        sqlParams.isMultiStageRestore =
          sqlParams.isAutoSyncEnabled = true;
      }
    }

    /**
     * Sets the newDatabaseName property default according to the choices made
     * in the UI.
     *
     * @method   setNewDatabaseOracleSettings
     */
    function setNewDatabaseOracleSettings() {
      // TODO: Consider moving handling of instanceName in here too. Will
      // require additional testing to ensure it's correct.

      var copyAttachOpts = $scope.copyAttachOpts;
      var firstObject =
        $scope.shared.task.restoreAppParams.restoreAppObjectVec[0];
      var restoreParams = $scope.shared.restoreAppObject.restoreParams;
      var databaseName = firstObject.appEntity &&
        firstObject.appEntity.oracleEntity &&
        firstObject.appEntity.oracleEntity.databaseName;

      if (!copyAttachOpts.recoverToOriginalServer ||
        !copyAttachOpts.overwriteOriginalDatabase) {
        // When restoring to a new server or a new database name

        // Set a default new DB name.
        restoreParams.oracleRestoreParams.newDatabaseName =
          restoreParams.oracleRestoreParams.newDatabaseName || databaseName;
      } else {
        // Clear a configured new DB name.
        restoreParams.oracleRestoreParams.newDatabaseName = undefined;
      }

    }

    /**
     * GetterSetter function for syncing logFileDestination and
     * dataFileDestination values when in 'Basic' mode.
     *
     * @method     combinedFileDestinationGetterSetter
     * @param      {string}   newDest  The new destination
     * @return     {string}   the currently synced destination value
     */
    function combinedFileDestinationGetterSetter(newDest) {
      var restoreParams;

      if (!$scope.copyAttachOpts.syncFileDestinations) {
        return;
      }

      restoreParams = $scope._isSQL ?
        $scope.shared.restoreAppObject.restoreParams.sqlRestoreParams :
        $scope.shared.restoreAppObject.restoreParams.oracleRestoreParams;

      if ($scope._isSQL && angular.isDefined(newDest)) {
        restoreParams.dataFileDestination =
          restoreParams.secondaryDataFileDestination =
          restoreParams.logFileDestination = newDest;

      }

      /**
       * Incase of oracle the filepath has to be mapped inside oracle
       * databaseFileDestination
       */
      if (!restoreParams.alternateLocationParams) {
        restoreParams.alternateLocationParams = {};
      }

      if ($scope._isOracle && angular.isDefined(newDest)) {
        restoreParams.alternateLocationParams.databaseFileDestination =
          newDest;
      }

      return $scope._isSQL ? restoreParams.dataFileDestination :
        restoreParams.alternateLocationParams.databaseFileDestination;
    }

    /**
     * Toggles $scope.copyAttachOpts.syncFileDestinations boolean value and
     * syncs the two destination values if syncing (basic mode) is enabled, or
     * resets them otherwise.
     *
     * @method   toggleAdvancedPaths
     */
    function toggleAdvancedPaths() {
      // Toggle the value
      $scope.copyAttachOpts.syncFileDestinations =
        !$scope.copyAttachOpts.syncFileDestinations;

      if ($scope.copyAttachOpts.syncFileDestinations) {
        // Sync the properties
        syncFileDestinations();
      } else {
        // Pre-populate the NDF path mapping vec with one empty object.
        $scope.shared.restoreAppObject.restoreParams.sqlRestoreParams
          .secondaryDataFileDestinationVec = [{}];
      }
    }

    /**
     * Sync the file destination properties (simple mode).
     *
     * @method   syncFileDestinations
     */
    function syncFileDestinations() {
      var sqlRestoreParams =
        $scope.shared.restoreAppObject.restoreParams.sqlRestoreParams;

      // Sync the properties
      sqlRestoreParams.logFileDestination =
        sqlRestoreParams.secondaryDataFileDestination =
        sqlRestoreParams.dataFileDestination;

      // Clear the NDF mapping
      sqlRestoreParams.secondaryDataFileDestinationVec = undefined;
    }

    /**
     * Sets all options bools to false and triggers a change on the cart to
     * reset the task options
     *
     * @method     resetOptions
     */
    function resetOptions() {
      $scope.showOptions = false;
      $scope.showRenameOptions = false;
      $scope.showLocationOptions = false;
      // This forces a change event. Is there a better way?
      $scope.shared.taskCart[0] = angular.copy($scope.shared.taskCart[0]);
    }

    /**
     * Sets the showOptions bool to true
     *
     * @method     showTaskOptions
     */
    function showTaskOptions() {
      $scope.showOptions = true;
    }

    /**
     * Triggered when the parentSource selection changes
     *
     * @method     parentSourceChanged
     * @param      {object}  selection  The selected parentSource
     */
    function parentSourceChanged(selection) {
      $scope.selectedParentSource = selection;
      if (selection && selection.id) {
        $scope.selectedResourcePool =
          $scope.selectedDatastore =
          $scope.shared.task.restoreAppParams.ownerRestoreInfo
            .ownerRestoreParams.resourcePoolEntity =
          $scope.shared.task.restoreAppParams.ownerRestoreInfo
            .ownerRestoreParams.datastoreEntity =
          $scope.shared.task.restoreAppParams.ownerRestoreInfo
            .ownerRestoreParams.restoredObjectsNetworkConfig = undefined;
        $scope.datastores.length =
          $scope.networkEntities.length = 0;
        getResourcePools(selection);
      }
    }

    /**
     * Triggered when the resourcePool selection changes
     *
     * @method     resourcePoolChanged
     * @param      {object}  selection  The selected resourcePool
     */
    function resourcePoolChanged(selection) {
      $scope.selectedResourcePool = selection;
      if (selection && selection.vmwareEntity) {
        $scope.selectedDatastore =
          $scope.shared.task.restoreAppParams.ownerRestoreInfo
            .ownerRestoreParams.datastoreEntity =
          $scope.shared.task.restoreAppParams.ownerRestoreInfo
            .ownerRestoreParams.restoredObjectsNetworkConfig = undefined;
        $scope.datastores.length =
          $scope.networkEntities.length = 0;

        if ('recover' === $scope.flowType) {
          getDatastores(selection);
        }
        getNetworkConfigs();
      }
    }

    /**
     * Change handler for when a datastore is selected
     *
     * @method     datastoreChanged
     * @param      {Entity}  selection  Datastore Entity
     */
    function datastoreChanged(selection) {
      $scope.selectedDatastore = selection;
    }

    /**
     * Change handler for when NFSView is selected
     *
     * @method     NFSViewChanged
     * @param      {String}  selection  View name
     */
    function NFSViewChanged(selection) {
      if (selection) {
        $scope.shared.task.restoreAppParams.ownerRestoreInfo
          .ownerRestoreParams.viewName = selection;
      } else {
        $scope.selectedNFSView =
          $scope.shared.task.restoreAppParams.ownerRestoreInfo
            .ownerRestoreParams.viewName = undefined;
      }
    }

    /**
     * Change handler for when a network config is selected
     *
     * @method     networkConfigChanged
     * @param      {Entity}  selection  Network config entity
     */
    function networkConfigChanged(selection) {
      angular.extend($scope.shared.task.restoreAppParams
        .ownerRestoreInfo.ownerRestoreParams, {
        restoredObjectsNetworkConfig: {
          networkEntity: selection
        }
      });
      $scope.selectedNetworkEntity = selection;
    }

    /**
     * updates SQL instances on selection of a new SQL Host
     *
     * @method     sqlHostChanged
     * @param      {Object}  newSqlEntity  The new sql entity
     */
    function sqlHostChanged(newSqlEntity) {
      var restoreParams = $scope.shared.restoreAppObject.restoreParams;
      var ownerEnvType = $scope.attributeHash['OWNER_ENV'];
      var envType = ownerEnvType ? ENV_TYPE_CONVERSION[ownerEnvType] :
        $scope.shared.taskCart[0].registeredSource.type;

      $scope.shared.restoreAppObject.restoreParams
        .sqlRestoreParams.instanceName = undefined;

      // Reset any previously user-entered override credentials
      $scope.shared.task.restoreAppParams.credentials = undefined;

      if (newSqlEntity) {
        // User selected one of the sources already registered for SQL.

        restoreParams.targetHost = newSqlEntity.appEntity.entity;
        restoreParams.targetHostParentSource =
          restoreParams.targetHost.parentId ?
            { id: restoreParams.targetHost.parentId } : undefined;

        // If username is stored, then the host uses an ephemeral agent and we
        // need to prompt for full credentials.
        $scope.copyAttachOpts.showCredentials =
          !!newSqlEntity.appEntity.registeredEntityInfo.connectorParams
            .credentials.username;

        $scope.sqlInstances = getSqlInstancesList(newSqlEntity);

        // End of selection
        return;
      }

      // User opted to browse the sources not yet registered for SQL
      resetSqlHost();

      // Browse envType restricted sources
      SourceService
        .browseForLeafEntities(envType, undefined, { singleSelect: true })
        .then(function sourceSelected(entity) {
          // _browserSelected is used to enforce required user/pass
          entity._browserSelected = true;

          restoreParams.targetHost = entity;
          restoreParams.targetHostParentSource = entity.parentId ?
              { id: entity.parentId } : undefined;

          // set the currentSqlHost for proper ui-select validation
          // handling in the form
          $scope.copyAttachOpts.currentSqlHost = {
            appEntity: {
              entity: entity,
            },
          };

          /**
           * Context: User browsed the sources which are not yet registered for
           * SQL and selected one of them. In this returning promise we need to
           * indicate whether to prompt for credentials. In this context,
           * credentials are required for any selected VM but never for Physical
           * because Physical servers always use an agent.
           */

          /**
           * TODO(maulik): This is in the context of a browser selected Host,
           * and not those pre-populated in the dropdown (known SQL hosts).
           * Currently this will always show the credentials whether it has the
           * agent or not. When we have the info that a particular node is SQL
           * and does not need credentials, below needs to be updated.
           */
          if (FEATURE_FLAGS.sqlRecoveryBrowseShowCredentials) {
            $scope.copyAttachOpts.showCredentials = true;
          }

          // TODO: its possible an SQL Host was selected via browse, can we
          // build the sqlInstances list in such a scenario?
        });
    }

    /**
     * Determines if an MS SQL Instance is compatible for restoration to.
     *
     * @method   _isInstanceCompatible
     * @param    {object}    instance   The MS SQL Instance to check.
     * @return   {boolean}   True if compatible. False otherwise.
     */
    function _isInstanceCompatible(instance) {
      return $scope.shared.taskCart.some(function eachCartItem(cartItem) {
        var entity = cartItem.vmDocument.objectId.entity;

        // TODO (spencer): Still needs integration with Magneto.
        return _.get(instance, 'sqlInstanceVersion.versionString') ===
          _.get(entity, 'sqlEntity.sqlInstanceVersion.versionString');
      });
    }

    /**
     * Updates Oracle host on selection for recovery.
     *
     * @method     oracleHostChanged
     * @param      {Object}  newOracleEntity  The new oracle entity
     */
    function oracleHostChanged(newOracleEntity) {
      if (!newOracleEntity.browseForHost) {
        // User selected one of the sources already registered for Oracle

        $scope.shared.restoreAppObject.restoreParams.targetHost =
          newOracleEntity.appEntity.entity;
        $scope.shared.restoreAppObject.restoreParams.targetHostParentSource = {
          id: $scope.shared.restoreAppObject.restoreParams.targetHost.id,
        };

        // If username is stored, then it is a non-persistent VM and we need to
        // prompt for full credentials.
        $scope.copyAttachOpts.showCredentials =
          !!newOracleEntity.appEntity.registeredEntityInfo.connectorParams
            .credentials.username;

        // Set Oracle home directory based on selected host value.
        setOracleHomeDir(newOracleEntity);

      } else {
        // User opted to browse the sources not yet registered for Oracle
        resetOracleHost();

        /**
         * Browse envType is required only for physical entities as currently
         * Oracle servers are supported on physical servers.
         * TODO(Tauseef): Modify the below function if more entities are to be
         * included
         */
        SourceService
          .browseForLeafEntities(ENV_TYPE_CONVERSION.kPhysical, undefined,
            {
              singleSelect: true,
              onlyRegisteredApp: ENV_TYPE_CONVERSION.kOracle,
            }
          )
          .then(function sourceSelected(entity) {

            // Derive oracle entity value based on entity id.
            var newEnitity = $scope.oracleEntities.find(
              function findOracleEntity(entityValue) {
                return _.get(entityValue, 'appEntity.entity.id') === entity.id;
              }
            );
            var restoreParams = $scope.shared.restoreAppObject.restoreParams;

            // _browserSelected is used to enforce required user/pass
            entity._browserSelected = true;

            restoreParams.targetHost = entity;
            restoreParams.targetHostParentSource = {
              id: entity.parentId,
            };

            // Set Oracle home directory based on selected host value.
            setOracleHomeDir(newEnitity);

            // set the currentSqlHost for proper ui-select validation
            // handling in the form
            $scope.copyAttachOpts.currentOracleHost = {
              appEntity: {
                entity: entity,
              },
            };
          }, function selectSourceCancelled(info) {

            // Clear the pre-populated oracle home value on cancelling the
            // browse servers modal.
            if(info === 'canceled') {
              $scope.shared.restoreAppObject.restoreParams.oracleRestoreParams
                .alternateLocationParams.homeDir = undefined;
            }
          });
      }


      // Clear Multi Node Multi Channel form data.
      $ctrl.multiNodeChannelFormData.length = 0;

      // To check the agent verion and set isOlderAgent property
      // which is used in deciding whether agent supports
      // PIT restore or not.
      setIsOlderAgent();
    }

    /**
     * Method called to pre-populate oracle home folder based on selected host
     * during alernate restore/clone tasks.
     *
     * @method     setOracleHomeDir
     * @param      {Object}  oracleEntity   Oracle entity information.
     */
    function setOracleHomeDir(oracleEntity) {
      var homeDir;
      var oracleEntities = _.get(oracleEntity, 'appEntity.auxChildren', []);
      var selectedDbObject = $scope.shared.restoreObject.vmDocument.objectId.entity;
      var selectedDbEntityInfo = _.get(selectedDbObject, 'oracleEntity.dbEntityInfo');

      // If same host is selected as target then oracle sid is matched to auto-populate
      // oracle home.
      if (oracleEntity.appEntity.entity.id === selectedDbObject.parentId) {
        oracleEntities.forEach(function populateHomeDirBasedOnOracleSid(auxChild) {
          var dbEntityInfo = _.get(auxChild, 'entity.oracleEntity.dbEntityInfo');

          if (auxChild.entity.id === selectedDbObject.id &&
            dbEntityInfo.dbUniqueName === selectedDbEntityInfo.dbUniqueName) {
            homeDir = _.get(dbEntityInfo, 'hostVec[0].sessionConfVec[0].oracleHome');
          }
        });
      } else {

        // If same host is not selected as target then oracle version is matched to
        // auto-populate oracle home.
        oracleEntities.forEach(function populateHomeDirByOracleVersion(auxChild) {
          var oracleVersion = _.get(auxChild, 'entity.oracleEntity.dbEntityInfo.version');

          if (oracleVersion === selectedDbEntityInfo.version) {
            homeDir = _.get(auxChild,
              'entity.oracleEntity.dbEntityInfo.hostVec[0].sessionConfVec[0].oracleHome');
          }
        });
      }

      $scope.shared.restoreAppObject.restoreParams.oracleRestoreParams
        .alternateLocationParams.homeDir = homeDir;
    }

    /**
     * Checks the agent version, whether it is greater than or equal to 6 and
     * updates the isOlderAgent property.
     *
     * @method     setIsOlderAgent
     */
    function setIsOlderAgent() {
      var host;

      if ($scope.copyAttachOpts.recoverToOriginalServer) {
        var entity = _.find($scope.oracleEntities, isOriginalEntity);

        if (entity) {
          host = entity.appEntity.entity;
        }
      } else {
        host = $scope.shared.restoreAppObject.restoreParams.targetHost;
      }

      $scope.shared.isOlderAgent = CloneService.isOlderAgent(host);
    }

    /**
     * builds and returns a list of unique instances for a give sqlEntity
     *
     * @method     getSqlInstancesList
     * @param      {Object}  sqlEntity  The sql entity
     * @return     {Array}   The sql instances list.
     */
    function getSqlInstancesList(sqlEntity) {
      var instances = [];

      if (_.get(sqlEntity.appEntity, 'auxChildren.length')) {
        sqlEntity.appEntity.auxChildren.forEach(
          function checkAuxChild(auxChild) {
            if (auxChild.entity.type === 3 &&
              auxChild.entity.sqlEntity.instanceName &&
              !instances.includes(auxChild.entity.sqlEntity)) {
              instances.push(auxChild.entity.sqlEntity);
            }
          }
        );
      }

      return instances;
    }

    /**
     * Fetches the ResourcePoolEntities list from the server
     *
     * @method     getResourcePools
     * @param      {Entity}   selection  ParentSourceEntity
     * @return     {Promise}  Promise carrying server's response
     */
    function getResourcePools(selection) {
      $scope.resourcePoolsLoading = true;
      $scope.resourcePools.length = 0;
      return SourceService.getResourcePool({
          vCenterId: selection.id
        })
        .then(function resourcePoolGotten(resp) {
          $scope.resourcePools = resp;
          return resp;
        }, evalAJAX.errorMessage)
        .finally(function resourcePoolsFinally(resp) {
          $scope.resourcePoolsLoading = false;
        });
    }

    /**
     * Fetches the DatastoreEntities form the server
     *
     * @method     getDatastores
     * @param      {Entity}   selection  ResourcePoolEntity
     * @return     {Promise}  Promise carrying the server's response
     */
    function getDatastores(selection) {
      $scope.datastoresLoading = true;
      $scope.datastores.length = 0;
      return SourceService.getDatastores({
          resourcePoolId: selection.id,
          vCenterId: selection.parentId
        })
        .then(function dataStoresGotten(resp) {
          $scope.datastores = resp;
          return resp;
        }, evalAJAX.errorMessage)
        .finally(function datastoresFinally() {
          $scope.datastoresLoading = false;
        });
    }

    /**
     * Fetches clone-compatible NFSViews from the server
     *
     * @method     getNFSViews
     * @return     {Promise}  Promise carrying the server's response
     */
    function getNFSViews() {
      var params = {
        includeInactive: true,
        maxCount: 1000
      };
      $scope.NFSViewsLoading = true;
      $scope.NFSViews.length = 0;
      return ViewService.getViews(params)
        .then(function NFSViewsGotten(resp) {
          $scope.NFSViews = processNFSViewsResponse(resp);
          return resp;
        }, function error(resp) {
          // Suppress this if the user can't view views ayway.
          if ($rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW) {
            evalAJAX.errorMessage(resp);
          }
        })
        .finally(function nfsViewsFinally() {
          $scope.NFSViewsLoading = false;
        });
    }

    /**
     * Fetches Available NetworkConfigEntities from the server
     *
     * @method     getNetworkConfigs
     * @return     {Promise}  Promise carrying the server's response
     */
    function getNetworkConfigs() {
      $scope.networkConfigsLoading = true;
      $scope.networkEntities.length = 0;
      return SourceService
        .getNetworkEntities({
          resourcePoolId: $scope.selectedResourcePool.id,
          vCenterId: $scope.selectedParentSource.id
        })
        .then(function networkEntitiesGotten(resp) {
          $scope.networkEntities = resp;
          return resp;
        }, evalAJAX.errorMessage)
        .finally(function networkEntitiesFinally() {
          $scope.networkConfigsLoading = false;
        });
    }

    /**
     * Retrieves a list of known SQL entities for Vmware & Physical
     * environments.
     *
     * @method     getSqlEntities
     * @return     {object}  Promise to resolve with the list of SQL entities,
     *                       or the raw response if error.
     */
    function getSqlEntities() {
      var cartItem = $scope.shared.taskCart[0];
      var cartEntity = cartItem.vmDocument.objectId.entity;
      var isEntityFromFileBasedJob = _.get(
        cartItem._job,
        'jobDescription.envBackupParams.sqlBackupJobParams.fullBackupType') > 0;
      var params = {
        envType: cartItem._hostServerType,
        appEnvType: cartEntity.type,
      };

      if (cartItem._hostServerType === 6) {
        // Set numLevels in case of physical server to 3 by default.
        // Level 3 is SQL instances and SQL DBs are pruned by applying param.
        params.numLevels = 3
      } else if (FEATURE_FLAGS.restrictSqlVmAppEntitiesEnabled &&
        cartItem._hostServerType === 1) {
        // Set numLevels in case of SQL VM entities to 8 if flag is enabled.
        // Level 8 is VMs and SQL instances and DBs underneath VM are pruned
        // by applying param. Feature flag is by default off because Vcenters
        // can have different structures and level 8 is generally the right
        // level but may not work for few customers.
        params.numLevels = 8;
      }

      return SourceService.getAppEntities(params).then(
        function getAppEntitiesSuccess(entities) {
          // first option will be "Browse for Host"
          $scope.sqlEntities = entities.filter(
            function validatedSqlHostsOnly(host) {
              var appEntity = host.appEntity;
              var regInfo = appEntity.registeredEntityInfo;

              // This means is the registration info valid and connectable.
              var isGenerallyValid = _verifyEntityRegistrationInfo(regInfo);

              // If this is a DB Migration workflow and Migrating to original
              // host is disabled...
              if ($scope.isDbMigration &&
                !FEATURE_FLAGS.databaseMigrationToOriginalHost) {
                return appEntity.entity.id !== cartEntity.parentId &&
                  isGenerallyValid;
              }

              // If the core things are (generally) valid, and this is a SQL
              // Cluster (kWindowsCluster)...
              if (isGenerallyValid &&
                params.envType === 6 &&
                appEntity.entity.physicalEntity.type === 2) {
                switch (FEATURE_FLAGS.sqlCloneToSqlCluster) {
                  case true:
                    return $scope.isRecover || isEntityFromFileBasedJob;

                  default:
                    return !$scope.isClone;
                }
              }

              return isGenerallyValid;
            }
          );

          return $scope.sqlEntities;
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Retrieves a list of known Oracle entities for Physical environments.
     *
     * @method     getOracleEntities
     * @return     {object}  Promise to resolve with the list of Oracle entities
     *                       or the raw response if error.
     */
    function getOracleEntities() {
      var params = {
        appEnvType: 19,
      };

      return SourceService.getAppEntities(params).then(
        function getAppEntitiesSuccess(entities) {
          // first option will be "Browse for Host"
          $scope.oracleEntities = [{ browseForHost: true }].concat(
            entities.filter(function validatedOracleHostsOnly(host) {

              /**
               * Querying for AppEntities returns the app entities along with
               * rootEntity out of which only appEntity should be added.
               */
              if (host.rootEntity) {
                return false;
              }
              var appEntity = host.appEntity;
              var regInfo = appEntity.registeredEntityInfo;

              return regInfo &&
                // Not Oracle Cluster during Clone. NOTE: This is a temporary
                // condition for 4.2 because Magneto plans to allow cloning to
                // Oracle Clusters in 5.0.
                !($scope.isClone && params.envType === 6 &&
                  appEntity.entity.physicalEntity.type === 2) &&
                _verifyEntityRegistrationInfo(regInfo);
            })
          );
          return $scope.oracleEntities;
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Determines if the Entity registartion verification status is acceptable
     * for restores.
     *
     * @method   _verifyEntityRegistrationInfo
     * @param    {object}    regInfo   Specifies the details about entity
     *                                 registration info.
     * @return   {Boolean}   True if verification status is acceptable for
     *                       restore; False otherwise.
     */
    function _verifyEntityRegistrationInfo(regInfo) {
      // NOTE: Even if the Server Registration status is not Finished and the
      // refresh is in progress, iris will not stop the restore to original
      // server. Magneto will handle failing the restore task if needed.
      return !!regInfo &&
        (regInfo.registeredAppInfoVec[0].verificationStatus ===
          REGISTRATION_VERIFICATION_STATUS.kFinished ||
          regInfo.registeredAppInfoVec[0].verificationStatus ===
          REGISTRATION_VERIFICATION_STATUS.kRefreshInProgress) &&

        // Check for errors.
        !regInfo.verificationError;
    }

    /**
     * Pre-processes The server response for NFSViews. Returns a list of
     * name strings.
     *
     * @method     processNFSViewsResponse
     * @param      {Array}  data    response.data
     * @return     {Array}  Transformed list
     */
    function processNFSViewsResponse(data) {
      return filterNFSViewsByViewBox(data);
    }

    /**
     * Shared Function that filters a given list of Views against either the
     * restricted ViewBox id, or the first item in the taskCart (previously
     * restricted to same viewBox)
     *
     * @method     filterNFSViewsByViewBox
     * @param      {Array}  list    List of Views
     * @return     {Array}  Filtered subset of the list
     */
    function filterNFSViewsByViewBox(list) {
      var cart = $scope.shared.taskCart;

      if (!list || !list.length) {
        return [];
      }

      // Reduce this response to a list filtered by matching viewBoxIds
      return list.reduce(function matchViewsToViewbox(_views, view) {
        if (view.viewBoxId === cart[0].vmDocument.viewBoxId) {
          _views.push(view.name);
        }
        return _views;
      }, []);
    }

    /**
     * Toggles the options view and sets up or resets the location task
     * options
     *
     * @method     restoreToNewLocation
     * @param      {boolean}  isNew   True if new location, False if
     *                                original location
     */
    function restoreToNewLocation(isNew) {
      $scope.showLocationOptions = isNew;
      if (!isNew) {
        // Clear out the custom config
        $scope.selectedParentSource =
          $scope.selectedDatastore =
          $scope.selectedNFSView =
          $scope.selectedResourcePool =
          $scope.selectedViewName =
          $scope.shared.task.restoreAppParams.ownerRestoreInfo
            .ownerRestoreParams.restoreParentSource =
          $scope.shared.task.restoreAppParams.ownerRestoreInfo
            .ownerRestoreParams.resourcePoolEntity =
          $scope.shared.task.restoreAppParams.ownerRestoreInfo
            .ownerRestoreParams.datastoreEntity =
          $scope.shared.task.restoreAppParams.ownerRestoreInfo
            .ownerRestoreParams.restoredObjectsNetworkConfig = undefined;
      }
    }

    /**
     * Open the snapshot selector to let the user choose a different restore
     * point.
     *
     * @method     selectDBRestorePoint
     * @param      {object}  row     The search result entity in question
     */
    function selectDBRestorePoint(row) {
      var dbParams = $scope._isSQL ?
        $scope.shared.restoreAppObject.restoreParams.sqlRestoreParams :
        $scope.shared.restoreAppObject.restoreParams.oracleRestoreParams;
      var modalOpts = {
        templateUrl: 'app/protection/recovery/db/db-modal-snapshot.html',
        controller: 'dbSnapshotModalController as $ctrl',
        size: 'full',
        resolve: {
          task: angular.copy($scope.shared.task),
          timezone: {name: $scope.shared.currentTimezone},
          entity: function() { return row; },
          restoreTimeSecs: function() { return dbParams.restoreTimeSecs; },
          copyAttachOpts: function() { return $scope.copyAttachOpts; },
          isOlderAgent: $scope.shared.isOlderAgent,
        },
      };

      SlideModalService
        .newModal(modalOpts)
        .then(function snapshotSelectedFn(resp) {
          _.assign(row, {
            _snapshotIndex: resp.selectedIndex,
            _snapshot: resp.snapshot,
            _archiveTarget: resp.archiveTarget,
          });

          // Since the snapshot picker modal updated it's local copy of the
          // task we passed in, lets update the task out here with those
          // changes.
          _.merge(
            $scope.shared.restoreAppObject.restoreParams,
            resp.task.restoreAppParams.restoreAppObjectVec[0].restoreParams
          );

          // When this response property is empty, it means a Magneto snapshot
          // was selected, not a PIT. Empty the value in the task. This is
          // necessary because _.assign ignores the incoming undefined property
          // in the updated task above.
          if (!resp.isPIT) {
            $scope.dbRestoreParams.restoreTimeSecs = undefined;
          }

          // Set variable defining whether it is Point-in-Time.
          $scope.shared.isPIT = resp.isPIT;

          // Set if db restore point selection is updated.
          $ctrl.dbParamsUpdated = true;
        })
        .catch(_.noop);
    }

    /**
     * Callback for MNMC nodes initiator click.
     *
     * @method     selectDBNodes
     */
    function selectDBNodes() {
      var entityId;
      var entity = _.get($scope.shared.restoreObject,
        'vmDocument.objectId.entity', {})[
          $scope.shared.restoreObject._entityKey];
      var isAlternateRestore = !_.get($scope,
        'shared.overwriteOriginalDatabase', false);

      if (isAlternateRestore) {
        _openOracleMultiNodeMultiChannelModal(entity);
      } else {
        entityId = _.get($scope.shared.restoreObject,
          'vmDocument.objectId.entity.id');
        $scope.loadingOracleMnmc = true;
        SourceService.getEntitiesById(entityId)
          .then(function entityReceived(resp) {
            entity = _.get(resp, '[0].oracleEntity');
            _openOracleMultiNodeMultiChannelModal(entity);
          }, evalAJAX.errorMessage)
          .finally(function hideLoader() {
            $scope.loadingOracleMnmc = false;
          });
      }
    }

    /**
     * Open the node selector to let the user choose nodes and input channels.
     *
     * @method   _openOracleMultiNodeMultiChannelModal
     * @param    {object}  entity   oracle db entity.
     */
    function _openOracleMultiNodeMultiChannelModal(entity) {
      var parentEntity = $scope.oracleEntities.find(
        function findServer(entity) {
          var recoverToOriginalServer = _.get($scope,
            'copyAttachOpts.recoverToOriginalServer', false);
          var parentId = recoverToOriginalServer ?
            $scope.shared.restoreObject._parentId :
            $scope.shared.restoreAppObject.restoreParams.targetHost.id;
          return _.get(entity, 'appEntity.entity.id') === parentId;
        }
      );

      var modalOpts = {
        templateUrl: 'app/protection/recovery/db/db-modal-nodes.html',
        controller: 'dbNodesModalController as $ctrl',
        size: 'md',
        resolve: {
          parentEntity: function resolveParentEntity() {
            return parentEntity;
          },
          isAlternateRestore: function checkIfAlternateRestore() {
            return !_.get($scope, 'shared.overwriteOriginalDatabase',
              false);
          },
          isActivePassive: $ctrl.isActivePassive,
          isViewExposeRestore: $ctrl.targetLocation === 'cohesityView',
          entity: function resolveEntity() {
            return entity;
          },
          prevFormData: function resolveFormData() {
            return $ctrl.multiNodeChannelFormData;
          },
          prevDbCredentials: function resolveDbCredentials() {
            return $scope.shared.dbCredentials;
          },
        },
      };

      SlideModalService
        .newModal(modalOpts)
        .then(function nodesSelectedFn(resp) {
          if (resp) {
            $ctrl.multiNodeChannelFormData = resp.formData;
            $ctrl.isActivePassive = resp.isActivePassive;
            $scope.shared.dbCredentials = resp.dbCredentials;
            _setMultiNodeChannelData(parentEntity);
          }
        })
        .catch(_.noop);
    }

    /**
     * Determines if the UI to overwrite alt DBs is usable.
     *
     * @method   _canUseOverwriteAltDb
     * @return   {boolean}   True if usable.
     */
    function _canUseOverwriteAltDb() {
      var cartItem = _.get($scope.shared.taskCart, '[0]', {});

      return FEATURE_FLAGS.overwriteAltDatabase &&
        !$scope.isClone &&
        !$scope.isDbMigration &&
        _.get(cartItem, 'vmDocument.objectId.entity.type') ===
          ENV_TYPE_CONVERSION.kSQL &&
        !cartItem._isHost;
    }

    /**
     * Determines if changing the CDC setting is eligible in this user session.
     *
     * @function   changeCdcEligible
     * @returns    {Boolean}   True if changing the setting is allowed. False
     *                         otherwise.
     */
    function changeCdcEligible() {
      // True when all the following conditions are met: feature must be
      // enabled, this must be a SQL workflow, and must be the recovery
      // workflow.
      return FEATURE_FLAGS.sqlCdc &&
        $scope._isSQL &&
        !$scope.isClone &&
        $scope.getSetWithNoRecovery();
    }

    /**
     * Typeahead query handler that substring matches & limits results for
     * optimal UI performance.
     *
     * @function   getTargetsByQuery
     * @param      {string}   query   The substring query to match SQL Host
     *                                entities.
     * @returns    {Array}    The list of matching hosts, limited to 10.
     */
    function getTargetsByQuery(query) {
      var rxQuery = new RegExp(query, 'i');
      var matches = !query ?
        $scope.sqlEntities :
        $scope.sqlEntities.filter(function filter(host) {
          return rxQuery.test(host.appEntity.entity.displayName);
        });

      return matches.slice(0, 10);
    }

    /**
     * Initializes/removes the pre/post script details on the task object.
     *
     * @method togglePrePostScript
     * @param {string} scriptType Type of the script, can be one either of
     * '_preScript', '_postScript'.
     * @param {boolean} isEnabled Indicates the initialization/removal of the
     * script details.
     */
    function togglePrePostScript(scriptType, isEnabled) {
      if (isEnabled) {
        // Set the default values.
        $scope.shared[scriptType] = {
          timeoutMins: 15,
        };

        if (scriptType === '_preScript') {
          $scope.shared[scriptType].continueOnError = true;
        }
      } else {
        delete $scope.shared[scriptType];
      }
    }

    /**
     * Initializes/removes the shell variables for Oracle.
     *
     * @method toggleShellEnv
     * @param {boolean} isEnabled Whether shell environment is enabled.
     */
    function toggleShellEnv(isEnabled) {
      if (isEnabled) {
        $scope.dbRestoreParams.shellEnvironmentVec =
          $scope.dbRestoreParams.shellEnvironmentVec || [{}];
      } else {
        delete $scope.dbRestoreParams.shellEnvironmentVec;
      }
    }

    /**
     * Toggles the skip nid change for Oracle clone.
     *
     * @method toggleSkipNidStep
     */
    function toggleSkipNidStep() {
      $scope.dbRestoreParams.skipCloneNid = !$ctrl.changeDbId;
    }

    /**
     * Toggles the restore scn change for Oracle clone.
     *
     * @method toggleUseScnForRestore
     */
    function toggleUseScnForRestore() {
      $scope.dbRestoreParams.useScnForRestore = $ctrl.useScnForRestore;
    }

    /**
     * Prepoulates the recovery settings for the selected restore task on which
     * restore reconfiguration was triggerred.
     * Currently supports only Oracle.
     *
     * TODO(tauseef/spencer): Can be extended for MS-SQL too.
     *
     * @method   _preConfigureRestoreParams
     */
    function _preConfigureRestoreParams() {
      // Merge the state restore params
      if ($stateParams.restoreParams && $scope._isOracle) {
        _.merge($scope.shared.restoreAppObject.restoreParams,
          $stateParams.restoreParams);
        $scope.copyAttachOpts.currentOracleHost =
          $scope.shared.restoreAppObject.restoreParams.targetHost;

        // TODO(tauseef): Check for Oracle restore vs clone config.
      }
    }

    /**
     * Method called on updating pfile params configuration.
     *
     * @param   updatedValue   Updated value of pfile parameter.
     */
    function updatePfile(updatedValue) {
      const pfileParameterMap = [];

      if (!!updatedValue) {

        // Filtering out # comments and empty lines added inside pfile input.
      const pfileParamConfig = updatedValue.split('\n').filter(line => line?.trim() &&
        line?.trim()?.[0] !== '#');

        // Iterating over each line of pfile config to update pfile
        // params mapping.
        for (let i = 0; i < pfileParamConfig.length; i++) {
          const value = pfileParamConfig[i];

          if (!value) {
            continue;
          }

          // Since parameter of pfile are case insensitive, to avoid
          // confusion at backend, converting all keys to uppercase.
          const pfileParameter = value.split('=');
          let keyValue = pfileParameter[0].trim().toUpperCase();
          const paramValue = pfileParameter.slice(1).join('=');

          // In case of rac either line starts with *. meaning its applicable to all
          // instances or starts with instance name meanings its applicable to only
          // that instance. In such cases we are removing the *. and parsing only
          // key value pair.
          //
          // For example:
          // *.rac1 = 1000M
          // *.paramKey = value
          if (keyValue[0] === '*' && keyValue[1] === '.') {
            keyValue = keyValue.substring(2);
          }

          // Formatting previous string values in order to concat multiline string
          // value pair if they are found in consecutive order.
          const prevValue = pfileParamConfig[i + 1];
          const prevPfileParameter = prevValue ? prevValue.split('=') : null;
          const prevKey = prevPfileParameter && prevPfileParameter.length ?
            prevPfileParameter[0].trim().toUpperCase() : null;

          // Trimming values with quotes in pfile to differentiate between values such
          // as eg: "'123M'"", "'/path/test'", "300".
          const prevParamValue = prevPfileParameter && prevPfileParameter.length ?
            prevPfileParameter.slice(1).join('=') : null;

          if (prevParamValue && paramValue && prevKey === keyValue &&
            isNaN(parseFloat(prevParamValue))) {

            // Find previous index of repeated key and update the value foreg: '(a==100)','(b=200)'.
            const prevIndex = pfileParameterMap.findIndex(element => element.key === prevKey);

            if (prevIndex !== -1) {
              pfileParameterMap[prevIndex].value = `${paramValue.trim()},${prevParamValue.trim()}`;
            }
          } else {
            if ((keyValue && keyValue.trim().length > 0) && paramValue) {
              pfileParameterMap.push({
                key: keyValue,
                value: paramValue.trim()
              });
            }
          }
        }
      }
      $ctrl.userDefinedPfileConfig = pfileParameterMap;
      const uniquePfileValues = new Set(pfileParameterMap.map(v => v.key));

      // Checking for duplicate pfile key value pairs.
      if (uniquePfileValues.size < pfileParameterMap.length) {
        uniquePfileValues.forEach(keyName => {
          if (pfileParameterMap.map(v => v.key).filter(val => val === keyName).length > 1 &&
            !$ctrl.duplicatePfileValues.includes(keyName)) {
              $ctrl.duplicatePfileValues.push(keyName);
          }
        });
      } else {
        $ctrl.duplicatePfileValues = [];
      }

      // Updating pfile value.
      $scope.shared.pfileTextarea = pfileParameterMap.concat(
        $ctrl.pfileParamsMapper.restrictedPfileParamMap);

      isRestrictedParamEntered();
    }

    /**
     * Determines whether db configuration params are filled or not.
     */
    function dbParamsConfigured() {
      const dbConfig = _.get($scope.shared.restoreAppObject,
        'restoreParams.oracleRestoreParams.alternateLocationParams');

      // Show/hide pfile settings based on db setup configuration.
      if (dbConfig &&
        (dbConfig.newDatabaseName && dbConfig.newDatabaseName.trim().length > 0)
        && (dbConfig.baseDir && dbConfig.baseDir.trim().length > 0) &&
        (dbConfig.homeDir && dbConfig.homeDir.trim().length > 0)) {
        return true;
      }
    }

    /**
     * Method called on updating db configuration params.
     */
    function dbParamsChanged() {
      // Show warnings on updating dbparams only when pfile is generated.
      $ctrl.dbParamsUpdated = $ctrl.isPfileGenerated ? true : false;
    }

    /**
     * Method called to generate and prepopulate pfile config.
     */
    function generatePfile() {
      $ctrl.loadingPfile = true;
      $ctrl.isPfileGenerated = false;
      const cloneObject = $scope.shared.restoreObject ||
        $scope.shared.taskCart[0];
      const snapshotParams = {
        runInstanceIds: [cloneObject._snapshot.instanceId.jobInstanceId],
      };
      const entityId = _.get(cloneObject, 'vmDocument.objectId.entity.id');

      RestoreService.getObjectSnapshots(parseInt(entityId, 10), snapshotParams)
        .then(function fetchSnapshotsInfo(resp) {
          const dbConfig = $scope.shared.restoreAppObject.restoreParams
          .oracleRestoreParams.alternateLocationParams;
          const oracleParams = {
            baseDir: dbConfig.baseDir,
            dbName: dbConfig.newDatabaseName,
            homeDir: dbConfig.homeDir,
            isClone: true
          };

          const metaInfoParams = {
            environment: 'kOracle',
            oracleParams: oracleParams,
          };

          // Constructing pfile meta data based on db setup configuration.
          RestoreService.getPfileMetadata(metaInfoParams, resp.snapshots[0].id)
            .then(function getPfileDataSuccess(response) {

              // Adding comments/instructions to pfile configuration editor.
              $ctrl.pfileConfig = [
                $translate.instant('oracleRestore.pfile.title'),
                $translate.instant('oracleRestore.pfile.description'),
                $translate.instant('oracleRestore.pfile.userDefinedParams')
              ].join('\r\n');
              $ctrl.dbParamsUpdated = false;
              $ctrl.pfileParamsMapper.restrictedPfileParamMap = response
                .oracleParams.restrictedPfileParamMap;

              // Appending pfile parameter along with new line in order to
              // prepopulate pfile editor with default pfile configuration.
              $ctrl.pfileParamTypes.forEach(param => {
                $ctrl.pfileConfig = [$ctrl.pfileConfig, '\r\n' +
                  $translate.instant(param.key)].join('\r\n');

                response.oracleParams[param.type].forEach(metaInfo => {
                  $ctrl.pfileParamsMapper[param.type].push(metaInfo.key);
                  $ctrl.pfileConfig = [$ctrl.pfileConfig, metaInfo.key + '=' +
                    metaInfo.value].join('\r\n');
                });
              });

              // Update pfile configuration.
              updatePfile($ctrl.pfileConfig);
            }, evalAJAX.errorMessage)
            .finally(function fetchPfileDone() {
              $ctrl.loadingPfile = false;
              $ctrl.isPfileGenerated = true;
            });
          }, evalAJAX.errorMessage)
        .finally(function fetchObjectSnapshotsDone() {
          $ctrl.loadingPfile = false;
        }
      );
    }
  }

})(angular);
