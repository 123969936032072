import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { LoginRedirectComponent as MsftLoginRedirectComponent } from '@cohesity/adaptors/office365/dashboard';
import {
  ExternalConnectionServiceApi,
  ExternalTargetServiceApi,
  KerberosProviderServiceApi,
  LDAPServiceApi,
  ObjectServiceApi,
  RemoteStorageServiceApi,
  StorageDomainServiceApi,
  UserServiceApi,
} from '@cohesity/api/v2';
import { ByteSizeService, MathService } from '@cohesity/helix';
import { IrisContextService } from '@cohesity/iris-core';
import { SourceTreeViewComponent } from '@cohesity/iris-source-tree';
import { NaLabelPipeService } from '@cohesity/utils';
import { AppServiceManagerService } from 'src/app/app-services';
import { LicenseNotificationService } from 'src/app/core/notifications/license-notiification/license-notification.service';
import {
  AdaptorAccessService,
  AppStateService,
  BroadcastChannelService,
  ClusterGflagService,
  ClusterService,
  EulaService,
  FeatureFlagsService,
  FileDownloadService,
  GCPMultiSubnetService,
  HomeService,
  LicenseService,
  LocaleService,
  McmViewService,
  NetgroupWhitelistService,
  NetworkingService,
  PassthroughOptionsService,
  QuorumErrorService,
  RemoteClusterService,
  StateManagementService,
  TenantService,
  TranslationLoaderService,
  UploadHostsFileService,
  UserScopeService,
  UserStoreService,
  WorkflowAccessContextService,
} from 'src/app/core/services';
import { SalesforceLoginRenderer } from '@cohesity/shared/login';
import { StaticRoutes } from 'src/app/core/state/state-guard';
import { ViewsService } from 'src/app/modules/views-shared';
import { ActiveDirectoryService } from 'src/app/modules/access-management/on-prem-access-management/active-directory/active-directory.service';
import { IpmiService } from 'src/app/modules/admin/networking/ipmi/ipmi.service';
import { AppsService } from 'src/app/modules/apps/services/apps.service';
import { StorageDomainsService } from 'src/app/modules/cluster/storage-domains/storage-domains.service';
import { ProtectionGroupService, ProtectionRunService } from 'src/app/modules/protection-group-shared';
import { OracleRegistrationService } from 'src/app/modules/protection/oracle/shared/oracle-registration.service';
import { ClusterManagementUtilsService } from 'src/app/modules/software-upgrades/services/cluster-management-utils.service';
import { IpUtilsService } from 'src/app/services/ip-utils.service';
import { NavStateService } from 'src/app/shared/action-buttons/nav-state.service';
import { OracleUtilityService } from 'src/app/shared/oracle/services/oracle-utility.service';
import { SourceHealthCheckMatrixComponent } from 'src/app/shared/source-health-checks';
import { GCPFleetInstanceSettingsComponent } from 'src/app/shared/source-tree/protection-source/gcp/fleet-settings/gcp-fleet-instance-settings.component';
import { environment } from 'src/environments/environment';

import { LocalizedEmailListComponent, SelectLocaleComponent } from '../localization';
import { TimeRangePipeService } from '../pipes/time-range.pipe';
import { LinuxUserService } from '../support-user/linux-user.service';
import { PolicySynopsisComponent, ProtectionPolicyService } from '../policy';
import { ExternalTargetSelectorService } from '../forms/external-target-selector/external-target-selector.service';

// angularJS module having all NG downgraded service can be used to modernize
// the angularJS codebase.
declare const angular;

function downgradeServices() {
  const ajsModule = angular.module('C.NgService', []);

  // downgrade angular Injectables.
  ajsModule.factory('AdaptorAccessService', downgradeInjectable(AdaptorAccessService) as any);
  ajsModule.factory('LinuxUserService', downgradeInjectable(LinuxUserService) as any);
  ajsModule.factory('LocaleService', downgradeInjectable(LocaleService) as any);
  ajsModule.factory('NaLabelPipeService', downgradeInjectable(NaLabelPipeService) as any);
  ajsModule.factory('NavStateService', downgradeInjectable(NavStateService) as any);
  ajsModule.factory('NgActiveDirectoryService', downgradeInjectable(ActiveDirectoryService) as any);
  ajsModule.factory('NgAppServiceManager', downgradeInjectable(AppServiceManagerService) as any);
  ajsModule.factory('NgAppsService', downgradeInjectable(AppsService) as any);
  ajsModule.factory('NgBroadcastChannelService', downgradeInjectable(BroadcastChannelService) as any);
  ajsModule.factory('NgByteSizeService', downgradeInjectable(ByteSizeService) as any);
  ajsModule.factory('NgClusterGflagService', downgradeInjectable(ClusterGflagService) as any);
  ajsModule.factory('NgClusterService', downgradeInjectable(ClusterService) as any);
  ajsModule.factory('NgEulaService', downgradeInjectable(EulaService) as any);
  ajsModule.factory('NgExternalConnectionServiceApi', downgradeInjectable(ExternalConnectionServiceApi) as any);
  ajsModule.factory('NgExternalTargetSelectorService', downgradeInjectable(ExternalTargetSelectorService) as any);
  ajsModule.factory('NgExternalTargetServiceApi', downgradeInjectable(ExternalTargetServiceApi) as any);
  ajsModule.factory('NgFileDownloadService', downgradeInjectable(FileDownloadService) as any);
  ajsModule.factory('NgIpmiService', downgradeInjectable(IpmiService) as any);
  ajsModule.factory('NgIpUtilsService', downgradeInjectable(IpUtilsService) as any);
  ajsModule.factory('NgIrisContextService', downgradeInjectable(IrisContextService) as any);
  ajsModule.factory('NgUserServiceApi', downgradeInjectable(UserServiceApi) as any);
  ajsModule.factory('NgKerberosService', downgradeInjectable(KerberosProviderServiceApi) as any);
  ajsModule.factory('NgLicenseNotificationService', downgradeInjectable(LicenseNotificationService) as any);
  ajsModule.factory('NgLicenseService', downgradeInjectable(LicenseService) as any);
  ajsModule.factory('NgMathService', downgradeInjectable(MathService) as any);
  ajsModule.factory('NgMcmViewService', downgradeInjectable(McmViewService) as any);
  ajsModule.factory('NgNetgroupWhitelistService', downgradeInjectable(NetgroupWhitelistService) as any);
  ajsModule.factory('NgNetworkingService', downgradeInjectable(NetworkingService) as any);
  ajsModule.factory('NgLDAPServiceApi', downgradeInjectable(LDAPServiceApi) as any);
  ajsModule.factory('NgObjectServiceApi', downgradeInjectable(ObjectServiceApi) as any);
  ajsModule.factory('NgOracleRegistrationService', downgradeInjectable(OracleRegistrationService) as any);
  ajsModule.factory('NgOracleUtilityService', downgradeInjectable(OracleUtilityService) as any);
  ajsModule.factory('NgPassthroughOptionsService', downgradeInjectable(PassthroughOptionsService) as any);
  ajsModule.factory('NgProtectionGroupService', downgradeInjectable(ProtectionGroupService) as any);
  ajsModule.factory('NgProtectionRunsService', downgradeInjectable(ProtectionRunService) as any);
  ajsModule.factory('NgRemoteClusterService', downgradeInjectable(RemoteClusterService) as any);
  ajsModule.factory('NgRemoteStorageServiceApi', downgradeInjectable(RemoteStorageServiceApi) as any);
  ajsModule.factory('NgSfdcRenderer', downgradeInjectable(SalesforceLoginRenderer) as any);
  ajsModule.factory('NgStateManagementService', downgradeInjectable(StateManagementService) as any);
  ajsModule.factory('NgStorageDomainServiceApi', downgradeInjectable(StorageDomainServiceApi) as any);
  ajsModule.factory('NgStorageDomainsService', downgradeInjectable(StorageDomainsService) as any);
  ajsModule.factory('NgTenantService', downgradeInjectable(TenantService) as any);
  ajsModule.factory('NgTranslationLoaderService', downgradeInjectable(TranslationLoaderService) as any);
  ajsModule.factory('NgUploadHostsFileService', downgradeInjectable(UploadHostsFileService) as any);
  ajsModule.factory('NgWorkflowAccessContextService', downgradeInjectable(WorkflowAccessContextService) as any);
  ajsModule.factory('NgQuorumErrorService', downgradeInjectable(QuorumErrorService) as any);
  ajsModule.factory('UserScopeService', downgradeInjectable(UserScopeService) as any);
  ajsModule.factory('NgUserStoreService', downgradeInjectable(UserStoreService) as any);
  ajsModule.factory('LinuxUserService', downgradeInjectable(LinuxUserService) as any);
  ajsModule.factory('NgIpmiService', downgradeInjectable(IpmiService) as any);
  ajsModule.factory('NgMcmViewService', downgradeInjectable(McmViewService) as any);
  ajsModule.factory('TimeRangePipeService', downgradeInjectable(TimeRangePipeService) as any);
  ajsModule.factory('UserScopeService', downgradeInjectable(UserScopeService) as any);
  ajsModule.factory('NgGCPMultiSubnetService', downgradeInjectable(GCPMultiSubnetService) as any);
  ajsModule.factory('NgClusterManagementUtils', downgradeInjectable(ClusterManagementUtilsService) as any);
  ajsModule.factory('NgAppStateService', downgradeInjectable(AppStateService) as any);
  ajsModule.factory('NgViewsService', downgradeInjectable(ViewsService) as any);
  ajsModule.factory('NgProtectionPolicyService', downgradeInjectable(ProtectionPolicyService) as any);
  ajsModule.factory('NgFeatureFlagsService', downgradeInjectable(FeatureFlagsService) as any);

  // This is defined in C.constants for now since that is how the
  // value was originally defined in constants.js.
  const constModule = angular.module('C.constants');
  constModule.factory('HOME_STATE', downgradeInjectable(HomeService));
  constModule.constant('StaticRoutes', StaticRoutes);
  constModule.constant('NG_IS_IBM_AQUA_ENV', environment.ibmAqua);
}

/**
 * Downgrade localization specific components that are being used in AJS.
 */
function downgradeLocaleModules() {
  const ajsModule = angular.module('C.NgLocaleModules', []);

  ajsModule.directive(
    'ngLocalizedEmailList',
    downgradeComponent({
      component: LocalizedEmailListComponent,
      inputs: ['model', 'hideLabel', 'withoutLocale', 'enableRecipientType'],
      outputs: ['modelChange'],
    })
  );

  ajsModule.directive(
    'ngSelectLocale',
    downgradeComponent({
      component: SelectLocaleComponent,
      inputs: ['selectedLocale', 'hideLabel'],
      outputs: ['selectedLocaleChange'],
    })
  );

  // TODO: Delete this after Jobs are migrated to NG Protection Groups, the Sources landing page is rewritten, and
  // Source Details page too.
  ajsModule.directive(
    'ngSourceHealthCheckMatrix',
    downgradeComponent({
      component: SourceHealthCheckMatrixComponent,
      inputs: ['checks', 'showAllChecks'],
    })
  );

  ajsModule.directive(
    'ngSourceTreeView',
    downgradeComponent({
      component: SourceTreeViewComponent,
    })
  );

  ajsModule.directive(
    'ngGcpFleetSettings',
    downgradeComponent({
      component: GCPFleetInstanceSettingsComponent,
      inputs: ['hypervisor', 'view'],
      outputs: ['valueUpdate'],
    })
  );

  ajsModule.directive(
    'ngPolicySynopsis',
    downgradeComponent({
      component: PolicySynopsisComponent,
      inputs: ['policy', 'showName', 'policyType'],
    })
  );
}

/**
 * Downgrade components to be reused in AJS app.
 */
function downgradeComponents() {
  angular.module('C')
    .directive('ng2CohMsftLoginRedirect',
      downgradeComponent({ component: MsftLoginRedirectComponent }));
}

export function defineDowngradeModule() {
  downgradeServices();
  downgradeComponents();
  downgradeLocaleModules();
}
