import { Injectable } from '@angular/core';
import { Api, RestoreTasksApiService } from '@cohesity/api/private';
import { RestorePointsForTimeRangeParam, RestoreTasksServiceApi } from '@cohesity/api/v1';
import {
  GetRestorePointsInTimeRangeParams,
  ProtectionGroups,
  ProtectionGroupServiceApi,
  Recoveries,
  Recovery as RecoveryApiModel,
  RecoveryServiceApi,
} from '@cohesity/api/v2';
import { DataFilterValue, NavItem, SnackBarService, timeRangeParameterMap } from '@cohesity/helix';
import { flagEnabled, getUserPrivilegeCheck, IrisContextService, isClusterScope, isEntityOwner, isMcm } from '@cohesity/iris-core';
import { AjaxHandlerService, AutoDestroyable, sanitizeParameters, sanitizeUrlByParams } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { get, isEmpty } from 'lodash';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { RpaasRecoveryUtilsService } from 'src/app/app-services/rpaas/shared';
import {
  AjsUpgradeService,
  DialogService,
  FeatureFlag,
  PassthroughOptionsService,
  RemoteClusterService,
  UserService,
} from 'src/app/core/services';
import { Recovery } from 'src/app/modules/restore/restore-shared';
import {
  enumGroupMap,
  Environment,
  HostType,
  RecoveryAction,
  TaskStatus,
  TearDownStatus,
  tearDownStatusList,
} from 'src/app/shared';
import { MigrationStatus } from 'src/app/shared/constants/migration-status.constants';
import { isNoSqlHadoopSource } from 'src/app/util';

import {
  VmCompleteMigrationDialogComponent,
} from '../../restore-shared/components/vm-complete-migration-dialog/vm-complete-migration-dialog.component';

/**
 * Recovery menu action.
 */
export enum RecoveryMenuAction {
  Cancel = 'cancel',
  DownloadDebugLogs = 'downloadDebugLogs',
  Migrate = 'migrate',
  Recover = 'recover',
  Resubmit = 'resubmit',
  Reconfigure = 'reconfigure',
  TearDown = 'tearDown',
  CompleteMigration = 'completeVmMigration',
  SyncAllObjects = 'syncAllObjects',
  CancelMigration = 'cancelMigration',
}

/**
 * Get recoveries API parameters.
 */
export type GetRecoveriesParams = RecoveryServiceApi.GetRecoveriesParams;

/**
 * Interface to define the recovery details page display configuration.
 */
export interface DetailsConfig {
  featureFlag: FeatureFlag;
  action?: RecoveryAction | RecoveryAction[];
  match?: (env: Environment) => boolean;
}

/**
 * Wait time in ms for reloading page. It takes a while for backend data to reflect
 * the data change caused by the action just taken.
 */
const reloadWaitMs = 5000;

/**
 * List of NG Recovery Detail page display configuration.
 */
const showNgDetailConfigList: DetailsConfig[] = [
  {
    featureFlag: 'ngRecoveryDetailVm',
    action: [
      RecoveryAction.RecoverVMs,
      RecoveryAction.RecoverVApps,
      RecoveryAction.RecoverVmDisks,
      RecoveryAction.RecoverVAppTemplates,
    ],
    match: (environment: Environment) => enumGroupMap[environment] === 'vms'
  },
  {
    featureFlag: 'ngRecoverRds',
    action: RecoveryAction.RecoverAurora
  },
  {
    featureFlag: 'ngRecoverRds',
    action: RecoveryAction.RecoverRDS
  },
  {
    featureFlag: 'ngRecoverS3',
    action: RecoveryAction.RecoverS3
  },
  {
    featureFlag: 'ngRestoreMsSql',
    action: RecoveryAction.RecoverApps,
    match: (environment: Environment) => environment === Environment.kSQL,
  },
  {
    featureFlag: 'ngRecoveryDetailNoSql',
    match: (environment: Environment) => isNoSqlHadoopSource(Environment[environment])
  },
  {
    featureFlag: 'ngRecoveryDetailStorageVolume',
    action: [
      RecoveryAction.RecoverNasVolume,
      RecoveryAction.RecoverSanGroup,
      RecoveryAction.RecoverSanVolumes
    ]
  },
  {
    featureFlag: 'ngRecoveryDetailFiles',
    action: [
      RecoveryAction.DownloadFilesAndFolders,
      RecoveryAction.RecoverFiles,
    ]
  },
  {
    featureFlag: 'ngRecoveryDetailInstantVolumeMount',
    action: RecoveryAction.InstantVolumeMount
  },
  {
    featureFlag: 'ngRecoveryDetailOffice365',
    action: [
      RecoveryAction.RecoverMsGroup,
      RecoveryAction.RecoverMailbox,
      RecoveryAction.RecoverMails,
      RecoveryAction.RecoverMsTeam,
      RecoveryAction.RecoverPublicFolders,
      RecoveryAction.ConvertToPst,
      RecoveryAction.DownloadChats,
      RecoveryAction.RecoverOneDrive,
      RecoveryAction.RecoverOneDriveDocuments,
      RecoveryAction.RecoverSharePoint,
      RecoveryAction.RecoverSharePointDocuments,
      RecoveryAction.RecoverMailboxCSM,
      RecoveryAction.RecoverOneDriveCSM,
      RecoveryAction.RecoverSharePointCSM
    ]
  },
  {
    featureFlag: 'ngRecoveryDetailOracle',
    action: RecoveryAction.RecoverApps,
    match: (environment: Environment) => environment === Environment.kOracle,
  },
  {
    featureFlag: 'includeExcludePvcKubernetes',
    action: RecoveryAction.RecoverNamespaces,
    match: (environment: Environment) => environment === Environment.kKubernetes,
  },
  {
    featureFlag: 'ngRecoverUda',
    match: (environment: Environment) => environment === Environment.kUDA,
  },
  {
    featureFlag: 'sapHanaEnabledDms',
    match: (environment: Environment) => environment === Environment.kSAPHANA,
  },
  {
    featureFlag: 'ngRecoveryDetailExchange',
    action: [
      RecoveryAction.RecoverApps,
      RecoveryAction.RecoverExchangeDbs
    ],
    match: (environment: Environment) => environment === Environment.kExchange
  },
];

/**
 * Recovery service for getting Recoveries.
 */
@Injectable()
export class RecoveryService extends AutoDestroyable {

  /**
   * Optional region id to get sources from. This is set from the
   * region id state param if present.
   */
  get regionId(): string {
    return this.uiRouterGlobals?.params?.regionId;
  }

  /**
   * Legacy AJS RestoreService service.
   */
  private ajsRestoreService: any;

  /**
   * Legacy AJS ExternalTargetService service.
   */
  private ajsExternalTargetService: any;

  /**
   * PIT cache.
   */
  private PIT_CACHE = {};

  /**
   * Constructor.
   */
  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private recoveriesService: RecoveryServiceApi,
    private restoreTasksApi: RestoreTasksApiService,
    private restoreTaskService: RestoreTasksServiceApi,
    private snackBarService: SnackBarService,
    private state: StateService,
    private translate: TranslateService,
    private upgradeService: AjsUpgradeService,
    private userService: UserService,
    private passthroughOptionsService: PassthroughOptionsService,
    private rpaasRecoveryUtilsService: RpaasRecoveryUtilsService,
    private uiRouterGlobals: UIRouterGlobals,
    private v2Service: ProtectionGroupServiceApi,
    private remoteClusterService: RemoteClusterService,
  ) {
    super();
    this.ajsRestoreService = this.upgradeService.get('RestoreService');
    this.ajsExternalTargetService = this.upgradeService.get('ExternalTargetService');
  }

  /**
   * Get recovery by ID.
   *
   * @param    id  Recovery ID.
   * @returns  Observable of Recovery.
   */
  getRecovery(id: string): Observable<RecoveryApiModel> {
    return this.recoveriesService.GetRecoveryById({
      id,
      includeTenants: true,
      ...this.passthroughOptionsService.requestParams,
    });
  }

  /**
   * Check and set recovery objects archival target name if it is empty.
   * Sometimes the name is empty because the target is deleted.
   *
   * @param    recovery  Recovery.
   */
  checkAndSetRecoveryObjectTargetName(recovery: Recovery) {
    if (recovery && recovery.objects && recovery.objects.length) {
      recovery.objects.forEach(object => {
        if (object && object.archivalTargetInfo && !object.archivalTargetInfo.targetName) {
          from(this.ajsExternalTargetService.getTarget(object.archivalTargetInfo.targetId)).subscribe(
            (target: any) => object.archivalTargetInfo.targetName = target.name
          );
        }
      });
    }
  }

  /**
   * Get restore task (old UI) by ID.
   *
   * @param    id  Recovery legacy ID.
   * @returns  Observable of restore task.
   */
  getRecoveryV1(id: number): Observable<any> {
    return from(this.ajsRestoreService.getTask(id, this.passthroughOptionsService.requestHeaders)).pipe(
      map((response: any[]) => response && response.length ? response[0] : response)
    );
  }

  /**
   * Get recoveries.
   *
   * @param    params  Recoveries parameters.
   * @returns  Observable of Recoveries.
   */
  getRecoveries(params: GetRecoveriesParams): Observable<Recoveries> {
    return this.recoveriesService.GetRecoveries({
      ...params,
      includeTenants: true,
    });
  }

  /**
   * Get recoveries from a filters observable
   *
   * @param    filters  Recoveries landing page filters.
   * @returns  Observable of Recovery list.
   */
  getRecoveriesFromFilters(filters: DataFilterValue<any>[]): Observable<Recovery[]> {
    const clientSideFilters = filters.filter(dataFilter => ['name', 'migrationStatus', 'validationStatus',
      'vmMigrationStatus', 'cassandraMigrationStatus'].includes(dataFilter.key));
    const params = this.mapParams(filters);

    this.updateUrlParams(filters);

    const hasProtectionViewPriv = getUserPrivilegeCheck('PROTECTION_VIEW')(this.irisCtx.irisContext);
    return this.getRecoveries(params).pipe(
      map(response => (response.recoveries || []).map(recovery => new Recovery(recovery))),
      switchMap(recoveries => hasProtectionViewPriv ?
          this.addNetappRecoveryTypeToRecoveries(recoveries) :
          of(recoveries)),
      map((result: Recovery[]) => {
        const filteredResult = this.filter(result, clientSideFilters);
        filteredResult.forEach(
          recovery => recovery.availableActions = this.getRecoveryMenuItems(recovery));
        return filteredResult;
      })
    );
  }

  /**
   * Cancel recovery by ID
   *
   * @param    id  Recoveries landing page filters.
   * @returns  Observable.
   */
  cancelRecovery(id: string): Observable<null> {
    return this.recoveriesService.CancelRecoveryById({
      id,
      regionId: this.regionId,
      ...this.passthroughOptionsService.requestParams,
    });
  }

  /**
   * Tear down recovery by ID
   *
   * @param    id  Recoveries landing page filters.
   * @returns  Observable.
   */
  tearDownRecovery(id: string): Observable<null> {
    return this.recoveriesService.TearDownRecoveryById({
      id,
      ...this.passthroughOptionsService.requestParams,
    });
  }

  /**
   * Client side filter of recoveries using name and status filters.
   *
   * @param   recoveries  Recoveries.
   * @param   filters  Name and status filters.
   * @returns Filtered recoveries.
   */
  private filter(recoveries: Recovery[], filters: DataFilterValue<any>[]): Recovery[] {
    if (!filters || !filters.length) {
      return recoveries;
    }
    return recoveries.filter(recovery => {
      for (let i = 0; i < filters.length; i++) {
        if (!filters[i].predicate(recovery, filters[i].value, filters[i].key)) {
          return false;
        }
      }
      return true;
    });
  }

  /**
   * Map filters to get recoveries API call parameters.
   *
   * @param    filters  Filters applied to the table
   * @returns  Get recoveries API call parameters
   */
  private mapParams(filters: DataFilterValue<any>[]): GetRecoveriesParams {
    const params: GetRecoveriesParams = {};

    filters.forEach(appliedFilter => {
      const { key, value } = appliedFilter;

      if (!value || (key !== 'dateRange' && !value.length)) {
        return;
      }

      switch (key) {
        case 'snapshotTargetType': {
          const targetTypeValue = value[0].value;

          if (['Cloud', 'Tape', 'Nas'].includes(targetTypeValue)) {
            params[key] = ['Archival'];
            params.archivalTargetType = [targetTypeValue];

            // TODO(ang): Remove the Nas push when we add the Nas filter back after Magneto fix.
            if (targetTypeValue === 'Cloud') {
              params.archivalTargetType.push('Nas');
            }
          } else {
            params[key] = [targetTypeValue];
          }
          break;
        }

        case 'status':
        case 'tenantIds':
          params[key] = value.map(v => v.value);
          break;

        case 'dateRange': {
          const { start, end } = value;

          if (start) {
            params.startTimeUsecs = start.valueOf() * 1000;
          }
          if (end) {
            params.endTimeUsecs = end.valueOf() * 1000;
          }
          break;
        }

        case 'recoveryType':
          value.forEach(({ value: recoveryType }) => {
            if (!params.recoveryActions) {
              params.recoveryActions = [];
            }
            if (Environment[recoveryType]) {
              if (!params.snapshotEnvironments) {
                params.snapshotEnvironments = [];
              }
              params.snapshotEnvironments.push(recoveryType);
              if (!params.recoveryActions.includes(RecoveryAction.RecoverApps)) {
                params.recoveryActions.push(RecoveryAction.RecoverApps);
              }
              if (!params.recoveryActions.includes(RecoveryAction.CloneAppView)) {
                params.recoveryActions.push(RecoveryAction.CloneAppView);
              }
            } else {
              params.recoveryActions.push(recoveryType);
            }
          });
          if (params.recoveryActions.includes(RecoveryAction.RecoverVMs)) {
            params.recoveryActions.push(
              RecoveryAction.RecoverVmDisks,
              RecoveryAction.RecoverVApps
            );
          }
          if (params.recoveryActions.includes(RecoveryAction.RecoverPhysicalVolumes)) {
            params.recoveryActions.push(RecoveryAction.RecoverSystem);
          }
          if (params.recoveryActions.includes(RecoveryAction.RecoverFiles)) {
            params.recoveryActions.push(RecoveryAction.DownloadFilesAndFolders);
          }
          break;
      }
    });
    return params;
  }

  /**
   * Handles updating the state's url query params based on changes to the filters.
   *
   * @param filters   the current filters for the table.
   */
  private updateUrlParams(filters: DataFilterValue<any>[]) {
    const params = { ...this.uiRouterGlobals.params };

    params.startTime = undefined;
    params.endTime = undefined;

    // Update the dynamic state params so navigating away from and back to
    // this page will restore the params.
    filters.forEach(dataFilter => {
      switch (dataFilter.key) {
        case 'dateRange': {
          const { start, end, timeframe } = dataFilter.value;

          if (timeframe && timeRangeParameterMap[timeframe]) {
            params.timeRange = timeRangeParameterMap[timeframe];
          } else {
            if (start) {
              params.startTime = start.valueOf();
            }
            if (end) {
              params.endTime = end.valueOf();
            }
          }
          break;
        }

        case 'status':
          params.status = dataFilter.value.map(val => val.value);
          break;

        case 'migrationStatus':
          params.migrationStatus = dataFilter.value.map(val => val.value);
          break;

        case 'vmMigrationStatus':
          params.vmMigrationStatus = dataFilter.value.map(val => val.value);
          break;

        case 'snapshotTargetType':
          params.targetType = dataFilter.value.length ? dataFilter.value[0].value : undefined;
          break;

        case 'recoveryType':
          params.recoveryType = dataFilter.value.map(val => val.value);
          break;

        case 'tenantIds':
          // This filter might not be initialized yet. Don't blow away existing
          // params if there are no tenant values but a param for tenants.
          if (dataFilter.value.length) {
            params.tenantIds = dataFilter.value.map(val => val.value);
          }
          break;
      }
    });

    // Fire a state change so url params get updated. `reloadOnSearch: false`
    // in state config will prevent this from reloading the entire state.
    this.state.go(this.uiRouterGlobals.current?.name, params, { notify: false });
  }

  /**
   * Get the list of recovery type filter values
   *
   * @returns Recovery Types which is usually recovery action and sometimes environment.
   */
  getRecoveryTypes(): string[] {
    if (this.userService.isHeliosTenantUser()) {
      return [ RecoveryAction.RecoverVMs ];
    }

    return [
      RecoveryAction.RecoverVMs,
      RecoveryAction.RecoverFiles,
      flagEnabled(this.irisCtx.irisContext, 'oracleSourcesEnabled') && Environment.kOracle,
      Environment.kSQL,
      flagEnabled(this.irisCtx.irisContext, 'activeDirectorySourceEnabled') && Environment.kAD,
      RecoveryAction.InstantVolumeMount,
      RecoveryAction.RecoverPhysicalVolumes,
      RecoveryAction.RecoverSanGroup,
      RecoveryAction.RecoverSanVolumes,
      RecoveryAction.RecoverNasVolume,
      flagEnabled(this.irisCtx.irisContext, 'office365MailboxSupportEnabled') && RecoveryAction.RecoverMailbox,
      flagEnabled(this.irisCtx.irisContext, 'kubernetesEnabled') && RecoveryAction.RecoverNamespaces,
      flagEnabled(this.irisCtx.irisContext, 'office365OneDriveSupportEnabled') && RecoveryAction.RecoverOneDrive,
      flagEnabled(this.irisCtx.irisContext, 'office365SharePointSupportEnabled') && RecoveryAction.RecoverSharePoint,
      flagEnabled(this.irisCtx.irisContext, 'ngRestoreExchange') && Environment.kExchange,
      flagEnabled(this.irisCtx.irisContext, 'office365PublicFolderSupportEnabled') && RecoveryAction.RecoverPublicFolders,
      flagEnabled(this.irisCtx.irisContext, 'office365GroupSupportEnabled') && RecoveryAction.RecoverMsGroup,
      flagEnabled(this.irisCtx.irisContext, 'office365TeamsSupportEnabled') && RecoveryAction.RecoverMsTeam,
      flagEnabled(this.irisCtx.irisContext, 'office365MailboxesPstDownload') && RecoveryAction.ConvertToPst,
      flagEnabled(this.irisCtx.irisContext, 'dmsSfdcRegistration') && RecoveryAction.RecoverSfdcObjects,
      flagEnabled(this.irisCtx.irisContext, 'dmsSfdcRegistration') && RecoveryAction.RecoverSfdcRecords,
      flagEnabled(this.irisCtx.irisContext, 'dmsSfdcOrgRecovery') && RecoveryAction.RecoverSfdcOrg,

    ].filter(Boolean) as string[];
  }

  /**
   * Get the allowed actions for Recovery based on recovery data
   *
   * @param    recovery  Recovery.
   * @returns  Allowed recovery menu action items.
   */
  getRecoveryMenuItems(recovery: Recovery): NavItem[] {
    if (isEntityOwner(this.irisCtx.irisContext, recovery.tenantId)) {
      return Object.values(RecoveryMenuAction)
        .filter(action => this.isValidRecoveryMenuAction(action, recovery))
        .map(displayName => ({
          displayName,
          action: () => this.onRecoveryMenuAction(displayName, recovery)
        } as NavItem));
    }
  }

  /*
   * Check if specific action is allowed for the recovery.
   * Based on logic in canCancelTask(), canRetryRestoreTask(), canReconfigureRestoreTask() of AJS restore-service.js.
   *
   * @param    action    Specific recovery action.
   * @param    recovery  Recovery.
   * @returns  true if action is allowed.
   */
  private isValidRecoveryMenuAction(action: RecoveryMenuAction, recovery: Recovery): boolean {
    switch (action) {
      case RecoveryMenuAction.Cancel:
        // Do not show Cancel option for VM migration.
        if (recovery.environment === Environment.kVMware && recovery.isVmMigrationTask) {
          return false;
        }

        // Don't show cancel option for oracle overwrite restore tasks.
        if (recovery.environment === Environment.kOracle && recovery.isOracleOverwriteRestore) {
          return false;
        }

        if (![TaskStatus.Accepted, TaskStatus.Running].includes(recovery.status as TaskStatus)) {
          return false;
        }

        // TODO(ang): Check if owner (when available) can perform restore for SQL sources.
        // AJS code is checking restoreAppParams.ownerRestoreInfo.performRestore
        return true;

      case RecoveryMenuAction.Resubmit:
        // Do not show resubmit for rpaas recovery.
        if (this.rpaasRecoveryUtilsService.disableRecoveryResubmit(recovery)) {
          return false;
        }

        // Do not show Resubmit for VM migration.
        if (recovery.environment === Environment.kVMware && recovery.isVmMigrationTask) {
          return false;
        }

        // Can resubmit any time regardless of task status if SQL
        if (flagEnabled(this.irisCtx.irisContext, 'sqlRestoreResubmit') &&
          recovery.environment === Environment.kSQL) {
          return true;
        }
        if ([TaskStatus.Running, TaskStatus.Canceling].includes(recovery.status as TaskStatus)) {
          return false;
        }
        if (flagEnabled(this.irisCtx.irisContext, 'restoreVmResubmit') &&
          [
            RecoveryAction.RecoverAurora,
            RecoveryAction.RecoverVMs,
            RecoveryAction.RecoverVApps,
            RecoveryAction.RecoverRDS,
            RecoveryAction.RecoverS3,
            RecoveryAction.RecoverVAppTemplates,
          ].includes(recovery.action)) {
          return true;
        }
        switch (recovery.action) {
          case RecoveryAction.RecoverFiles:
            return flagEnabled(this.irisCtx.irisContext, 'restoreFlrResubmit');
          case RecoveryAction.InstantVolumeMount:
            return flagEnabled(this.irisCtx.irisContext, 'restoreIVMResubmit');
          case RecoveryAction.RecoverNasVolume:
            return !recovery.isNetappSnapDiffRecovery &&
            flagEnabled(this.irisCtx.irisContext, 'restoreNasResubmit');
          case RecoveryAction.RecoverSanGroup:
          case RecoveryAction.RecoverSanVolumes:
            return flagEnabled(this.irisCtx.irisContext, 'restoreSanResubmit');
          case RecoveryAction.RecoverVAppTemplates:
            return flagEnabled(this.irisCtx.irisContext, 'restoreVappTemplateResubmit');
          case RecoveryAction.RecoverNamespaces:
            return true;
        }
        return false;

      case RecoveryMenuAction.Reconfigure:
        if (flagEnabled(this.irisCtx.irisContext, 'oracleRestoreReconfigure') &&
          recovery.environment === Environment.kOracle) {
          return true;
        }
        return false;

      case RecoveryMenuAction.TearDown:

        // Filter out teardown action for running restore/ db migration tasks and
        // successfully db migrated oracle instant restore tasks.
        if (recovery.status === TaskStatus.Running || recovery.migrationStatus === TaskStatus.Succeeded ||
          recovery.migrationStatus === TaskStatus.Running) {
          return false;
        }
        return ![
          TearDownStatus.DestroyScheduled,
          TearDownStatus.Destroying,
          TearDownStatus.Destroyed
        ].includes(recovery.tearDownStatus as TearDownStatus) && recovery.canTearDown;

      case RecoveryMenuAction.DownloadDebugLogs: {
        // Download debug logs feature is not supported for windows restore.
        const isWindowsHost = (recovery?.objects || []).map(object => object?.osType).includes(HostType.kWindows);

        return (flagEnabled(this.irisCtx.irisContext, 'downloadDebugLogsEnabled') &&
          recovery.environment === Environment.kOracle &&
          [TaskStatus.Failed, TaskStatus.Succeeded, TaskStatus.SucceededWithWarning, TaskStatus.Canceled]
          .includes(recovery.status as TaskStatus)) &&
          !isWindowsHost;
      }

      case RecoveryMenuAction.Migrate:
        if (recovery.environment === Environment.kOracle) {
          const tearDownStatus = !tearDownStatusList.includes(recovery.tearDownStatus as TearDownStatus) &&
            recovery.canTearDown;
          const isInstantRecover = (recovery?.reference?.oracleParams?.recoverAppParams?.oracleTargetParams?.
            newSourceConfig?.recoverDatabaseParams as any)?.oracleUpdateRestoreOptions?.delaySecs === -1;
          return (flagEnabled(this.irisCtx.irisContext, 'oracleInstantRecover') &&
            [TaskStatus.Succeeded, TaskStatus.SucceededWithWarning].includes(recovery.status as TaskStatus)) &&
            isInstantRecover && tearDownStatus &&
            ![TaskStatus.Succeeded, TaskStatus.SucceededWithWarning, TaskStatus.Running].includes(
            recovery.migrationStatus as TaskStatus);
        }
        break;

      case RecoveryMenuAction.Recover:

        // Show recover option in case of successful oracle recovery validation task.
        return !!recovery.isOracleRecoveryValidation &&
          [TaskStatus.Succeeded, TaskStatus.SucceededWithWarning].includes(recovery.status as TaskStatus);

      case RecoveryMenuAction.CancelMigration:
        if (recovery.environment === Environment.kVMware && recovery.isVmMigrationTask &&
          [MigrationStatus.Accepted, MigrationStatus.Running,
            MigrationStatus.OnHold, MigrationStatus.Finalizing].includes(recovery.status as MigrationStatus)) {
          return true;
        }
        return false;

      case RecoveryMenuAction.CompleteMigration:
        if (recovery.environment === Environment.kVMware && recovery.isVmMigrationTask &&
          [MigrationStatus.Accepted, MigrationStatus.Running, MigrationStatus.OnHold].includes(
            recovery.status as MigrationStatus)) {
          return true;
        }
        if (recovery.environment === Environment.kCassandra && recovery.isCassandraMigrationTask &&
          [MigrationStatus.Accepted, MigrationStatus.Running, MigrationStatus.OnHold].includes(
            recovery.status as MigrationStatus)) {
          return true;
        }
        return false;

      case RecoveryMenuAction.SyncAllObjects:
        if (recovery.environment === Environment.kVMware && recovery.isVmMigrationTask &&
          [MigrationStatus.Accepted, MigrationStatus.Running, MigrationStatus.OnHold].includes(
          recovery.status as MigrationStatus)) {
          return true;
        }
        return false;
      default:
        // unknown action
        return false;
    }
  }

  /**
   * Perform the recovery menu action
   *
   * @param    action  Recovery menu action.
   * @param    recovery  The recovery object.
   */
  onRecoveryMenuAction(action: RecoveryMenuAction, recovery: Recovery) {
    switch (action) {
      case RecoveryMenuAction.Cancel: {
        const data = {
          confirmButtonLabel: 'cancelRecovery',
          declineButtonLabel: 'continueRecovery',
          title: 'cancelRecovery',
          copy: 'cancelRecoveryModalConfirmation',
        };

        this.dialogService.simpleDialog(null, data).subscribe((proceed: boolean) => {
          if (proceed) {
            this.cancelRecovery(recovery.id).subscribe(
              () => {
                // reload so user can see that the cancel has been initiated
                window.setTimeout(() => this.state.reload(), reloadWaitMs);
                this.snackBarService.open(this.translate.instant('cancelRecoveryModalSuccess'));
              },
              () => this.snackBarService.open(this.translate.instant('cancelRecoveryModalFailed', 'error'))
            );
          }
        });
        break;
      }

      case RecoveryMenuAction.Resubmit:
        if (recovery.environment === Environment.kOracle) {
          this.migrateDbRecoverTask(recovery);
        }

        // TODO(ang): use API v2 when it is ready
        this.restoreTasksApi.getRestoreTask(recovery.legacyId,
          this.passthroughOptionsService.requestHeaders).subscribe(task => {
            if (task && task.performRestoreTaskState) {
              this.ajsRestoreService.retryRestoreTask({
                ...task.performRestoreTaskState,
                resubmitRecoveryObject: recovery.reference,
                ...this.passthroughOptionsService.routerParams,
              });
            }
          });
        break;

      case RecoveryMenuAction.Reconfigure:
        // TODO(ang): use API v2 when it is ready
        this.restoreTasksApi.getRestoreTask(recovery.legacyId,
          this.passthroughOptionsService.requestHeaders).subscribe(task => {
            const params = get(task, 'performRestoreTaskState.restoreAppTaskState.restoreAppParams');

            if (params) {
              const owner = get(params, 'ownerRestoreInfo.ownerObject') || {};
              const vec = get(params, 'restoreAppObjectVec[0]') || {};
              const entity = get(vec, 'appEntity') || {};

              this.state.go('recover-db.options', {
                dbType: 'oracle',
                jobId: owner.jobId,
                jobInstanceId: owner.jobInstanceId,
                entityId: entity.id,
                sourceId: entity.parentId,
                restoreParams: vec.restoreParams
              });
            }
          });
        break;

      case RecoveryMenuAction.Migrate: {
        const dialogConfig = {
          confirmButtonLabel: 'migrate',
          declineButtonLabel: 'cancel',
          title: 'migrateOracleDatabase',
          copy: 'migrateDatabaseConfirmation',
        };

        this.dialogService.simpleDialog(null, dialogConfig).subscribe((proceed: boolean) => {
          if (proceed) {
            // Migrate db restore task.
            this.migrateDbRecoverTask(recovery);
          }
        });
        break;
      }

      case RecoveryMenuAction.TearDown: {
        const modalData = {
          confirmButtonLabel: 'tearDown',
          declineButtonLabel: 'cancel',
          title: 'teardownRecoveryObjects',
          copy: 'teardownRecoveryModalConfirmation',
        };

        this.dialogService.simpleDialog(null, modalData).subscribe((proceed: boolean) => {
          if (proceed) {
            this.tearDownRecovery(recovery.id).subscribe(
              () => {
                // reload so user can see that the tear down action has been initiated
                window.setTimeout(() => this.state.reload(), reloadWaitMs);
                this.snackBarService.open(this.translate.instant('teardownRecoveryModalSuccess'));
              },
              () => this.snackBarService.open(this.translate.instant('teardownRecoveryModalFailed', 'error'))
            );
          }
        });
        break;
      }

      case RecoveryMenuAction.DownloadDebugLogs: {
        let url = Api.publicV2('data-protect/recoveries/' + recovery.id + '/debug-logs');

        if (isMcm(this.irisCtx.irisContext) && isClusterScope(this.irisCtx.irisContext)) {
          // Add required cluster passthrough information if this call is being
          // made from Helios
          url += `?clusterId=${this.remoteClusterService.selectedScope.clusterId}`;
          window.open(url);
        } else {
          window.open(url);
        }

        break;
      }

      case RecoveryMenuAction.Recover:
        this.state.go('recover-oracle', {
          resubmitRecoveryObject: recovery.reference,
        });
        break;

      case RecoveryMenuAction.SyncAllObjects:
        this.syncVmMigrationTask(recovery);
        break;

      case RecoveryMenuAction.CompleteMigration:
        if (recovery.isVmMigrationTask) {
          this.completeMigrateVmTask(recovery);
        } else if (recovery.isCassandraMigrationTask) {
          this.completeMigrateCassandraTask(recovery);
        }
        break;

      case RecoveryMenuAction.CancelMigration:
        this.cancelMigrateVmTask(recovery);
        break;
    }
  }

  /**
   * Legacy AJS private getClonesTasks function.
   */
  getClonesTasks() {
    return this.ajsRestoreService.getRestoreTasks({
      restoreTypes: this.ajsRestoreService.getAllowedCloneTypes(),
      _tenantIds: [],
      _includeTenantInfo: true,
    });
  }

  /**
   * Return url to download recovery error report by recovery id.
   *
   * @param   id recovery id
   * @returns Download link to fetch recovery error report.
   */
  downloadRecoveryErrorReportUrl(id: string): string {
    const downloadUrl = [this.recoveriesService.rootUrl, RecoveryServiceApi.GetRecoveryErrorsReportPath].join('');
    return sanitizeUrlByParams(downloadUrl, { key: 'id', value: id });
  }

  /**
   * Return url to download recovery success report by recovery id.
   *
   * @param   id recovery id
   * @param   sourceName name of target source
   * @param   startTime start time of task
   * @returns Download link to fetch recovery success report.
   */
   downloadRecoverySuccessReportUrl(id: string, sourceName: string, startTime: number): string {
    const downloadUrl = [
      this.recoveriesService.rootUrl,
      RecoveryServiceApi.InternalApiDownloadFilesFromRecoveryPath
    ].join('');
    return (
      sanitizeUrlByParams(downloadUrl, { key: 'id', value: id }) +
      `?${sanitizeParameters({ fileType: 'success_files_list' })}&sourceName=${sourceName}&startTime=${startTime}`
    );
  }

  /**
   * Show NG detail page or not.
   *
   * @param    action              The recovery action.
   * @param    environment         The environment.
   * @param    isSqlMigrationTask  True if this is a SQL Migration task.
   * @returns  true if showing the NG detail page.
   */
  showNgDetail({ action, environment, isSqlMigrationTask }: Recovery): boolean {
    return !isSqlMigrationTask && showNgDetailConfigList.some((config) =>
      flagEnabled(this.irisCtx.irisContext, config.featureFlag) &&
      (!config.action || config.action === action || (config.action.length && config.action.includes(action))) &&
      (!config.match || config.match(environment as Environment))
    );
  }

  /**
   * Migrates oracle db instant recovery task.
   *
   * @param    recovery  The recovery task.
   */
  migrateDbRecoverTask(recovery: Recovery) {
    const oracleParams = recovery?.reference?.oracleParams?.recoverAppParams?.oracleTargetParams;

    // TODO(Mythri): use API v2 when it is ready
    const restoreTaskParams: any = {
      body: {
        restoreTaskId: recovery.legacyId,
        oracleOptions: {
          migrateCloneParams: {
            delaySecs: 0,
            targetPathVec: (oracleParams?.newSourceConfig?.recoverDatabaseParams as any)?.oracleUpdateRestoreOptions
              ?.targetPathVec
          }
        }
      }
    };

    this.restoreTaskService.UpdateRestoreTask(restoreTaskParams).pipe(
      mergeMap(() => this.restoreTasksApi.getRestoreTask(recovery.legacyId,
        this.passthroughOptionsService.requestHeaders))
    ).subscribe(resp => {
      const subTaskId = resp?.performRestoreTaskState?.restoreSubTaskVec.pop();
      this.state.go('recovery.detail', {id: recovery.id, subTaskId}, {reload: true});
    }, error => this.ajaxHandlerService.handler(error));
  }

  /**
   * Cancel oracle db instant recovery migration task.
   *
   * @param   id  The migration task id.
   */
  cancelMigrateDbTask(id: string) {
    const data = {
      confirmButtonLabel: 'yes',
      declineButtonLabel: 'no',
      title: 'oracle.instantRecovery.cancelMigration.title',
      copy: 'oracle.instantRecovery.cancelMigrationConfirmation'
    };

    this.dialogService.simpleDialog(null, data).pipe(
      // Wait some time for the backend to be updated after cancel before reload.
      delay(reloadWaitMs)
    ).subscribe((proceed: boolean) => {
      if (proceed) {
        this.cancelRecovery(id).subscribe(
          () => {
            this.snackBarService.open(this.translate.instant('oracle.instantRecovery.cancelMigrationModalSuccess'));
            this.state.reload();
          },
          () => this.snackBarService.open(this.translate.instant('oracle.instantRecovery.cancelMigrationModalFailed',
            'error'))
        );
      }
    });
  }

  /**
   * Cancel VM migration task(VMware Only).
   *
   * @param   id  The migration task id.
   */
  cancelMigrateVmTask(recovery: Recovery) {
    const migrateCanceldata = {
      confirmButtonLabel: 'cancelMigration',
      declineButtonLabel: 'continueMigration',
      title: 'cancelMigration',
      copy: 'vm.cancelMigrationModalConfirmation',
    };

    this.dialogService.simpleDialog(null, migrateCanceldata).subscribe((proceed: boolean) => {
      if (proceed) {
        this.cancelRecovery(recovery.id).subscribe(
          () => {
            this.state.reload();
            this.snackBarService.open(this.translate.instant('vm.cancelMigrationModalSuccess'));
          },
          error => this.ajaxHandlerService.handler(error)
        );
      }
    });
  }

  /**
   * Sync VM migration task(VMware Only).
   *
   * @param   id  The migration task id.
   */
  syncVmMigrationTask(recovery: Recovery, childRestoreTaskIds = []) {
    const restoreTaskParams: any = {
      body: {
        restoreTaskId: recovery.legacyId,
        childRestoreTaskIds: childRestoreTaskIds,
        options: {
          multiStageRestoreAction : 'kUpdate'
        }
      }
    };

    this.restoreTaskService.UpdateRestoreTask(restoreTaskParams).pipe(
      mergeMap(() => this.restoreTasksApi.getRestoreTask(recovery.legacyId,
        this.passthroughOptionsService.requestHeaders))
    ).subscribe(() => {
      this.state.reload();
      this.snackBarService.open(this.translate.instant('vm.syncMigrationModalSuccess'));
    },
    error => this.ajaxHandlerService.handler(error)
  );
  }

  /**
   * Complete VM migration task(VMware Only).
   *
   * @param   id  The migration task id.
   */
  completeMigrateVmTask(recovery: Recovery) {
    this.dialogService.showDialog(VmCompleteMigrationDialogComponent, recovery)
      .pipe(
        filter(result => !!result),
        switchMap((result) => {
          const restoreTaskParams: any = {
            body: {
              restoreTaskId: recovery.legacyId,
              options: {
                multiStageRestoreAction: 'kFinalize',
                multiStageFinalizeParams: result,
              }
            }
          };
          return this.restoreTaskService.UpdateRestoreTask(restoreTaskParams).pipe(
            mergeMap(() => this.restoreTasksApi.getRestoreTask(recovery.legacyId,
              this.passthroughOptionsService.requestHeaders))
          );
        })).subscribe(() => {
          // reload so user can see that the cancel has been initiated
          this.state.reload();
          this.snackBarService.open(this.translate.instant('vm.completeMigrationModalSuccess'));
        }, error => this.ajaxHandlerService.handler(error)
      );
  }

  /**
   * Complete Cassandra Migration
   *
   * @param   recovery  The recovery object corresponding to the migration
   */
  completeMigrateCassandraTask(recovery: Recovery) {
    const completeMigrateCassandradata = {
      confirmButtonLabel: 'completeMigration',
      declineButtonLabel: 'cancel',
      title: 'completeMigration',
      copy: 'cassandra.completeMigrationModalConfirmation',
    };

    this.dialogService.simpleDialog(null, completeMigrateCassandradata).subscribe((proceed: boolean) => {
      if (proceed) {
        const restoreTaskParams: any = {
          body: {
            restoreTaskId: recovery.legacyId,
            options: {
              multiStageRestoreAction: 'kFinalize',
            }
          }
        };
        this.restoreTaskService.UpdateRestoreTask(restoreTaskParams).pipe(
          mergeMap(() => this.restoreTasksApi.getRestoreTask(recovery.legacyId,
            this.passthroughOptionsService.requestHeaders))
        ).subscribe(
          () => {
            this.state.reload();
            this.snackBarService.open(this.translate.instant('cassandra.completeMigrationModalSuccess'));
          },
          error => this.ajaxHandlerService.handler(error)
        );
      }
    });
  }

  /**
   * If Netapp Recovery, set recovery type i.e. isNetappSnapDiffRecovery value.
   *
   * @param recovery The recovery object.
   */
  addNetappRecoveryTypeToRecovery(mappedRecovery: Recovery): Observable<Recovery> {
    return this.v2Service.GetProtectionGroupById({
      id: mappedRecovery.protectionGroupId,
      ...this.passthroughOptionsService.requestParams
    }).pipe(
      this.untilDestroy(),
      map(group => {
        mappedRecovery.isNetappSnapDiffRecovery = typeof group.netappParams.snapMirrorConfig === 'object';
        return mappedRecovery;
      })
    );
  }

  /**
   * Take an array or recoveries, add isNetappSnapDiffRecovery value if
   * recovery is for a SnapDiff NetApp protection group.
   *
   * @param recoveries Array of recoveries.
   */
  addNetappRecoveryTypeToRecoveries(recoveries: Recovery[]): Observable<Recovery[]> {
    return this.v2Service.GetProtectionGroups({
      ...this.passthroughOptionsService.requestParams
    }).pipe(
      this.untilDestroy(),
      map((groups: ProtectionGroups) => {
        if (groups?.protectionGroups) {
          const groupIdToGroupMapping = {};
          for (const group of groups.protectionGroups) {
            groupIdToGroupMapping[group.id] = group;
          }
          for (const recovery of recoveries) {
            recovery.isNetappSnapDiffRecovery = Environment[recovery.environment] === Environment.kNetapp &&
              recovery.action === RecoveryAction.RecoverNasVolume && typeof groupIdToGroupMapping[
                recovery.protectionGroupId]?.netappParams?.snapMirrorConfig === 'object';
          }
        }
        return recoveries;
      })
    );
  }

  /**
   * Get the PIT ranges & info for an entity and date range.
   *
   * @param requestArg Specifies the request parameters to restore points for time range API
   * @returns Restore points in time range.
   */
  getPITRestorePointsInTimeRange(requestArg): Observable<any> {
    const useCache = get(requestArg, 'endTimeUsecs') < Date.now() * 1000;
    const cacheKey = JSON.stringify(requestArg);

    if (this.PIT_CACHE[cacheKey]) {
      return of(structuredClone(this.PIT_CACHE[cacheKey]));
    }

    return this.getRestorePointsInTimeRange(requestArg).pipe(
      map(response => {
        if (useCache) {
          this.PIT_CACHE[cacheKey] = structuredClone(response);
        }
        return isEmpty(response) ? throwError(response) : of(response);
      }),
      catchError(error => {
        console.error('Error fetching restore points', error);
        return of(null);
      })
    );
  }


  /**
   * List Restore Points i.e. returns the snapshots in in a given time range.
   *
   * @param body Specifies the request parameters to restore points for time range API.
   * @returns Observable for Restore points in time range.
   */
  getRestorePointsInTimeRange({
    environment,
    protectionSourceId,
    startTimeUsecs = 0,
    endTimeUsecs = 0,
    jobUid = null,
    protectionGroupIds = null,
    ...params
  }): Observable<any> {

    if(flagEnabled(this.irisCtx.irisContext, 'enableV2ApiForDbAdaptor')) {
      const v2Params: GetRestorePointsInTimeRangeParams = {
        protectionGroupIds,
        environment,
        sourceId: protectionSourceId,
        startTimeUsecs,
        endTimeUsecs,
        ...params,
      };

      return this.recoveriesService.GetRestorePointsInTimeRange(v2Params);
    } else {
      const v1Params: RestoreTasksServiceApi.GetRestorePointsForTimeRangeParams = {
        body: {
          jobUids: [jobUid],
          environment: environment as RestorePointsForTimeRangeParam['environment'],
          protectionSourceId,
          startTimeUsecs,
          endTimeUsecs,
          ...params,
        },
      };

      return this.restoreTaskService.GetRestorePointsForTimeRange(v1Params);
    }
  }
}
