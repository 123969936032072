import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  GlobalObjectTagsList,
  HeliosTaggingApiService,
  ListAssociatedTagsResp,
  SnapshotTags,
  SnapshotTagsList,
  Tag,
  TagAction,
  TagCategory,
  TagList,
  TagType,
  TagsActionParams,
  TagsActionResp,
} from '@cohesity/api/helios-metadata';
import { SnackBarService } from '@cohesity/helix';
import { IrisContextService } from '@cohesity/iris-core';
import { TagsService } from '@cohesity/shared/tags';
import { AjaxHandlerService, AutoDestroyable } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { Observable, forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

/**
 * Enum for object types supported by tags.
 */
export enum ObjectTypesForTags {
  Snapshots = 'snapshots',
  GlobalObjects = 'globalObjects',
}

/**
 * Decorated associated object tags.
 */
export interface DecoratedAssociatedObjectTags {
  /**
   * The object id. (can be snapshot id, object id, etc..)
   */
  objectId: string;

  /**
   * List of tags associated with the object.
   */
  tags: TagList;
}

/**
 * Dialog data model for callers.
 */
export interface TagSelectorDialogData {
  /**
   * Objects ids for which we need to show selected tags.
   */
  globalObjectsIds?: string[];

  /**
   * Snapshot ids for which we need to show selected tags.
   */
  snapshotIds?: string[];
}

/**
 * Represents the response from this dialog to the caller.
 */
export interface TagSelectorDialogResponse {
  /**
   * The action taken on the dialog.
   */
  action: 'close' | 'complete';

  /**
   * list of currently applied tags on the selected objects
   */
  appliedTags?: TagList;
}

/**
 * Decorated tags states for checkbox.
 */
export interface DecoratedTagStates {
  /**
   * Is selected flag for checkbox.
   */
  isSelected: boolean;

  /**
   * Is in determinate flag for checkbox.
   */
  isInDeterminate: boolean;
}

/**
 * DecoratedTag has additional props like isSelected for UI.
 */
export interface DecoratedTag extends Tag {
  /**
   * Decorated tag state.
   */
  state: DecoratedTagStates;
}

@Component({
  selector: 'dg-sc-tag-selector-dialog',
  templateUrl: './tag-selector-dialog.component.html',
  styleUrls: ['./tag-selector-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TagSelectorDialogComponent extends AutoDestroyable implements OnInit {
  /**
   * Form control for search input.
   */
  searchFormControl: FormControl = this.fb.control('');

  /**
   * List of all available tags.
   */
  tags: DecoratedTag[] = [];

  /**
   * Filtered tags list.
   */
  filteredTags: DecoratedTag[] = [];

  /**
   * Is the dialog getting submitted.
   */
  isSubmitting = false;

  /**
   * Loading state.
   */
  loading = false;

  /**
   * List of object ids built from dialog data.
   */
  globalObjectIds: string[] = [];

  /**
   * List of snapshot ids built from dialog data.
   */
  snapshotIds: string[] = [];

  /**
   * List of associated object tags built from associated tags list (api)
   */
  associatedObjectTags: DecoratedAssociatedObjectTags[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: TagSelectorDialogData,
    private ajaxHandlerService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<TagSelectorDialogComponent>,
    private irisCtx: IrisContextService,
    private fb: FormBuilder,
    private hmsService: HeliosTaggingApiService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    readonly tagsService: TagsService
  ) {
    super();
  }

  ngOnInit() {
    this.setDialogData();
    this.loadData();

    this.searchFormControl.valueChanges
      .pipe(
        this.untilDestroy(),
        map((tag: string | null) => (tag ? this.filter(tag) : [...this.tags]))
      )
      .subscribe((tags) => {
        this.filteredTags = tags;
        this.cdr.detectChanges();
      });
  }

  /**
   * Loads tags data.
   */
  loadData() {
    this.loading = true;
    this.cdr.detectChanges();

    const requiredTagTypes = this.getAllowedTagType();

    const allTags$ = this.hmsService
      .listTagsOp({
        categories: [TagCategory.Security],
        types: requiredTagTypes,
      })
      .pipe(map((resp) => resp?.tags ?? []));

    const associatedTags$ = this.hmsService.listAssociatedTagsOp({
      body: {
        snapshotIds: this.snapshotIds,
        globalObjectIds: this.globalObjectIds,
      },
    });

    forkJoin([allTags$, associatedTags$])
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.loading = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe(this.handlerTagResponse, this.ajaxHandlerService.handler);
  }

  /**
   * Generates a list of supported tag type based on the current user
   *
   * @returns list of supported tag type for the selection dialog
   */
  getAllowedTagType() {
    const requiredTagTypes = [TagType.Custom];
    const ctx = this.irisCtx.irisContext;
    const isSuperAdmin = ctx.user.roles?.includes('COHESITY_MCM_SUPER_ADMIN');
    if (isSuperAdmin && this.dialogData.snapshotIds?.length) {
      // fetch system tags only if the dialog is opened in snapshot's context. As currently system tags are only
      // applied on the snapshots
      requiredTagTypes.push(TagType.System);
    }

    return requiredTagTypes;
  }

  /**
   * Handler for tag selection.
   *
   * @param tag the selected tag.
   */
  onTagSelect(selectedTag: DecoratedTag): void {
    selectedTag.state.isSelected = !selectedTag.state.isSelected;

    if (selectedTag.state.isInDeterminate) {
      selectedTag.state.isInDeterminate = false;
    }

    /**
     * Copy the state of filtered selected tag to the global tag.
     */
    const thisTag = this.tags.find((tag) => tag.uuid === selectedTag.uuid);
    thisTag.state = { ...selectedTag.state };

    this.cdr.detectChanges();
  }

  /**
   * Handler apply tags.
   */
  applyTags() {
    let apiCalls$ = null;

    if (this.snapshotIds && this.snapshotIds.length) {
      apiCalls$ = this.processTagsAndBuildApiCallsForTagAction(
        this.associatedObjectTags,
        ObjectTypesForTags.Snapshots
      );
    } else if (this.globalObjectIds && this.globalObjectIds.length) {
      apiCalls$ = this.processTagsAndBuildApiCallsForTagAction(
        this.associatedObjectTags,
        ObjectTypesForTags.GlobalObjects
      );
    }

    if (!apiCalls$) {
      return;
    }

    this.isSubmitting = true;
    this.cdr.detectChanges();

    // Step 4: Perform api call to add/remove and handle responses.
    forkJoin(apiCalls$)
      .pipe(
        this.untilDestroy(),
        finalize(() => {
          this.isSubmitting = false;
          this.cdr.detectChanges();
        })
      )
      .subscribe(() => {
        this.snackBarService.open(this.translateService.instant('dg.sc.inventory.updateTagsSuccess'));

        const response: TagSelectorDialogResponse = {
          action: 'complete',
          appliedTags: this.tags.filter((tag) => tag.state.isSelected),
        };
        this.dialogRef.close(response);
      }, this.ajaxHandlerService.handler);
  }

  /**
   * Handler to dismiss the dialog.
   */
  closeDialog() {
    const response: TagSelectorDialogResponse = { action: 'close' };
    this.dialogRef.close(response);
  }

  private processTagsAndBuildApiCallsForTagAction(
    objectTags: DecoratedAssociatedObjectTags[],
    objectTypesForTags: ObjectTypesForTags
  ): Observable<TagsActionResp>[] {
    // Step 1: Build base payload object.
    const { addTagsPayload, removeTagsPayload } = this.initializePayloads();

    // Step 2: Iterate over snapshot's tags and short tags to add or remove.
    const allowedTagTypes = this.getAllowedTagType();
    for (const object of objectTags) {
      const tagsInObjects = object.tags.filter((objectTag) => allowedTagTypes.includes(objectTag.type));
      if (tagsInObjects.length) {
        for (const tag of tagsInObjects) {
          const isThisTagSelected = this.tags.some(
            (filteredTag) => filteredTag.name === tag.name && filteredTag.state.isSelected
          );
          const payload = isThisTagSelected ? addTagsPayload : removeTagsPayload;
          payload.tagUuids.push(tag.uuid);
        }
      }
    }

    // Step 3: Populate the originally selected snapshot ids or object ids in the payload.
    [addTagsPayload, removeTagsPayload].forEach((payload) => {
      switch (objectTypesForTags) {
        case ObjectTypesForTags.Snapshots:
          payload.snapshotIds.push(...this.snapshotIds);
          break;
        case ObjectTypesForTags.GlobalObjects:
          payload.globalObjectIds.push(...this.globalObjectIds);
          break;
        default:
          break;
      }
    });

    // Step 4: Add the selected tags which is not in the snapshot's tags.
    const selectedTagsIds = this.tags
      .filter((tag) => tag.state.isSelected && tag.type === TagType.Custom).map((tag) => tag.uuid);
    addTagsPayload.tagUuids = Array.from(new Set([...addTagsPayload.tagUuids, ...selectedTagsIds]));

    const addTags$ = addTagsPayload.tagUuids.length
      ? this.hmsService.tagsActionOp({ body: addTagsPayload, forceSyncFlow: 'true' })
      : null;
    const removeTags$ = removeTagsPayload.tagUuids.length
      ? this.hmsService.tagsActionOp({ body: removeTagsPayload, forceSyncFlow: 'true' })
      : null;

    const apiCalls$ = [addTags$, removeTags$].filter((api) => api !== null);

    return apiCalls$;
  }

  /**
   * Initializes payload for tag actions.
   *
   * @returns add and remove TagActionParams.
   */
  private initializePayloads(): { addTagsPayload: TagsActionParams; removeTagsPayload: TagsActionParams } {
    const addTagsPayload: TagsActionParams = {
      action: TagAction.Add,
      tagUuids: [],
      snapshotIds: [],
      globalObjectIds: [],
    };

    const removeTagsPayload: TagsActionParams = {
      action: TagAction.Remove,
      tagUuids: [],
      snapshotIds: [],
      globalObjectIds: [],
    };

    return { addTagsPayload, removeTagsPayload };
  }

  /**
   * Response handler for tags.
   *
   * @param resp combination of Tag[] and ListAssociatedTagsResp
   */
  private handlerTagResponse = (resp: [Tag[], ListAssociatedTagsResp]) => {
    const [tags, associatedTags] = resp;
    const decoratedTags = this.decorateTags([...tags], {
      isSelected: false,
      isInDeterminate: false,
    });

    this.tags = [...decoratedTags];
    this.filteredTags = [...decoratedTags];

    if (associatedTags.snapshotTags && associatedTags.snapshotTags.length) {
      this.associatedObjectTags = [...this.decorateAssociatedObjectTags(associatedTags, ObjectTypesForTags.Snapshots)];
      this.selectFilteredSnapshotsTags(associatedTags.snapshotTags);
    } else if (associatedTags.globalObjectTags && associatedTags.globalObjectTags.length) {
      this.associatedObjectTags = [
        ...this.decorateAssociatedObjectTags(associatedTags, ObjectTypesForTags.GlobalObjects),
      ];
      this.selectFilteredGlobalObjectTags(associatedTags.globalObjectTags);
    }
  };

  /**
   * Mark filtered snapshots tags with selected state based on tags to be selected.
   *
   * @param objectTags list of object snapshot tags to select.
   */
  private selectFilteredSnapshotsTags(objectTags: SnapshotTagsList) {
    const selectedSnapshots = this.snapshotIds;

    this.filteredTags = this.filteredTags
      .map(tag => {
        const isAssociated = objectTags.filter((object: SnapshotTags) =>
          object.tags.some(objectTag => objectTag.uuid === tag.uuid)
        );

        const isTagAssociatedToEverySelectedObjects = selectedSnapshots.every(selectedSnapshot =>
          isAssociated.find(snapshotTag => snapshotTag.snapshotId === selectedSnapshot)
        );

        // set initial state.
        tag.state.isSelected = false;
        tag.state.isInDeterminate = false;

        /**
         * Tag is associated to every selected snapshots,
         * meaning we need to show the selected checkmark.
         */
        if (isAssociated.length && isTagAssociatedToEverySelectedObjects) {
          tag.state.isSelected = true;
          tag.state.isInDeterminate = false;
        }

        /**
         * Tag is associated but is not associated to every selected snapshots
         * then the tag is in a indeterminite state.
         */
        if (isAssociated.length && !isTagAssociatedToEverySelectedObjects) {
          tag.state.isSelected = false;
          tag.state.isInDeterminate = true;
        }

        return tag;
      })
      // only include the selected system tags
      .filter(tag => tag.type !== TagType.System || (tag.type === TagType.System && tag.state.isSelected));
  }

  /**
   * Mark filtered global object tags with selected state based on tags to be selected.
   *
   * @param objectTags list of global object tags to select.
   */
  private selectFilteredGlobalObjectTags(objectTags: GlobalObjectTagsList) {
    const selectedGlobalObjects = this.globalObjectIds;

    this.filteredTags.forEach((tag) => {
      const isAssociated = objectTags.filter((object: SnapshotTags) =>
        object.tags.some((objectTag) => objectTag.uuid === tag.uuid)
      );

      const isTagAssociatedToEverySelectedObjects = selectedGlobalObjects.every((selectedGlobalObject) =>
        isAssociated.find((globalObjectTag) => globalObjectTag.globalObjectId === selectedGlobalObject)
      );

      // set initial state.
      tag.state.isSelected = false;
      tag.state.isInDeterminate = false;

      /**
       * Tag is associated to every selected global object,
       * meaning we need to show the selected checkmark.
       */
      if (isAssociated.length && isTagAssociatedToEverySelectedObjects) {
        tag.state.isSelected = true;
        tag.state.isInDeterminate = false;
      }

      /**
       * Tag is associated but is not associated to every selected global objects
       * then the tag is in a indeterminite state.
       */
      if (isAssociated.length && !isTagAssociatedToEverySelectedObjects) {
        tag.state.isSelected = false;
        tag.state.isInDeterminate = true;
      }
    });
  }

  /**
   * Filters tags list based on filter value.
   *
   * @param value the filter value.
   * @returns filtered list of tags.
   */
  private filter(value: string): DecoratedTag[] {
    const filterValue = value.toLowerCase();

    return this.tags.filter((tag) => tag.name.toLowerCase().includes(filterValue));
  }

  /**
   * Method to set dialog data to component class.
   */
  private setDialogData() {
    this.globalObjectIds = this.dialogData.globalObjectsIds;
    this.snapshotIds = this.dialogData.snapshotIds;
  }

  /**
   * Decorates tags list with ui states like isSelected etc.
   *
   * @param tags List of tags.
   * @returns Decorated Tags list.
   */
  private decorateTags(tags: Tag[], state: DecoratedTagStates): DecoratedTag[] {
    return tags.map((tag) => ({
      ...tag,
      state: { ...state },
    }));
  }

  /**
   * Decorates tags list with ui states like isSelected etc.
   *
   * @param tags List of tags.
   * @returns Decorated Tags list.
   */
  private decorateAssociatedObjectTags(
    listAssociatedTags: ListAssociatedTagsResp,
    objectType: ObjectTypesForTags
  ): DecoratedAssociatedObjectTags[] {
    switch (objectType) {
      case ObjectTypesForTags.Snapshots:
        return listAssociatedTags.snapshotTags.map((objectTag) => ({
          objectId: objectTag.snapshotId,
          tags: objectTag.tags,
        }));
      case ObjectTypesForTags.GlobalObjects:
        return listAssociatedTags.globalObjectTags.map((objectTag) => ({
          objectId: objectTag.globalObjectId,
          tags: objectTag.tags,
        }));
      default:
        break;
    }
  }
}
