import { Component, OnInit, Self } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { McmSource, SourceServiceApi } from '@cohesity/api/v2';
import { BehaviorSubject, combineLatest, iif, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs/operators';
import { McmSourceWithRmsConnections } from 'src/app/modules/helios-source-registration/azure-registration/azure-saas-connection-form.model';
import { AzureSaasConnectionFormService } from 'src/app/modules/helios-source-registration/azure-registration/azure-saas-connection/azure-saas-connection-form.service';
import { SourceTreeState } from 'src/app/modules/protection/protection-builder/components/form-controls';
import { DmsConnectionService } from 'src/app/modules/sources/shared';
import { ProtectionItemName } from '../../../models';

import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';
import { PassthroughOptionsService } from 'src/app/core/services';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PrivateEndpointsDialogComponent } from './sas-private-endpoints-dialog/private-endpoints-dialog/private-endpoints-dialog.component';
import { RegionSubscription } from './region-subscription.interface';
import _ from 'lodash';
import {
  PrivateEndpointDialogData
} from './sas-private-endpoints-dialog/private-endpoints-dialog/private-endpoints-dialog.interface';
import {
  getConnectionVNetIds,
} from 'src/app/modules/restore/hypervisor-shared/azure/shared/recover-vm-azure.utils';

@Component({
  selector: 'coh-settings-list-sas-url',
  templateUrl: './settings-list-sas-url.component.html',
  styleUrls: ['./settings-list-sas-url.component.scss'],
  providers: [DmsConnectionService, AzureSaasConnectionFormService]
})
export class SettingsListSasUrlComponent extends BaseProtectionBuilderComponent<any, any> implements OnInit {
  /**
   * Selected protection source node.
   */
  protected protectionSource$ = new BehaviorSubject<ProtectionSourceNode>(null);

  /**
   * List of sources with registration details
   */
  mcmSources: McmSource[];

  /**
   * The list of source ids selected for protection.
   */
  sourceIds$ = new BehaviorSubject<number[]>(null);

  /**
   * Flag for link to show sas url.
   */
  showLink = false;

  /**
   * Observable list of restricted VNETs
   *
   * @type {BehaviorSubject<number[]>}
   * @private
   */
  private restrictedVNets$ = new BehaviorSubject<number[]>([]);

  constructor(
    @Self() private azureSaasConnectionService: AzureSaasConnectionFormService,
    private mcmSourceService: SourceServiceApi,
    private protectionSourcesApi: ProtectionSourcesServiceApi,
    private passthroughOptionsService: PassthroughOptionsService,
    private irisContext: IrisContextService,
    public dialog: MatDialog
  ) {
    super();
  }

  /**
   * Endpoint types list.
   */
  endPointTypes = [
    { key: 'publicEndpoint', value: 'public' },
    { key: 'privateEndpoint', value: 'private' },
  ];

  /**
   * Selected endpoint type.
   */
  selectedEndpointType: string;

  /**
   * Default privacy value.
   */
  _value = false;

  /**
   * DMS user Flag.
   */
  isDmsUser = isDmsScope(this.irisContext.irisContext);

  /**
   * Internal FormGroup for managing time and timezone values.
   */
  sasUrlFormGroup: FormGroup = new FormGroup({
    endPointType: new FormControl('public', Validators.nullValidator),
    privateEndpointFields: new FormArray([]),
  });

  /**
   * selected private endpoints.
   */
  networkPrivateEndPoint: RegionSubscription[] = [];

  /**
   * Override of addFormControl from BaseProtectionBuilderComponent so this component's FormControl isn't required.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.sasUrlFormGroup);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    if (this.protectionGroup?.dataTransferInfo?.isPrivateNetwork) {
      this.showLink = true;
      const endPointType = 'private';
      const validator = this.selectedEndpointType === 'private' ? Validators.required : [];
      const privateEndpointFields = (this.protectionGroup?.dataTransferInfo?.privateNetworkInfoList ?? []).map(
        e =>
          new FormGroup({
            region: new FormControl(e.location, validator),
            virtualNetwork: new FormControl(e.vpn.id, validator),
            subnet: new FormControl(e.subnet.id, validator),
          })
      );

      this.sasUrlFormGroup.patchValue({ endPointType });
      const privateEndpointFieldsFormArray = this.sasUrlFormGroup.get('privateEndpointFields') as FormArray;
      privateEndpointFields.forEach(element => {
        privateEndpointFieldsFormArray.push(element);
      });
      this.selectedEndpointType = 'private';
    } else {
      this.showLink = false;
      const endPointType = 'public';
      this.sasUrlFormGroup.patchValue({ endPointType });
      this.selectedEndpointType = 'public';
    }
  }

  /**
   * Handles the selection of end points for protection group.
   *
   * @param event  Mat Radio change event
   */
  handleEndPointSelection(event: MatRadioChange) {
    this.formControl.get('endPointType').setValue(event.value);
    this.selectedEndpointType = event.value;
    if (this.selectedEndpointType === 'private' && !this.privateEndpointFields.length) {
      if (this.isDmsUser) {
        this.networkPrivateEndPoint.forEach(ele => {
          this.addPrivateEndpointWithSubscription(ele.subscription, ele.region);
        });
      } else {
        this.addPrivateEndpointFields();
      }
      this.showLink = true;
    }

    if (this.selectedEndpointType === 'public') {
      this.showLink = false;
      (this.sasUrlFormGroup.get('privateEndpointFields') as FormArray).clear();
    }
  }

  ngOnInit() {
    const sourceControl = this.formGroup?.parent?.get(ProtectionItemName.Source);
    const objectsControl = this.formGroup?.parent?.get(ProtectionItemName.Objects);

    // Source & Object controls not available in readOnly context
    if (sourceControl) {
      sourceControl.valueChanges
        .pipe(
          startWith<ProtectionSourceNode, ProtectionSourceNode>(sourceControl.value),
          distinctUntilChanged((prev, curr) => prev?.protectionSource?.id === curr?.protectionSource?.id),
          this.untilDestroy())
        .subscribe((source: ProtectionSourceNode) => {
          if (source?.protectionSource?.id) {
            this.fetchSourceDetails(source.protectionSource.id);
          }
        });
    }
    if (objectsControl) {
      objectsControl?.valueChanges
        .pipe(
          startWith<SourceTreeState, SourceTreeState>(objectsControl.value),
          filter(value => !!value?.selection),
          this.untilDestroy())
        .subscribe(selectedObjects => {
          this.sourceIds$.next(selectedObjects.selection.sourceIds);
        });
    }

    this.protectionSource$.pipe(this.untilDestroy()).subscribe(source => {
      const regionSubscription = this.getNodesByIds(source, this.sourceIds$.value);
      this.networkPrivateEndPoint = _.uniqWith(regionSubscription, _.isEqual);
    });
  }

  /**
   * Retrieves the selected source registration details
   *
   * @param sourceId The selected source ID
   * @return Observable of source with full registration and connection details
   */
  private getSourceRegistrationDetails(sourceId: number): Observable<McmSourceWithRmsConnections> {
    // As there's currently no available api call to get source registration by sourceId,
    // Find source registration ID from available source list based on source ID to get source registration details
    return this.mcmSourceService
      .McmGetProtectionSources({
        excludeProtectionStats: true,
        ...this.passthroughOptionsService.requestParams,
      })
      .pipe(
        map((response) => {
          const sourceInfo = (response.sources ?? []).flatMap((source) => source.sourceInfoList)
            .find((source) => Number(source.sourceId) === Number(sourceId) &&
              source.regionId === this.passthroughOptionsService.regionId);
          return sourceInfo?.registrationId ?? null;
        }),
        switchMap(sourceRegId => sourceRegId ? this.azureSaasConnectionService.fetchSourceRegistrationData(
          sourceRegId, this.passthroughOptionsService.regionId) : of(null)
        )
      );
  }


  /**
   * Fetch source tree.
   */
  fetchSourceDetails(id: number) {
    // If page context is in DMaaS and flag for vnet restriction is enabled, get source registration details
    const dmsVNETRestriction = this.isDmsUser && flagEnabled(this.irisContext.irisContext, 'dmsAzureSaaSConnectionVNETRestriction');

    combineLatest([
      this.protectionSourcesApi
        .ListProtectionSources({
          id: id,
          excludeTypes: ['kResourcePool'],
          environments: ['kAzure'],
          includeVMFolders: true,
          includeSystemVApps: true,
          includeEntityPermissionInfo: true,
          allUnderHierarchy: false,
          ...this.passthroughOptionsService.requestParams,
        }).pipe(map(resp => resp?.[0])),
      iif(() => dmsVNETRestriction, this.getSourceRegistrationDetails(id), of(null))
    ])
      .pipe(this.untilDestroy())
      .subscribe(([source, sourceRegDetails]: [ProtectionSourceNode, McmSourceWithRmsConnections]) => {
        this.protectionSource$.next(source);
        if (sourceRegDetails?.rmsConnections?.length) {
          this.restrictedVNets$.next(getConnectionVNetIds(source, sourceRegDetails.rmsConnections));
        }
      });
  }

  /**
   * Function to add a new item to the privateEndpointFields list.
   */
  addPrivateEndpointFields() {
    const privateEndPointField = new FormGroup({
      region: new FormControl('', Validators.required),
      virtualNetwork: new FormControl('', Validators.required),
      subnet: new FormControl('', Validators.required),
    });

    (this.sasUrlFormGroup.get('privateEndpointFields') as FormArray).push(privateEndPointField);
  }

  /**
   * Returns FormControls[] from privateEndpointFields FormArray.
   */
  get privateEndpointFields(): AbstractControl[] {
    return (this.sasUrlFormGroup.get('privateEndpointFields') as FormArray).controls;
  }

  /**
   * Function to remove a form control item from the privateEndpointFields list.
   *
   * @param index   Index of the item to be removed.
   */
  removePrivateEndPointField(index: number) {
    (this.sasUrlFormGroup.get('privateEndpointFields') as FormArray).removeAt(index);
  }

  /**
   * Create endpoints for DMASS user.
   *
   * @param subscription subscription value.
   * @param region region value.
   */
  addPrivateEndpointWithSubscription(subscription: string, region: string) {
    const privateEndPointField = new FormGroup({
      subscription: new FormControl(subscription),
      region: new FormControl(region),
      virtualNetwork: new FormControl('', Validators.required),
      subnet: new FormControl('', Validators.required),
    });

    (this.sasUrlFormGroup.get('privateEndpointFields') as FormArray).push(privateEndPointField);
  }

  /**
   * Open dialog for sas details.
   */
  openDialog() {
    const data: PrivateEndpointDialogData = {
      protectionSource$: this.protectionSource$,
      protectionFieldValue: this.sasUrlFormGroup.get('privateEndpointFields').value,
      restrictedVNets$: this.restrictedVNets$
    };
    this.dialog
      .open<PrivateEndpointsDialogComponent, PrivateEndpointDialogData>(PrivateEndpointsDialogComponent, {
        width: '42rem',
        data: data,
      })
      .afterClosed()
      .subscribe(resp => {
        if (resp?.length > 0) {
          this.sasUrlFormGroup.get('privateEndpointFields').patchValue(resp);
        }
        this.sasUrlFormGroup.markAllAsTouched();
      });
  }

  /**
   * Given a tree, get all the region-subscription list of specific type.
   *
   * @param sourceNode source node
   * @param ids Selected Ids
   * @returns RegionSubscription list
   */
  getNodesByIds(sourceNode: ProtectionSourceNode, ids: number[]): RegionSubscription[] {
    const result = [];
    sourceNode?.nodes?.forEach((nodeLvlOne: ProtectionSourceNode) => {
      nodeLvlOne?.nodes?.forEach((nodeLvlTwo: ProtectionSourceNode) => {
        nodeLvlTwo?.nodes?.forEach((nodeLvlThree: ProtectionSourceNode) => {
          if (
            ids?.includes(nodeLvlThree.protectionSource?.id) &&
            nodeLvlThree?.protectionSource?.azureProtectionSource?.location
          ) {
            result.push({
              region: nodeLvlThree.protectionSource.azureProtectionSource.location,
              subscription: nodeLvlOne.protectionSource.name,
            });
          }
        });
      });
    });
    return result;
  }
}
