import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import {
  appConfigMap,
  AppName,
  AppType,
  flagEnabled,
  hasCompanyContext,
  IrisContext,
  IrisContextService,
  isAllClustersScope,
  isClusterScope,
  isDmsScope,
  isFlashRecover,
  isHeliosTenantUser,
  isIbmBaaSEnabled,
  isMcm,
  isMcmOnPrem,
  isSecurityCenterUser,
  isTenantUser,
  PillarType,
  getUserPrivilegeCheck,
  translateAppName,
} from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';
import { isNavItemHidden } from 'src/app/core/app-layout/is-nav-item-hidden';
import { OrganizationsService, StateManagementService } from 'src/app/core/services';
import { ClusterManagementUtilsService } from 'src/app/modules/software-upgrades/services/cluster-management-utils.service';
import { Routes } from 'src/app/shared/constants';

import { ServiceNavProvider } from '../service-nav-provider';

/**
 * This Service configures the Cluster Manager navigation menu with iris.
 */
@Injectable({ providedIn: 'root' })
export class ClusterManagerNavService implements ServiceNavProvider {
  pillar: PillarType = 'protection';

  app: AppType = 'clusterManager';

  private appName: AppName = 'clusterManager';

  get serviceNavTitle(): string {
    return translateAppName(this.translate)(this.appName);
  }

  get showNavBranding(): boolean {
    return isMcm(this.irisContextService.irisContext);
  }

  serviceNavTitleIcon = appConfigMap[this.appName].icon;

  serviceNavTitleClass = 'cluster-manager';

  /**
   * used to show the cluster dropdown on nav banner in classic way
   */
  get useClassicClusterDropdown(): boolean {
    return !this.showNavBranding;
  }

  /**
   * Some nav items in Helios are dependant on organization feature.
   * Use this to identify if the feature is on. (Different from feature flag
   * being on. This is controlled by the user.)
   */
  get isOrganizationsEnabled(): boolean {
    return this.organizationsService.isOrganizationsEnabled;
  }

  /**
   * Show NG cluster management pages
   */
  get showNGClusterManagement(): boolean {
    return (
      (this.clusterManagementUtilsService.showNgClusterManagementPage())
      && flagEnabled(this.irisContextService.irisContext, 'ngHeliosClusterUpgrade')
    );
  }

  /**
   * used to show the runbooks nav option
   */
  get shouldShowRunbooks(): boolean {
    return true;
  }

  constructor(
    private translate: TranslateService,
    protected irisContextService: IrisContextService,
    private organizationsService: OrganizationsService,
    private clusterManagementUtilsService: ClusterManagementUtilsService
  ) {}

  getNavItems(ctx: IrisContext, stateManagementService: StateManagementService): NavItem[] {
    let navList: NavItem[] = this.getStandardNavList(ctx, stateManagementService);

    if (this.isAllClustersScope) {
      // Items for Helios All Cluster.
      navList = this.getHeliosAllClusterNavItems(ctx, stateManagementService);
    }

    return navList;
  }

  /**
   * Determines whether all cluster scope is selected and used to switch navs
   * eg. for `cluster-upgrades` in Helios and `cluster-upgrade` in on-prem
   */
  get isAllClustersScope(): boolean {
    return isAllClustersScope(this.irisContextService.irisContext);
  }

  /**
   * Returns a fresh copy of navList for main navigation.
   *
   * @returns The list of nav items.
   */
  getStandardNavList(irisContext: IrisContext, stateManagementService: StateManagementService): NavItem[] {
    const navList: NavItem[] = [
      // Add the dashboards..
      this.getDashboardNavs(irisContext, stateManagementService),

      // Data Protection Section
      this.getStandardDataProtectionNavOptions(irisContext),

      // Infrastructure
      ...this.getInfrastructureNavs(irisContext),

      // Disable file services tab for PXG1 platform (ENG-160086).
      this.getStandardSmartFilesNavOptions(irisContext),

      // Test & Dev
      this.getStandardTestNDevNavOptions(),

      // Marketplace Section
      this.getStandardMarketplaceNavOptions(irisContext),

      // System Section
      this.getStandardSystemNavOptions(irisContext),

      // reporting
      this.getReportingNavItem(irisContext, 'onPrem'),

      // settings
      this.getStandardSettingsNavOptions(irisContext),
    ];

    return this.getValidNavItems(navList);
  }

  /**
   * Gets settings nav item and all accessible subNav items.
   *
   * @returns   settings nav and sub items, if any are accessible.
   */
  protected getStandardSettingsNavOptions(irisContext: IrisContext): NavItem<any, any> {
    return {
      displayName: 'settings',
      state: 'cluster',
      icon: 'settings!outline',
      subNavList: [
        ...this.getSettingsItems(irisContext)
      ],
      options: {
        allowCustomization: false
      }
    };
  }

  /**
   * Gets data protection nav item and all accessible subNav items.
   *
   * @returns data protection nav and sub items, if any are accessible.
   */
  protected getStandardDataProtectionNavOptions(irisContext: IrisContext): NavItem<any, any> {
    return {
      displayName: 'dataProtection',
      state: 'protection-group',
      icon: 'helix:protection',
      subNavList: [
        {
          displayName: 'protection',
          state: 'protection-group',
        },
        {
          displayName: 'recoveries',
          state: flagEnabled(irisContext, 'ngRecovery') ? 'recovery.list' : 'recover',
        },
        {
          displayName: 'sources',
          state: flagEnabled(irisContext, 'ngSourceEnabled') ? 'sources-ng' : 'sources-new',
        },
        {
          displayName: 'policies',
          state: isAllClustersScope ?
            flagEnabled(irisContext, 'ngPolicyHelios') ?
              'global-policies' :
              'protection-policy' :
            'protection-policy',
        },
        {
          displayName: 'cloudRetrieve',
          state: 'cloud-retrieval',
        },
        this.shouldShowRunbooks ? {
          displayName: 'Runbooks',
          state: 'dr-runbook',
        } : null,
      ],
    };
  }

  /**
   * Gets smart files nav item and all accessible subNav items.
   *
   * @returns   smart files nav and sub items, if any are accessible.
   */
  private getStandardSmartFilesNavOptions(irisContext: IrisContext): NavItem<any, any> {
    return (
      flagEnabled(irisContext, 'hideFileServicesNav')) ||
      !irisContext.privs.STORAGE_VIEW ? undefined : {
      displayName: 'SmartFiles',
      state: 'ng-views',
      icon: 'helix:file-services',
      subNavList: [
        {
          displayName: 'views',
          state: 'ng-views.views',
        },
        flagEnabled(irisContext, 'ngFileServicesDashboardV2') ? {
          displayName: 'consumption',
          state: 'file-services-consumption-details',
        } : null,
        flagEnabled(irisContext, 'ngFileServicesDashboardV2') ? {
          displayName: 'performance',
          state: 'file-services-performance-details',
        } : null,
        flagEnabled(irisContext, 'ngFileServicesDashboardV2') ? {
          displayName: 'clientConnections',
          state: 'file-services-client-distribution-details',
        } : null,
        isFlashRecover(irisContext) ? null : {
          displayName: 'nasTiering',
          state: flagEnabled(irisContext, 'dataTieringEnabled') ? 'data-tiering.dashboard' : 'dataMigration',
        },
        {
          displayName: 'antivirus',
          state: flagEnabled(irisContext, 'ngAntivirus') ? 'ng-antivirus' : 'antivirus',
        },
      ],
    };
  }

  /**
   * Gets test & dev nav item and all accessible subNav items.
   *
   * @returns   test & dev nav and sub items, if any are accessible.
   */
  protected getStandardTestNDevNavOptions(): NavItem<any, any> {
    return {
      displayName: 'testAndDev',
      state: flagEnabled(this.irisContextService.irisContext, 'ngTestAndDev') ? 'clone.task-list' : 'devops.clone',
      icon: 'build!outline'
    };
  }

  /**
   * Gets marketplace nav item and all accessible subNav items.
   *
   * @returns   marketplace nav and sub items, if any are accessible.
   */
  protected getStandardMarketplaceNavOptions(irisContext: IrisContext): NavItem<any, any> {
    return {
      displayName: 'marketplace',
      state: 'apps-management',
      icon: 'store!outline',
      subNavList: [
        {
          displayName: 'summary',
          state: 'app-store-dashboard'
        },
        {
          displayName: 'myApps',
          state: 'apps-management',
        },
        isTenantUser(irisContext) || isHeliosTenantUser(irisContext) || isMcmOnPrem(irisContext) ? null : {
          displayName: 'allApps',
          state: undefined,
          disabled: !hasCompanyContext(this.irisContextService.irisContext),
          href: Routes.marketplace
        },
      ].filter(Boolean),
    };
  }

  /**
   * Gets system nav item and all accessible subNav items.
   *
   * @returns   system nav and sub items, if any are accessible.
   */
  protected getStandardSystemNavOptions(irisContext: IrisContext): NavItem<any, any> {
    return {
      displayName: 'system',
      state: 'cluster',
      icon: 'helix:system',
      subNavList: [
        flagEnabled(irisContext, 'ngHealth') ? {
          displayName: 'health',
          state: 'health',
        }
          : {
            displayName: 'monitoringAlerts',
            state: 'alerts',
          },
        {
          displayName: 'storage',
          state: 'ng-storage',
        },
        flagEnabled(irisContext, 'appPillarsEnabled') ? undefined : {
          displayName: 'simulation',
          state: 'simulation.list',
        },
        {
          displayName: 'monitoringPerformance',
          state: 'performance',
        },
        {
          displayName: 'advancedDiagnostics',
          state: 'diagnostics',
        },
        {
          displayName: 'auditLogs',
          state: flagEnabled(irisContext, 'ngAuditLogsEnabled') ? 'ng-audit-logs' : 'audit',
        },
        isMcmOnPrem(irisContext) || isClusterScope(irisContext) || isHeliosTenantUser(irisContext) ? null : {
          displayName: 'fieldMessages',
          state: 'field-messages',
        },
      ].filter(Boolean),
    };
  }

  /**
   * Returns a fresh copy of navList for main navigation.
   *
   * @returns The list of nav items.
   */
  private getHeliosAllClusterNavItems(
    irisContext: IrisContext,
    stateManagementService: StateManagementService
  ): NavItem[] {
    const navList: NavItem[] = [
      // Add the dashboards..
      this.getDashboardNavs(irisContext, stateManagementService),

      // Data Protection
      this.isOrganizationsEnabled ? {
        displayName: 'dataProtection',
        state: 'protection-group',
        icon: 'helix:protection',
        subNavList: [
          {
            displayName: 'policies',
            state: 'protection-policy',
          },
        ],
      } : undefined,

      // Marketplace Section
      {
        displayName: 'marketplace',
        state: 'apps-management',
        icon: 'store!outline',
        subNavList: [
          {
            displayName: 'summary',
            state: 'app-store-dashboard'
          },
          {
            displayName: 'myApps',
            state: 'apps-management',
          },

          isMcmOnPrem(irisContext) || isHeliosTenantUser(irisContext) ? null : {
            displayName: 'allApps',
            state: 'apps-management.browse-apps',
            disabled: !hasCompanyContext(this.irisContextService.irisContext),
          },
        ],
      },
      this.getSecurityNavs(irisContext),

      // System Section
      this.getSystemNavs(irisContext),
      // reporting
      this.getReportingNavItem(irisContext, 'mcm'),

      {
        displayName: 'settings',
        state: 'cluster-upgrades',
        icon: 'settings!outline',
        subNavList: [
          ...this.getHeliosAllClusterSettingsItem()
        ]
      },
    ];

    return this.getValidNavItems(navList);
  }

  /**
   * Gets dashboard nav item and all accessible subNav items.
   *
   * @returns   Dashboard nav and sub items, if any are accessible.
   */
  protected getDashboardNavs(irisContext: IrisContext, stateManagementService: StateManagementService): NavItem {
    // Get all accessible Dashboard subNav items.
    const dashboardSubItems = this.getDashboardItems(irisContext)
      .filter(navItem => !isNavItemHidden(navItem, irisContext, stateManagementService));
    const hasMultipleDashboards = dashboardSubItems.length > 1;

    // When no subNavs are accessible, return nothing.
    if (dashboardSubItems.length < 1) {
      return null;
    }

    const { icon, displayName, state } = dashboardSubItems[0];

    // When there's at least 1 subNav, Return a top-level Nav item which
    // points to the first subNav item.
    return {
      displayName: hasMultipleDashboards
        ? 'dashboards'
        : isIbmBaaSEnabled(this.irisContextService.irisContext) &&
          isTenantUser(this.irisContextService.irisContext)
          ? 'dashboard'
          : this.translate.instant('typedSingularDashboard', {
            displayName: this.translate.instant(displayName),
        }),

      // Always send the user to the root dashboards state and let the state guard
      // figure out the dashboard to which the user should be sent.
      state: hasMultipleDashboards ? 'dashboards' : state,
      icon: hasMultipleDashboards ? 'dashboard!outline' : icon,
    };
  }

  /**
   * Gets security nav item and all accessible subNav items.
   *
   * @returns   security nav and sub items, if any are accessible.
   */
  protected getSecurityNavs(irisContext: IrisContext): NavItem {
    return {
      displayName: 'security',
      state: 'helios-security-advisor',
      icon: 'fingerprint',
      subNavList: [
        ((getUserPrivilegeCheck('SECOPS_VIEW_POSTURE_ADVISOR')(irisContext) ||
          getUserPrivilegeCheck('DGAAS_VIEW')(irisContext)) &&
          isSecurityCenterUser(irisContext)) ? {
          displayName: 'postureAdvisor',
          state: 'helios-security-advisor',
        } : null,
        {
          displayName: 'quorum.pageTitle',
          state: 'helios-security-quorum',
        },
        ((getUserPrivilegeCheck('SECOPS_VIEW_POSTURE_ADVISOR')(irisContext) ||
          getUserPrivilegeCheck('DGAAS_VIEW')(irisContext)) &&
          !isSecurityCenterUser(irisContext)) ? {
          displayName: 'securityAdvisor',
          state: 'helios-security-advisor',
        } : null
      ],
    };
  }

  /**
   * Gets system nav item and all accessible subNav items.
   *
   * @returns   system nav and sub items, if any are accessible.
   */
  protected getSystemNavs(irisContext: IrisContext): NavItem {
    return {
      displayName: 'system',
      state: 'cluster',
      icon: 'helix:system',
      subNavList: [
        flagEnabled(irisContext, 'ngHealth') ? {
          displayName: 'health',
          state: 'health',
        }
          : {
            displayName: 'monitoringAlerts',
            state: 'alerts',
          },
        flagEnabled(irisContext, 'appPillarsEnabled') ? undefined : {
          displayName: 'simulation',
          state: 'simulation.list',
        },
        flagEnabled(irisContext, 'heliosAuditLogs') ? {
          displayName: 'auditLogs',
          state: 'helios-audit-logs',
        }
        : null,
        isMcmOnPrem(irisContext) || isClusterScope(irisContext) || isHeliosTenantUser(irisContext) ? null : {
          displayName: 'fieldMessages',
          state: 'field-messages',
        },
      ].filter(Boolean),
    };
  }

  /**
   * Returns a fresh copy of navItems[] for dashboard states.
   *
   * @returns The list of dashboard items.
   */
  getDashboardItems(irisContext: IrisContext): NavItem[] {
    const cdpEnabled = flagEnabled(irisContext, 'cdpEnabled');

    const dashboardItems = [
      {
        displayName: 'summary',
        state: 'dashboards.summary',
        icon: 'apps',
      },
      {
        displayName: 'dataProtection',
        state: 'dashboards.data-protection',
        icon: 'helix:protection',
      },
      {
        displayName: 'smartFiles',
        state: 'dashboards.file-services',
        icon: 'helix:file-services',
      },
      {
        displayName: 'cloud',
        state: 'dashboards.cloud',
        icon: 'cloud_queue',
      },
      {
        displayName: 'summary',
        state: 'dashboards.organization',
        icon: 'apps',
      },

      // SQL Dashboard is not available in all clusters scope.
      isAllClustersScope(irisContext) ? undefined : {
        displayName: 'msSql',
        state: 'sql-dashboard.hosts',
        icon: 'helix:database',
      },
      {
        displayName: 'security',
        state: 'security',
        icon: 'fingerprint',
      },
      cdpEnabled && isClusterScope(irisContext) && {
        displayName: 'continuousDataProtection',
        state: 'dashboards.cdp',

        // TODO(piyush.sonagara): Get the CDP icon from UX.
        icon: 'helix:cdp-backup',
      },
    ];

    // Filter out falsy values.
    return dashboardItems.filter(Boolean);
  }

  /**
   * Returns infrastructure navList.
   *
   * @returns the list of infrastructure items.
   */
  getInfrastructureNavs(irisContext: IrisContext): NavItem[] {
    return [
      {
        displayName: 'infrastructure',
        state: 'cluster',
        icon: 'iris:inventory',
        subNavList: [
          {
            displayName: 'remoteClusters',
            state: flagEnabled(irisContext, 'ngRemoteClusters') ? 'ng-remote-clusters' : 'remote-clusters',
          },
          {
            displayName: 'externalTargets',
            state: flagEnabled(irisContext, 'ngVaults') ? 'external-targets-ng' : 'external-targets',
          },
          {
            displayName: 'flashBlade',
            state: 'remote-disks',
          },
        ],
      },
    ];
  }

  /**
   * Returns a fresh copy of navItems[] for settings flyout.
   *
   * @returns The list of settings items.
   */
  private getSettingsItems(irisContext: IrisContext): NavItem[] {
    const settingNavItems: NavItem[] = [
      {
        displayName: 'summary',
        state: 'cluster.summary',
      },
      {
        displayName: 'accessManagement',
        state: flagEnabled(irisContext, 'ngUsersAndGroups') ? 'on-prem-access-management' : 'access-management',
      },
      flagEnabled(irisContext, 'pkiCertificateManagement') ?
      {
        displayName: 'certificateManagement',
        state: 'certificate-management',
      } : null,
      {
        displayName: 'accountSecurity',
        state: 'account-security',
      },
      {
        displayName: 'networking',
        state: flagEnabled(irisContext, 'ngNetworking') ? 'ng-networking' : 'networking',
      },
      {
        displayName: 'snmp',
        state: 'snmp-view',
      },
      flagEnabled(irisContext, 'patchV2Enhancements')
        ? {
            displayName: 'softwareUpdate',
            state: 'ngCluster-upgrade',
          }
        : flagEnabled(irisContext, 'ngClusterUpdate')
        ? {
            displayName: 'softwareUpdate',
            state: 'cluster-update',
          }
        : {
            displayName: 'upgrade',
            state: 'cluster-upgrade',
          },
      isMcmOnPrem(irisContext)
        ? null
        : flagEnabled(irisContext, 'clusterLicensePageRevamp')
          ? {
              displayName: 'license',
              state: 'license.revamp',
            }
          : {
              displayName: 'license',
              state: 'license.dashboard',
            },
      {
        displayName: 'organizations',
        state: 'access-management.tenants',
      },
      {
        displayName: 'customization',
        state: 'customization.list',
        options: {
          allowCustomization: false
        }
      },
    ];

    return settingNavItems;
  }

  /**
   * Get the appropriate nav item based on the current scope.
   *
   * @param scope The current cluster scope
   * @returns The reporting nav item.
   */
  protected getReportingNavItem(irisContext: IrisContext, scope: 'mcm' | 'global' | 'onPrem'): NavItem {
    const mcmEnabled = flagEnabled(irisContext, 'ngReportMcm');

    if (scope === 'onPrem' || (scope === 'mcm' && !mcmEnabled)) {
      return {
        displayName: 'reports',
        state: 'reporting.blank',
        icon: 'assessment!outline',
      };
    }

    if ((['mcm', 'global'].includes(scope) && mcmEnabled)) {
      return {
        displayName: 'reports',
        state: 'reporting.list',
        icon: 'assessment!outline',
        activeStates: ['reporting.detail', 'reporting.schedule'],
      };
    }
    return null;
  }

  /**
   * Returns a list of menu items for the all cluster states in Helios.
   *
   * @return   An array of nav items for helios all cluster
   */
  private getHeliosAllClusterSettingsItem(): NavItem[] {
    // TODO: can this be cleaned up? This function shouldn't be called in this scenario.
    if (!this.isAllClustersScope) {
      return [];
    }

    // For the DMaaS user we don't have any items.
    // TODO: can this be cleaned up? This function shouldn't be called in this scenario.
    if (isDmsScope(this.irisContextService.irisContext)) {
      return [];
    }

    return [
      {
        displayName: 'clusterManagement',
        state: this.showNGClusterManagement ? 'software-upgrade.cluster-list' : 'cluster-upgrades',

        // parentState does not make the following state active automatically.
        activeStates: [
          'software-upgrade.cluster-activity',
          'software-upgrade.cluster-backup',
          'cluster-config-detail',
          'cluster-config-detail.home',
          'cluster-config-detail.activity',
          'cluster-detail',
          'cluster-detail.home',
          'cluster-detail.history',
          'cluster-detail.activity',
        ],
      },
      {
        displayName: 'accessManagement',
        state: 'helios-access-management',
      },
      isMcmOnPrem(this.irisContextService.irisContext) ? null :
        {
          displayName: 'license',
          state: 'license.dashboard',
        },
      {
        displayName: 'cloudEdition.dataPlatformForCloud',
        state: 'cloud-edition-manager',
      },
      {
        displayName: 'organizations',
        state: 'organizations',
      },
      {
        displayName: 'organizationsSelf',
        state: 'organizations.self',
      },
      this.isOrganizationsEnabled ? {
        displayName: 'customization',
        state: 'customization.helios',
      } : undefined,
      this.showNGClusterManagement ? {
        displayName: 'downloads',
        state: 'cluster-downloads',
      }
      : undefined,
    ];
  }

  /**
   * Filters out invalid nav items.
   *
   * @param navList List of nav items
   * @returns List of valid nav items.
   */
  protected getValidNavItems(navList: NavItem[]) {
    return navList?.filter(Boolean)?.map(navItem => {
      navItem.subNavList = navItem.subNavList?.filter(Boolean);
      return navItem;
    });
  }
}
