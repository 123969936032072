import { Injectable } from '@angular/core';
import { AgentDeployment, AgentDeploymentsServiceApi } from '@cohesity/api/agent-deployment';
import { ProtectionSourceNode, ProtectionSourcesServiceApi } from '@cohesity/api/v1';
import { AgentServiceApi, AgentUpgradeTaskState, CreateUpgradeTaskRequest, McmSourceInfo } from '@cohesity/api/v2';
import { SnackBarService } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { AjaxHandlerService } from '@cohesity/utils';
import { TranslateService } from '@ngx-translate/core';
import { StateService, UIRouterGlobals } from '@uirouter/core';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { DialogService, SourceService } from 'src/app/core/services';
import { GenericSourceAuthTypes } from 'src/app/modules/sources-shared/interfaces/form-interfaces';
import { SourceSummary } from 'src/app/modules/sources/shared/source-summary';
import { ENV_GROUPS } from 'src/app/shared';
import { ENUM_HOST_TYPE, Environment, HostType } from 'src/app/shared/constants';
import { DecoratedProtectionSourceNode } from 'src/app/shared/models';

import {
  AgentDeploymentForm,
  AgentDeploymentOptions,
  AgentRegistrationForm,
  AgentRegistrationMsSqlOptions,
  AgentRegistrationOracleOptions,
  AgentRegistrationPhysicalOptions,
  CsvAgentDeployment,
  HostnameOrIpAgentDeployment,
  JobProgressDialogData,
  UpgradeAgentDialogData,
  VcenterAgentDeployment,
} from '../models';

enum DeploymentSource {
  VCenter,
  HostnameOrIp,
  Csv,
}

/*
 * This service provides agent deployment workflow.
 */
@Injectable({
  providedIn: 'root',
})
export class AgentService {
  constructor(
    private ajaxHandlerService: AjaxHandlerService,
    private agentApi: AgentDeploymentsServiceApi,
    private agentServiceApi: AgentServiceApi,
    private dialogService: DialogService,
    private irisCtx: IrisContextService,
    private protectionSourcesServiceApi: ProtectionSourcesServiceApi,
    private snackbarService: SnackBarService,
    private stateService: StateService,
    private translate: TranslateService,
    private uiRouterGlobals: UIRouterGlobals,
    private sourceService: SourceService,
  ) {}

  /**
   * Navigates to the application registration page with the supplied source details and application environment.
   *
   * @param source The source object for which the app registration has to be done.
   * @param appEnvironment The application environment on the source.
   */
  goToAppRegistration(source: SourceSummary, appEnvironment: Environment) {
    this.agentApi.HealthCheck().subscribe(
      () => this.stateService.go('agent.registration', { source, appEnvironment }),
      () => this.snackbarService.open(this.translate.instant('agentDeployment.appNotAvailableMessage'), 'error')
    );
  }

  /**
   * Navigates to Agent Deployment page with or without any preselected source.
   *
   * @param source The source object from which the VM will be selected for agent deployment.
   */
  goToAgentDeployment(source: SourceSummary) {
    this.agentApi.HealthCheck().subscribe(
      () => this.stateService.go('agent.deploy', { source }),
      () => this.snackbarService.open(this.translate.instant('agentDeployment.appNotAvailableMessage'), 'error')
    );
  }

  /**
   * Converts the app registration form to the Agent deployment API request.
   *
   * @param environment The application environment.
   * @param form The agent registration form object.
   * @returns The agent deployment request object.
   */
  convertAppRegistrationForm(environment: Environment, form: AgentRegistrationForm): AgentDeployment {
    const objectIds: number[] = form.objects;
    const source: DecoratedProtectionSourceNode = form.source;
    const hostType: any = form.hostType;

    const req: AgentDeployment = {
      type: 'vmWare',
      objects: objectIds.map(id => ({ cohesityId: { entityId: id, parentId: source.protectionSource.id } })),
      installAgent: true,
      registerApplications: true,
      osType: ENUM_HOST_TYPE[hostType].toLowerCase(),
    };

    if (environment === Environment.kOracle) {
      const objectParams = form.objectParams as AgentRegistrationOracleOptions;
      const { authType, username, password } = objectParams.oracleAuth;

      req.applications = {
        registerOracle: true,
        oracleParams: { authType, username, password },
      };

      req.connection = {
        type: 'vmware',
        authType: 'password',
        user: objectParams.guestAuth.username,
        password: objectParams.guestAuth.password,
      };

      req.registerPhysical = true;
      req.linuxConfigParams = {
        installPath: objectParams.deploymentPath,
        username: objectParams.deployAsUser,
        groupname: objectParams.deployAsGroup,
        createUser: objectParams.createUserIfNotAvailable,
      };
    } else if (environment === Environment.kSQL) {
      const objectParams = form.objectParams as AgentRegistrationMsSqlOptions;
      const { username, password } = objectParams.guestAuth;

      req.applications = { registerMsSql: true };
      req.registerPhysical = objectParams.registerAsPhysical;

      req.connection = {
        type: 'vmware',
        authType: 'password',
        user: username,
        password,
      };

      req.winConfigParams = {
        fileCBT: objectParams.fileCBT,
        volumeCBT: objectParams.volumeCBT,
        installPath: objectParams.deploymentPath,
      };

      if (objectParams.useServiceAccount) {
        req.winConfigParams.username = objectParams.serviceAccount.username;
        req.winConfigParams.password = objectParams.serviceAccount.password;
      }
    } else if (environment === Environment.kPhysical) {
      const objectParams = form.objectParams as AgentRegistrationPhysicalOptions;
      const { username, password } = objectParams.guestAuth;
      req.registerPhysical = true;

      req.connection = {
        type: 'vmware',
        authType: 'password',
        user: username,
        password,
      };

      req.registerApplications = false;

      if (hostType === HostType.kWindows) {
        req.winConfigParams = {
          fileCBT: objectParams.fileCBT,
          volumeCBT: objectParams.volumeCBT,
          installPath: objectParams.deploymentPath,
        };

        if (objectParams.useServiceAccount) {
          req.winConfigParams.username = objectParams.serviceAccount.username;
          req.winConfigParams.password = objectParams.serviceAccount.password;
        }
      } else if (hostType === HostType.kLinux) {
        req.linuxConfigParams = {
          installPath: objectParams.deploymentPath,
          username: objectParams.deployAsUser,
          groupname: objectParams.deployAsGroup,
          createUser: objectParams.createUserIfNotAvailable,
        };
      }
    }

    return req;
  }

  /**
   * Converts the agent deployment form to the Agent deployment API request.
   *
   * @param deploymentSource  Option selected for objects input.
   * @param form The agent deployment form object.
   * @returns The agent deployment request object.
   */
  convertAgentDeploymentForm(
    deploymentSource: DeploymentSource,
    form: AgentDeploymentForm,
  ): AgentDeployment {
    // TODO(rohit): Combine App registration and Agent deployment common code.
    let objectsInfo;
    const req: AgentDeployment = {
      installAgent: true,
      registerApplications: false,
    };
    const objectParams = form.objectParams as AgentDeploymentOptions;
    req.registerPhysical = objectParams.registerAsPhysical;

    switch (deploymentSource) {
      case DeploymentSource.VCenter: {
        objectsInfo = form.objectInfo as VcenterAgentDeployment;
        const objectIds: number[] = objectsInfo.objects;
        const source: DecoratedProtectionSourceNode = objectsInfo.source;
        req.type = 'vmWare';
        req.osType = ENUM_HOST_TYPE[objectsInfo.hostType].toLowerCase();
        req.objects = objectIds.map(id => ({ cohesityId: { entityId: id, parentId: source.protectionSource.id } }));
        req.connection = {
          type: 'vmware',
          authType: 'password',
          user: objectParams.guestAuth.username,
        };

        if (objectsInfo.hostType === HostType.kWindows) {
          req.winConfigParams = {
            fileCBT: objectParams.fileCBT,
            volumeCBT: objectParams.volumeCBT,
            installPath: objectParams.deploymentPath,
          };

          if (objectParams.useServiceAccount) {
            req.winConfigParams.username = objectParams.serviceAccount.username;
            req.winConfigParams.password = objectParams.serviceAccount.password;
          }

          req.connection.password = objectParams.guestAuth.password;
          req.connection.authType = 'password';
        } else if (objectsInfo.hostType === HostType.kLinux) {
          req.linuxConfigParams = {
            installPath: objectParams.deploymentPath,
            username: objectParams.deployAsUser,
            groupname: objectParams.deployAsGroup,
            createUser: objectParams.createUserIfNotAvailable,
          };

          if (objectParams.guestAuthType === GenericSourceAuthTypes.password) {
            req.connection.password = objectParams.guestAuth.password;
            req.connection.authType = 'password';
          } else {
            req.connection.privateKey = objectParams.guestAuth.password;
            req.connection.authType = 'privateKey';
          }
        }

        break;
      }

      case DeploymentSource.HostnameOrIp: {
        objectsInfo = form.objectInfo as HostnameOrIpAgentDeployment;
        const ips: string[] = objectsInfo.objects;
        req.type = 'physicalServer';
        req.osType = ENUM_HOST_TYPE[objectsInfo.hostType].toLowerCase();
        req.objects = ips.map(ip => ({ ip: ip }));
        req.connection = {
          type: 'vmware',
          user: objectParams.guestAuth.username,
        };

        if (objectsInfo.hostType === HostType.kWindows) {
          req.connection.type = 'winrm';
          req.winConfigParams = {
            fileCBT: objectParams.fileCBT,
            volumeCBT: objectParams.volumeCBT,
            installPath: objectParams.deploymentPath,
          };

          if (objectParams.useServiceAccount) {
            req.winConfigParams.username = objectParams.serviceAccount.username;
            req.winConfigParams.password = objectParams.serviceAccount.password;
          }

          req.connection.password = objectParams.guestAuth.password;
          req.connection.authType = 'password';
        } else if (objectsInfo.hostType === HostType.kLinux) {
          req.connection.type = 'ssh';
          req.linuxConfigParams = {
            installPath: objectParams.deploymentPath,
            username: objectParams.deployAsUser,
            groupname: objectParams.deployAsGroup,
            createUser: objectParams.createUserIfNotAvailable,
          };

          if (objectParams.guestAuthType === GenericSourceAuthTypes.password) {
            req.connection.password = objectParams.guestAuth.password;
            req.connection.authType = 'password';
          } else {
            req.connection.privateKey = objectParams.guestAuth.password;
            req.connection.authType = 'privateKey';
          }
        }

        break;
      }

      case DeploymentSource.Csv:
        objectsInfo = form.objectInfo as CsvAgentDeployment;
        req.type = 'physicalServer';
        req.objects = objectsInfo.objects;

        break;
    }

    return req;
  }

  /**
   * Opens the job progress dialog for the specified job ID.
   *
   * @param jobId The job ID.
   */
  openJobProgressDialog(jobId: string) {
    const data: JobProgressDialogData = { jobId };

    this.dialogService.showDialog('agent-job-progress-dialog', data, {
      width: '70%',
    });
  }

  /**
   * Opens a dialog to start/schedule agent upgrade on the selected sources.
   *
   * @param sourceAgents The list of sources agents to upgrade.
   */
  openAgentUpgradeDialog(sourceAgents: SourceSummary[]) {
    const data: UpgradeAgentDialogData = { sourceAgents };

    return this.dialogService.showDialog('agent-upgrade-dialog', data, {
      width: '40rem',
    });
  }

  /**
   * Opens a dialog to schedule agent upgrade for later on the selected sources.
   *
   * @param source selected protection source to upgrade.
   */
  openDmsScheduleAgentUpgradeDialog(source: McmSourceInfo | ProtectionSourceNode) {
    return this.dialogService.showDialog('schedule-agent-upgrade-dialog', {...source}, {
      width: '40rem',
    });
  }

  /**
   * Opens a dialog to start agent upgrade on the selected sources.
   *
   * @param source The list of sources agents to upgrade.
   */
  openDmsAgentUpgradeDialog(source: any) {
    const dialogData = {
      title: 'agentUpgrade.title',
      copy: 'agentUpgrade.confirmText',
      confirmButtonLabel: 'confirm',
      declineButtonLabel: 'cancel',
    };

    return this.dialogService.simpleDialog(null, dialogData, { width: '40rem' })
      .pipe(filter(Boolean))
      .subscribe(() => {
        const upgradeParams = {
          agentIDs: (source?.physicalSourceInfo?.agentsInfo || []).map(agent => agent?.agentID),
        };
        return this.dmsUpgradeAgents(upgradeParams, source?.regionId).subscribe(() => {
          this.snackbarService.open(this.translate.instant('upgradingAgent'));
        }, (error) => {
          this.ajaxHandlerService.handler(error);
        });
      });
  }

  /**
   * Returns the list of available protection sources under the supplied source ID.
   *
   * @param sourceId The ID of the source.
   * @returns An observable of the protection nodes.
   */
  getProtectionSources(sourceId: number): Observable<ProtectionSourceNode[]> {
    return this.protectionSourcesServiceApi.ListProtectionSources({
      allUnderHierarchy: false,
      excludeTypes: ['kResourcePool'],
      excludeAwsTypes: ['kAuroraCluster', 'kRDSInstance'],
      excludeKubernetesTypes: flagEnabled(this.irisCtx.irisContext, 'excludeKubernetesTypes') ? ['kService'] : undefined,
      includeEntityPermissionInfo: true,
      includeVMFolders: true,
      id: sourceId,
      includeSystemVApps: true,
    });
  }

  /**
   * Triggers a refresh of the protection sources, and returns an updated list of the same.
   *
   * @param sourceId The ID of the source.
   * @returns An observable of the protection nodes.
   */
  refreshProtectionSources(sourceId: number): Observable<ProtectionSourceNode[]> {
    return this.sourceService.refreshProtectionSources({ id: sourceId, regionId: this.uiRouterGlobals.params.regionId })
      .pipe(switchMap(() => this.getProtectionSources(sourceId)));
  }

  /**
   * Extracts and returns the agent IDs from a source.
   *
   * @param   source   The source to be upgraded.
   * @return  The list of agent id to be upgraded.
   */
  extractAgentIdsFromSourceSummary(source: SourceSummary): number[] {
    const agentIds: number[] = [];
    if (!source?.environmentProtectionSource?.agents?.length) {
      return agentIds;
    }

    switch (true) {
      // For OracleRAC and SQL, there may be multiple agents.
      case source.type === 'kOracleRACCluster':
      case source.isSqlCluster:
      case source.isUnixCluster:
        (source.environmentProtectionSource.agents || []).forEach(agent => {
          if (agent.upgradability === 'kUpgradable' && !agentIds.includes(agent.id)) {
            agentIds.push(agent.id);
          }
        });
        break;
      case ENV_GROUPS.usesAgent.includes(source.nodeEnvironment):
        agentIds.push(source.agent.id);
        break;
    }
    return agentIds;
  }

  /**
   * Triggers an upgrade agent task.
   *
   * @param sourceAgents The list of sources agents to upgrade.
   * @param scheduleUsecs Timestamp when schedule selected is later.
   * @returns An observable of the agent upgrade task.
   */
  upgradeAgents(sourceList: SourceSummary[], scheduleUsecs: number): Observable<AgentUpgradeTaskState> {
    const agentIds = sourceList.reduce((ids, source) => [...ids, ...this.extractAgentIdsFromSourceSummary(source)], []);
    const params: AgentServiceApi.CreateUpgradeTaskParams = {
      body: {
        agentIDs: agentIds,
        description: '',
        name: '',
        scheduleTimeUsecs: scheduleUsecs,
      },
    };
    return this.agentServiceApi.CreateUpgradeTask(params);
  }

  /**
   * Triggers an upgrade agent task in dmaas.
   *
   * @param upgradeParams agent upgrade params.
   * @returns An observable of the agent upgrade task.
   */
  dmsUpgradeAgents(upgradeParams: CreateUpgradeTaskRequest, regionId?: string): Observable<AgentUpgradeTaskState> {
    const params = {
      body: {
        agentIDs: upgradeParams?.agentIDs,
        description: '',
        name: '',
        scheduleTimeUsecs: upgradeParams?.scheduleTimeUsecs,
        scheduleEndTimeUsecs: upgradeParams?.scheduleEndTimeUsecs,
      },
      regionId: regionId || this.uiRouterGlobals.params?.regionId,
    };
    return this.agentServiceApi.CreateUpgradeTask(params);
  }
}
