// Module: Job Details

(function _iife(angular, undefined) {
  'use strict';

  var moduleDeps = [
    'C.policyService',
    'C.jobRunsService',
    'C.backupNow',
    'C.editRun',
  ];

  angular.module('C.jobDetails', moduleDeps)
    .config(jobDetailsConfigFn)
    .controller('jobDetailsParentController', jobDetailsParentControllerFn);

  function jobDetailsConfigFn($stateProvider, $urlRouterProvider) {
    var viewAccess = 'PROTECTION_VIEW';

    $stateProvider
      .state('job-details', {
        url: '/protection/job/{id}/',

        // help codes for detail pages will be set dynamically based on
        // circumstances (job type, tab, etc)
        help: '',
        title: 'Job Details',
        canAccess: viewAccess,

        // Activating All Clusters support for DMaaS.
        allClustersSupport: {
          heliosOnly: true,
          singleClusterState: 'job-details',
        },
        params: {
          id: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          '': {
            templateUrl: 'app/views/page-layouts/ls.html'
          },
          'col-l@job-details': {
            templateUrl: 'app/protection/jobs/details/details.html',
            controller: 'jobDetailsParentController'
          }
        },
      })
      .state('job-details.job', {
        url: '^/protection/job/{id}/details',
        title: 'Job Details',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          'content@job-details': {
            templateUrl: 'app/protection/jobs/details/job-details.html',
            controller: 'jobDetailsProtectionJobController'
          }
        }
      })
      .state('job-details.file-stubbing-job', {
        url: '^/datamigration/job/{id}/details',
        params: {
          id: { type: 'string' },
          fileStubbing: true,
        },
        canAccess: viewAccess,
        title: 'Job Details',
        help: 'platform_datamigration',
        parentState: 'dataMigration',
        views: {
          'content@job-details': {
            templateUrl: 'app/protection/jobs/details/job-details.html',
            controller: 'jobDetailsProtectionJobController'
          }
        }
      })
      .state('job-details.delete-snapshots', {
        url: '^/protection/job/{id}/delete-snapshots',
        title: 'Job Details',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          'content@job-details': {
            templateUrl: 'app/protection/jobs/details/job-details.html',
            controller: 'jobDetailsProtectionJobController'
          }
        }
      })
      .state('job-details.file-stubbing-delete-snapshots', {
        url: '^/datamigration/job/{id}/delete-snapshots',
        params: {
          id: { type: 'string' },
          fileStubbing: true,
        },
        title: 'Job Details',
        canAccess: viewAccess,
        parentState: 'jobs',
        views: {
          'content@job-details': {
            templateUrl: 'app/protection/jobs/details/job-details.html',
            controller: 'jobDetailsProtectionJobController'
          }
        }
      })
      .state('job-details.job.audit', {
        url: '^/protection/job/{id}/audit',
        title: 'Job Details',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          'content@job-details': {
            component: 'cJobDetailsProtectionAudit',
          },
        },
        resolve: {
          pageConfig: function setPageConfig($transition$) {
            var routeParams = $transition$.params();
            return {
              jobId: routeParams.id || undefined,
            };
          },
        }
      })
      .state('job-details.file-stubbing-job.audit', {
        url: '^/datamigration/job/{id}/audit',
        params: {
          id: { type: 'string' },
          fileStubbing: true,
        },
        title: 'Job Details',
        canAccess: viewAccess,
        parentState: 'jobs',
        views: {
          'content@job-details': {
            component: 'cJobDetailsProtectionAudit',
          },
        },
        resolve: {
          pageConfig: function setPageConfig($transition$) {
            var routeParams = $transition$.params();
            return {
              jobId: routeParams.id || undefined,
            };
          },
        }
      })
      .state('job-details.job.settings', {
        url: '^/protection/job/{id}/settings',
        title: 'Job Details',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          'content@job-details': {
            templateUrl: 'app/protection/jobs/details/job-settings.html',
            controller: 'jobDetailsProtectionSettingsController'
          }
        }
      })
      .state('job-details.job.statistics', {
        url: '^/protection/job/{id}/statistics',
        title: 'Job Details',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          'content@job-details': {
            templateUrl: 'app/protection/jobs/details/job-statistics.html',
            controller: 'jobDetailsProtectionStatisticsController'
          }
        }
      })
      .state('job-details.run', {
        url: '^/protection/job/details/{id}',
        title: 'Job Run Details',
        canAccess: viewAccess,
        params: {
          id: { type: 'string' },
        },
        parentState: 'jobs',
        views: {
          'content@job-details': {
            templateUrl: 'app/protection/jobs/details/run.html',
            controller: 'jobDetailsRunController'
          }
        }
      });
  }

  function jobDetailsParentControllerFn($rootScope, $scope, $q,
    $state, $location, $timeout, $transitions, JobActionService, DeleteJobRunsModal,
    DateTimeService, DateRangerService, ViewBoxService, evalAJAX,
    cUtils, PolicyService, ViewService, JobService, JobRunsService,
    ExternalTargetService, cMessage, JobDetailsService, ENV_GROUPS,
    ENV_TYPE_CONVERSION, JobActionFormatter, FEATURE_FLAGS) {

    // Help Ids hashed based on job type. When documentation requires it, this
    // could be further sub-hashed based on the loaded child state/tab. See
    // updateHelpId() for implementation.
    var helpIds = {
      1: 'protection_job_details_vms',
      4: 'protection_job_details_views'
    };
    var dtFormat = DateTimeService.getPreferredDateFormat();

    $scope.text = $rootScope.text.protectionJobsDetails_details;

    /**
     * Object to hold all job data for display
     * @type {Object}
     */
    $scope.job = {};

    /**
     * Object to hold all job summary properties for display
     * @type {Object}
     */
    $scope.jobSummary = {};

    /**
     * Flag data retrieval in progress
     * This flag will toggle ajax spinner display
     * @type {Boolean}
     */
    $scope.fetching = false;

    /**
     * Number of job runs to show at one time
     * @type {Number}
     */
    $scope.itemsPerPage = 20;

    /**
     * Flag denoting user can delete snapshots
     * @type {Boolean}
     */
    $scope.isDeleteSnapshotMode = false;

    /**
     * Magic Shared Object
     * Supports scope changes across child states
     *
     * @type {Object}
     */
    $scope.shared = {
      /**
       * Config Map of snapshots to delete maps startTimeUsecs to a boolean
       * @type {Object}
       */
      runsToDelete: {},

      /**
       * Is Select All checked?
       * @type {Boolean}
       */
      selectAllCheckbox: false,

      /**
       * Do runs with snapshots exist?
       * @type {Boolean}
       */
      hasDeleteableRuns: false,

      /**
       * Is a Log run selected for deletion?
       * @type {Boolean}
       */
      hasLogRun: false,

      /**
       * Build/Updates the Job Actions Menu
       * @type {Function}
       */
      getJobActions: getJobActions,
    };

    $scope.isFileStubbingJob = !!$state.params.fileStubbing;

    /**
     * dates for display and interaction with cDateRanger
     * @type {Object}
     */
    $scope.dates = DateRangerService.getDateRangerValues();

    $scope.selectedFilters = {
      timeObject: {
        from: DateTimeService.dateToUsecs($scope.dates.startDate),
        until: DateTimeService.dateToUsecs($scope.dates.endDate),
      },

      // kAll, kFull, kRegular
      runTypes: 'kAll',
    };

    // refresh data set when filters are changed
    $scope.$watchCollection(
      'selectedFilters',
      function filtersChanged(ov, nv) {
        if (ov !== nv) {
          // broadcast a message so charts and tables will update accordingly
          $scope.$broadcast('applyFilters');
        }
        // Clear Selected Runs To Delete
        $scope.clearSelectedRuns();
      }
    );

    /**
     * Activate this controller.
     */
    function activate() {
      var deleteSnapshotStates = ['job-details.delete-snapshots',
      'job-details.file-stubbing-delete-snapshots'];
      $scope.jobId = $state.params.id;
      $scope.jobSummaryReady = false;

      // Redirect as necessary
      if (!$scope.jobId) {
        $state.go('jobs');
        return;
      }

      // $scope.getData is a promise so we can make sure that $scope.job
      // is populated before building the tree for job details
      $scope.getData().then(function onSuccess() {
        // If in delete snapshot mode and the job is owned by the tenant, the SP
        // admin should not be able to see the page.
        if ($scope.isDeleteSnapshotMode && !$scope.job._isJobOwner) {
          $state.go('jobs');
        }

        // Set up tabs after get job information.
        _setUpTabConfig();

        getSourceTreeAndBuildObjectsList();

      }, evalAJAX.errorMessage);

      if ($state.current.name === 'job-details') {
        $scope.currentTab = 'job-details.job';
        $state.go(
          'job-details.job',
          { id: $scope.jobId },
          { reload: false }
        );
      }

      if (deleteSnapshotStates.includes($state.current.name)) {
        $scope.isDeleteSnapshotMode = true;
      }
    }

    /**
     * Parse through URL Params. Update selectedFilters if params are valid.
     *
     * @method    getParams
     */
    $scope.getParams = function getParams() {
      var params = $location.search();
      if (params.type) {
        $scope.selectedFilters.type = params.type * 1;
      }
    };

    /**
     * Returns total column count for shared templates. Used to dynamically set
     * column count for tables in various states.
     *
     * @method    getFullColSpan
     * @param     {Integer}   cols   Default column count
     * @returns   {Integer}   Adjusted column count
     */
    $scope.getFullColSpan = function getFullColSpan(cols) {
      // Delete Snapshot Mode requires an extra column for the delete checkbox
      if ($scope.isDeleteSnapshotMode) {
        // Add a column for the delete selection checkbox.
        cols = cols + 1;
      }

      // Remote Adapter Jobs
      if ($scope.job.type === 5) {
        // Add extra columns for the clone action.
        cols = cols + 1;
      }

      return cols;
    };

    /**
     * Request data from API to get all data necessary for Job Detail view.
     *
     * @return  {object}   returns a promise so we can make sure that
     *                      $scope.job has been populated before we get sources
     *                      and build the tree
     */
    $scope.getData = function getData() {
      var summaryParams = {
        ids: [+$scope.jobId],
        includeJobsWithoutRun: true,
        excludeTasks: true,
        onlyReturnDataMigrationJobs: $scope.isFileStubbingJob,
      };
      var jobParams = {
        onlyReturnDataMigrationJobs: $scope.isFileStubbingJob,
      };
      var deferred = $q.defer();
      var promiseObject = {};
      var jobPolicyId;
      var jobClusterId;

      // If time filters are set, then add them to the summaryParams
      if (typeof $scope.selectedFilters.timeObject.from === 'number' ||
        typeof $scope.selectedFilters.timeObject.until === 'number') {
        summaryParams.startTimeUsecs = $scope.selectedFilters.timeObject.from;
        summaryParams.endTimeUsecs = $scope.selectedFilters.timeObject.until;
      }

      promiseObject = {
        job: JobService.getJob(+$scope.jobId, jobParams),
        jobSummary: JobRunsService.getJobSummary(summaryParams),
      };

      // If ExternalTargetService.targetNameMapById is empty,
      // we need to build it.
      if ($rootScope.user.privs.CLUSTER_EXTERNAL_TARGET_VIEW &&
        angular.equals({}, ExternalTargetService.targetNameMapById)) {
        promiseObject.externalTargets = ExternalTargetService.getTargets();
      }

      $q.all(promiseObject).then(
        function promiseObjectSuccess(responses) {
          // Evaluate getJob response
          $scope.job = responses.job;
          $scope.jobReady = true;

          if (!$scope.job) {
            deferred.reject({data: {message: 'jobDetails.jobNotFound'}});
            $state.go($scope.isFileStubbingJob ? 'dataMigration' : 'jobs');
            return;
          }

          $scope.$broadcast('jobReady');

          $scope.jobSummary = responses.jobSummary.data[0] ?
            angular.copy(responses.jobSummary.data[0].backupJobSummary) :
            {};

          // Set new Job ID for API v2
          $scope.ngJobId = [
            $scope.jobSummary.jobDescription.jobUid.clusterId,
            $scope.jobSummary.jobDescription.jobUid.clusterIncarnationId,
            $state.params.id
          ].join(':');

          /**
           * Populate the "jobDescription" in case there are no runs. NOTE:
           * jobDescription probably shouldn't be relied on as the source of
           * truth for the job configration ($scope.job should) unless its
           * specific to the actual run, since it's possible the Job config
           * has been changed since the most recently returned run.
           */
          $scope.jobSummary.jobDescription =
            $scope.jobSummary.jobDescription || $scope.job;

          if ($scope.job.type === 3) {
            $scope.jobSummary.jobDescription =
              JobService.transformDBJob($scope.jobSummary.jobDescription);
          }

          if ($scope.jobSummary) {
            // Copy the "isCloudArchiveDirect" property from jobSummary to job
            // since the proto is only populated in the private jobSummary api.
            $scope.job._isCloudArchiveDirect =
              !!$scope.jobSummary.isCloudArchiveDirect;

            $scope.jobSummary.$dataReductionRatio = cUtils.getRatio(
              $scope.jobSummary.totalLogicalBackupSizeBytes,
              $scope.jobSummary.totalPhysicalBackupSizeBytes,
              $scope.text.na
            );

            $scope.$broadcast('jobSummaryReady');
            $scope.jobSummaryReady = true;
          }

          deferred.resolve($scope.job);
        }, deferred.reject)
        .finally(function afterPromise() {
          jobPolicyId = _.get($scope, 'jobSummary.jobDescription.policyId');

          updateHelpId();

          // Let's get the policy details
          if ($rootScope.user.privs.PROTECTION_POLICY_VIEW && jobPolicyId) {
            if ($scope.job.type === 5 &&
              !$scope.jobSummary.jobDescription.isActive) {
              // This is a remote RA job, Get the policy from the remote
              // cluster.

              jobClusterId =
                $scope.jobSummary.jobDescription.primaryJobUid.clusterId;

              PolicyService.getRemotePolicy(jobClusterId, jobPolicyId)
                .then(
                  function getPolicySuccess(policy) {
                    $scope.jobSummary.jobDescription._policyDetails = policy;
                    $scope.$broadcast('policyDetailsReady');
                    $scope.shared.getJobActions();
                  }
                ).catch(
                  function getPolicyError() {
                    // Fall back to display this run type as a View
                    $scope.jobSummary.jobDescription._policyDetails = {
                      templateDetails: {
                        type: 4,
                      }
                    };

                    // Make sure that the job actions are updated
                    $scope.shared.getJobActions();
                  }
                );
            } else if ($scope.jobSummary.jobDescription.isActive) {
              // Only get the Policy for active Jobs. Policy details are not
              // displayed for inactive jobs, and are often not in on the
              // current Cluster. For example, Policies associated with Jobs
              // restored from the cloud or coped here via replication will
              // not be present on this Cluster.
              PolicyService.getPolicy(jobPolicyId).then(
                function getPolicySuccess(policy) {
                  // Assign policy details to $scope.jobSummary.jobDescription
                  // for consumption within the template.
                  $scope.jobSummary.jobDescription._policyDetails = policy;
                  $scope.$broadcast('policyDetailsReady');
                  $scope.shared.getJobActions();
                },
                evalAJAX.errorMessage
              );
            } else {
              // Make sure that the job actions are updated even if the
              // job is inactive.
              $scope.shared.getJobActions();
            }
          }

           // for View and Remote Adapter Jobs, we need to get the View.
          if ($rootScope.user.privs.STORAGE_VIEW &&
            ENV_GROUPS.spanFS.includes($scope.jobSummary.jobDescription.type)) {
            getView();
          }
        });

        return deferred.promise;
      };

    /**
     * Clears Selected Runs. Resets select all checkbox.
     *
     * @method   clearSelectedRuns
     */
    $scope.clearSelectedRuns = function clearSelectedRuns() {
      // Clear previouly selected runs
      $scope.shared.runsToDelete = {};

      // Uncheck the select all checkbox
      $scope.shared.selectAllCheckbox = false;
    };


    /**
     * presents a confirmation modal before deleting job runs.
     *
     * @method    deleteJobRuns
     */
    $scope.deleteJobRuns = function deleteJobRuns() {
      DeleteJobRunsModal.showModal($scope.shared.hasLogRun).then(
        function deleteJobConfirmed(response) {
          var data = {
            jobRuns: [],
          };
          angular.forEach($scope.shared.runsToDelete,
            // Assemble data for API consumption
            function prepareRunForDeletion(runValue, runStartTimeUsecs) {
              if (runValue !== false) {
                data.jobRuns.push({
                  copyRunTargets: [{
                    daysToKeep: 0,
                    type: 'kLocal',
                  }],
                  jobUid:
                    JobActionFormatter.formatJobUid(runValue.base.jobUid),
                  runStartTimeUsecs: parseInt(runStartTimeUsecs, 10),
                });
              }
            }
          );

          JobRunsService.deleteJobRuns(data).then(
            function deleteJobRunsSuccess(response) {
              // successfully deleted, show success message and reload data.
              cMessage.success({
                textKey: 'job.deletedMultipleSnapshots',
              });

              $timeout(
                // Give magneto a brief delay to update this job before
                // refreshing the page again.
                function refreshPageDelay() {
                  $state.go($scope.isFileStubbingJob ?
                    'job-details.file-stubbing-job' : 'job-details.job', {
                    id: $state.params.id,
                  }, {
                    reload: true,
                  });
                },
                300
              );
            },
            evalAJAX.errorMessage
          );
        }
      );
    };

    /**
     * updates the context sensitive help key for the current state based upon
     * the circumstances.
     *
     * @method    updateHelpId
     */
    function updateHelpId() {
      if ($scope.job && $scope.job.type) {
        $state.current.help = helpIds[$scope.job.type];
      }
    }

    /**
     * Get the View for Remote Adapter and View Protection Jobs.
     *
     * @method   getView
     */
    function getView() {
      var source = $scope.jobSummary.jobDescription.sources[0];
      var viewName;

      if (source && Array.isArray(source.entities)) {
        viewName = source.entities[0].displayName;

        ViewService
          .getView(viewName, { includeInactive: true })
          .then(
            function getViewSuccess(view) {
              // Assign view details to $scope.jobSummary for consumption within
              // the template.
              $scope.jobSummary._view = view;
            }
          );
      }

    }

    /**
     * Gets a configuration object for contextual actions menu based on job
     * status.
     *
     * @method    getJobActions
     * @param     {object}   job   The Job object to get valid actions for.
     * @returns   {array}    Contextual menu configuration objects.
     */
    function getJobActions() {
      var jobPolicy = $scope.jobSummary.jobDescription._policyDetails ?
        $scope.jobSummary.jobDescription._policyDetails : undefined;
      $scope.actions = [];

      var actions = [];

      if (JobActionService.isValidJobAction('start', $scope.jobSummary)) {
        actions.push(
          JobActionService.getJobAction('start', $scope.jobSummary,
            function startJobCallback() {
              // Reload general page data on a timeout to give the job time to
              // actually start. Don't rebuild runs per hour chart as it
              // shouldn't have changed.
              $timeout($scope.getData, 2500);
            }
          )
        );
      }

      if (JobActionService.isValidJobAction('failover', $scope.jobSummary)) {
        actions.push(
          JobActionService.getJobAction('failover', $scope.jobSummary,
            function failoverCallback(jobId, source) {
              // when failing over a cloud source,
              // go to clone flow instead of recover flow.
              if (ENV_GROUPS.cloudDeploySources.includes(
                  ENV_TYPE_CONVERSION[source.type])) {
                $state.go('clone-vms.clone-options', {
                  sourceEntity: source,
                  entityId: source.id,
                  entityType: source.type,
                  jobId: jobId,
                });

                return;
              }

              // activates failover flow after user accepts confirmation
              // modal.
              $state.go('recover-vm.recover-options', {
                sourceEntity: source,
                jobId: jobId
              });
            }
          )
        );
      }

      if (JobActionService.isValidJobAction('pause', $scope.jobSummary)) {
        actions.push(
          JobActionService.getJobAction('pause', $scope.jobSummary,
            function pauseCallback() {
              // reload the page
              $scope.getData();
            }
          )
        );
      }

      if (JobActionService.isValidJobAction('resume', $scope.jobSummary)) {
        actions.push(
          JobActionService.getJobAction('resume', $scope.jobSummary,
            function resumeCallback() {
              // reload the page
              $scope.getData();
            }
          )
        );
      }

      if (JobActionService.isValidJobAction('cancel', $scope.jobSummary)) {
        actions.push(
          JobActionService.getJobAction('cancel', $scope.jobSummary,
            function cancelCallback() {
              // reload the page after a brief timeout
              $timeout($scope.getData, 2500);
            }
          )
        );
      }

      if (JobActionService.isValidJobAction('edit', $scope.jobSummary)) {
        actions.push(
          JobActionService.getJobAction('edit', $scope.jobSummary)
        );
      }

      if (JobActionService.isValidJobAction('delete', $scope.jobSummary)) {
        actions.push(
          JobActionService.getJobAction('delete', $scope.jobSummary,
            function deleteJobCallback(snapshotsDeleted) {

              if (snapshotsDeleted) {
                // snapshots were deleted, redirect user to job landing page as
                // the job no longer exists.
                $state.go('jobs');
                return;
              }

              // snapshots weren't deleted, leave user on the current page and
              // refresh the data.
              $scope.getData();

            }
          )
        );
      }

      if (JobActionService.isValidJobAction('deleteSnapshotMode', $scope.jobSummary, {
          hasDeleteableRuns: $scope.shared.hasDeleteableRuns
        })) {
        actions.push(
          JobActionService.getJobAction('deleteSnapshotMode', $scope.jobSummary,
            function deleteSnapshotCallback() {
              // reload the page
              $scope.getData();
            }
          )
        );
      }

      if (JobActionService.isValidJobAction('deactivate', $scope.jobSummary, {
          policy: jobPolicy
        })) {
        actions.push(
          JobActionService.getJobAction('deactivate', $scope.jobSummary,
            function deactivateCallback() {
              // reload the page
              $scope.getData();
            }
          )
        );
      }
      $scope.actions = $scope.job._isJobOwner ? actions : [];
    }

    /**
     * Calls JobDetailsService to get source tree, on success it will call
     * JobDetailsService again to build our objects list to be used in job
     * details
     */
    function getSourceTreeAndBuildObjectsList() {
      // This can raise errors if the source isn't registered in inactive jobs,
      // like Remote Retrieved jobs. So don't run in that case.
      if (!$scope.job.isActive && !$scope.job.isLocal) {
        $scope.treeReady = true;
        return;
      }

      JobDetailsService.getSourceTree($scope.job)
        .then(JobDetailsService.getObjectsList, evalAJAX.errorMessage)
        .finally(function getSourcesFinished() {
          $scope.treeReady = true;
        });
    }

    /**
     * Set up tabs.
     *
     * @method   _setUpTabConfig
     */
    function _setUpTabConfig() {
      var _unregisterTrans;

      /**
       * Config for visible tabs
       * @type {string}
       */
      $scope.currentTab = $state.current.name;

      // define the tabs
      if ($scope.isFileStubbingJob) {
        $scope.cTabConfig = [
          {
            headingKey: 'dataMigrationJobRuns',
            route: 'job-details.file-stubbing-job',
            params: {
              id: $state.params.id,
            },
          },
          {
            headingKey: 'auditTrail',
            route: 'job-details.file-stubbing-job.audit',
            params: {
              id: $state.params.id,
            },
          },
        ];
      } else {
        $scope.cTabConfig = _.compact([
          FEATURE_FLAGS.ngProtectionGroup ?
            {
              headingKey: 'details',
              route: 'protection-group.group.details',
              params: {
                groupId: $scope.ngJobId
              },
            } :
            {
              headingKey: 'jobRuns',
              route: 'job-details.job',
              params: {
                id: $state.params.id,
              },
            },
          FEATURE_FLAGS.ngProtectionGroup ?
            {
              headingKey: 'auditTrail',
              route: 'protection-group.group.audit',
              params: {
                groupId: $scope.ngJobId
              },
            } :
            {
              headingKey: 'auditTrail',
              route: 'job-details.job.audit',
              params: {
                id: $state.params.id,
              },
            },
          {
            headingKey: 'settings',
            route: 'job-details.job.settings',
            params: {
              id: $state.params.id,
            },
          },
          !FEATURE_FLAGS.ngProtectionGroup ||
          FEATURE_FLAGS.ngProtectionGroupStatisticsPage ?
            {
              headingKey: 'statistics',
              route: 'job-details.job.statistics',
              params: {
                id: $state.params.id,
              },
            } : undefined,
          FEATURE_FLAGS.storageNgDesign && {
            headingKey: 'consumption',
            route: 'protection-group.group.consumption',
            params: {
              groupId: $scope.ngJobId,
              id: $state.params.id,
              consumerType: $scope.jobSummary.jobDescription.isActive ?
                'kProtectionRuns' : 'kReplicationRuns',
            },
          },
          FEATURE_FLAGS.storageNgDesign && {
            headingKey: 'trend',
            route: 'protection-group.group.trend',
            params: {
              groupId: $scope.ngJobId,
              id: $state.params.id,
              consumerType: $scope.jobSummary.jobDescription.isActive ?
                'kProtectionRuns' : 'kReplicationRuns',
            },
          },
        ]);
      }

      // for remote snapshot jobs, 'statistics', 'consumption' and 'trends'
      // tab are not needed since there is no local data
      if (ENV_GROUPS.cloudJobsWithoutLocalSnapshot.includes($scope.job.type)) {
        $scope.cTabConfig =
          _.reject($scope.cTabConfig, function rejectTabs(tab) {
            return ['statistics', 'consumption', 'trend']
              .includes(tab.name);
          });
      }

      // Watch currentTab changes. On success, switch tabs and update help id.
      _unregisterTrans = $transitions.onSuccess({}, updateHelpId);

      $scope.$on('$destroy', _unregisterTrans);

      // Wait for the policy details to be loaded.
      $scope.$on('policyDetailsReady', function policyDetailsReady() {
        var policy = $scope.jobSummary.jobDescription._policyDetails;

        // Add the CDP tab, if the protection policy is CDP enabled.
        if (!$scope.isFileStubbingJob &&
          FEATURE_FLAGS.cdpEnabled && policy && policy.cdpSchedulingPolicy) {

          $scope.cTabConfig.splice(1, 0, {
            headingKey: 'cdp',
            route: 'protection-group.group.cdp',
            params: { groupId: $scope.ngJobId },
          })
        }
      });
    }

    // I Can See Clearly Now The Rain is Gone
    activate();
  }

})(angular);
