// Service: Job Action Service
// NOTE: This Service should *never* be used as an example and needs to be
// refactored/deprecated in favor of a simpler solution

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.jobActions', [
      'C.jobRunsService',
      'C.selectPolicy',
      'C.constants',
    ])
    .service('JobActionService', JobActionServiceFn)
    .service('FailoverJobModal', failoverJobModal)
    .service('DeleteJobModal', deleteJobModal)
    .service('DeleteJobRunsModal', deleteJobRunsModal)
    .service('DeactivateJobModal', deactivateJobModal);

  function failoverJobModal($uibModal, JobService, SourceService, evalAJAX) {

    var modalDefaultOptions = {
      backdrop: 'static',
      keyboard: true,
      modalFade: true,
      templateUrl: 'app/protection/jobs/failover.html',
      controller: FailoverJobModalCtrl,
    };

    var modalOptions = {
      container: []
    };

    this.showModal = function showModal(job) {
      var instanceOptions = angular.merge({
        resolve: {
          job: function() { return job; },
        },
      }, modalDefaultOptions);

      return $uibModal.open(instanceOptions).result;
    };
  }

  /**
   * Failover modal controller. To be passed as the control option to
   * $uibModal.open().
   */
   /* @ngInject */
  function FailoverJobModalCtrl($scope, $uibModalInstance, evalAJAX, JobService,
    JobRunsService, job, ENV_TYPE_CONVERSION, ENV_GROUPS, CLOUD_SUBSCRIPTION) {

    $scope.job = job;
    $scope.ENV_TYPE_CONVERSION = ENV_TYPE_CONVERSION;
    $scope.ENV_GROUPS = ENV_GROUPS;

    var isHyperVJob = job._backupParamsKey === 'hypervBackupParams';

    if (job.drToCloudParams && job.drToCloudParams.needToFailOver) {
      $scope.isCloudMigrationJob = true;
      if (isHyperVJob) {
        $scope.envTypes = [
          ENV_TYPE_CONVERSION.kHyperV,
          ENV_TYPE_CONVERSION.kAzure
        ];
      } else {
        $scope.envTypes = [
          ENV_TYPE_CONVERSION.kVMware,
          ENV_TYPE_CONVERSION.kAzure,
          ENV_TYPE_CONVERSION.kAWS
        ];
      }
    } else {
      switch(true) {
        case isHyperVJob:
          $scope.envTypes = [ENV_TYPE_CONVERSION.kHyperV];
          break;

        case job.type === ENV_TYPE_CONVERSION.kAWSNative:
          $scope.envTypes = [ENV_TYPE_CONVERSION.kAWS];
          break;

        case job.type === ENV_TYPE_CONVERSION.kAzureNative:
          $scope.envTypes = [ENV_TYPE_CONVERSION.kAzure];
          break;

        case job.type === ENV_TYPE_CONVERSION.kGCPNative:
          $scope.envTypes = [ENV_TYPE_CONVERSION.kGCP];
          break;

        default:
          $scope.envTypes = [ENV_TYPE_CONVERSION.kVMware];
      }
    }

    /**
     * Handles form submission of the failover modal
     *
     * @method   submit
     * @param    {object}   theForm   The form being submitted
     */
    $scope.submit = function submit(theForm) {
      var source = $scope.selectedSource;
      var policy = $scope.selectedPolicy;

      if (theForm.$invalid) { return; }

      if ($scope.isCloudMigrationJob && $scope.isCloudTargetSelected()) {
        $uibModalInstance.close({
          job: job,
          source: source,
        });

        return;
      }

      $scope.submitting = true;

      JobService.failoverJob(job.jobId, source.id, policy.id).then(
        function failoverJobSuccess(resp) {
          $uibModalInstance.close({
            job: job,
            source: source,
            policy: policy,
          });
        },
        evalAJAX.errorMessage
      ).finally(
        function failoverFinally() {
          $scope.submitting = false;
        }
      );
    };

    /**
     * Is a cloud source selected as failover target
     *
     * @method isCloudTargetSelected
     */
    $scope.isCloudTargetSelected = function isCloudTargetSelected() {
      return $scope.selectedSource && ENV_GROUPS.cloudDeploySources.includes(
        ENV_TYPE_CONVERSION[$scope.selectedSource.type]);
    }

    /**
     * Cancel/close modal function
     *
     * @method   close
     */
    $scope.close = function close() {
      $uibModalInstance.dismiss('cancel');
    };

    /**
     * Check for snapshots associated with jobId upfront and
     * do not allow failover if none are available.
     * If the failover job source is vcd, apply filter function.
     *
     * @method   $onInit
     */
    this.$onInit = function init() {
      $scope.loading = true;
      $scope.preventFailover = false;
      $scope.sourceFilterFn = sourceFilter;

      JobRunsService.getJobRuns({
        excludeNonRestoreableRuns: true,
        id: job.jobId,
      }).then(function successCallback(jobRuns) {
        if (jobRuns && !jobRuns.length) {
          $scope.preventFailover = true;
        }
      }).finally(function finallyCallback() {
        $scope.loading = false;
      });
    };

    /**
     * If the failover task's parent source is vCD, vCenter source is not
     * allowed in the source selection.
     */
    function sourceFilter(sources) {
      if (_.get($scope.job.parentSource, 'vmwareEntity.type') === 17) {
        return sources.filter(source => source[source._entityKey].type === 17);
      } else if (job.type === ENV_TYPE_CONVERSION.kAzureNative) {
        return sources.filter(source => {
          var subsType = _.get(source, 'azureEntity.commonInfo.subscriptionType');
          return !ENV_GROUPS.azureStackTypes.includes(subsType)  &&
            subsType === _.get(job, 'parentSource.azureEntity.commonInfo.subscriptionType');
        });
      } else if (job.type === ENV_TYPE_CONVERSION.kAWSNative) {
        return sources.filter(source => _.get(source, 'awsEntity.commonInfo.subscriptionType')
          === _.get(job, 'parentSource.awsEntity.commonInfo.subscriptionType'));
      }

      return sources;
    };
  }

  /**
   * DeleteJobModal Service function
   *
   * @method   deleteJobModal
   * @param    {object}   $uibModal   The uibModal
   * @param    {object}   FORMATS     Regex formats, as defined in constants.js
   */
  function deleteJobModal($uibModal) {
    var modalConfig = {
      keyboard: true,
      modalFade: true,
      backdrop: 'static',
      size: 'lg',
      templateUrl: 'app/protection/jobs/delete.html',
      controller: deleteJobModalControllerFn,
    };

    /**
     * Instantiate the Modal
     *
     * @method   showModal
     * @param    {String}    job          The job configuration to be deleted
     * @param    {String}    policy       The job policy
     * @param    {Boolean}   hasOrphans   Indicates if the Job contains orphaned
     *                                    snapshots
     * @return   {Promise}   uibModal
     */
    this.showModal = function showModal(job, policy, hasOrphans) {
      var isDataMigration = job._isDataMigrationJob;

      if (isDataMigration) {
        modalConfig.templateUrl =
          'app/protection/jobs/delete-data-migration.html';
      }

      angular.extend(
        modalConfig,
        {
          resolve: {
            isDataMigration: isDataMigration,
            job: job,
            policy: policy,
            hasOrphans: hasOrphans,
          },
        }
      );

      return $uibModal.open(modalConfig).result;
    };

    /**
     * Controller Logic for Delete Job Modal
     *
     * @method   deleteJobModalControllerFn
     * @param    {object}    $rootScope          The root scope
     * @param    {object}    $scope              The scope
     * @param    {object}    job                 The job, provided via resolve
     * @param    {Boolean}   hasOrphans          Indicates if the Job contains
     *                                           orphaned snapshots
     * @param    {object}    $uibModalInstance   The uibModalInstance
     */
     /* @ngInject */
    function deleteJobModalControllerFn(
      $rootScope, $scope, isDataMigration, job, policy, hasOrphans, $uibModalInstance) {

      $scope.isDataMigration = isDataMigration;
      $scope.job = job;
      $scope.policy = policy;
      $scope.hasOrphans = hasOrphans;

      $scope.modalConfirmDeleteJobWithSnapshots = function modalConfirmDeleteJobWithSnapshots() {
        $uibModalInstance.close(true);
      };

      $scope.modalConfirmDeleteJob = function modalConfirmDeleteJob() {
        $uibModalInstance.close(false);
      };

      $scope.modalCancel = function modalCancel() {
        $uibModalInstance.dismiss('cancel');
      };
    }
  }

  function deleteJobRunsModal($uibModal) {

    var modalConfig = {
      keyboard: true,
      modalFade: true,
      backdrop: 'static',
      templateUrl: 'app/protection/jobs/delete-runs.html',
      controller: deleteJobRunsModalController
    };
    var hasLogRun;
    var singleRun;
    var isArchiveTask;

    /**
     * Instantiate the Modal
     * @param   {Boolean}  hasLog     Does this Job contain log run snapshots?
     * @param   {Boolean}  single     Is this a single run to be deleted
     *                                (as opposed to more than one run)
     * @param   {Boolean}  isArchive  Is this an archived snapshot to be deleted
     * @return  {Promise}  uibModal
     */
    this.showModal = function showModal(hasLog, single, isArchive) {
      hasLogRun = hasLog;
      singleRun = single;
      isArchiveTask = isArchive;
      return $uibModal.open(modalConfig).result;
    };

    /**
     * Controller Logic for Delete Modal
     * @param  {Object} $scope
     * @param  {Object} uibModal
     */
    /* @ngInject */
    function deleteJobRunsModalController($rootScope, $scope, $uibModalInstance) {

      $scope.modalText = $rootScope.text.jobActionService.deleteJobRunsModal;

      $scope.hasLog = hasLogRun;
      $scope.singleRun = singleRun;
      $scope.isArchive = isArchiveTask;

      $scope.modalConfirmDeleteJobRuns = function modalConfirmDeleteJob() {
        $uibModalInstance.close(true);
      };

      $scope.modalCancel = function modalCancel() {
        $uibModalInstance.dismiss('cancel');
      };
    }
  }

  function deactivateJobModal($uibModal, ENV_TYPE_CONVERSION) {
    var modalConfig = {
      keyboard: true,
      modalFade: true,
      backdrop: 'static',
      templateUrl: 'app/protection/jobs/deactivate.html',
      controller: deactivateJobModalController
    };

    var jobName = '';
    var jobType;

    this.showModal = function showModal(job) {
      jobName = job.jobDescription.name;
      jobType = job.jobDescription.type;
      return $uibModal.open(modalConfig).result;
    };

    /* @ngInject */
    function deactivateJobModalController($rootScope, $scope, $uibModalInstance,
      FEATURE_FLAGS) {
      $scope.modalText = $rootScope.text.jobActionService.deactivateJobModal;

      $scope.jobName = jobName;

      $scope.disablePowerOffBtn =
        [ENV_TYPE_CONVERSION.kAzureNative, ENV_TYPE_CONVERSION.kAWSNative].includes(jobType)
          || FEATURE_FLAGS.hideDeactivateAndPowerOffVMs;

      $scope.modalDeactivate = function modalDeactivate() {
        $uibModalInstance.close(false);
      };

      $scope.modalDeactivatePowerOff = function modalDeactivatePowerOff() {
        $uibModalInstance.close(true);
      };

      $scope.modalCancel = function modalCancel() {
        $uibModalInstance.dismiss('cancel');
      };
    }

  }

  /**
   * @ngdoc  service
   * @name   C.jobActions.JobActionsService
   * @description
   *   Generates actions for a given job by its state and other params.
   */
  function JobActionServiceFn(_, $rootScope, $state, cMessage, JobService,
    JobRunsService, FailoverJobModal, DeleteJobModal, DeactivateJobModal,
    cModal, evalAJAX, ENV_TYPE_CONVERSION, FEATURE_FLAGS, PubJobService, featureFlagsService,
    NgProtectionRunsService) {

    var service = {
      getJobAction: getJobAction,
      isValidJobAction: isValidJobAction,
    };

    /**
     * Initializes modal config and options. Opens modal for backup now flow.
     *
     * @method   startBackupNowFlow
     * @param    {object}     job          The Job to load for backup now modal
     * @param    {function}   [callback]   Notifies us when a Job run has been
     *                                     successfuly submitted.
     * @return   {object}     Promise containing the modal's resolve & reject
     *                        response.
     */
    function startBackupNowFlow(job, callback) {
      var modalConfig = {
        size: 'lg',
        templateUrl: 'app/protection/jobs/modals/job-backup-now.html',
        controller: 'JobBackupNowController',
        resolve: {
          jobId: function() {
            return job.jobId;
          },
          clusterId: function() {
            return job.jobUid.clusterId;
          },
          nodeId: function() {
            return undefined;
          }
        },
      };

      var windowOptions = {
        actionButtonKey: false,
        closeButtonKey: false,
        titleKey: 'backupNowModal.header',
        titleKeyContext: job,
      };


      // Some API volumeInfo contains nodeName field which breaks AJS clone function.
      // Need to remove nodeName from API response to avoid run-time error
      for (let key of ['sources', 'excludeSources']) {
        if (Object.prototype.hasOwnProperty.call(job, key)) {
          job[key].forEach(source => {
            if (source.entities) {
              source.entities.forEach(entity => {
                if (entity.netappEntity && entity.netappEntity.volumeInfo) {
                  delete entity.netappEntity.volumeInfo.nodeName;
                }
              })
            }
          });
        }
      }

      return cModal.standardModal(modalConfig, windowOptions)
        .then(callback || angular.noop)
        .catch(angular.noop);
    }

    /**
     * Presents user with confirmation modal
     *
     * @method   failoverJob
     * @param    {object}     job        job to be failed over
     * @param    {function}   callback   The callback function
     * @return   {object}     promise to resolve request for failover
     */
    function failoverJob(job, callback) {
      return FailoverJobModal.showModal(job.jobDescription).then(
        function failoverInitiated(failoverSelections) {
          return (callback || angular.noop)(
            failoverSelections.job.jobUid, failoverSelections.source,
            failoverSelections.job.drToCloudParams &&
              failoverSelections.job.drToCloudParams.needToFailOver
          );
        }
      ).catch(angular.noop);
    }

    /**
     * interfaces JobService to pause future Job Runs
     *
     * @param      {Object}    job       jobDescription
     * @param      {Function}  callback  function to be executed on success
     */
    function pauseJob(job, callback) {
      var jobWrapper = {
        backupJob: job,
      };

      jobWrapper.backupJob.isPaused = true;
      JobService.pauseOrResumeJob(jobWrapper).then(
        function pauseJobSuccess(reponse) {
          cMessage.success({
            textKey: job._isDataMigrationJob ?
              'jobActionService.pauseDataMigrationJobSuccess' :
              'jobActionService.pauseJobSuccess',
          });
          (callback || angular.noop)();
        },
        evalAJAX.errorMessage
      );
    }


    /**
     * interfaces JobService to resume a paused job
     *
     * @param      {Object}    job       jobDescription
     * @param      {Function}  callback  function to be executed on success
     */
    function resumeJob(job, callback) {
      var jobWrapper = {
        backupJob: job,
      };

      jobWrapper.backupJob.isPaused = false;
      JobService.pauseOrResumeJob(jobWrapper).then(
        function resumeJobSuccess(reponse) {
          cMessage.success({
            textKey: job._isDataMigrationJob ?
              'jobActionService.resumeDataMigrationJobSuccess' :
              'jobActionService.resumeJobSuccess',
          });
          (callback || angular.noop)();
        },
        evalAJAX.errorMessage
      );
    }

    /**
     * Interfaces JobRunsService to request the cancelation of a job run.
     * Presents a confirmation modal before submitting request
     *
     * @param      {Object}    job       to be canceled
     * @param      {Function}  callback  function to be executed on success
     */
    function cancelJobRun(job, callback) {
      var text = $rootScope.text.jobActionService;
      var options = {
        title: text.cancelModal.title,
        content: text.cancelModal.text + job.jobDescription.name + ' ?',
        closeButtonText: text.cancelModal.cancel,
        actionButtonText: text.cancelModal.cancelJobRun
      };

      cModal.showModal({}, options).then(function(r) {
        // Locate JobInstanceId
        // if this job was retreived from backupjopbsummary, get jobInstanceId from lastProtectionRun
        // if this job was retreived from backupjobruns, get jobInstanceId from protectionRuns[0]
        var jobInstanceId;
        jobInstanceId = job.hasOwnProperty('lastProtectionRun') ?
          job.lastProtectionRun.backupRun.base.jobInstanceId:
          _.get(job, 'protectionRuns[0].backupRun.base.jobInstanceId');

        // This is to handle cases where this function is called from ng-ui
        if (!jobInstanceId && job.hasOwnProperty('jobDescription')) {
          jobInstanceId = job.jobDescription.lastRunId;
        }

        featureFlagsService.enabled('enableV2ApiForDbAdaptor') ?
          cancelJobRunV2(job, callback) : cancelJobRunV1(job, jobInstanceId, callback);

      }).catch(angular.noop);
    }

    /**
     * V1 API version of cancel job run request
     *
     * @param      {Object}    job             to be canceled
     * @param      {String}    jobInstanceId   instance id of job
     * @param      {Function}  callback        function to be executed on success
     */
    function cancelJobRunV1(job, jobInstanceId, callback) {
      var data = {
        jobRunId: jobInstanceId
      };
      JobRunsService.cancelJobRun(job.jobDescription.jobId, data).then(
        function cancelJobSuccess(response) {
          cMessage.success({
            textKey: 'jobActionService.cancelJobRunSuccess',
          });
          (callback || angular.noop)();
        },
        evalAJAX.errorMessage
      ).catch(angular.noop);
    }

    /**
     * Method to call V2 API version of cancel job run request.
     * Requires latest run taskId and runId from getRuns request.
     *
     * @param      {Object}    job       to be canceled
     * @param      {Function}  callback  function to be executed on success
     */
    function cancelJobRunV2(job, callback) {
      const jobUid = job?.jobDescription?.jobUid || {};
      const groupId = `${jobUid.clusterId}:${jobUid.clusterIncarnationId}:${jobUid.objectId}`;
      const getRunsParams = {
        id : groupId,
        useCachedData: false,
        localBackupRunStatus: ['Running'],
        includeObjectDetails: false,
      };

      NgProtectionRunsService.getRuns(getRunsParams).toPromise().then(jobRuns => {
          const runId = jobRuns?.runs[0]?.id;
          const taskId = jobRuns?.runs[0]?.localBackupInfo?.localTaskId;
          const taskType = 'backup';
          NgProtectionRunsService.cancelRun(groupId, runId, taskId, taskType).toPromise().then(
          function cancelJobSuccess(response) {
            cMessage.success({
              textKey: 'jobActionService.cancelJobRunSuccess',
            });
            (callback || angular.noop)();
          },
          evalAJAX.errorMessage
        );
        }, evalAJAX.errorMessage).catch(angular.noop);
    }

    /**
     * Presents a confirmation modal before deleting a job
     *
     * @param      {Object}    job       to be delete
     * @param      {Function}  callback  function to be executed on success
     */
    function deleteJob(job, callback) {
      var orphanedSnapshots =
        (job.jobDescription.hasOwnProperty('deletionStatus') &&
        job.jobDescription.deletionStatus === 1) ||

        // Protect once job is deleted internally after first run
        // only show options for user to delete snapshot.
        !!job.jobDescription.isProtectOnce;

      // Show a challenge modal to prevent accidental delete
      DeleteJobModal.showModal(
        job.jobDescription, job._policy, orphanedSnapshots).then(
        function showModalConfirm(deleteSnapshots) {
          var opts = {
            deleteSnapshots: deleteSnapshots
          };
          // TODO: move this API call handling into DeleteJobModal
          JobService.deleteJob(job.jobDescription.jobId, opts).then(
            function deleteJobSuccess(response) {
              cMessage.success({
                textKey: job.jobDescription._isDataMigrationJob ?
                  'jobActionService.deleteDataMigrationJobSuccess' :
                  'jobActionService.deleteJobSuccess',
              });
              (callback || angular.noop)(deleteSnapshots);
            },
            evalAJAX.errorMessage
          );
        }
      ).catch(angular.noop);
    }

    /**
     * Presents a confirmation modal before deactivating a job
     *
     * @param      {Object}    job       to be deactivated
     * @param      {Function}  callback  function to be executed on success
     */
    function deactivateJob(job, callback) {
      // Show a challenge modal to prevent accidental delete
      DeactivateJobModal.showModal(job).then(
        function submitModal(response) {
          var params = {
            powerOff: response
          };
          JobService.deactivateJob(job.jobDescription.jobId, params).then(
            function deactivateJobSuccess(response) {
              cMessage.success({
                textKey: 'jobActionService.deactivateJobSuccess',
              });
              (callback || angular.noop)();
            },
            evalAJAX.errorMessage
          );
        }
      ).catch(angular.noop);
    }


    /**
     * Gets the configuration object for a given Job Action
     *
     * @param      {String}    action    the job action, must match one of the
     *                                   below
     * @param      {Object}    job       as returned by backupjobsummary or
     *                                   backupjobruns
     * @param      {Function}  callback  function to run on success of the given
     *                                   action
     *
     * @return     {Object}    job action configuration
     */
    function getJobAction(action, job, callback) {
      var text = $rootScope.text.jobActionService;

      switch (action) {
        case 'failover':
          return {
            display: text.failover,
            icon: 'icn-activate',
            id: 'failover-activate-job-anchor',
            action: function failoverJobWrapper() {
              failoverJob(job, callback);
            }
          };

        case 'start':
          return {
            display: text.runNow,
            icon: 'icn-run-now',
            id: 'run-job-now-anchor',
            action: function startJobWrapper() {
              startBackupNowFlow(job.jobDescription, callback);
            }
          };

        case 'pause':
          return {
            display: text.pauseFutureRuns,
            icon: 'icn-pause',
            id: 'pause-future-runs-anchor',
            action: function pauseJobWrapper() {
              pauseJob(job.jobDescription, callback);
            }
          };

        case 'resume':
          return {
            display: text.resumeFutureRuns,
            icon: 'icn-play',
            id: 'resume-future-runs-anchor',
            action: function resumeJobWrapper() {
              resumeJob(job.jobDescription, callback);
            }
          };

        case 'cancel':
          return {
            display: text.cancelJobRunToolTip,
            icon: 'icn-cancel',
            id: 'cancel-job-run-anchor',
            disabled: /^kCancel/i.test(
              _.get(job.protectionRuns, '[0].backupRun.base.publicStatus', '')
            ),
            action: function cancelJobRunWrapper() {
              cancelJobRun(job, callback);
            }
          };

        case 'edit':
          return {
            display: text.edit,
            icon: 'icn-edit',
            id: 'edit-job-details-anchor',
            action: function editJobWrapper() {
              if (job.jobDescription._isDataMigrationJob && job.jobDescription._isUpTieringJob) {
                PubJobService.modifyFileUpTiering(job).then(callback);
                return;
              }

              const uid = job.jobDescription.jobUid;
              $state.go(job.jobDescription._isDownTieringJob ?
                'file-stubbing-modify' : 'job-modify',
              {
                id: job.jobDescription.jobId,

                // The ng protection group edit page requires the environment
                // in order to know which adapter state to navigate to.
                environments: [ENV_TYPE_CONVERSION[job.jobDescription.type]],

                // v2 protection group ids need to be derived from the uid.
                // pass both the id and the uid to the state so that the
                // appropriate redirects can be made.
                uid: [uid.clusterId, uid.clusterIncarnationId, uid.objectId]
                  .join(':')
              });
            },
          };

        case 'delete':
          return {
            display: job.jobDescription._isDataMigrationJob ?
              text.delDataMigrationJob : text.del,
            icon: 'icn-delete',
            id: 'delete-job-anchor',
            action: function deleteJobWrapper() {
              deleteJob(job, callback);
            }
          };

        case 'deleteSnapshots':
          return {
            display: text.delSnaps,
            icon: 'icn-delete-snapshot',
            id: 'delete-snapshots-anchor',
            action: function deleteSnapshotsWrapper() {
              deleteJob(job, callback);
            }
          };

        case 'deleteSnapshotMode':
          return {
            display: text.delSnaps,
            icon: 'icn-delete-snapshot',
            id: 'delete-snapshot-mode-anchor',
            action: function deleteSnapshotModeWrapper() {
              $state.go(job.jobDescription._isDataMigrationJob ?
                'job-details.file-stubbing-delete-snapshots'
                :'job-details.delete-snapshots',
                { id: $state.params.id }, { reload: true });
            }
          };

        case 'deactivate':
          return {
            display: text.deactivateToolTip,
            icon: 'icn-deactivate',
            id: 'deactivate-job-anchor',
            action: function deactivateJobWrapper() {
              deactivateJob(job, callback);
            }
          };

        case 'upTiering':
          return {
            display: text.addUpTiering,
            icon: 'icn-add',
            id: 'add-up-tiering-anchor',
            action: function deactivateJobWrapper() {
              PubJobService.modifyFileUpTiering(job).then(callback);
            }
          };

      }
    }

    /**
     * Evaluates Job object to determine if a given Job Action is valid
     *
     * @param      {String}   action  the job action, must match one of the
     *                                below
     * @param      {Object}   job     as returned by backupjobsummary
     * @param      {Object}   opts    Additional options used in evaluation go
     *                                here
     *
     * @return     {Boolean}  is valid action?
     */
    function isValidJobAction(action, job, opts) {

      switch (action) {

        case 'failover':
          // for inactive VM jobs add failover option
          return $rootScope.user.privs.PROTECTION_MODIFY &&
            $rootScope.user.privs.RESTORE_MODIFY &&
            !job.jobDescription.isActive &&
            ['kVMware', 'kAWSNative', 'kAzureNative', 'kHyperV', 'kHyperVVSS']
              .includes(ENV_TYPE_CONVERSION[job.jobDescription.type]);

        case 'start':
          // Job is local/active, future runs aren't paused, job is at rest, AND
          // job is not deleted, show play button
          return $rootScope.user.privs.PROTECTION_JOB_OPERATE &&
            job.jobDescription.isActive &&
            jobAtRest(job) &&
            !job.jobDescription.isDeleted;

        case 'pause':
          // Job is not paused and active, and not deleted, show pause option
          return $rootScope.user.privs.PROTECTION_JOB_OPERATE &&
          !job.jobDescription.isPaused &&
          job.jobDescription.isActive &&
          !job.jobDescription.isDeleted;

        case 'resume':
          // Job is paused, show un-pause option
          return $rootScope.user.privs.PROTECTION_JOB_OPERATE &&
          job.jobDescription.isPaused &&
          !job.jobDescription.isDeleted;

        case 'cancel':
          // Job is running, and not deleted, show the cancel button
          return $rootScope.user.privs.PROTECTION_JOB_OPERATE &&
            job.lastProtectionRun &&
            job.lastProtectionRun.backupRun.base.status === 1 &&
            !job.jobDescription.isDeleted;

        case 'edit':
          // if job is not deleted and is local (not replication target), it is
          // editable
          return $rootScope.user.privs.PROTECTION_MODIFY &&
            !job.jobDescription.isDeleted &&
            job.jobDescription.isActive;

        case 'deleteSnapshots':
          // If job is at rest and deleted, show delete snapshot action
          return $rootScope.user.privs.PROTECTION_MODIFY &&
            jobAtRest(job) &&
            job.jobDescription.deletionStatus === 1;

        case 'deleteSnapshotMode':
          // we should always show deleteSnapshotsMode
          // if the job has deletableRuns
          return $rootScope.user.privs.PROTECTION_MODIFY &&
            opts.hasDeleteableRuns;

        case 'delete':
          // If job is at rest, not wormlocked or deleted, show delete action
          return $rootScope.user.privs.PROTECTION_MODIFY &&
            jobAtRest(job) &&
            !job.jobDescription.deletionStatus;

        case 'deactivate':
          // Deactivate Job if Policy has a Remote Replication rule
          return $rootScope.user.privs.PROTECTION_MODIFY &&
            job.jobDescription.type === 1 &&
            job.jobDescription.isActive;

        case 'upTiering':
          // Add data migration Up-tiering Job.
          return $rootScope.user.privs.PROTECTION_MODIFY && job.jobDescription._isDownTieringJob;

        default:
          // unknown action
          return false;

      }

    }

    /**
     * Utility evaluates if a job is at rest
     *
     * @param      {Object}   job     The job
     *
     * @return     {Boolean}  true if job is not currently running, false
     *                        otherwise
     */
    function jobAtRest(job) {
      // Set this to an unused number (-1) when sqlParallelLogRuns is true and
      // this is a SQL job. Otherwise set to 1 ("Running" [See
      // ENUM_BACKUP_JOB_STATUS for details]).
      var runningJobEnum = (FEATURE_FLAGS.sqlParallelLogRuns &&
          job.jobDescription.type === ENV_TYPE_CONVERSION.kSQL) ? -1 : 1;

      return !job.lastProtectionRun ||
        // 3.1 is "Canceling"
        ![runningJobEnum, 3.1].includes(job.lastProtectionRun.backupRun.base.status);
    }

    return service;
  }
})(angular);
