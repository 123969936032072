import { inject, Injectable } from '@angular/core';
import { flagEnabled, IrisContextService, isMcm } from '@cohesity/iris-core';
import { StateObject, Transition } from '@uirouter/core';

import { GuardPriority, GuardResult, StateGuard } from '../state-guard';
import { UserService } from '../../services';
import { IS_IBM_AQUA_ENV } from '@cohesity/shared/core';

const matchStates = ['login', 'logout'];

/**
 * Login Guard
 * Handles conditional redirecting to the new Angular refactored login component.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements StateGuard {
  /**
   * This guard priority is set to App, which indicates it should run as part of the main application
   * After all bootstrapping and initializing is completed.
   */
  guardPriority = GuardPriority.App;

  /**
   * Match requests for the old login state and redirecting
   * to the new login state.
   */
  matchCriteria = {
    to: (state: StateObject, transition: Transition) => matchStates.includes(transition.targetState().name()),
  };

  /**
   * Determines whether the UI is running in IBM Aqua mode.
   */
  readonly isIBMAquaEnv = inject(IS_IBM_AQUA_ENV);

  constructor(
    private irisContextService: IrisContextService,
    private userService: UserService
  ) {}

  /**
   * Redirects conditionally to the new angular login state
   * if feature flag is set and we are running as Mcm.
   *
   * @param   transition  The UI router transition
   * @returns a conditional redirect transition.
   */
  onStart(transition: Transition): GuardResult {
    const targetName = transition.targetState().name();
    const irisContext = this.irisContextService.irisContext;
    const isLoggedIn = !!this.userService.isLoggedIn;

    if (isMcm(irisContext) && flagEnabled(irisContext, 'ngLoginEnabled') && !this.isIBMAquaEnv && !isLoggedIn) {
      if (targetName === 'login') {
        return transition.router.stateService.target('login-ng', transition.params());
      } else if (targetName === 'logout') {
        return transition.router.stateService.target('logout-ng', transition.params());
      }
    }
  }
}
