// Component: Select clusters

;(function(angular) {
  'use strict';

  angular.module('C.appsManagement')
    .controller('SelectClusterCtrl', SelectClusterCtrlFn)
    .component('clusterSelect', {
      templateUrl:
        'app/apps-management/app-store/select-cluster/select-cluster.html',
      controller: 'SelectClusterCtrl',
      bindings: {
        /**
         * App information that will be installed on the selected clusters
         * from this component.
         *
         * @type {object}
         */
        app: '=',
      },
      require: {
        cModalHoc: '^^?cModalHoc',
      }
    });

  function SelectClusterCtrlFn(_, $state, UpgradeClusterService, CHART_COLORS) {
    var $ctrl = this;

    _.assign($ctrl, {
      CHART_COLORS: CHART_COLORS,
      isLoading: false,
      remoteClusters: [],
      selectedCluster: null,

      // Component life-cycle methods
      $onInit: $onInit,
    });

    /**
     * Initializes the controller
     *
     * @method   $onInit
     */
    function $onInit() {
      if ($ctrl.cModalHoc) {
        $ctrl.cModalHoc.onModalSave = runAppOnCluster;
      }
      _getAllClusters();
    }

    /**
     * Fetches the list of all clusters for the specific app
     *
     * @method   _getAllClusters
     */
    function _getAllClusters() {
      $ctrl.isLoading = true;

      return UpgradeClusterService.getMcmClustersForUpgrade().then(
        function onSuccess(clusters) {
          clusters.map(function mapSearchResults(remoteCluster) {
            $ctrl.app.clusters.map(function fetchAppState(cluster) {
              if ((remoteCluster.clusterId === cluster.clusterId) &&
                remoteCluster.connectedToCluster) {
                  $ctrl.remoteClusters.push(remoteCluster);
              }
            });
          });
        }
      )
      .finally(function gotClusterDetails() {
        $ctrl.isLoading = false;
      });
    }

    /**
     * run app instance on selected cluster
     *
     * @method   runAppOnCluster
     */
    function runAppOnCluster() {
      return $state.go('apps-management-applaunch', {appUid: $ctrl.app.appId,
        version: $ctrl.app.version, cid: $ctrl.selectedCluster.clusterId});
    }

  }
})(angular);
