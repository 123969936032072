/* eslint-disable no-bitwise */
import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { NfsRootPermissions } from '@cohesity/api/v2';

/**
 * Default permissions.
 */
export const defaultPermissions = {
  gid: 0,
  mode: 493,
  uid: 0,
};

/**
 * Nfs Permission model defintion.
 */
interface NfsPermission {
  /**
   * Key name for Nfs permission.
   */
  key: 'owner' | 'group' | 'others';

  /**
   * Base value for permission.
   */
  base: number;
}

/**
 * Model defintion for permission types.
 */
interface PermissionModel {
  /**
   * Read type permission data.
   */
  read: number;

  /**
   * Write type permission data.
   */
  write: number;

  /**
   * Execute type permission data.
   */
  execute: number;
}

/**
 * Definition for Encoded Nfs Permission.
 */
interface EncodedNfsPermission {
  /**
   * Specifies owner based nfs permissions.
   */
  owner: PermissionModel;

  /**
   * Specifies group based nfs permissions.
   */
  group: PermissionModel;

  /**
   * Specifies others nfs permissions.
   */
  others?: PermissionModel;
}

/**
 * Modify Nfs root permissions component.
 *
 * @example
 *   <coh-nfs-root-permissions></coh-nfs-root-permissions>
 */
@Component({
  selector: 'coh-nfs-root-permissions',
  templateUrl: './nfs-root-permissions.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NfsRootPermissionsComponent),
      multi: true,
    },
  ],
})
export class NfsRootPermissionsComponent implements ControlValueAccessor {

  /**
   * Indicates whether the form is editable or not. Default to true.
   */
  @Input() editable = true;

  /**
   * Nfs permissions data.
   */
  nfsPermissionsData: NfsPermission[] = [
    {
      key: 'owner',
      base: 256,
    },
    {
      key: 'group',
      base: 32,
    },
    {
      key: 'others',
      base: 4,
    },
  ];

  /**
   * Encoded Nfs Permissions.
   */
  permissions: EncodedNfsPermission;

  /**
   * Get the form controls needed for the sub form.
   *
   * @returns   The form controls object.
   */
  nfsRootPermissionsForm: UntypedFormGroup = new UntypedFormGroup({
    gid: new UntypedFormControl(null),
    mode: new UntypedFormControl(null),
    uid: new UntypedFormControl(null),
  });

  public onTouched: () => void = () => {};

  /**
   * Update the permission with a value passed from a form
   *
   * @param   value   the new form control value
   */
  writeValue(value: NfsRootPermissions) {
    if (value) {
      this.nfsRootPermissionsForm.setValue(value, { emitEvent: false });
    }

    if (this.nfsRootPermissionsForm.controls.mode.value === null) {
      this.nfsRootPermissionsForm.setValue(defaultPermissions);
    }

    this.permissions = this.decodeDecimalNfsPermissions(this.nfsRootPermissionsForm.controls.mode.value);

    if (!this.editable) {
      this.nfsRootPermissionsForm.disable();
    } else {
      this.nfsRootPermissionsForm.enable();
    }
  }

  registerOnChange(fn: any) {
    this.nfsRootPermissionsForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(_fn: any) { }

  /**
   * Method called to set disabled state based on form control attributes.
   *
   * @param   isDisabled   form control disabled attribute.
   */
  setDisabledState?(isDisabled: boolean) {
    isDisabled ? this.nfsRootPermissionsForm.disable() : this.nfsRootPermissionsForm.enable();
  }

  /**
   * Decodes the decimal mode of the NFS permissions and returns an object.
   *
   * @param     decimalMode   Decimal (base 10) form of NFS permissions.
   * @returns                 Decoded NFS permissions object.
   */
  decodeDecimalNfsPermissions(decimalMode: number): EncodedNfsPermission {
    return {
      owner: {
        read: decimalMode & 256,
        write: decimalMode & 128,
        execute: decimalMode & 64,
      },
      group: {
        read: decimalMode & 32,
        write: decimalMode & 16,
        execute: decimalMode & 8,
      },
      others: {
        read: decimalMode & 4,
        write: decimalMode & 2,
        execute: decimalMode & 1,
      },
    };
  }

  /**
   * Calculates and updates the decimal mode of the NFS permissions.
   *
   * @param   change       Checkbox toggle changes.
   * @param   permission   checked permission source.
   * @param   type         permission type eg: read, write, execute.
   */
  onChangePermissions(change: MatCheckboxChange, permission: NfsPermission, type: string) {
    let baseValue = permission.base;

    if (!change.checked) {
      this.permissions[permission.key][type] = 0;
    } else {
      // Computing base values based on permission type.
      switch (type) {
        case 'write':
          baseValue *= 0.5;
          break;
        case 'execute':
          baseValue *= 0.25;
          break;
      }
      this.permissions[permission.key][type] = baseValue;
    }

    // Deriving mode value based on the sum of base values of nfs permissions.
    this.nfsRootPermissionsForm.controls.mode.setValue(this.encodeDecimalNfsPermissions());
  }

  /**
   * Calculates and returns the decimal mode of the NFS permissions.
   * An output of 493 (default perms) expects the following input param:
   * {
   *   owner: {
   *     read: 256,
   *     write: 128,
   *     execute: 64,
   *   },
   *   group: {
   *     read: 32,
   *     write: 0, // This value is off
   *     execute: 8,
   *   },
   *   others: {
   *     read: 4,
   *     write: 0, // This value is off
   *     execute: 1,
   *   },
   * }
   *
   * @param     nfsPermissions   NFS permissions object.
   * @returns                    Decimal (base 10) mode of NFS permissions.
   */
  encodeDecimalNfsPermissions() {
    return [
      ...Object.values(this.permissions.owner),
      ...Object.values(this.permissions.group),
      ...Object.values(this.permissions.others)
    ].reduce((accumulator, value) => accumulator + value, 0);
  }
}
