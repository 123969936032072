import { getConfigByKey, isOneHeliosAppliance } from '@cohesity/iris-core';
import { AppModuleConfig, StateAccessContext, StateAccessMap } from 'src/app/app-module.config';

const abacViewAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.abacModule &&
  ctx.S3_ABAC_VIEW &&
  getConfigByKey(ctx.irisContext, 'accessManagement.features.abac', true);

const activeDirectoryViewAccess = (ctx: StateAccessContext) =>
  ctx.AD_LDAP_VIEW &&
  ctx.FEATURE_FLAGS.ngActiveDirectory &&
  getConfigByKey(ctx.irisContext, 'accessManagement.features.activeDirectory', true);

const apiKeysAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.apiKeysEnabled &&
  ctx.API_KEY_VIEW &&
  getConfigByKey(ctx.irisContext, 'accessManagement.features.apiKeys', true);

const kerberosAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.kerberosEnabled &&
  ctx.KERBEROS_VIEW &&
  getConfigByKey(ctx.irisContext, 'accessManagement.features.kerberos', true);

const keystoneViewAccess = (ctx: StateAccessContext) =>
  ctx.KEYSTONE_VIEW &&
  ctx.FEATURE_FLAGS.keystoneModuleEnabled &&
  getConfigByKey(ctx.irisContext, 'accessManagement.features.keystone', true);

const ldapViewAccess = (ctx: StateAccessContext) =>
  ctx.AD_LDAP_VIEW &&
  ctx.FEATURE_FLAGS.ngLdapProvider &&
  getConfigByKey(ctx.irisContext, 'accessManagement.features.ldap', true);

const rolesViewAccess = (ctx: StateAccessContext) =>
  ctx.PRINCIPAL_VIEW &&
  ctx.FEATURE_FLAGS.ngRoles &&
  getConfigByKey(ctx.irisContext, 'accessManagement.features.roles', true);

const ssoAccess = (ctx: StateAccessContext) =>
  ctx.FEATURE_FLAGS.ngSSO && getConfigByKey(ctx.irisContext, 'accessManagement.features.sso', true);

const secureLoginAccess = (ctx: StateAccessContext) =>
  ((ctx.FEATURE_FLAGS.mfaEnabled && ctx.MFA_MODIFY) || (ctx.FEATURE_FLAGS.splitKeyHostAccess && ctx.CLUSTER_MODIFY)) &&
  (getConfigByKey(ctx.irisContext, 'accessManagement.features.secureLogin.mfa', true) ||
    getConfigByKey(ctx.irisContext, 'accessManagement.features.secureLogin.splitKey', true));

const nisAccess = (ctx: StateAccessContext) =>
  ctx.NIS_VIEW && ctx.FEATURE_FLAGS.nisEnabled && getConfigByKey(ctx.irisContext, 'accessManagement.features.nis', true);

const organizationsAccess = (ctx: StateAccessContext) =>
  ctx.ORGANIZATION_VIEW && getConfigByKey(ctx.irisContext, 'accessManagement.features.organizations', true);

const supportUserMfaAccess = (ctx: StateAccessContext) =>
  (ctx.FEATURE_FLAGS.supportUserMfaEnabled || isOneHeliosAppliance(ctx.irisContext)) &&
  ctx.CLUSTER_MODIFY &&
  getConfigByKey(ctx.irisContext, 'accessManagement.features.support', true);

const viewAccess = (ctx: StateAccessContext) =>
  ctx.PRINCIPAL_VIEW &&
  (ctx.FEATURE_FLAGS.ngUsersAndGroups || isOneHeliosAppliance(ctx.irisContext)) &&
  getConfigByKey(ctx.irisContext, 'accessManagement.features.usersAndGroups', true);

export const OnPremAccessManagementConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'on-prem-access-management.**',
      url: '/admin/on-prem-access-management',
      loadChildren: () => import('./on-prem-access-management.module').then(m => m.OnPremAccessManagementModule),
    },
    {
      name: 'access-management.kerberos.**',
      url: '^/admin/access/kerberos',
      loadChildren: () => import('./on-prem-access-management.module').then(m => m.OnPremAccessManagementModule),
    },
    {
      name: 'access-management.ng-sso.**',
      url: '^/admin/access/sso',
      loadChildren: () => import('./on-prem-access-management.module').then(m => m.OnPremAccessManagementModule),
    },
    {
      name: 'access-management.secure-login.**',
      url: '^/admin/access/secure-login',
      loadChildren: () => import('./on-prem-access-management.module').then(m => m.OnPremAccessManagementModule),
    },
    {
      name: 'access-management.support.**',
      url: '^/admin/access/support',
      loadChildren: () => import('./on-prem-access-management.module').then(m => m.OnPremAccessManagementModule),
    },
  ],
  getStateAccessMap(): StateAccessMap {
    return {
      'on-prem-access-management': viewAccess,
      'on-prem-access-management.users': viewAccess,
      'on-prem-access-management.support': supportUserMfaAccess,
      'on-prem-access-management.roles': rolesViewAccess,
      'on-prem-access-management.active-directory': activeDirectoryViewAccess,
      'on-prem-access-management.mfa': secureLoginAccess,
      'on-prem-access-management.kerberos': kerberosAccess,
      'on-prem-access-management.ldap': ldapViewAccess,
      'on-prem-access-management.organizations': organizationsAccess,
      'on-prem-access-management.sso': ssoAccess,
      'on-prem-access-management.keystone': keystoneViewAccess,
      'on-prem-access-management.nis': nisAccess,
      'on-prem-access-management.abac': abacViewAccess,
      'on-prem-access-management.api-keys': apiKeysAccess,
      'access-management.kerberos': kerberosAccess,
      'access-management.kerberos.list': kerberosAccess,
      'access-management.ng-sso': ssoAccess,
      'access-management.ng-sso.list': ssoAccess,
      'access-management.secure-login': secureLoginAccess,
      'access-management.support': supportUserMfaAccess,
      'access-management.support.list': supportUserMfaAccess,
      'access-management-support-mfa-setup': supportUserMfaAccess,
    };
  }
};
