/**
 * Specifies the type of backup for an Office365 User entity(kUser).
 *
 * Although Magneto is capable of backing up both Mailbox & Onedrive for a given user, but this segregation is required
 * so as to not run into scale issues & increased backup times. Hence, only one of listed backup types is allowed
 * within an Office365 Protection Job. This may converge in future.
 */
export enum Office365BackupType {
  kMailbox = 'kMailbox',
  kOneDrive = 'kOneDrive',
  kSharePoint = 'kSharePoint',
  kPublicFolders = 'kPublicFolders',
  kTeams = 'kTeams',
  kGroups = 'kGroups',
  kMailboxCSM = 'kMailboxCSM',
  kOneDriveCSM = 'kOneDriveCSM',
  kSharePointCSM = 'kSharePointCSM'
}
