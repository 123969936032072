import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProtectionPolicy, SlaRule } from '@cohesity/api/v2';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { filter, startWith } from 'rxjs/operators';
import { JobRunType } from 'src/app/shared';
import { getScheduleDuration } from 'src/app/util';

import { ProtectionItemName } from '../../../models';
import { BaseProtectionBuilderComponent } from '../../base-protection-builder/base-protection-builder.component';

interface Sla {
  full: number;
  incremental: number;
  log?: number;
}

@Component({
  selector: 'coh-settings-list-sla',
  templateUrl: './settings-list-sla.component.html',
  styleUrls: ['./settings-list-sla.component.scss'],
})
export class SettingsListSlaComponent
  extends BaseProtectionBuilderComponent<Sla, any> {
  /**
   * Default SLA value.
   */
  _value: Sla = getDefaultSla(null, isDmsScope(this.irisContextService.irisContext));

  /**
   * Form group for SLA.
   */
  slaFormGroup: UntypedFormGroup = new UntypedFormGroup({
    full: new UntypedFormControl(
      this.value.full,
      [Validators.required, Validators.min(1), Validators.pattern('^[1-9][0-9]*$')],
    ),
    incremental: new UntypedFormControl(
      this.value.incremental,
      [Validators.required, Validators.min(1), Validators.pattern('^[1-9][0-9]*$')],
    ),
    log: new UntypedFormControl(
      this.value?.log,
      [Validators.min(1)],
    ),
  });

  /**
   * Returns true if log backup sla settings are enabled.
   */
  get slaLogBackupEnabled(): boolean {
    const isLogBackup = this.formGroup.parent?.value?.policy?.backupPolicy?.log?.schedule;
    return flagEnabled(this.irisContextService.irisContext, 'logBackupSlaSettings') && isLogBackup;
  }

  /**
   * Specifies if the current user is a DMaaS user.
   */
  get isDmsScope(): boolean {
    return isDmsScope(this.irisContextService.irisContext);
  }

  constructor(
    readonly irisContextService: IrisContextService
  ) {
    super();
  }

  /**
   * Add the sla form group as control.
   */
  addFormControl() {
    this.formGroup.addControl(this.name, this.slaFormGroup);
  }

  /**
   * Form control init.
   */
  initFormControl() {
    const policyControl = this.formGroup.parent?.get(ProtectionItemName.Policy);

    policyControl?.valueChanges.pipe(
      this.untilDestroy(),
      startWith(policyControl.value),
      filter(value => !!value),
    ).subscribe(policy => {
      const defaultValue = getDefaultSla(policy, this.isDmsScope);

      if (this.protectionGroup?.id && this.protectionGroup?.sla) {
        // The API returns the SLA in an array of exactly two objects.
        const fullValue = this.protectionGroup.sla.find(
          slaItem => slaItem.backupRunType === 'kFull'
        ) || {slaMinutes: defaultValue.full};
        const incrementalValue = this.protectionGroup.sla.find(
          slaItem => slaItem.backupRunType === 'kIncremental'
        ) || {slaMinutes: defaultValue.incremental};
        const logValue = this.protectionGroup.sla.find((slaItem: SlaRule) =>
          slaItem.backupRunType === JobRunType.kLog) || {slaMinutes: defaultValue.log};

        this.value = {
          full: fullValue.slaMinutes,
          incremental: incrementalValue.slaMinutes,
          log: logValue.slaMinutes,
        };

        this.formControl.patchValue(this.value);
      } else if (!this.protectionGroup?.id) {

        // Fetch default SLA values during creation of Protection Group workflow.
        this.value = getDefaultSla(null, this.isDmsScope);
        this.formControl.patchValue(this.value);
      } else {
        if (policy) {
          this.value = defaultValue;
          this.formControl.patchValue(this.value);
        }
      }
    });
  }
}

/**
 * Function to return the default SLA for a protection configuration.
 *
 * @param policy Optional. Return default SLA based on policy.
 * @param dmsScope Optional and Defaults to false. Return SLA value based on DMaaS
 * or On-Prem scope and along with selected Policy.
 * @returns The default SLA value.
 */
export function getDefaultSla(policy?: ProtectionPolicy, dmsScope?: boolean): Sla {
  // Use this value if no policy provided.
  const defaultSla: Sla = dmsScope ?
    { full: 720, incremental: 720, log: 720 } :
    { full: 120, incremental: 60, log: 60 };

  if (!policy) {
    return defaultSla;
  }

  const {log, regular} = policy?.backupPolicy || {};

  const incrementalDuration = getScheduleDuration(regular?.incremental?.schedule);

  const logSchedule = getScheduleDuration(log?.schedule);

  if (!incrementalDuration) {

    // In case if log duration value exists update value for log sla.
    if (logSchedule) {
      defaultSla.log = Math.min(logSchedule, 1440);
    }
    return defaultSla;
  }

  // The default SLA is the duration of backup intervals, but max of 24 hours.
  // However, if the value is less than 12 hours, select 12 hours.
  // In case of On Prem the sla duration has to remain 2hr for full and 1hr for
  // incremental by default instead of 12 or 24 hours.
  const slaDuration = dmsScope ? Math.max(Math.min(incrementalDuration, 1440), 720) :
    Math.min(incrementalDuration, 1440);
  const logDuration = dmsScope ? Math.max(Math.min(logSchedule, 1440), 720) :
    Math.min(logSchedule, 1440);

  // Use the same value for both full and incremental.
  return {
    full: slaDuration,
    incremental: slaDuration,
    log: logDuration,
  };
}
