import { ChangeDetectionStrategy, Component, OnChanges } from '@angular/core';
import { HighchartsDataConfigService } from '@cohesity/helix';
import { Options, SeriesPieOptions } from 'highcharts';

import { CustomChartInput } from '../../../iris-reporting.model';
import { ChartReportsService } from '../../chart-reports.service';
import { DonutChartItemComponent } from '../donut-chart-item/donut-chart-item.component';

/**
 * A pie chart report item.
 */
@Component({
  selector: 'iris-rpt-pie-chart-item',
  templateUrl: './pie-chart-item.component.html',
  styleUrls: ['./pie-chart-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PieChartItemComponent extends DonutChartItemComponent implements OnChanges, CustomChartInput {
  /**
   * The chart's series, transformed from the input.
   */
  get pieChartSeries(): SeriesPieOptions[] {
    return this.donutChartSeries;
  }

  /**
   * Custom options for the bubble chart.
   */
  options: Options = {
    plotOptions: {
      pie: {
        innerSize: '0%',
        ...this.chartPlotOptions,
      },
    },
  };

  /**
   * Given a chart config, determine whether this chart can be used to render it or now.
   *
   * @param input The chart input
   * @returns True if this component can render it.
   */
  static canRender(input: CustomChartInput): boolean {
    return DonutChartItemComponent.canRender(input);
  }

  constructor(chartService: ChartReportsService, highchartConfigService: HighchartsDataConfigService) {
    super(chartService, highchartConfigService);
  }
}
