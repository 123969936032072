import { HttpClient } from '@angular/common/http';
import { Directive, HostListener, Input, OnInit } from '@angular/core';
import { IrisContextService, getConfigByKey, isAllClustersScope, isGlobalScope, isMcm, isTenantUser } from '@cohesity/iris-core';
import { UIRouterGlobals } from '@uirouter/core';
import { HELP_MAP, Routes } from 'src/app/shared/constants';
import { HelpLinkInfo } from '../models';

// Directive: Context Sensitive Help

// This list represents the converted alert ids that are currently documented.
// we will fall back to defaultAlertCshId if a derived alert ID isn't in this
// list. This list will be retrieved from help docs upon loading of the first
// helpLinkAlertCode directive.
let documentedAlertIds = [];

// For dev and team branches, which don't have online documentation,
// use a well known version as fallback.
export const defaultClusterVersionHelpPath = '7_1_1';

/**
 * Returns the tech doc version based cluster version.
 * For example: Converts 7.0_u1_release-20230213_10c8d5d8 to /7_0/, location
 * where tech docs are hosted for 7.0
 *
 * @returns The matching techdoc url.
 */
export function getTechDocVersionForCluster(clusterSoftwareVersion: string) {
  let clusterVersion: string = clusterSoftwareVersion.split('_')[0];
  // Remove any patch release version.
  clusterVersion = clusterVersion.replace(/[^0-9.]/g, '');

  // Replace any . version to underscore, as tech doc url replace . with _
  clusterVersion = clusterVersion.replace(/[.]/gi, '_');

  // Trim any non-zero minor version
  // Eg: 6_4_0 => 6_4, 6_8_1 => 6_8_1, 7_0 => 7_0, 7_0_1 => 7_0_1
  if (clusterVersion.split('_').length > 2) {
    clusterVersion = clusterVersion.replace(/(_0)$/g, '');
  }

  if (clusterVersion === '') {
    clusterVersion = defaultClusterVersionHelpPath;
  }

  return `/${clusterVersion}/`;
}


@Directive({
  selector: '[cohHelpLink]'
})
export class HelpLinkDirective implements OnInit {
  @Input() helpKeyInline: string;
  @Input() helpLinkAlertCode: string;
  @Input() helpLinkAlertSeverity: string;

  // For alert links, cluster software version is passed by parent
  // as for All Clusters and Global scope, we cannot rely on software
  // version from iris context.  It can also be used in other scenarios,
  // if needed.
  @Input() clusterSoftwareVersion: string;

  private helpWindow;

  constructor(
    private routerGlobals: UIRouterGlobals,
    private http: HttpClient,
    private irisContext: IrisContextService) {}

  /**
   * Click handler for CSH links.
   *
   */
  @HostListener('click') clickHelpElement() {
    const helpUrl = this.getHelpUrl();

    if (!this.helpWindow || this.helpWindow.closed) {
      this.helpWindow = window.open(helpUrl);
    } else {
      this.helpWindow.location = helpUrl;
      this.helpWindow.focus();
    }
  }

  /**
   * OnInit lifecycle hook.
   */
  ngOnInit() {
    // If this is an alert code based help link and the list of
    // known/documented alerts hasn't already been loaded, do so now.
    if (this.helpLinkAlertCode && !documentedAlertIds.length) {
      this.populateDocumentedAlertIds();
    }
  }

  /**
   * Resolves the Url for the help link based on provided helpKey and
   * cluster setting for local/remote help.
   *
   * @return   The help url.
   */
  private getHelpUrl(): string {
    // Cast current state to any so that it can reference properties that have been
    // added to it apart from what is defined by the interface.
    const currentState: any = this.routerGlobals.current;
    let isDocumentationLocal = false;
    let isCloudInstall = false;
    let isAzureInstall = false;
    let isAWSInstall = false;
    let isGCPInstall = false;
    let helpQueryParams = '';
    let clusterId;
    let id;
    let helpRoot;
    let helpSubdir;
    let isAllClustersOGlobalScope;
    const isAlertsHelp = !!this.helpLinkAlertCode && !!this.helpLinkAlertSeverity;

    const clusterInfo = this.irisContext.irisContext.clusterInfo as any;

    if (clusterInfo) {
      // show local docs to tenant's as they are lacking salesforce account needed to access remote docs.
      isDocumentationLocal = clusterInfo.isDocumentationLocal || isTenantUser(this.irisContext.irisContext);
      isCloudInstall = clusterInfo._isCloudInstall;
      isGCPInstall = clusterInfo._isGCPInstall;
      isAWSInstall = clusterInfo._isAWSInstall;
      isAzureInstall = clusterInfo._isAzureInstall;
      clusterId = clusterInfo.id;
      helpQueryParams = ['?clusterId=', clusterId].join('');
      isAllClustersOGlobalScope = isMcm(this.irisContext.irisContext) &&
        (isAllClustersScope(this.irisContext.irisContext) || isGlobalScope(this.irisContext.irisContext));
    }


    switch (true) {
      case !!this.helpKeyInline:
        id = this.getHelpIdFromMap(this.helpKeyInline);
        break;

      case !!this.helpLinkAlertCode && !!this.helpLinkAlertSeverity:
        id = this.getHelpIdFromAlertCode(this.helpLinkAlertCode, this.helpLinkAlertSeverity, isDocumentationLocal);
        break;


      case isCloudInstall && !!currentState.cloudHelp:
        id = this.getHelpIdFromMap(currentState.cloudHelp);
        break;

      default:
        id = this.getHelpIdFromMap(currentState.help || 'index');
    }

    helpRoot = Routes.helpRoot.local;
    let helpIndexFile = Routes.helpRoot.index;
    switch (true) {
      // If current context is in Helios MultiCluster Mode. Switch to
      // Helios Docs - except of alerts, which will come from cluster version
      // specific help pages.
      case isAllClustersOGlobalScope && !isAlertsHelp: {
        const helpConfig = getConfigByKey<HelpLinkInfo>(
          this.irisContext?.irisContext, 'helpCenterLink', Routes.helpRoot
        );
        helpIndexFile = helpConfig?.index;
        helpRoot = [
          helpConfig.remote,
          helpConfig.heliosSuffix,
        ].join('');
        break;
      }

      // If cluster is configured to use web docs, add the correct sub
      // directory to the URL based on cluster type.
      case !isDocumentationLocal:
        helpRoot = [
          Routes.helpRoot.remote,
          getTechDocVersionForCluster(this.clusterSoftwareVersion ||
            this.irisContext?.irisContext?.clusterInfo?.clusterSoftwareVersion),
        ].join('');

        if (isAzureInstall) {
          helpSubdir = Routes.helpSubdir.azureCloud;
        } else if (isAWSInstall) {
          helpSubdir = Routes.helpSubdir.amazonCloud;
        } else if (isGCPInstall) {
          helpSubdir = Routes.helpSubdir.googleCloud;
        } else {
          helpSubdir = Routes.helpSubdir.physical;
        }

        helpRoot = helpRoot.concat(helpSubdir);
        break;
    }

    return [
      helpRoot,
      helpIndexFile,
      '#cshid=',
      id,
      helpQueryParams,
    ].join('');
  }

  /**
   * Gets the help identifier from the help map or provides index as
   * default.
   *
   * @param    helpKey   The string help key
   * @return   The help identifier from map.
   */
  private getHelpIdFromMap(helpKey) {
    return (helpKey && HELP_MAP[helpKey]) ?
      HELP_MAP[helpKey] :

      // Defaults to index
      HELP_MAP.index;
  }

  /**
   * Retrieves a list of generated known/documented alert ids from the a
   * dark site text file.
   *
   */
  private populateDocumentedAlertIds() {

    const knownAlertsListUrl = [
      Routes.helpRoot.local,
      Routes.helpAlertList,
    ].join('');

    this.http.get(knownAlertsListUrl, { responseType: 'text' as 'json'}).subscribe((resp: any) => {
      if (resp) {
        // Replace any whitespace characters (including new line) and split
        // the file contents on comma.
        documentedAlertIds = resp.replace(/\s/g, '').split(',');
      }
    }
    );
  }

  /**
   * Transforms an alert code into a CSH help id based on rules provided by
   * doc team in ENG-12780.
   *
   * @param    alertCode       The alert code
   * @param    alertSeverity   The alert severity enum
   * @return   The help identifier from alert code.
   */
  private getHelpIdFromAlertCode(alertCode: string, alertSeverity: string, isDocumentationLocal: boolean): any {

    const defaultAlertCshId = 999999999;

    const severityIntMap = {
      kCritical: 1,
      kWarning: 2,
      kInfo: 3
    };

    if (!alertCode) {
      // Defaults to index
      return HELP_MAP.index;
    }

    alertCode = alertCode
      // Remove all non numeric characters and (assumed) leading 0.
      .replace(/\D/g, '').substring(1);

    // add a 9 to the beginning and end with numeric representation of
    // severity
    alertCode = ['9', alertCode, severityIntMap[alertSeverity]].join('');

    // if the derived alertCode isn't known to be documented,
    // fall back to the default alert CSH id in case of locally hosted docs.
    // In hosted docs at docs.cohesity.com, we don't have alerts_known.txt,
    // or it may be outdated rendered from iris pod, so, we will ignore
    // this check if the help is not hosted locally.
    if (isDocumentationLocal && !documentedAlertIds.includes(alertCode)) {
      return defaultAlertCshId;
    }

    return alertCode;
  }
}
