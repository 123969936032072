import { Environment } from '../env';

/**
 * Type definition for mapping environments to it's object type icons.
 */
export type ObjectIconType = { [K in Environment]?: {[objectType: string]: string} };

/**
 * Environment type to (object type -> helix icon name) mappings. This is currently
 * being used in the protection-source-data-node.ts to render helix icons next to
 * the tree nodes.
 */
export const ObjectTypeToIconMap: ObjectIconType = {
  kAcropolis: {
    kVirtualMachine: 'helix:object-vm',
    kSCVMMServer: 'helix:object-datacenter',
    kStandaloneHost: 'helix:object-hostsystem',
    kStandaloneCluster: 'helix:object-cluster',
    kHostGroup: 'helix:object-cluster',
    kHypervHost: 'helix:object-hostsystem',
    kHostCluster: 'helix:object-cluster',
    kTag: 'helix:object-tag',
  },
  kAD: {
    kDomainController: 'helix:object-ad',
  },
  kCassandra: {
    kCluster: 'helix:object-cluster',
    kKeyspace: 'helix:object-keyspace',
    kTable: 'helix:object-table'
  },
  kCouchbase: {
    kCluster: 'helix:object-db',
    kBucket: 'helix:object-db',
  },
  kExchange: {
    kExchangeDAG: 'helix:object-hostsystem',
    kExchangeNode: 'helix:object-hostsystem',
    kExchangeDAGDatabase: 'helix:object-volume',
    kExchangeDAGDatabaseCopy: 'helix:object-volume',
    kExchangeStandaloneDatabase: 'helix:object-volume',
  },
  kHBase: {
    kCluster: 'helix:object-cluster',
    kNamespace: 'helix:object-db',
    kTable: 'helix:object-table'
  },
  kHdfs: {
    kCluster: 'helix:object-cluster',
  },
  kHive: {
    kCluster: 'helix:object-cluster',
    kDatabase: 'helix:object-db',
    kTable: 'helix:object-table',
    kView: 'helix:object-table-view',
  },
  kMongoDB: {
    kCluster: 'helix:object-cluster',
    kCollection: 'helix:object-table',
    kDatabase: 'helix:object-db'
  },
  kUDA: {
    kCluster: 'helix:object-db',
    kObject: 'helix:object-db',
  },
  kVMware: {
    kVCenter: 'helix:object-vmware-vcenter',
    kFolder: 'helix:object-folder',
    kDatacenter: 'helix:object-datacenter',
    kComputeResource: 'helix:object-hostsystem',
    kClusterComputeResource: 'helix:object-cluster',
    kOrganization: 'helix:object-organization',
    kResourcePool: 'helix:object-resource-pool',
    kDatastore: 'helix:object-datastore',
    kHostSystem: 'helix:object-hostsystem',
    kVirtualDatacenter: 'helix:object-vcloud-vapp',
    kVirtualMachine: 'helix:object-vm',
    kVirtualApp: 'helix:object-vcloud-vapp',
    kVirtualAppTemplate: 'helix:object-vapp-template',
    kStandaloneHost: 'helix:object-vmware-esx-host',
    kTag: 'helix:object-tag',
    kvCloudDirector: 'helix:object-vcloud-dir',
  },
  kElastifile: {
    kCluster: 'helix:object-cluster',
    kContainer: 'helix:object-volume',
  },
  kFlashBlade: {
    kStorageArray: 'helix:object-datacenter',
    kFileSystem: 'helix:object-volume',
  },
  kGenericNas: {
    kGroup: 'helix:object-datastore',
    kHost: 'helix:object-volume',
    kDfsGroup: 'helix:object-datastore',
    kDfsTopDir: 'helix:object-volume',
    kVolume: 'helix:object-volume',
  },
  kGPFS: {
    kCluster: 'helix:object-cluster',
    kFilesystem: 'helix:object-server',
    kFileset: 'helix:object-volume',
  },
  kIsilon: {
    kCluster: 'helix:object-cluster',
    kZone: 'helix:object-server',
    kMountPoint: 'helix:object-volume',
    kVolume: 'helix:object-volume',
  },
  kNetapp: {
    kCluster: 'helix:object-cluster',
    kVserver: 'helix:object-server',
    kVolume: 'helix:object-volume',
  },
  kKubernetes: {
    kCluster: 'helix:object-cluster',
    kLabel: 'helix:object-tag',
    kNamespace: 'helix:object-kubernetes',
    kPersistentVolumeClaim: 'helix:object-pvc'
  },
  kHyperV: {
    kVirtualMachine: 'helix:object-vm-hyperv',
    kSCVMMServer: 'helix:object-datacenter',
    kStandaloneHost: 'helix:object-hostsystem',
    kStandaloneCluster: 'helix:object-cluster',
    kHostGroup: 'helix:object-cluster',
    kHypervHost: 'helix:object-hostsystem',
    kHostCluster: 'helix:object-cluster',
    kTag: 'helix:object-tag',
  },
  kAzure: {
    kVirtualMachine: 'helix:object-azure',
    kAzureSQL: 'helix:object-db',
    kSQLServer: 'helix:db-instance',
    kSQLManagedInstance: 'helix:db-instance',
    kSQLDatabase: 'helix:object-db',
    kSQL: 'helix:object-db',
    kTenant:'helix:object-azure-resting',
    kAzureEntraID: 'helix:object-entra-id',
    kEntraID: 'helix:object-entra-id',
  },
  kAWS: {
    kEC2Instance: 'helix:object-aws-vm',
    kIAMUser: 'helix:object-aws',
    kTag: 'helix:object-tag',
    kRegion: 'helix:object-aws-region',
    kAvailabilityZone: 'helix:object-aws-availability-zone',
    kRDSTag: 'helix:object-tag',
    kAuroraTag: 'helix:object-tag',
    kRDSInstance: 'helix:db-instance',
    kRDSPostgresDb: 'helix:object-aws-rds',
    kAuroraCluster: 'helix:db-instance',
    kAuroraClusterPostgresDb: 'helix:object-aws-aurora',
    kS3Bucket: 'helix:object-aws-s3',
    kS3Tag: 'helix:object-tag',
    kDynamoDBTable: 'helix:object-table',
  },
  kGCP: {
    kVirtualMachine: 'helix:object-gcp',
  },
  kKVM: {
    kVirtualMachine: 'helix:object-vm',
  },
  kPure: {
    kVolume: 'helix:object-volume',
    kPureProtectionGroup: 'helix:object-pure-protection-group',
  },
  kIbmFlashSystem: {
    kVolume: 'helix:object-volume',
    kVolumeGroup: 'helix:object-ibmflashsystem-protection-group',
  },
  kSQL: {
    kHost: 'helix:object-windows',
    kWindowsCluster: 'helix:object-cluster',
    kDatabase: 'helix:object-db',
    kAAGDatabase: 'helix:object-multi-db',
    kInstance: 'helix:object-multi-db',
    kSystemDatabases: 'helix:object-folder',
    kAAG: 'helix:object-cluster',
  },
  kOracle: {
    kHost: 'helix:object-hostsystem',
    kDatabase: 'helix:object-db',
    kOracleAPCluster: 'helix:object-hostsystem',
    kOracleRACCluster: 'helix:object-hostsystem',
  },
  kO365: {
    kDomain: 'helix:object-o365',
    kGroup: 'helix:object-o365-msgroup',
    kGroups: 'helix:object-o365-msgroup',

    // NOTE: There is not entity of this type, instead the type of entity is
    // still kGrooup but has an attribute as security-enabled for which the
    // need is to render a different icon.
    kSecurityGroup: 'helix:object-o365-security-group',
    kMailbox: 'helix:object-email',
    kMailboxCSM: 'helix:object-email',
    kO365Exchange: 'helix:object-email',
    kO365OneDrive: 'helix:object-o365-onedrive',
    kO365Sharepoint: 'helix:object-o365-sites',
    kO365ExchangeCSM: 'helix:object-email',
    kO365OneDriveCSM: 'helix:object-o365-onedrive',
    kO365SharepointCSM: 'helix:object-o365-sites',
    kOneDrive: 'helix:object-o365-onedrive',
    kOneDriveCSM: 'helix:object-o365-onedrive',
    kPublicFolders: 'helix:object-o365-publicfolder',
    kPublicFolder: 'helix:object-o365-publicfolder',
    kSharePoint: 'helix:object-o365-sites',
    kSharePointCSM: 'helix:object-o365-sites',
    kSite: 'helix:object-o365-sites',
    kSites: 'helix:object-o365-sites',
    kTeam: 'helix:object-o365-msteam',
    kTeams: 'helix:object-o365-msteam',
    kUser: 'helix:object-user',
    kUsers: 'helix:object-user',
  },
  kPhysical: {
    kHost: 'helix:object-hostsystem',
    kWindowsCluster: 'helix:object-cluster'
  },
  kSfdc: {
    kOrg: 'helix:object-salesforce',
    kObject: 'helix:object-table'
  },
  kSAPHANA: {
    kSapHanaCluster: 'helix:object-db',
    kObject: 'helix:object-db',
    kSapHanaDatabase: 'helix:object-db',
  },
  kView: {
    kViewBox: 'helix:view',
    kView: 'helix:view',
  }
};

/**
 * Some of the above icons types have os specific versions. This map
 * is used to look them up.
 */
export const OsVariantIconMap: {[icon: string]: {[osType: string]: string}} = {
  'helix:object-vm': {
    kAix: 'helix:object-vm-aix',
    kLinux: 'helix:object-vm-linux',
    kSolaris: 'helix:object-vm-solaris',
    kWindows: 'helix:object-vm-windows',
  },
  'helix:object-aws-vm': {
    kAix: 'helix:object-aws-vm',
    kLinux: 'helix:object-aws-vm',
    kSolaris: 'helix:object-aws-vm',
    kWindows: 'helix:object-aws-vm-windows',
  },
  'helix:object': {
    kAix: 'helix:object-aix',
    kLinux: 'helix:object-linux',
    kSolaris: 'helix:object-solaris',
    kWindows: 'helix:object-windows',
  },
  'helix:object-hostsystem': {
    kLinux: 'helix:object-linux',
    kWindows: 'helix:object-windows',
    kSolaris: 'helix:object-solaris',
  }
};

/**
 * Gets tooltip from icon to distinguish between group and objects.
 *
 * @param    icon  Icon string used in cog-icon.
 * @returns  tooltip string for translation.
 */
export function getIconTooltipKey(icon: string): string {
  if (!icon) {
    return undefined;
  }

  if (icon === 'helix:object-volume') {
    return 'volume';
  }
  if (icon === 'helix:object-ibmflashsystem-protection-group') {
    return 'enum.sourceType.kIbmFlashSystem.kIbmFlashSystemProtectionGroup';
  }
  if (icon.startsWith('helix:object-vm')) {
    return 'enum.envObject.tooltip.vm';
  }
  if (icon.startsWith('helix:group-')) {
    return 'enum.envGroup.tooltip.' + icon.substring(12);
  }
  return undefined;
}
