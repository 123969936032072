import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { PaginationParameters, ProtectionSourcesServiceApi, ProtectionSourceUid } from '@cohesity/api/v1';
import { DataTreeSelection } from '@cohesity/helix';
import { flagEnabled, IrisContextService, isDmsScope } from '@cohesity/iris-core';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { AppStateService } from 'src/app/core/services';
import {
  Office365SourceDetailsService,
} from 'src/app/modules/sources/office365/services/office365-source-details.service';
import {
  Environment,
  OFFICE365_GROUPS,
  Office365LeafNodeType,
  Office365ProtectionGroupEnvMap,
} from 'src/app/shared/constants';
import { Office365UtilityService } from 'src/app/shared/office365/services/office365-utility.service';

import { BaseProtectionSourceService } from '../shared/base-protection-source.service';
import { Office365SourceDataNode } from './office365-source-data-node';
import { Office365ViewFilters } from './office365-view-filters';

/**
 * Source tree service for Office365.
 */
@Injectable({
  providedIn: 'root',
})
export class Office365SourceTreeService extends BaseProtectionSourceService<Office365SourceDataNode> {
  /**
   * Specifies the View filters for Office365 Source tree.
   */
  office365ViewFilters: Office365ViewFilters<Office365SourceDataNode>;

  /**
   * Indicates if this is in DMaaS scope or not.
   */
  get isDmsScope(): boolean {
    return isDmsScope(this.irisContext.irisContext);
  }

  constructor(
    private appStateService: AppStateService,
    private irisContext: IrisContextService,
    private office365SourceDetailsService: Office365SourceDetailsService,
    private office365UtilityService: Office365UtilityService
  ) {
    super(flagEnabled(irisContext.irisContext, 'objectProtectionOverwrite'));
    this.office365ViewFilters = new Office365ViewFilters(
      this.filters,
      this.treeTransformer,
      this.office365SourceDetailsService,
      this.office365UtilityService
    );
  }

  /**
   * Determines if the source special parameters component is to be rendered.
   *
   * @param     node   Specifies the Protection Source Node
   * @returns   source special component selector.
   */
  getSpecialParametersComponent(): ComponentType<any> {
    return null;
  }

  /**
   * Returns the Array of leaf entities.
   */
  getLeafEntities(): string[] {
    return OFFICE365_GROUPS.office365Leaves as string[];
  }

  /**
   * Determine what nodes are leaf nodes for office365
   *
   * @param   node   The node to check.
   * @returns True if this is a leaf node.
   */
  isLeaf(node: Office365SourceDataNode): boolean {
    return node.isLeaf;
  }

  /**
   * Handles the page navigation for the entity hierarchy in view. In case of Office365 it is essentially a flat list
   * under a dummy container. Refer visualization of the same in ../office365/office365.constants.ts
   */
  updateCursorParamsWithPageEvent(pageEvent: PageEvent,
    listProtectionSourcesParams: ProtectionSourcesServiceApi.ListProtectionSourcesParams,
    paginationParameters: PaginationParameters,
    pageSize: number): ProtectionSourcesServiceApi.ListProtectionSourcesParams {
    if (!pageEvent || !listProtectionSourcesParams || !paginationParameters || !pageSize) {
      return listProtectionSourcesParams;
    }

    // In case of paginated response, response is within the range [afterCursorEntityId, beforeCursorEntityId)
    // where the afterCursorEntityId is the entity ID of the 1st entity in response and the beforeCursorEntityId is
    // the entity ID of the next to last entity in response.
    //
    // Check for forward or backward traversal. The change in pageIndex takes preference over page size change.
    if (pageEvent.pageIndex > pageEvent.previousPageIndex) {
      // In case of forward traversal the last entity of the current response becomes the after cursor for the next
      // request.
      listProtectionSourcesParams.afterCursorEntityId =
        paginationParameters.beforeCursorEntityId;
      listProtectionSourcesParams.beforeCursorEntityId = undefined;
    } else if (pageEvent.pageIndex < pageEvent.previousPageIndex) {
      // In case of backward traversal the first entity of the current response becomes the before cursor for the next
      // request.
      listProtectionSourcesParams.beforeCursorEntityId =
        paginationParameters.afterCursorEntityId;
      listProtectionSourcesParams.afterCursorEntityId = undefined;
    } else if (pageSize !== pageEvent.pageSize) {
      // Check for page size change and force traversal from the start.
      // Page size change should only be taken into consideration if the cursors haven't changed.
      listProtectionSourcesParams.afterCursorEntityId = undefined;
      listProtectionSourcesParams.beforeCursorEntityId = undefined;
      listProtectionSourcesParams.pageSize = pageEvent.pageSize;
    }
    return listProtectionSourcesParams;
  }

  /**
   * Transforms the node object from the API struct as defined in 'ProtectionSourceNode' || 'ProtectionSourceResponse'
   * to its equivalent 'Office365SourceDataNode' to be passed to the Data tree.
   *
   * The node data can be obtained from either of the 2 sources:
   * -- A. Magneto's Entity Hierarchy         -- type ProtectionSourceNode
   * -- B. ElasticSearch's GlobalEntity Index -- type ProtectionSourceResponse
   *
   * In case of A, the node has the id as the correct one. On the other hand, case B, has the sourceId as 0, and
   * instead has a list of all source ids present on all the clusters.
   *
   * @param    node    Specifies the Protection Source Node received either from Magneto' EH or ElasticSearch.
   * @param    level   Specifies the level in the tree.
   * @return   Instance of Office365SourceDataNode.
   */
  transformData(node: any, level: number): Office365SourceDataNode {
    // If the node is obtained from ES(source id is 0), then populate correct source id after matching the cluster id.
    if (!node.protectionSource.id && node.protectionSourceUidList && node.protectionSourceUidList.length) {
      const currentSourceUid = node.protectionSourceUidList.find((sourceUid: ProtectionSourceUid) =>
        sourceUid.clusterId === this.appStateService.selectedScope.clusterId);
      node.protectionSource.id = currentSourceUid.sourceId;
    }
    return new Office365SourceDataNode(node, level, this.treeControl, this.office365SourceDetailsService.workload$);
  }

  /**
   * Transforms the data tree selection model to the Job selection Model and returns an instance of SourceSelection
   * with the 'office365SpecialParameters' poulated within the same.
   * Refer 'SourceSpecialParameter' for details.
   *
   * @param    selection   Specifies the currently selected data from the tree.
   * @return   Instance of SourceSelection.
   */
  transformFromDataTreeSelection(selection: DataTreeSelection<Office365SourceDataNode>): SourceSelection {
    const autoSelected = selection.autoSelected;
    let sources = selection.selected.filter(item => item.isLeaf).concat(autoSelected);

    const autoSelectedIdSet = new Set();
    selection.autoSelected.forEach(autoSelectObj => {
      autoSelectedIdSet.add(autoSelectObj.id);
    });

    // In DMaaS, when all the nodes are selected with no auto-protect selection,
    // switch the selection to auto-protect the container node instead.
    if (this.isDmsScope &&
      selection.selected.length > 0 &&
      autoSelectedIdSet.size === 0 &&
      selection.selected.length === this.treeControl.allDataNodes.length
    ) {
      // For O365, there can be 2 scenarios:
      //
      // Case 1: With SecurityGroups ENABLED - There are 2 subtrees sitting
      // within 'kDomain' for workloads that support SecurityGroup based
      // backups. In such case - 'selection' will NEVER contain 'kDomain'
      // since nodes within only 1 subtree will ever be selected.
      //
      // Case 2: With SecurityGroups DISABLED - There is only 1 subtree sitting
      // within 'kDomain' for all workloads. In such case - 'selection' MAY
      // contain 'kDomain' if the entire subtree is selected.
      //
      // Determination of container node hence should NOT be done based on
      // level instead should always be taken by TYPE.
      const containerNode = selection.selected.find(
        node => OFFICE365_GROUPS.office365EntityContainers.includes(node.type));
      autoSelectedIdSet.add(containerNode.id);
      sources = [containerNode];
    }

    return {
      sourceIds: sources.map(item => Number(item.id)),
      excludeSourceIds: selection.excluded.map(item => Number(item.id)),
      sourceSpecialParameters: sources.map(source => ({
        id: Number(source.id),
        shouldAutoProtectObject: autoSelectedIdSet.has(source.id),
        excludeIds: this.getExcludeIds(selection.excluded),
      }))
    };
  }

  /**
   * Transforms the source selection model to the Data tree selection model.
   *
   * @param    allNodes          Specifies array of Office365SourceDataNode
   * @param    sourceSelection   Specifies the Job selection Model
   * @return   Data tree selection Model for Office365SourceDataNode
   */
  transformToDataTreeSelection(allNodes: Office365SourceDataNode[],
    sourceSelection: SourceSelection): DataTreeSelection<Office365SourceDataNode> {
    const selection: DataTreeSelection<Office365SourceDataNode> = {
      autoSelected: [],
      excluded: [],
      selected: [],
      options: {},
    };

    if (!sourceSelection || !allNodes || !allNodes.length) {
      return selection;
    }

    // For differentiating between AutoProtect on nodes & granular selection,
    // leaf nodes and internal nodes are segregated.
    // This is needed because the entity of type 'kSite' can be either a leaf
    // or an internal node.
    const leafNodeMap = new Map<number, Office365SourceDataNode>();
    const internalNodeMap = new Map<number, Office365SourceDataNode>();

    allNodes.forEach((node: Office365SourceDataNode) => {
      node.expandable ? internalNodeMap.set(Number(node.id), node) : leafNodeMap.set(Number(node.id), node);
    });

    // Use a set of IDs to optimize lookups.
    const selectedInternalNodeMap = new Map<number, Office365SourceDataNode>();
    const selectedLeafNodeMap = new Map<number, Office365SourceDataNode>();

    sourceSelection.sourceIds.forEach(id => {
      if (leafNodeMap.has(id)) {
        selectedLeafNodeMap.set(id, leafNodeMap.get(id));
      } else if (internalNodeMap.has(id)) {
        selectedInternalNodeMap.set(id, internalNodeMap.get(id));
      }
    });

    const autoSelectedEntites = this.determineAutoProtectedEntities(
      sourceSelection, selectedInternalNodeMap);
    selection.autoSelected.push(...autoSelectedEntites);

    // Iterate over the selected leaf nodes to decide for selected leaves.
    selectedLeafNodeMap.forEach((node) => {
      selection.selected.push(node);
    });

    // Determine exclusions of entities. Check on leaf node is preferred.
    // Note: This is only needed for kSite entities.
    sourceSelection.excludeSourceIds.forEach((id) => {
      if (leafNodeMap.has(id)) {
        selection.excluded.push(leafNodeMap.get(id));
      } else {
        selection.excluded.push(internalNodeMap.get(id));
      }
    });

    return selection;
  }

  /**
   * Runs a check to find descendants of an auto protected node that already have object protection applied to them
   *
   * @param autoSelected The auto selected node to check
   * @returns A list of auto protected nodes.
   */
  getAlreadyObjectProtectedDescendants(autoSelected: Office365SourceDataNode) {
    if (!this.dataTreeSelection.isAutoSelected(autoSelected)) {
      return;
    }

    const workloadEnvironment = Office365ProtectionGroupEnvMap[autoSelected.workloadType] as Environment;

    // Look for object-protected nodes which are children of this node, but protected by something else
    // In case of O365, needs to explicitly check isEnvironmentProtected to ensure
    // the node is protected in the current workload.
    return this.treeControl.allDataNodes.filter(node => node.isObjectProtected &&
      node.isEnvironmentProtected(workloadEnvironment) &&
      node.parentAutoProtectedObjectId !== autoSelected.id &&
      this.dataTreeSelection.isAutoSelectedByAncestor(node, autoSelected));
  }

  /**
   * Gets the list of excluded node ids including their descendants from the selection exclusion list.
   * This is only needed for kSite entities since there can be multiple levels of subsites.
   * We need to send all the exclusion ids to exclude them in auto-protection.
   *
   * @param    exclusions   List of nodes that are excluded by user.
   * @returns  The Id list of all the excluded nodes along with their descendants
   *           to send in api payload.
   */
  private getExcludeIds(exclusions: Office365SourceDataNode[]): number[] {
    const excludeIdSet = new Set<number>();

    (exclusions || []).forEach((item) => {
      excludeIdSet.add(Number(item.id));

      item.getDescendants().forEach(descendant => {
        excludeIdSet.add(Number(descendant.id));
      });
    });
    return Array.from(excludeIdSet);
  }

  /**
   * Given the currently selected non-leaf O365 nodes, and the special handling
   * for site entities, this function determines which entities are marked as
   * autoprotected.
   *
   * For kUser/kGroup/kTeam/kPublicFolder, the autoprotected entity will always
   * be the container for the same since they are always a flat list.
   *
   * For kSite, the autoprotected entity can be an intermediary kSite node too.
   * Determination of the same is handled by the API response field
   * 'shouldAutoProtectObject'.
   *
   * Incase of new job creation it is possible that the
   * 'selectedInternalNodeMap' will not be exhausted completely since there is
   * no previous protection job present. This function treats those entities
   * as 'auto-protected'.
   *
   * @param sourceSelection Specifies the current SourceSelection object
   * @param selectedInternalNodeMap Specifies the Map from entity ID to the
   *                                Node which are currently selected.
   */
  private determineAutoProtectedEntities(sourceSelection: SourceSelection,
    selectedInternalNodeMap: Map<number, Office365SourceDataNode>): Office365SourceDataNode[] {
    const autoprotectedEntities: Office365SourceDataNode[] = [];
    const autoprotectedSiteIdSet = new Set<number>();

    // Only add kSite if they have 'shouldAutoProtectObject' set to true.
    (sourceSelection.sourceSpecialParameters || []).forEach(siteEntity => {
      if (siteEntity.shouldAutoProtectObject) {
        autoprotectedSiteIdSet.add(siteEntity.id);
      }
    });

    selectedInternalNodeMap.forEach((node, id) => {
      // Add all internal nodes if they are not of type kSite.
      if (node.type !== Office365LeafNodeType.kSite) {
        autoprotectedEntities.push(node);
        selectedInternalNodeMap.delete(id);
      } else if (autoprotectedSiteIdSet.has(id)) {
        autoprotectedEntities.push(node);
        selectedInternalNodeMap.delete(id);
      }
    });

    // Only add kSite if they have 'shouldAutoProtectObject' set to true.
    (sourceSelection.sourceSpecialParameters || []).forEach(item => {
      if (item.shouldAutoProtectObject) {
        autoprotectedSiteIdSet.add(item.id);
        selectedInternalNodeMap.delete(item.id);
      }
    });

    // For cases of kSite in internal, it is possible that the
    // 'sourceSpecialParameters' is not present if the flow is for new job
    // instead of edit. Ensure all internal nodes are auto-selected.
    selectedInternalNodeMap.forEach((node) => {
      autoprotectedEntities.push(node);
    });

    return autoprotectedEntities;

  }
}
