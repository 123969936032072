import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SeriesBarOptions, SeriesColumnOptions, XAxisOptions } from 'highcharts';
import { xor } from 'lodash';

import { HighchartsComponent } from '../core/index';

/**
 * @description
 * Angular Component wrapper for Highcharts column bar Chart.
 *
 * @example
 *  <cog-bar-chart
 *    [seriesData]="series"
 *    [categories]="categories">
 *  </cog-bar-chart>`
 */
@Component({
  selector: 'cog-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BarChartComponent extends HighchartsComponent<SeriesBarOptions | SeriesColumnOptions> {

  /**
   * Optional list of categories
   */
  private _categories: string[] = [];

  /**
   * Sets bar chart categories.
   */
  @Input() set categories(categories: string[]) {
    this._categories = categories;
    this.render();
  }

  /**
   * Returns bar chart categories.
   */
  get categories(): string[] {
    return this._categories;
  }

  /**
   * Color settings for bar chart. Use high chart default color if colorSetClass is not provided.
   */
  @Input() colorSetClass: string;

  /**
   * Flag to force rendering only when categories have changed.
   */
  @Input() forceCheckCategories = false;

  constructor() {
    super({
      chart: {
        type: 'column',
        spacing: [0, 0, 0, 0],
        styleMode: true,
      },

      title: null,

      legend: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },

      xAxis: {
        categories: [],
        crosshair: true,
        type: 'category',
      },

      yAxis: {
        visible: false,
      },

      series: [],

      plotOptions: {
        column: {
          pointWidth: 6,
          pointPadding: 0.1,
          borderWidth: 0,
          borderRadius: 3,
        },
      }
    });
  }

  /**
   * Updates bar chart using current chart options.
   */
  render() {
    const { chartOptions } = this;

    const xAxis = chartOptions.xAxis as XAxisOptions;

    // Only re-render if categories update when 'forceCheckCategories' is true.
    if (this.forceCheckCategories) {
      if (xor(xAxis.categories, this._categories).length) {
        xAxis.categories = this._categories;
        super.render();
      }
    } else {
      xAxis.categories = this._categories;
      super.render();
    }
  }
}
