import { Component } from '@cohesity/api/reporting';

import { ReportColumnConfigs } from '../common-report-items/custom-reports.service';
import { ReportSection } from '../iris-reporting.model';
import { allObjectsColumns, allObjectsReport } from './all-objects-report';
import { externalTargetsColumns, externalTargetsReport } from './external-targets-report';
import { failededObjectsColumns, failededObjectsReport } from './failed-objects-report';
import { objectGrowthColumns, objectGrowthReport } from './object-growth-report';
import { m365StorageConsumptionColumns, m365StorageConsumptionReport } from './m365-storage-consumption';
import { protectedObjectsColumns, protectedObjectsReport } from './protected-objects-report';
import { protectionActivitiesColumns, protectionActivitiesReport } from './protection-activities-report';
import { protectionGroupSummaryColumns, protectionGroupSummaryReport } from './protection-group-summary-report';
import { protectionRunsColumns, protectionRunsReport } from './protection-runs-report';
import { sourceGrowthColumns, sourceGrowthReport } from './source-growth-report';
import { systemProtectionColumns, systemProtectionReport } from './system-protection-report';
import { recoveryColumns, recoveryReport } from './recovery-report';
import { serviceConsumptionColumns, serviceConsumptionReport } from './service-consumption-report';
import { storageConsumptionObjectColumns, storageConsumptionObjectReport } from './storage-consumption-object-report';
import {
  storageConsumptionOrganizationColumns,
  storageConsumptionOrganizationReport,
} from './storage-consumption-organization-report';
import {
  storageConsumptionProtectionGroupColumns,
  storageConsumptionProtectionGroupReport,
} from './storage-consumption-protection-group-report';
import {
  storageConsumptionStorageDomainColumns,
  storageConsumptionStorageDomainReport,
} from './storage-consumption-storage-domain-report';
import { storageConsumptionSystemColumns, storageConsumptionSystemReport } from './storage-consumption-system-report';
import { storageConsumptionViewColumns, storageConsumptionViewReport } from './storage-consumption-view-report';
import { systemConnectionsColumns, systemConnectionsReport } from './system-connections-report';
import { drPlanSummaryReport } from './dr-plan-summary-report';
import { drPlanActivityReport } from './dr-plan-activity-report';

export * from './all-objects-report';
export * from './protected-objects-report';
export * from './report-layout-version';
export * from './service-consumption-report';

/**
 * Mapping from report ID to report layout.
 */
export const reportLayoutMap: Record<string, ReportSection[]> = {
  // Data Protection
  'cluster-connections': systemConnectionsReport,
  'failures': failededObjectsReport,
  'm365-backup-storage-usage': m365StorageConsumptionReport,
  'protected': protectedObjectsReport,
  'protected-unprotected': allObjectsReport,
  'protection-activity': protectionActivitiesReport,
  'protection-group-summary': protectionGroupSummaryReport,
  'protection-runs': protectionRunsReport,
  'system-protection': systemProtectionReport,
  'recovery': recoveryReport,
  'service-consumption': serviceConsumptionReport,

  // Storage
  'data-transferred-to-external-targets': externalTargetsReport,
  'object-growth': objectGrowthReport,
  'source-growth': sourceGrowthReport,
  'storage-consumption-cluster': storageConsumptionSystemReport,
  'storage-consumption-group': storageConsumptionProtectionGroupReport,
  'storage-consumption-object': storageConsumptionObjectReport,
  'storage-consumption-organization': storageConsumptionOrganizationReport,
  'storage-consumption-storage-domain': storageConsumptionStorageDomainReport,
  'storage-consumption-view': storageConsumptionViewReport,

  // DR plan
  'dr-plan-summary': drPlanSummaryReport,
  'dr-plan-activity': drPlanActivityReport,
};

/**
 * Fetch the report layout based on an id. This is temporary and will be removed
 * when th API supports including the layout.
 *
 * @param reportId The report id
 * @returns the layout config.
 */
export function getReportLayoutById(reportId: string): ReportSection[] {
  return reportLayoutMap[reportId] || [];
}

export function getColumnConfigs(reportType: Component['reportType']): ReportColumnConfigs {
  switch (reportType) {
    case 'ClusterConnections':
      return systemConnectionsColumns;
    case 'DataTransferredToExternalTargets':
    case 'DataTransferredToExternalTargetsTrend':
      return externalTargetsColumns;
    case 'Failures':
      return failededObjectsColumns;
    case 'M365BackupStorageUsage':
    case 'M365BackupStorageUsageTrend':
      return m365StorageConsumptionColumns;
    case 'ObjectGrowth':
      return objectGrowthColumns;
    case 'ProtectedUnprotectedObjects':
      return allObjectsColumns;
    case 'ProtectedObjects':
      return protectedObjectsColumns;
    case 'ProtectionActivity':
      return protectionActivitiesColumns;
    case 'ProtectionGroupSummary':
      return protectionGroupSummaryColumns;
    case 'ProtectionRuns':
    case 'ProtectionRunsTrend':
      return protectionRunsColumns;
    case 'ProtectionSummaryBySystem':
      return systemProtectionColumns;
    case 'Recovery':
      return recoveryColumns;
    case 'ServiceConsumption':
    case 'ServiceConsumptionTrend':
      return serviceConsumptionColumns;
    case 'SourceGrowth':
      return sourceGrowthColumns;
    case 'StorageConsumptionByClusters':
    case 'StorageConsumptionByClustersTrend':
      return storageConsumptionSystemColumns;
    case 'StorageConsumptionByObjects':
      return storageConsumptionObjectColumns;
    case 'StorageConsumptionByOrganizations':
      return storageConsumptionOrganizationColumns;
    case 'StorageConsumptionByProtectionGroups':
      return storageConsumptionProtectionGroupColumns;
    case 'StorageConsumptionByStorageDomains':
      return storageConsumptionStorageDomainColumns;
    case 'StorageConsumptionByViews':
      return storageConsumptionViewColumns;
  }
  return null;
}
