import { Injectable } from '@angular/core';
import { AjaxHandlerService } from '@cohesity/utils';
import { Observable, forkJoin, of } from 'rxjs';
import { IrisContextService, dmsTenantId, getUserTenantId, isIbmBaaSEnabled } from '@cohesity/iris-core';
import {
  ListRigelGroupResult,
  RigelGroup,
  RigelmgmtServiceApi,
} from '@cohesity/api/rms';
import {ConnectionConfig, DataSourceConnection, DataSourceConnectionList, DataSourceConnectionServiceApi, McmSourceRegistration, McmSources, SourceRegistration, SourceRegistrations, SourceServiceApi, DataSourceConnectorServiceApi, DataSourceConnectorList, DataSourceConnector } from '@cohesity/api/v2';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ClusterConnectionSources, DmsConnection, DmsConnectionSources } from './models/dms-connection';
import { DmsService } from 'src/app/core/services';
import { DmsConnectorGroup } from './models/dms-connector-group';
import { debounceTime } from 'rxjs/operators';

/**
 * This service acts as a wrapper for calling rigel connection and connector api endpoints
 * from the cluster context or DMaaS context.
 */
@Injectable()
export class CommonDmsClusterConnectionService {

  constructor(
    private ajaxService: AjaxHandlerService,
    private clusterConnectorRigelService: DataSourceConnectorServiceApi,
    private clusterRigelService: DataSourceConnectionServiceApi,
    protected dmsService: DmsService,
    private irisCtx: IrisContextService,
    private mcmSourceService: SourceServiceApi,
    private rigelService: RigelmgmtServiceApi,
    private sourceService: SourceServiceApi
  ) {}

  /**
   * Returns the same observable but with error handling included.
   *
   * @param   observable    Original observable.
   * @param   defaultValue  Default value returned if error.
   * @returns Same observable as input parameter.
   */
  handleError(observable: Observable<any>, defaultValue: any = []): Observable<any> {
    return observable.pipe(
      catchError(err => {
        this.ajaxService.errorMessage(err);
        return of(defaultValue);
      }),
    );
  }

  /**
   * Gets the list of connections on Cluster/Dmaas environment.
   *
   * @returns   A list of connections.
   */
  getConnectionsList(
    id?: number,
    connections?: ConnectionConfig[],
    fetchConnectorGroups = false,
  ): Observable<DmsConnection[]> {
    // If it is an IBM BaaS cluster, call data-protect cluster endpoint.
    if (isIbmBaaSEnabled(this.irisCtx.irisContext)) {
      // Input params to fetch cluster connection.
      const clusterConnectionParams = {
        tenantId: getUserTenantId(this.irisCtx.irisContext),
      } as DataSourceConnectionServiceApi.GetDataSourceConnectionParams;

      return this.clusterRigelService.GetDataSourceConnection(clusterConnectionParams).pipe(
        map((response: DataSourceConnectionList) =>
          (response.connections || []).map((rigelGroup: DataSourceConnection) => new DmsConnection({
            groupId: parseInt(rigelGroup.connectionId, 10),
            groupName: rigelGroup.connectionName,
            numberOfConnectors: rigelGroup.connectorIds?.length,
            tenantId: rigelGroup.connectionId,
          })))
      );
    } else { // Else call DMaaS end point to fetch the connection list.
      const params = {
        tenantId: getUserTenantId(this.irisCtx.irisContext),
        maxRecordLimit: 1000,
        fetchConnectorGroups,
      } as RigelmgmtServiceApi.GetRigelGroupsParams;
      if (id) {
        params.groupId = id;
      }

      if (connections) { // For AWS/Azure Adapter.
        const connections$ = forkJoin(connections.map(connection => this.rigelService.GetRigelGroups({
            tenantId: dmsTenantId(this.irisCtx.irisContext),
            groupId: connection.connectionId,
            getConnectionStatus: true,
            fetchConnectorGroups: true
          })
          .pipe(
            map((response: ListRigelGroupResult) => (response.rigelGroups || [])
              .map((rigelGroup: RigelGroup) => new DmsConnection(rigelGroup))),
            catchError(err => {
              this.ajaxService.errorMessage(err);
              return of([]);
            }),
          )))
        .pipe(
          map((dmsConnectionsArray: DmsConnection[][]) => [].concat(...dmsConnectionsArray))
        );
        return connections$;
      }

      return this.rigelService.GetRigelGroups(params).pipe(
        map((response: ListRigelGroupResult) =>
          (response.rigelGroups || []).map((rigelGroup: RigelGroup) => new DmsConnection(rigelGroup))
        ));
    }
  }

  /**
   * Gets the list of registered sources and its details based on context(cluster/DMaaS).
   *
   * @returns   List of sources registered on the cluster.
   */
  getSourceDetails() {
    // If it is an IBM BaaS cluster, call the data-protect source cluster end point.
    if (isIbmBaaSEnabled(this.irisCtx.irisContext)) {
      return this.sourceService.GetSourceRegistrations({
        tenantIds: [getUserTenantId(this.irisCtx.irisContext)]
      }).pipe(
        switchMap((response: SourceRegistrations) => {

          // Build an observable map to generate a map of all registration ids
          // and their corresponding source details.
          const observableMap: Record<string, Observable<SourceRegistration>> = {};

          for (const source of response.registrations) {
            observableMap[source.id] =
              this.sourceService.GetProtectionSourceRegistration({ id: source.id }).pipe(
                // Need to always return something to avoid one API call failed the rest
                catchError(() => of({}))
              );
          }
          // Return the array of sources and source registration map.
          return forkJoin(observableMap).pipe(
            map(() => ({
              sources: response.registrations,
            }))
          );
        }),
      );
    } else {// else call the MCM sources DMaaS endpoint.
      return this.sourceService.McmGetProtectionSources({
        regionIds: this.dmsService.configuredRegionIds
      }).pipe(
        switchMap((response: McmSources) => {
          // Need to avoid forkJoin when sources is empty as it will cancel the switchMap calls
          // under connections$
          if (!response?.sources?.length) {
            return of([]);
          }

          // Build an observable map to generate a map of all registration ids
          // and their corresponding source details.
          const observableMap: Record<string, Observable<McmSourceRegistration>> = {};

          for (const source of response.sources) {
            for (const sourceItem of source.sourceInfoList) {
              observableMap[sourceItem.registrationId] =
                this.sourceService.McmGetProtectionSourceRegistration(sourceItem.registrationId).pipe(
                  // Need to always return something to avoid one API call failed the rest
                  catchError(() => of({}))
                );
            }
          }

          // Return the array of sources and source registration map.
          return forkJoin(observableMap).pipe(
            map((registrationMap: DmsConnectionSources['registrationMap']) => ({
              sources: response.sources,
              registrationMap,
            }))
          );
        }),
      );
    }
  }

  /**
   * Gets the list of connections with registered sources and its details based on context(cluster/DMaaS).
   *
   * @returns   List of connections with linked sources on the cluster.
   */
  getConnectionsWithSourcesOnCluster() {
    return forkJoin([
      this.handleError(this.getConnectionsList()),
      this.handleError(this.getSourceDetails())
    ]).pipe(
      debounceTime(0),
      map(([dmsConnections, connectionSources]: [DmsConnection[], ClusterConnectionSources]) => {
        // Shallow copy all connections as this object is mutated.
        const connections = dmsConnections.map(connection => ({...connection}));

        // TODO(Sowmya): Add rigel upgrade code path in the future if needed.

        // Get list of all sources.
        const {sources = []} = connectionSources || {};

        for (const source of sources) {
            // Get connection details of the individual source item.
            const connectionId = source?.connectionId;
            let connection = connectionId && connections.find(
              connectionItem => connectionId === connectionItem.groupId
            );

            // For AWS Adapter.
            // In aws source registrations, connection Id does not exist. One source can have multiple connectors.
            if (!connection) {
              const conns = source?.connections;

              if (conns) {
                conns.forEach(conn => {
                  connection = connections.find(
                    connectionItem => conn.connectionId === connectionItem.groupId
                  );

                  if (connection) {
                    // Update sourceObjects array with a source object which has all the
                    // source details (local source info such as local source id,
                    // and global source info such as type, name).
                    connection.clusterSourceObjects = connection.clusterSourceObjects || [];
                    connection.clusterSourceObjects.push({
                      ...source,
                    });
                    // Need sources for filter purpose.
                    connection.sources = connection.sources || [];
                    connection.sources.push(source.sourceId.toString());
                  }
                });
              }
            } else {
              // For other adapters.

              // Update sourceObjects array with a source object which has all the
              // source details (local source info such as regionId, local source id,
              // and global source info such as type, name).
              connection.clusterSourceObjects = connection.clusterSourceObjects || [];
              connection.clusterSourceObjects.push({
                ...source,
              });

              // Need sources for filter purpose.
              connection.sources = connection.sources || [];
              connection.sources.push(source.sourceId.toString());
            }
        }

        // TODO(Sowmya): Add connection stats api here once available.
        return connections;
      })
    );
  }

  /**
   * Gets the list of connectors with registered connection and its details based on context(cluster/DMaaS).
   *
   * @returns   List of connectors in a connection on the cluster.
   */
  getConnectors(connectionId: number, params): Observable<DmsConnection[]> {
    if (isIbmBaaSEnabled(this.irisCtx.irisContext)) {
      return this.clusterConnectorRigelService.GetDataSourceConnector({
        tenantId: getUserTenantId(this.irisCtx.irisContext),
        connectionId: connectionId.toString()
      }).pipe(
        map((response: DataSourceConnectorList) => {
          const rigelGroupResult: ListRigelGroupResult = { rigelGroups : []};
          const rigelGroup: RigelGroup = {groupId: connectionId, connectorGroups: []};
          const connectorGroup: DmsConnectorGroup = {connectors : []};

          (response.connectors || []).forEach((connector: DataSourceConnector) => {
            const mappedStatus = connector.connectivityStatus.isConnected ? 'Connected' : 'NotConnected';
            connectorGroup.isUngroup = true;
            connectorGroup.status = mappedStatus;
            connectorGroup.connectors.push({
              rigelGuid: Number(connector.connectorId),
              rigelName: connector.connectorName,
            });
          });
          rigelGroup.connectorGroups.push(connectorGroup);
          rigelGroupResult.rigelGroups.push(rigelGroup);
          return rigelGroupResult;
        }),
        map((list: ListRigelGroupResult) =>
          (list.rigelGroups || []).map((rigelGroup: RigelGroup) => new DmsConnection(rigelGroup))
        )
      );
    } else {
      return this.rigelService.GetRigelGroups(params).pipe(
        map((response: ListRigelGroupResult) =>
          (response.rigelGroups || []).map((rigelGroup: RigelGroup) => new DmsConnection(rigelGroup))
        )
      );
    }
  }

  /**
   * Fetch source associated with the connection and update connection.
   *
   * @param connection  Connection object.
   * @param isConnectorGroup  True if connection is a connector group.
   */
  fetchSources(connection: DmsConnection): DmsConnection {
    if (isIbmBaaSEnabled(this.irisCtx.irisContext)) {
      this.mcmSourceService.GetSourceRegistrations({tenantIds: [getUserTenantId(this.irisCtx.irisContext)]})
      .subscribe(response => {
        connection.sources = [];
        connection.sourceObjects = [];
        connection.clusterSourceObjects = [];
        for (const source of response?.registrations ?? []) {
          if (source.connectionId === connection.groupId) {
            connection?.clusterSourceObjects.push(source);
            connection.sources.push(source.sourceId.toString());
          }
        }
      });
    } else {
      this.mcmSourceService.McmGetProtectionSources({}).subscribe(result => {
        connection.sources = [];
        connection.sourceObjects = [];
        for (const source of result?.sources ?? []) {
          for (const sourceInfo of source.sourceInfoList) {
            if (sourceInfo.registrationDetails?.connectionId === connection.groupId) {
              connection.sourceObjects.push(source);
              connection.sources.push(source.id);
              sourceInfo.registrationDetails.connections?.forEach(c => {
                const connectorGroup =
                  connection.connectorGroups?.find(cg =>
                    cg.connectorGroupId && cg.connectorGroupId === c.connectorGroupId
                  );
                if (connectorGroup) {
                  if (!connectorGroup.sourceInfos) {
                    connectorGroup.sourceInfos = [];
                  }
                  connectorGroup.sourceInfos.push(sourceInfo);
                }
              });
            }
          }
        }
      });
    }
    return connection;
  }

  /**
   * Deletes the connection.
   *
   * @param connection  Connection.
   */
  deleteConnection(connection: DmsConnection, isForceDelete: boolean): Observable<unknown> {
    if (isIbmBaaSEnabled(this.irisCtx.irisContext)) {
      return this.clusterRigelService.DeleteDataSourceConnection({connectionId: connection.tenantId});
    } else {
      return this.rigelService.DeleteRigelConnection({
        connectionId: connection.groupId,
        tenantId: getUserTenantId(this.irisCtx.irisContext),
        forceDelete: Boolean(isForceDelete)
      });
    }
  }

  /**
   * Rename the connection.
   *
   * @param connection  Connection object that needs to be updated.
   * @param connectionName Updated name of the connection .
   */
  renameConnection(connection: DmsConnection, connectionName: string): Observable<unknown> {
    if (isIbmBaaSEnabled(this.irisCtx.irisContext)) {
      const updateConnectionParams = {
        connectionId: connection.tenantId,
        body: {
          connectionName: connectionName
        }
      } as DataSourceConnectionServiceApi.PatchDataSourceConnectionParams;

      return this.clusterRigelService.PatchDataSourceConnection(updateConnectionParams);
    } else {
      return this.rigelService.UpdateConnection({
        ConnectionId: connection.groupId,
        ConnectionName: connectionName,
      });
    }
  }

  /**
   * Fetch the connection token.
   *
   * @param connection  Connection object that needs to be updated.
   */
  getConnectionToken(connection: DmsConnection): Observable<unknown> {
    if (isIbmBaaSEnabled(this.irisCtx.irisContext)) {
      return this.clusterRigelService.GetDataSourceConnectionRegistrationToken({
        connectionId: connection.tenantId}).pipe(
          map((response: string) => ({
            token: response,
            expiration: null
          }))
        );
    } else {
      return this.rigelService.GetRigelGroups({
        groupId: connection.groupId,
        tenantId: getUserTenantId(this.irisCtx.irisContext),
        fetchToken: true
      }).pipe(
        filter((response: ListRigelGroupResult) => Boolean(response?.rigelGroups?.length)),
        map((response: ListRigelGroupResult) => ({
          token: response.rigelGroups[0].claimToken,
          expiration: response.rigelGroups[0].tokenExpiryTimeUsecs
        }))
      );
    }
  }
}
