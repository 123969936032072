import { AppModuleConfig, StateAccessMap, StateAccessContext } from 'src/app/app-module.config';

const nisAccess = (ctx: StateAccessContext) => ctx.NIS_VIEW && ctx.FEATURE_FLAGS.nisEnabled;

export const NisConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'access-management.nis.**',
      url: '^/admin/access/nis',
      loadChildren: () => import('./nis.module').then(m => m.NisModule),
    },
  ],

  getStateAccessMap(): StateAccessMap {
    return {
      'access-management.nis': nisAccess,
      'access-management.nis.all-nis': nisAccess,
    };
  },
};
