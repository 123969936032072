import { AppModuleConfig, StateAccessMap, StateAccessContext } from 'src/app/app-module.config';

const keystoneViewAccess = (ctx: StateAccessContext) => ctx.KEYSTONE_VIEW &&
  ctx.FEATURE_FLAGS.keystoneModuleEnabled;
const keystoneModifyAccess = (ctx: StateAccessContext) => ctx.KEYSTONE_MODIFY &&
  ctx.FEATURE_FLAGS.keystoneModuleEnabled;

export const KeystonesConfig: AppModuleConfig = {
  futureStates: [
    {
      name: 'access-management.keystone.**',
      url: '^/admin/access/keystone',
      loadChildren: () => import('./keystones.module').then(m => m.KeystonesModule),
    },
    {
      name: 'keystone-add.**',
      url: '^/admin/access/keystone/add',
      loadChildren: () => import('./keystones.module').then(m => m.KeystonesModule),
    },
    {
      name: 'keystone-edit.**',
      url: '^/admin/access/keystone/edit/{id}',
      loadChildren: () => import('./keystones.module').then(m => m.KeystonesModule),
    },
  ],

  getStateAccessMap(): StateAccessMap {
    return {
      'access-management.keystone': keystoneViewAccess,
      'access-management.keystone.all-keystones': keystoneViewAccess,
      'keystone-add': keystoneModifyAccess,
      'keystone-edit': keystoneModifyAccess,
    };
  },
};
