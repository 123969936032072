// Service: UpgradeClusterService contains states and methods for upgrade
// cluster workflow

;(function(angular, undefined) {
  'use strict';

  angular
    .module('C.clusterUpgrade')
    .service('UpgradeClusterService', upgradeClusterServiceFn);

  function upgradeClusterServiceFn(_, $rootScope, $translate, moment, $http,
    API, $state, $q, ClusterService, Upload, evalAJAX, cModal, cMessage,
    cUtils, UserService, featureFlagsService) {

    var upgradeClusterService = {
      abortUpload: abortUpload,
      cancelUpgrade: cancelUpgrade,
      downloadPackage: downloadPackage,
      getClustersLocation: getClustersLocation,
      getClusterRecipes: getClusterRecipes,
      getClusterUpgradeState: getClusterUpgradeState,
      getMcmClustersForUpgrade: getMcmClustersForUpgrade,
      getPackagesList: getPackagesList,
      getPossibleUpgradePath: getPossibleUpgradePath,
      initUpgradeState: initUpgradeState,
      openLocationUpdateModal: openLocationUpdateModal,
      openMultipleUploadModal: openMultipleUploadModal,
      openUploadModal: openUploadModal,
      parseTargetVersion: parseTargetVersion,
      registerClusterToHeliosApp: registerClusterToHeliosApp,
      resetUpgrade: resetUpgrade,
      removePackage: removePackage,
      searchCities: searchCities,
      setMode: setMode,
      setTargetPackage: setTargetPackage,
      updateClustersLocation: updateClustersLocation,
      upgradeConfirm: upgradeConfirm,
      upgradeSelectedClusters: upgradeSelectedClusters,
      uploadPackage: uploadPackage,
      getUpgradeCheckResults: getUpgradeCheckResults,
      runUpgradeCheck: runUpgradeCheck,
    };

    // state object for the cluster upgrade
    var clusterUpgradeState = {
      // @type       {string} The Mode['choose', 'confirm'] upgrade cluster
      //                      workflow can have
      mode: 'choose',

      // @type       {boolean} set to true when upgrading on the cluster in
      //                       going on so that other action can be disabled
      isUpgrading: false,

      // @type       {boolean} set to true when cluster is already upgrade with
      //                       latest software
      isSwLatest: false,

      // @type       {boolean} set to true Upgrade package list is available at
      //                       UI to show
      upgradeListReady: false,

      // @type       {boolean} set to true to show upgrade pulse progress
      uploadInProgress: false,

      // @type       {integer} percentage of the upgrade progress
      uploadPercentageComplete: 0,

      // @type       {boolean} set to true when upload package finish
      uploadCompleted: false,

      // @type       {boolean} set to true to show upload error
      uploadError: false,

      // @type       {string} Target package version where upgrading to
      targetPackage: undefined,

      // @type       {string} Target Patch version where upgrading to
      targetPatch: undefined,

      // @type       {object} The cluster details
      clusterInfo: undefined,

      // @type       {object} Upgrade package details
      upgradesData: undefined,

      // @type       {Array} List of node objects where upgrade would apply
      nodes: [],

      // @type       {number} number of list of nodes
      nodesLength: undefined,

      // @type       {boolean} checks if any pakage is present in other nodes
      existInAllNodes: true,

      // @type       {string} patch to be removed if it does'nt exist in all nodes
      patchToBeRemoved: undefined,

      // @type        {number} number of patches
      numOfPatches: undefined,
    };

    // var to store upload instance
    var currentUploadRequest = undefined;

    /**
     * Initialize the upgrade cluster states.
     *
     * @method initUpgradeState
     * @return {object} upgrade cluster state object
     */
    function initUpgradeState() {
      // Start by resetting data in service if any
      resetUpgrade();

      // Gather upgrade status
      getClusterInfo();
      getClusterStatus();
      return clusterUpgradeState;
    }

    /**
     * Gets the upgrade cluster states.
     *
     * @method getClusterUpgradeState
     * @return {object} upgrade cluster state object
     */
    function getClusterUpgradeState() {
      return clusterUpgradeState;
    }

    /**
     * Fetch the cluster info from server and update 'isUpgrading' state.
     *
     * @method getClusterInfo
     */
    function getClusterInfo() {
      ClusterService.getClusterInfo().then(function(r) {
        clusterUpgradeState.clusterInfo = r.data;
        clusterUpgradeState.isUpgrading =
          /kUpgrade/.test(clusterUpgradeState.clusterInfo.currentOperation);
      });
    }

    /**
     * Fetch the upgrade check results.
     *
     * @method getUpgradeCheckResults
     */
    function getUpgradeCheckResults() {
      return ClusterService.getUpgradeCheckResults();
    }

    /**
     * Run upgrade check.
     *
     * @method runUpgradeCheck
     */
    function runUpgradeCheck() {
      return ClusterService.runUpgradeCheck();
    }

    /**
     * Fetch the cluster status, nodes from server and it fetches the list of version packages.
     *
     * @method getClusterInfo
     */
    function getClusterStatus() {
      var nodeIds = [];
      ClusterService.getClusterStatus()
        .then(function(result) {
          clusterUpgradeState.nodes.length = 0;
          clusterUpgradeState.nodesLength = result.data.nodeStatuses.length;
          (result.data.nodeStatuses || []).forEach(function(node, index) {
            clusterUpgradeState.nodes.push(node);
            nodeIds.push(node.id);
          });

          //Get nodes marked for removal as well
          var params = {
            includeMarkedForRemoval: true
          };
          ClusterService.getClusterNodes(params).then(function(result) {
            (result.data || []).forEach(function(node, index) {
              for (var x = 0, len = clusterUpgradeState.nodes.length; x < len; x++) {
                if (node.id === clusterUpgradeState.nodes[x].id) {
                  angular.extend(clusterUpgradeState.nodes[x], node);

                  if (!clusterUpgradeState.nodes[x].services) {
                    clusterUpgradeState.nodes[x]._unreachable = true;
                  }

                  break;
                }
              }
            });

            // Sort the nodes by IP
            clusterUpgradeState.nodes = _.sortBy(clusterUpgradeState.nodes, ['ip']);
          });

          // now get package list, as it requires nodeIds to be defined
          getPackagesList();
        });
    }

    /**
     * Get list of cluster recipes.
     *
     * @param clusterIdentifiers list of cluster identifiers
     */
    function getClusterRecipes(clusterIdentifiers) {
      return ClusterService.getClusterRecipes(clusterIdentifiers);
    }

    /**
     * checks the package type and return packageName
     *
     * @method getPackageName
     *
     * @param {string} packageName
     * @return {string}
     */
    function getPackageName(pkgData,packageType, numNodes) {
      if (packageType === 'patch') {
        // Find the patch that is present in all nodes
        const pkgIndex = pkgData.packageTypes ?pkgData.packageTypes.findIndex((val, index) => {
          return val.toLowerCase() === packageType && pkgData.nodeIds[index].length === numNodes;
        }) : -1;
        return pkgIndex!==-1 ? pkgData.packages[pkgIndex] : '';
      } else {
        const pkgIndex = pkgData.packageTypes ? pkgData.packageTypes.findIndex(val => val.toLowerCase() === packageType) : -1;
        return pkgIndex!==-1 ? pkgData.packages[pkgIndex] : '';
      }
    }

    /**
     * Checks if the node exists in all nodes
     */
    function isPatchPresentInAllNodes(pkgData,numNodes) {
      const pkgIndexes = pkgData.packageTypes ? pkgData.packageTypes
        .map((val, index) => (val === 'PATCH' && pkgData.nodeIds[index].length !== numNodes ? index : -1))
        .filter(index => index !== -1) : [];
      if (pkgIndexes.length > 0) {
        clusterUpgradeState.patchToBeRemoved = pkgIndexes.map(pkgIndex => pkgData.packages[pkgIndex]);
        return false;
      } else {
        return true;
      }
    }

    /**
     * Gets the list of upgrade packages available from Nexus.
     *
     * @method    getPackagesList
     *
     * @param     {boolean} refreshPackages   array of nodeIDs
     * @return    {promise}   a promise to resolve API query
     */
    function getPackagesList(refreshPackages = true) {
      clusterUpgradeState.upgradeListReady = !refreshPackages;
      return ClusterService.listPackages()
        .then(function listPackagesThen(r) {
          if (r.data) {
            clusterUpgradeState.existInAllNodes = isPatchPresentInAllNodes(r.data,clusterUpgradeState.nodesLength);
            clusterUpgradeState.upgradesData = ClusterService.parsePackageResponse(r.data);
            clusterUpgradeState.targetPackage = getPackageName(r.data, 'upgrade', clusterUpgradeState.nodesLength) || '';
            clusterUpgradeState.targetPatch = getPackageName(r.data, 'patch', clusterUpgradeState.nodesLength) || '';
            clusterUpgradeState.numOfPatches = r.data.packageTypes ? r.data.packageTypes.filter(val => val.toLowerCase() == 'patch').length : 0;
            if (r.data.packages) {
              clusterUpgradeState.isSwLatest =
                angular.isArray(clusterUpgradeState.upgradesData) && clusterUpgradeState.upgradesData.length < 1;
            }
            if (r.data.uploadStatus !== null && r.data.uploadStatus.uploading) {
              clusterUpgradeState.uploadInProgress = true;
            } else if (featureFlagsService.enabled('legacyPatchWithUpgrade')) {
              if (r.data.packageTypes && r.data.packageTypes.some(packageType => packageType.toLowerCase() === 'upgrade')) {
                clusterUpgradeState.uploadInProgress = false;
              }
            } else if (r.data.packages) {
              // If the list package contains packages, it means the package has been downloaded.
              // Therefore, we clean up the PollTaskStatus poller and mark that the upload is no longer in progress.
              clusterUpgradeState.uploadInProgress = false;
            }
          }
        })
        .finally(function listPackagesFinally(resp) {
          UserService.registerNewActivity();
          clusterUpgradeState.upgradeListReady = true;
          return resp;
        });
    }

    /**
     * Operate an action on the Cluster. This function can be use to upgrade the
     * cluster and cancel the on-going upgrade.
     *
     * @method     opCluster
     * @param   dataParam   params required to perform an action
     * @param   successCallback   success callback function
     */
    function opCluster(dataParam, successCallback) {
      return ClusterService.upgradeCluster(dataParam).then(
        successCallback,
        evalAJAX.errorMessage
      );
    }

    /**
     * Displays the Upgrade Success cMessage
     *
     * @method     triggerUpgradeSuccessMessage
     */
    function triggerUpgradeSuccessMessage() {
      cMessage.success({
        textKey: 'clusterUpgrade.upgradeAccepted',
        persist: true,
      });
    }

    /**
     * Displays the Cancel Upgrade Success cMessage
     *
     * @method     triggerCancelUpgradeSuccessMessage
     */
    function triggerCancelUpgradeSuccessMessage() {
      cMessage.success({
        textKey: 'clusterUpgrade.upgradeCancelled',
        persist: true,
      });
    }

    /**
     * Triggers the upgrade cluster flow.
     *
     * @method     upgradeConfirm
     * @param   data   params required to upgrade the cluster
     */
    function upgradeConfirm(data) {
      var callback = function successCallback() {
        triggerUpgradeSuccessMessage();
        $state.go('cluster');
      };

      opCluster(data, callback);
    }

    /**
     * Triggers the cancel upgrade cluster flow.
     *
     * @method     cancelUpgrade
     * @param   data   params required to cancel the running upgrade
     */
    function _cancelUpgrade(data) {
      var callback = function successCallback() {
        clusterUpgradeState.isUpgrading = false;
        resetUpgrade();
        triggerCancelUpgradeSuccessMessage();
        $state.go('cluster');
      };

      opCluster(data, callback);
    }

    /**
     * Sends the upgrade package to the Nexus to upgrade the nodes with uploaded
     * packages.
     *
     * @method     uploadPackage
     * @param   file   upgrade package
     */
    function uploadPackage(file,showReviewDialog) {
      var swVersion = parseTargetVersion(file.name);
      clusterUpgradeState.uploadInProgress = true;
      clusterUpgradeState.uploadError = false;
      currentUploadRequest = Upload.upload({
        url: API.private('nexus/node/sync_upload_package'),
        file: file,
        method: 'post',
        fileFormDataName: 'uploadFile',
        headers: {
          swVersion: swVersion,
        },
      });

      return currentUploadRequest.progress(function uploadPackagePogress(evt) {
        clusterUpgradeState.uploadPercentageComplete =
          parseInt(100.0 * evt.loaded / evt.total, 10);
      }).success(function uploadPackageSuccess(response) {
        clusterUpgradeState.uploadCompleted = true;
        setTargetPackage(swVersion);
        if(!showReviewDialog){
            upgradeConfirm({
            clusterId: clusterUpgradeState.clusterInfo.id,
            targetSwVersion: clusterUpgradeState.targetPackage,
          })
        }
      }).error(function uploadPackageError(response) {
        clusterUpgradeState.uploadError = true;
        evalAJAX.errorMessage({data: response});
      }).finally(function uploadPackageFinally() {
        clusterUpgradeState.uploadInProgress = false;
      });
    }

    /**
     * Remove the given software package from cluster.
     *
     * @method removePackage
     * @return {string} packageName
     */
    function removePackage(packageName) {
      var reqData = {
        clusterId: clusterUpgradeState.clusterInfo.id,
        packageNames: [packageName],
      }
      return $http({
        method: 'post',
        data: reqData,
        url: API.private('nexus/cluster/delete_packages'),
      });
    }

    /**
     * Cancels ongoing upload if any
     *
     * @method    abortUpload
     */
    function abortUpload() {
      clusterUpgradeState.uploadInProgress = false;

      if(currentUploadRequest) {
        currentUploadRequest.abort();
      }
    }

    /**
     * Sets the upgrade cluster flow mode.
     *
     * @method     setMode
     */
    function setMode(mode) {
      clusterUpgradeState.mode = mode;
    }

    /**
     * Sets the target package version where the cluster is upgrading to.
     * If function is called without any targetPackage value, it will be set to
     * undefined.
     *
     * @method     setTargetPackage
     */
    function setTargetPackage(targetPackage) {
      clusterUpgradeState.targetPackage = targetPackage;
    }

    /**
     * Resets the upgrade.
     *
     * @method     resetUpgrade
     */
    function resetUpgrade() {
      setMode('choose');
      setTargetPackage();
      abortUpload();
    }

    /**
     * Download package to node.
     *
     * @method     downloadPackage
     */
    function downloadPackage(url, authHeaders) {
      var successCallback = function successCallback() {
        getPackagesList();
        cMessage.success({
          textKey: 'clusterUpgrade.downloadPackageAccepted',
          persist: true,
        });
      };
      return nodeUploadPackage(url, authHeaders).then(
        successCallback,
        evalAJAX.errorMessage
      ).finally(function finalizeDownloadPackage() {
        resetUpgrade();
      });
    }

    /**
     * Call url to upload upgrade package using url to a node
     *
     * @param     url        url of upgrade package
     *
     * @return    {object}   a promise to resolve API query
     */
    function nodeUploadPackage(url, authHeaders) {
      var reqData = {
        swVersion: parseTargetVersion(url),
        url: url,
        authHeaders: authHeaders
      }
      return $http({
        method: 'post',
        data: reqData,
        url: API.private('nexus/node/upload_package'),
      });
    }

    /**
     * Opens the manual upgrade cSlider
     *
     * @method     openUploadModal
     */
    function openUploadModal() {
      var modalConfig = {
        templateUrl: 'app/admin/cluster/upgrade/manual-upgrade.html',
        controller: 'manualClusterUpgradeController',
        controllerAs: '$ctrl',
        size: 'lg',
      };

      var modalOptios = {
        actionButtonKey: false,
        closeButtonKey: false,
        titleKey: false,
      };

      return cModal.standardModal(modalConfig, modalOptios);
    }

     /**
     * Cancel the running upgrade on the cluster.
     *
     * @method   cancelUpgrade
     * @param    {object}   data   params required to cancel the running upgrade
     */
    function cancelUpgrade(data) {
      var options;

      options = {
        closeButtonText: 'cancel',
        actionButtonText: 'delete',
        title: 'clusterUpgrade.cancelUpgradeTitle',
        contentKey: 'clusterUpgrade.cancelUpgradeText',
      };

      // Show the modal.
      cModal.standardModal({}, options).then(function(){
         _cancelUpgrade(data);
      });

    }

    /**
     * Parse the target version from URL
     *
     * @method     parseTargetVersion
     * @param string download url( e.g http://dipowd173xxgy.cloudfront.net/public/pkg/4jD0IHON73tsWnSiAUB0xA/cohesity-4.1.2c_release-20180412_b8e08cfb.tar.gz)
     * @return targetVersion (e.g '4.1.2c_release-20180412_b8e08cfb' from above url)
     */
    function parseTargetVersion(url) {
      if(!url) {
        return;
      }

      var arr = url.split('/');
      if (arr.length) {
        // drop 'cohesity-' from start and '.tar.gz' from end
        return arr[arr.length - 1].slice(9, -7);
      }
    }

    /**
     * Retrieves all the Clusters registered in Helios
     *
     * @method   getMcmClustersForUpgrade
     *
     * @param    {object}    params   The list of parameters such as upgrade
     *                                info to be sent.
     * @return   {object}    Returns a promise
     */
    function getMcmClustersForUpgrade(params) {
      return $http({
        method: 'GET',
        url: API.mcm('upgradeInfo'),
        params: params,
      }).then(function gotClusters(res) {
        return _decorateClusterInfo(res.data.upgradeInfo || []);
      });
    }

    /**
     * Trigger the upgrade for the selected clusters
     *
     * @method   upgradeSelectedClusters
     * @param   {array}   clusters   array of the clusters to be updated
     */
    function upgradeSelectedClusters(clusters) {
      return $http({
        method: 'POST',
        url: API.mcmPublic('upgradeClusters'),
        data: clusters,
      }).then(function updatedClustersSuccessfully(upgradeData) {
        return upgradeData.data;
      });
    }

    /**
     * Decorate the cluster information
     *
     * @method   decorateClusterInfo
     *
     * @param   {Array}   clustersInfo   List of clusters with information
     */
    function _decorateClusterInfo(clustersInfo) {
      return clustersInfo.map(function decorateCluster(cluster) {

        // Adding a name property so this response can be seemlessly used in
        // place of remoteClusters response in altClusterSelector.
        cluster.name = cluster.clusterName;

        cluster._isUpgradable = cluster.upgradeability === 'kUpgradable';

        cluster._storageTotal =
          cUtils.bytesToSize(cluster.storageCapacityBytes).string;
        cluster._storageUsed =
          cUtils.bytesToSize(cluster.storageConsumedBytes).string;
        cluster._storageUsedPercent =
          (cluster.storageConsumedBytes / cluster.storageCapacityBytes) * 100;

        cluster._isHealthy = !cluster.numCriticals;

        cluster._isUpgrading = cluster.upgradeStatus === 'kInProgress';
        cluster._upgradeFailed = cluster.upgradeStatus === 'kFailed';
        cluster._upgradeScheduled = cluster.upgradeStatus === 'kPending';

        // Get only the version not the build info
        cluster._version = getClusterVersion(cluster.softwareVersion);

        return cluster;
      });
    }

    /**
     * Returns the short qualified software version.
     *
     * @method   getClusterVersion
     * @param    {string}   softwareVersionFull   The fully qualified software
     *                                            version
     * @return   {string}   The short cluster version.
     */
    function getClusterVersion(softwareVersionFull) {
      if (!softwareVersionFull || !softwareVersionFull.length) {
        return '-';
      }

      // Split the software version at -.
      // Software version follows one of these two rules
      // 1. 6.5.0a_p1_release-20200526_7061ebaa
      // 2. 6.5.1_qa-20200602_f77c56a2
      var softwareSplit = softwareVersionFull.split('-')[0];
      var releaseList = softwareSplit.split('_');
      switch (releaseList.length) {
        case 3:
          // Follows new hotfix convention.
          return [releaseList[0], releaseList[1]].join('_');
        default:
          return releaseList[0];
      }
    }

    /**
     * Opens the location update for clusters
     *
     * @method   openLocationUpdateModal
     * @param   {array}   clusters   Selected clusters for location update
     */
    function openLocationUpdateModal(clusters) {
      var modalConfig = {
        templateUrl: 'app/admin/cluster/upgrade/update-location-modal.html',
        controller: 'multipleClusterLocationUpdateModalController',
        controllerAs: '$ctrl',
        size: 'lg',
        resolve: {
          clusters: function resolveSelectedClusters() { return clusters; },
        },
      };

      var modalOptions = {
        actionButtonKey: undefined,
        closeButtonKey: undefined,
        titleKey: 'clusterManagement.updateLocation',
        buttonIds: {
          close: 'close-update-cluster-location-modal',
        }
      };

      return cModal.standardModal(modalConfig, modalOptions);
    }

    /**
     * Gets location of registered clusters
     *
     * @method   getClustersLocation
     * @param   {object}   params   Country code
     */
    function getClustersLocation() {
      return $http({
        method: 'GET',
        url: API.mcm('clusters/locations'),
      }).then(function gotLocations(list) {
        return list.data;
      });
    }

    /**
     * Gets list of cities and lat-lon
     *
     * @method   searchCities
     * @param   {object}   params   Country and state code
     */
    function searchCities(params) {
      return $http({
        method: 'GET',
        url: API.mcm('places'),
        params: params,
      }).then(function citiesList(list) {
        return list.data;
      });
    }

    /**
     * Updates cluster location
     *
     * @method   updateClustersLocation
     * @param   {object}   params   location details of selected clusters
     */
    function updateClustersLocation(params) {
      return $http({
        method: 'PUT',
        url: API.mcm('clusters/locations'),
        data: params,
      });
    }

    /**
     * Opens the multiple upgrade
     *
     * @method   openMultipleUploadModal
     * @param   {array}   clusters   Selected clusters for upgrade
     */
    function openMultipleUploadModal(clusters) {
      var modalConfig = {
        templateUrl: 'app/admin/cluster/upgrade/multiple-upgrade-modal.html',
        controller: 'multipleClusterModalUpgradeController',
        controllerAs: '$ctrl',
        size: 'lg',
        resolve: {
          clusters: function resolveSelectedClusters() { return clusters; },
        },
      };

      var modalOptios = {
        actionButtonKey: undefined,
        closeButtonKey: undefined,
        titleKey: 'clusterUpgrade.upgradeClusters',
        buttonIds: {
          close: 'close-upgrade-cluster-modal',
        }
      };

      return cModal.standardModal(modalConfig, modalOptios);
    }

    /**
     * Opens the register cluster modal to facilitate cluster claim to app.
     *
     * @method   registerClusterToHeliosApp
     * @return   {object}   Promise to the modal events.
     */
    function registerClusterToHeliosApp() {
      var modalConfig = {
        templateUrl: 'app/admin/cluster/upgrade/register.html',
        controller: 'registerClusterController',
        controllerAs: '$ctrl',
        size: 'lg',
      };

      var modalOptions = {
        actionButtonKey: 'connect',
        titleKey: 'clusterManagement.registerTitle',
        buttonIds: {
          action: 'register-cluster-btn',
          close: 'cancel-register-cluster',
        },
      }

      return cModal.standardModal(modalConfig, modalOptions);
    }

    /**
     * Returns all the possible upgrade path in a sequence order.
     *
     * @method   getPossibleUpgradePath
     * @param    params   object   Upgrade version of a cohesity package.
     */
    function getPossibleUpgradePath(params) {

      _.assign(params, {
        accountId: $rootScope.user.salesforceAccount.accountId
      });

      return $http({
        method: 'GET',
        url: 'mcm/softwareReleasePaths',
        params: params
      }).then(function possiblePath(response) {
        return _decorateUpgradeRelease(response.data);
      });
    }

    /**
     * Decorate the software releases that the available.
     *
     * @method   _decorateClusterInfo
     * @param   upgrades   {array}    Array of software releases
     *
     * @return  {array}    Decorated upgrade releases
     */
    function _decorateUpgradeRelease(upgrades) {
      var upgradeReleases = [];
      var decoratedUpgrades = upgrades.map(function decorateUpgrade(upgrade) {
        // Release Date in Unix Time
        upgrade._releaseDateUsecs =
          new Date(upgrade.designationDate).getTime() * 1000;

          // End of Support in Unix Time
        upgrade._endOfSupport = moment(
          new Date(upgrade.endOfSupportDate).getTime()).fromNow(true);

        // The version string has tags like release and timestamp. Ex.
        // 6.0.1a_release_1234. We want only the version number of the release.
        upgrade._version = getClusterVersion(upgrade.versionInSeries);
        upgrade._isLts = upgrade.releaseType === 'LTS Release';

        // Check if the version has a blacklisted cluster, then we will
        // disable the version.
        upgrade.disabled = !!_.get(upgrade, 'blacklistedClusters.length', 0);

        return upgrade;
      });

      // Group the decorated source by the series version. ex. 6.0 or 5.0
      // and only get the active version in the upgrade series.
      var groupedRelease = _.groupBy(decoratedUpgrades, 'series');

      _.forEach(groupedRelease, function iterateOverRelease(release) {
        var item = _.find(release, 'isActiveVersionInSeries');
        if (item) {
          upgradeReleases.push(item);
        }
      });

      // Sort the upgrade by release date
      upgradeReleases = _.sortBy(upgradeReleases, 'versionInSeries');

      // Find in the list which one to be selected, If there is isLts release
      // select it or select the last one in the upgrade array.
      var recommendedVersion = _.findLast(upgradeReleases, {
        _isLts: true,
        disabled: false,
      });

      if (recommendedVersion) {
        recommendedVersion._checked = true;
        recommendedVersion._isRecommended = true;
      } else if (!!upgradeReleases.length) {
        // If there is no recommended version that is found. Find the latest
        // version which is not disabled.
        var selectedVersion = _.findLast(upgradeReleases, {
          disabled: false,
        });

        if (selectedVersion) {
          selectedVersion._checked = true;
        }
      }

      return upgradeReleases;
    }

    return upgradeClusterService;
  }
})(angular);
