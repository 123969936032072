import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { flagEnabled, IrisContextService, isMcm, isMcmOnPrem } from '@cohesity/iris-core';
import { isValidHttpURL } from '@cohesity/utils';
import { ClusterUpgradeAuthMethods, AuthHeadersForm } from '../models';
import { AuthHeader } from '@cohesity/api/v2';


/**
 * Utils for Cluster Management service. This is to be downgraded to use in AngularJS.
 */
@Injectable({
   providedIn: 'root'
})
export class ClusterManagementUtilsService {
   constructor(private irisContextService: IrisContextService) {}

   /**
    * Method decide whether to show the ng cluster management page or not.
    *
    * @returns True if we are to use Ng Cluster Management page
    */
   public showNgClusterManagementPage(): boolean {
      const ctx = this.irisContextService.irisContext;
      switch(true) {
         case isMcmOnPrem(ctx): return true;
         case isMcm(ctx): return flagEnabled(ctx, 'ngHeliosClusterUpgrade');
         default: return false;
      }
   }

   /**
    * Checks whether the upgrade status is applicable in current context.
    *
    * @param status Upgrade status
    * @returns true if applicable
    */
   public checkStatusApplicability(status: string, availableUpgradeOptions: any[]): boolean {
      switch (status) {
         case 'UpgradeAvailable': return availableUpgradeOptions.includes('whitelistUpgrades');
         default: return true;
      }
   }

   /**
    * Validator for the input url field in Url based flow
    *
    * @returns Validator Function that can be used to validate for controls
    */
  public urlValidator(): ValidatorFn {
   return (control: AbstractControl): { [key: string]: any } | null => {
     const isValid = isValidHttpURL(control.value);
     return isValid ? null : { invalidUrl: { value: control.value } };
   };
 }

  /**
   * Get a List of Possible Authorization Methods.
   *
   *  @returns ClusterUpgradeAuthMethods[] List of string of Auth Methods.
   */
  public getAuthMethods(): ClusterUpgradeAuthMethods[] {
      return [ClusterUpgradeAuthMethods.None, ClusterUpgradeAuthMethods.Basic, ClusterUpgradeAuthMethods.Custom];
    }

   /**
    * Convert formValue to a list of authHeaders
    *
    * @param AuthHeadersUrlBasedUpgradeForm  - The form value containing authentication details.
    * @returns AuthHeader[] An array of AuthHeader objects representing the authentication headers.
    */
   public validateAndFormAuthHeaders(formValue: AuthHeadersForm): AuthHeader[] {
      const authHeaders: AuthHeader[] = [];

      if (!formValue || formValue.selectedAuthMethod === ClusterUpgradeAuthMethods.None) {
        return authHeaders;

      }

      const selectedAuthMethod = formValue.selectedAuthMethod;
      const basicAuthCredentials = formValue.basicAuth;
      const customAuthHeaders = formValue.customAuths;

      switch (selectedAuthMethod) {
        case ClusterUpgradeAuthMethods.Basic:
          if (basicAuthCredentials) {
            const encodedCredentials = btoa(`${basicAuthCredentials.username}:${basicAuthCredentials.password}`);
            authHeaders.push({
              key: 'Authorization',
              value: `Basic ${encodedCredentials}`
            });
          }
          break;

        case ClusterUpgradeAuthMethods.Custom:
          if (customAuthHeaders) {
            // Trim out all empty headers
            const filteredCustomAuthHeaders = customAuthHeaders.filter(
              (header) => header.key.trim() !== '' && header.value.trim() !== ''
            );
            authHeaders.push(...filteredCustomAuthHeaders);
          }
          break;

        default:
          break;
      }
      return authHeaders;
   }
}
