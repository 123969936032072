import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AttributeFilter } from '@cohesity/api/reporting';
import { FiltersComponent, ValueFilterSelection } from '@cohesity/helix';
import { reportingM365Subtypes } from '@cohesity/iris-shared-constants';
import { Observable, of } from 'rxjs';

import { BaseReportFilterComponent } from '../base-report-filter.component';

/**
 * Base class for object type filter.
 */
abstract class BaseObjectTypeFilterComponent extends BaseReportFilterComponent {
  constructor(
    filters: FiltersComponent,
    property: string,
  ) {
    super(filters, property);
  }

  toApiValue(filterValues: ValueFilterSelection[]): AttributeFilter {
    return {
      attribute: this.property,
      filterType: 'In',
      inFilterParams: {
        attributeDataType: 'String',
        stringFilterValues: filterValues.map(value => value.value as string),
        attributeLabels: filterValues.map(value => value.label),
      }
    };
  }

  fromApiValue(apiValue: AttributeFilter): ValueFilterSelection[] {
    return (apiValue?.inFilterParams.stringFilterValues || []).map((value, index) => ({
      label: apiValue.inFilterParams.attributeLabels[index],
      value: value
    }));
  }
}


@Component({
  selector: 'iris-rpt-object-type-filter',
  templateUrl: './object-type-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectTypeFilterComponent extends BaseObjectTypeFilterComponent {

  /**
   * The entire group list is below, but only the currently supported values are enabled.
   */
  @Input() supportedGroups = [
    // 'adapter',
    // 'applications',
    'dbs',
    // 'hadoop',
    // 'kubernetes',
    'nas',
    // 'other',
    // 'physical',
    // 'san',
    // 'unknown',
    // 'views',
    'vms',
  ];

  constructor(filters: FiltersComponent) {
    super(filters, 'objectType');
  }

  getFilterValues(): Observable<ValueFilterSelection[]> {
    return of(
      (this.supportedGroups || []).map(group => ({
        label: this.translate(`enum.envGroup.longName.${group}`),
        value: group,
      }))
    );
  }
}

/**
 * M365 object sub-type filter.
 */
@Component({
  selector: 'iris-rpt-m365-app-object-type-filter',
  templateUrl: './object-type-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M365AppFilterComponent extends BaseObjectTypeFilterComponent {
  label = 'apps';

  @Input() supportedGroups = reportingM365Subtypes;

  constructor(filters: FiltersComponent) {
    super(filters, 'protectionEnvName');
  }

  getFilterValues(): Observable<ValueFilterSelection[]> {
    return of(
      (this.supportedGroups || []).map(group => ({
        label: this.translate(`enum.environment.${group}`),
        value: group,
      }))
    );
  }
}

/**
 * M365 object sub-type filter with different label and table column name (objectType).
 */
@Component({
  selector: 'iris-rpt-m365-object-type-filter',
  templateUrl: './object-type-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class M365FilterComponent extends BaseObjectTypeFilterComponent {
  @Input() supportedGroups = reportingM365Subtypes;

  constructor(filters: FiltersComponent) {
    super(filters, 'objectType');
  }

  getFilterValues(): Observable<ValueFilterSelection[]> {
    return of(
      (this.supportedGroups || []).map(group => ({
        label: this.translate(`enum.environment.${group}`),
        value: group,
      }))
    );
  }
}
