import { Injectable } from '@angular/core';
import { NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContext, IrisContextService, isMcm, isOneHeliosAppliance } from '@cohesity/iris-core';
import { StateRegistry, StateService, Transition, TransitionService, UIRouterGlobals } from '@uirouter/core';
import { assign, find, merge } from 'lodash';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AppServiceManagerService } from 'src/app/app-services';
import { AppStateService } from 'src/app/core/services/app-state.service';
import { EulaService } from 'src/app/core/services/eula.service';
import { StateManagementService } from 'src/app/core/services/state-management.service';
import { Routes } from 'src/app/shared/constants';
import { DownloadCliDialogComponent } from 'src/app/shared/dialogs';
import { environment } from 'src/environments/environment';

import { McmViewService } from '../services';
import { CustomizationService } from '../services/customization.service';
import { DialogService } from '../services/dialog.service';
import { OrganizationsService } from '../services/organizations.service';
import { AppStateDeclaration } from '../state/app-state-declaration';
import { isNavItemHidden } from './is-nav-item-hidden';

/**
 * Implements logic for the primary application navigation. All of the logic
 * in this service originated in _nav.js, and should produce equivalent results.
 */
@Injectable()
export class NavService {

  /** Observable of the primary navigation list. */
  navList$: Subject<NavItem[]> = new ReplaySubject(1);

  /** Observable of the dashboard state navItems. */
  dashboardsList$: Subject<NavItem[]> = new ReplaySubject(1);

  /** Observable of the help navigation list. */
  helpList$: Subject<NavItem[]> = new ReplaySubject(1);

  /**
   * Provides an observable of the fully filtered and customized nav list.
   * This will include UI customizations being applied and any can access checks.
   */
  customizedNavList$: Observable<NavItem[]>;

  /** The common nav config */
  private defaultNavConfig: Partial<NavItem> = {
    stateOpts: { reload: true }
  };

  /** States that allowed in Helios when there are no clusters registered. */
  readonly enabledInHeliosInitialState = [
    'cloud-edition-manager',
    'cluster-upgrades',
    'helios-access-management',
    'license.dashboard',
    'license-deprecated',
    'apps-management',
    'organizations',
    'helios-audit-logs',
  ];

  /** States that are allowed in all cluster selection of the Helios On-Prem app. */
  readonly enabledInHeliosOnPremState = [
    'alerts',
    'cluster-upgrades',
    'dashboard',
    'dashboards',
    'helios-access-management',
    'quorum',
    'organizations',
    'helios-audit-logs',
  ];


  constructor(
    private appServiceManager: AppServiceManagerService,
    private appStateService: AppStateService,
    private eulaService: EulaService,
    private irisContextService: IrisContextService,
    private organizationsService: OrganizationsService,
    private stateManagementService: StateManagementService,
    private stateRegistry: StateRegistry,
    private stateService: StateService,
    private transitionService: TransitionService,
    private dialogService: DialogService,
    private uiRouterGlobals: UIRouterGlobals,
    private customizationService: CustomizationService,
    private mcmViewService: McmViewService,
  ) {
    // Watch for state change so we can update the navigation to indicate
    // current location.  NOTE: Some of this logic and behavior has been
    // moved  to the cNavActivetree directive instead.
    this.transitionService.onSuccess({}, this.stateUpdate.bind(this));


    // If the user changes, update the nav list.
    this.irisContextService.irisContext$.pipe(
      map(context => context.user),
      distinctUntilChanged(),
    ).subscribe(() => this.updateNavList(this.irisContextService.irisContext));

    // Update nav items on the basis of whther MT on Helios is enabled or not
    this.organizationsService.isOrganizationEnabled$.subscribe(() => {
      this.updateNavList(this.irisContextService.irisContext);
    });


    this.customizedNavList$ = combineLatest([
      // User customizations aren't referenced in this logic directly, but are used to
      // determine the state to send users for the "Dashboards" NavItem, so
      // changes to user preferences can impact the nav list output.
      this.customizationService.userCustomizations$,
      this.navList$,
      this.customizationService.hiddenNavItems$,
    ]).pipe(
      filter(([customizations, navList, hiddenNavItems]) => !!customizations && !!navList && !!hiddenNavItems),
      map(([, navList, hiddenNavItems]) => {
        // If there are no hidden nav items specified, return the entire list...
        if (!hiddenNavItems.length) {
          return navList;
        }

        // ...otherwise filter out the items that should be hidden.
        return structuredClone(navList).filter(navItem => {
          const isItemHidden = hiddenNavItems.includes(navItem.displayName);

          // If the parent nav item is hidden, no reason to walk the children. Filter it out / exit early.
          if (isItemHidden) {
            return false;
          }

          // Filter the subNavList.
          navItem.subNavList =
            navItem.subNavList?.filter(subNavItem => !hiddenNavItems.includes(subNavItem.displayName));

          return true;
        });
      })
    );
  }

  /**
   * update nav active state. This function mutates scope.navList[].
   * if a child is active then make the parent nav active too.
   *
   * Main nav can be active even if no children are active.
   * e.g. when main nav navigate to a state which is not in subNavList.
   */
  updateNavList(irisContext: IrisContext) {
    // Update the nav only when scope is selected and cluster info is fetched.
    // TODO: investigate the possibility of simply running this.updateNavList()
    // on a subscription to irisContext$ in order to remove all the $rootScope
    // watchers, etc that fire this function.
    if (!irisContext?.clusterInfo || !irisContext.selectedScope.name) {
      return;
    }

    let navList: NavItem[] = [];
    let dashboardList: NavItem[] = [];

    const navProvider = this.appServiceManager.getActiveService()?.navProvider;

    if (navProvider) {
      navList = this.filterNavItems([
        ...navProvider.getNavItems(irisContext, this.stateManagementService),
        ...this.devOnlySettingsItems
      ]);
      if (navProvider.getDashboardItems) {
        dashboardList = navProvider.getDashboardItems(irisContext);
      }
    }

    // If in Helios and dealing with a cluster having a "heliosVersion" then
    // its necessary to build the navList from McmViewService as it holds the
    // logic for iframe navigation items. Ideally this would be handled in
    // Cluster Manager's navProvider, but there are circular dependency issues.
    if (isMcm(irisContext) && irisContext.selectedScope.heliosVersion) {
      // Items for Helios selected cluster version.
      navList = [
        ...navList.filter(navItem =>
          // This is a bit unholy, but McmViewService manages the navList except
          // for dashboards as its not loaded in the iframe. The possible dashboard
          // options from the navProvider will either have the term "dashboard" in
          // the state name or explicitly be "security" (highly unlikely scenario).
          navItem.state && ((navItem.state.includes('dashboard') || navItem.state === 'security'))
        ),
        ...this.mcmViewService.getNavItems()
      ];
    }

    const newNavList = this.mergeDefaultsIntoNavList(navList);
    this.navList$.next(this.processNavList(newNavList));

    const newDashboardList = this.mergeDefaultsIntoNavList(dashboardList);
    this.dashboardsList$.next(this.processNavList(newDashboardList));

    this.helpList$.next(this.getHelpItems(irisContext));
  }


  /**
   * Private function to update the navigation location values based on the
   * current URL of the page.
   *
   * @param   event     Event object.
   * @param   toState   The ui state object the user just changed to.
   */
  private stateUpdate(trans: Transition) {
    const toState = trans.$to();
    if (toState.url) {
      this.updateNavList(this.irisContextService.irisContext);
    }
  }

  /**
   * Takes a NavItem[] and filters out items that shouldn't be displayed and decorates the items with display
   * properties.
   *
   * @param   navList   The list of NavItems to process.
   */
  private processNavList(navList: NavItem[]): NavItem[] {
    const navProvider = this.appServiceManager.getActiveService()?.navProvider;

    if (navProvider?.updateNavActiveStatus) {
      return navProvider.updateNavActiveStatus(navList);
    }

    return (navList || []).filter(navItem => {
      // Process the subNavList[] first, as the properties of subItems are used to determine property values for parent.
      navItem.subNavList = navItem.subNavList.filter(subNavItem => {
        subNavItem.isActive = this.isNavActive(subNavItem, this.uiRouterGlobals.current);

        // if the parent nav item is already 'open' leave it as such, otherwise assign it the value of this subNavItem,
        // because if any subNavItem is active the parent should be open.
        navItem.isOpen = navItem.isOpen || subNavItem.isActive;
        subNavItem.disabled = this.isNavItemDisabled(subNavItem);

        return !subNavItem.hidden;
      });

      navItem.disabled = this.isNavItemDisabled(navItem);
      navItem.isActive = !navItem.subNavList.length && this.isNavActive(navItem, this.uiRouterGlobals.current);

      return !navItem.hidden;
    });
  }

  /**
   * merges default config into each nav in navList
   *
   * @param    navList      The navigation list
   * @returns   new navigation list
   */
  private mergeDefaultsIntoNavList(navList: NavItem[]): NavItem[] {
    const ctx = this.irisContextService.irisContext;
    return (navList || []).map(navItem => {
      let firstVisibleSubNav;
      const subNavList = (navItem.subNavList || []).map(subNavItem => {
        subNavItem.hidden = isNavItemHidden(subNavItem, ctx, this.stateManagementService);
        return merge({}, this.defaultNavConfig, subNavItem);
      });

      // Check if default top nav is visible
      navItem.hidden = isNavItemHidden(navItem, ctx, this.stateManagementService);

      // When top nav is hidden then find the 1st visible sub nav and allow
      // parent nav item's click to goto that state.
      if (navItem.hidden && subNavList.length) {
        // Get first visible sub nav and item if not found then hide the top nav
        // by falling back to first sub nav item.
        firstVisibleSubNav = find(subNavList, item => !item.hidden) || subNavList[0];

        assign(navItem, {
          state: firstVisibleSubNav.state,
          hidden: firstVisibleSubNav.hidden,
        });
      }

      return merge({}, this.defaultNavConfig, navItem, {
        subNavList: subNavList
      });
    });
  }

  /**
   * Indicates if a particular navItem is active or not.
   * Active means the item should be visually highlighted as selected.
   *
   * @example
   *   const states = [
   *     { name: 'job-run',  parentState: 'job-runs' },
   *     { name: 'job-runs', parentState: 'jobs' },
   *     { name: 'jobs' },
   *   ];
   *
   *   isNavActive({state: 'jobs'},    {name: 'job-run', ....});       true
   *   isNavActive({state: 'jobs'},    {name: 'job-runs', ...});       true
   *   isNavActive({state: 'polices'}, {name: 'job-run', ....});       false
   *
   * @param   navItem   A Navigation config item.
   * @param   currentState  The current state.
   * @returns  True if navItem is active, false otherwise.
   */
  private isNavActive(navItem: NavItem, currentState: AppStateDeclaration): boolean {
    if (navItem.state === currentState.name) {
      return true;
    }

    if (this.stateService.includes(navItem.state)) {
      return true;
    }

    // Show menu item as active if page/state is in the activeStates
    if (navItem.activeStates?.includes(currentState.name)) {
      return true;
    }

    // Recursively checking whether current nav is the ancestor of provided state.
    if (currentState.parentState) {
      return this.isNavActive(navItem, this.stateRegistry.get(currentState.parentState));
    }

    return false;
  }

  /**
   * Checks whether the menu item is enabled or not.
   *
   * @param   navItem   the nav item to be evaluated.
   */
  isNavItemDisabled(navItem: NavItem): boolean {
    const irisContext = this.irisContextService.irisContext;

    // Are some of the sub items enabled and visible.
    const hasEnabledSubMenuItem =
      (navItem.subNavList || []).some(item => !item.disabled && !item.hidden);

    if (hasEnabledSubMenuItem) {
      return false;
    }

    const navProvider = this.appServiceManager.getActiveService()?.navProvider;
    if (navProvider?.isNavItemDisabled) {
      return navProvider.isNavItemDisabled(navItem, irisContext);
    }

    // For Helios Initial state make sure some nav Items are enabled.
    if (isMcm(irisContext) && this.appStateService.remoteClusterList.length === 1) {

      // If there is no state defined and has a route.
      if (navItem.state === undefined && navItem.href) {
        return navItem.disabled || navItem.hidden;
      }
      return !this.enabledInHeliosInitialState.includes(navItem.state);
    }

    return navItem.hidden;
  }

  /**
   * Returns a fresh copy of navItems[] for help flyout.
   *
   * @returns The list of help items.
   */
  getHelpItems(irisContext: IrisContext): NavItem[] {

    const helpCenterEnabled = flagEnabled(irisContext, 'helpCenter') && isMcm(irisContext);

    const helpCenterItems = [
      {
        displayName: 'helpCenter',
        state: 'help.center',
        disabled: false,
        hidden: false,
      }
    ];

    return helpCenterEnabled ? helpCenterItems : [
      {
        displayName: 'supportPortal',
        state: undefined,
        href: Routes.support,
        disabled: false,
        hidden: false,
      },
      flagEnabled(irisContext, 'apiDocumentationEnabled') ? {
        displayName: 'apiDoc',
        href: Routes.apiDoc,
        disabled: false,
        hidden: false,
      } : environment.production ? {
        displayName: flagEnabled(irisContext, 'reDocV1Enabled') ?
          'restApiV1' : 'restApi',
        href: flagEnabled(irisContext, 'reDocV1Enabled') ?
          Routes.restApiV1Doc : Routes.restApiDocRoot,
        disabled: false,
        hidden: isOneHeliosAppliance(irisContext),
      } : undefined,
      flagEnabled(irisContext, 'reDocV2Enabled') && environment.production ? {
        displayName: 'restApiV2',
        href: Routes.restApiV2Doc,
        disabled: false,
        hidden: isOneHeliosAppliance(irisContext),
      } : undefined,
      {
        displayName: 'downloadCli',
        state: undefined,
        disabled: false,
        hidden: isMcm(irisContext),
        action: () => {
          this.dialogService.showDialog(DownloadCliDialogComponent);
        },
      },
      {
        displayName: 'licenseAgreement',
        state: undefined,
        href: this.eulaService.eulaPath,
        disabled: false,
        hidden: isMcm(irisContext) || isOneHeliosAppliance(irisContext),
      }
    ].filter(Boolean);
  }

  /**
   * Provides a list of dev only NavItems for display in the settings cog.
   */
  private get devOnlySettingsItems(): NavItem[] {
    const items = [];
    const examplePageEnabled = flagEnabled(this.irisContextService.irisContext, 'uiExamplesPage');
    const { production } = environment;

    if (!production) {
      items.push({
        displayName: 'featureFlags',
        state: 'feature-flags',
        icon: 'flag!outline',
      });
    }

    if (!production || examplePageEnabled) {
      items.push({
        displayName: 'uiExamples',
        state: 'ng2-examples',
        icon: 'format_quote!outline',
      });
    }

    return items;
  }

  /**
   * Returns the filtered list of NavItem with null or empty parent nav item &
   * its children nav items
   *
   * @returns The list of nav items.
   */
  filterNavItems(navList: NavItem[]): NavItem[] {
    return navList.filter(navItem => {
      if (navItem) {
        // keeping only valid sub nav items by removing null values.
        navItem.subNavList = (navItem.subNavList || []).filter(subNavItem => !!subNavItem);
        return true;
      }

      // removing the parent nav it navItem is not defined for him.
      return false;
    });
  }
}
