import { HasCustomRBACPermissions } from '@cohesity/data-govern/shared';
import { joinUrl, NavItem } from '@cohesity/helix';
import {
  appConfigMap,
  flagEnabled,
  getConfigByKey,
  IrisContext,
  isDmsOnlyUser,
  isDspmUser,
  isHeliosTenantUser,
  isRpaasUser
} from '@cohesity/iris-core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Default Nav List
 */
const defaultNavItems = [
  'antiransomwareItems',
  'datahawkItems',
  'securityItems',
  'miscItems',
];

/**
 * Generates the navigation list for datahawk/security center based on the feature enablement.
 *
 * @param translate translation service
 * @param irisCtx iris context
 * @returns navigation list for the datahawk/security center
 */
export const getNavList = (translate: TranslateService, irisCtx: IrisContext): NavItem[] => {
  const antiransomwareItems: NavItem[] = [
    (!isDmsOnlyUser(irisCtx) || isDmsOnlyUser(irisCtx) && flagEnabled(irisCtx, 'antiransomwareBaasSupported')) && {
      displayName: translate.instant('argus.enum.shieldType.ANTI_RANSOMWARE'),
      icon: 'helix:anti-ransomeware',
      href: '/anti-ransomware',
    },
    !isDmsOnlyUser(irisCtx) && flagEnabled(irisCtx, 'securityCenterInventory') && {
      displayName: translate.instant('inventory'),
      icon: 'helix:inventory',
      href: '/security/inventory',
    },
    !isDmsOnlyUser(irisCtx) && flagEnabled(irisCtx, 'cleanRoomRecoveryPhase2') && {
      displayName: translate.instant('cleanRooms'),
      icon: 'helix:meeting-room',
      href: '/security/clean-rooms',
    }
  ].filter(Boolean) as NavItem[];

  if (antiransomwareItems.length > 0) {
    antiransomwareItems.push({
      displayName: '',
      type: 'separator'
    });
  }

  const datahawkItems: NavItem[] = [
    !isDmsOnlyUser(irisCtx) &&  {
      displayName: translate.instant('dataHawk'),
      type: 'sub-header'
    },
    !isDmsOnlyUser(irisCtx) && HasCustomRBACPermissions(['DGAAS_VIEW_THREAT_SCAN', 'DGAAS_VIEW'], irisCtx) && {
      displayName: translate.instant('dg.threatProtection'),
      icon: 'helix:threat-protection',
      subNavList: [
        {
          displayName: translate.instant('dg.threatDetection'),
          href: '/threat-detection',
        },
      ].filter(Boolean),
    },
    !isDmsOnlyUser(irisCtx) && HasCustomRBACPermissions(['DGAAS_VIEW_THREAT_SCAN', 'DGAAS_VIEW'], irisCtx) && {
      displayName: translate.instant('dg.dataClassification'),
      icon: 'helix:data-sensitivity',
      subNavList:
        flagEnabled(irisCtx, 'dataHawkOnDemandDataClassificationEnabled') ?
          [
            {
              displayName: translate.instant('dg.dc.classificationScans'),
              href: '/data-classification/scans',
            },
            {
              displayName: translate.instant('dg.dc.classificationLibary'),
              href: '/data-classification/library'
            },
          ].filter(Boolean) : [
            {
              displayName: translate.instant('policies'),
              href: '/data-classification/library/policies',
            },
            {
              displayName: translate.instant('patterns'),
              href: '/data-classification/library/patterns',
            },
            {
              displayName: translate.instant('exclusions'),
              href: '/data-classification/library/exclusions',
            },
          ],
    },
    !isDmsOnlyUser(irisCtx) &&  {
      displayName: translate.instant('dg.cyberVaulting'),
      icon: 'helix:vault',
      suffixIcon: 'launch',
      href: isRpaasUser(irisCtx) ?
        `/${joinUrl(['..', appConfigMap.fortKnox.urlSegment])}` :
        'https://www.cohesity.com/products/fortknox/',
      stateParams: appConfigMap.fortKnox.params,
      options: {
        hrefTarget: '_blank',
        isExternal: !isRpaasUser(irisCtx),
      }
    },
    !isDmsOnlyUser(irisCtx) && flagEnabled(irisCtx, 'dataHawkClusterReplication') && {
      displayName: translate.instant('dg.dc.clusterScanOrderConfiguration'),
      icon: 'helix:object-cluster',
      href: '/cluster-scanning-preference',
    },
  ].filter(Boolean) as NavItem[];

  if (datahawkItems.length > 0) {
    datahawkItems.push({
      displayName: '',
      type: 'separator',
    });
  }

  const securityItems: NavItem[] = [
    {
      displayName: translate.instant('dg.securityPosture'),
      icon: 'helix:security-posture',
      subNavList: [
        flagEnabled(irisCtx, 'heliosSecurityAdvisorEnabled') && !isHeliosTenantUser(irisCtx) && HasCustomRBACPermissions(['SECOPS_VIEW_POSTURE_ADVISOR', 'DGAAS_VIEW'], irisCtx) &&
        {
          displayName: translate.instant('dg.postureAdviser'),
          icon: 'assignment!outline',
          href: '/security/posture-advisor',
          stateParams: appConfigMap.securityCenter.params,
        },
        flagEnabled(irisCtx, 'heliosQuorumEnabled') && !isHeliosTenantUser(irisCtx) &&
        {
          displayName: translate.instant('quorum.pageTitle'),
          icon: 'helix:object-user-group',
          href: '/security/quorum',
          stateParams: appConfigMap.securityCenter.params,
        },
      ].filter(Boolean) as NavItem[],
    },
  ];

  const miscItems: NavItem[] = [
    isDspmUser(irisCtx) && flagEnabled(irisCtx, 'dspmIntegrations') && {
      displayName: translate.instant('sensitiveDataPosture'),
      icon: 'helix:sensivity-data-posture',
      href: '/data-classification/sensitive-data-posture',
    },
    {
      displayName: translate.instant('dg.userActivity'),
      icon: 'helix:user-activity',
      subNavList: [
        {
          displayName: translate.instant('argus.auditTrails'),
          href: '/smartfiles',
        },
        !isDmsOnlyUser(irisCtx) && {
          displayName: translate.instant('usersAndGroups'),
          href: '/access/users-and-groups',
        },
        !isDmsOnlyUser(irisCtx) && {
          displayName: translate.instant('dataPools'),
          href: '/data-pools',
        },
        !isDmsOnlyUser(irisCtx) && {
          displayName: translate.instant('dataSources'),
          href: '/sources',
        },
      ].filter(Boolean) as NavItem[],
    },
    flagEnabled(irisCtx, 'securityCenterIntegrations') && {
      displayName: translate.instant('integrations'),
      icon: 'helix:integrations',
      href: '/security/integrations',
    },
    HasCustomRBACPermissions(['ALERT_VIEW'], irisCtx) && {
      displayName: translate.instant('alerts'),
      icon: 'notifications!outline',
      href: '/security/alerts',
    },
    flagEnabled(irisCtx, 'securityCenterAuditLogs') && {
      displayName: translate.instant('auditLogs'),
      icon: 'helix:audit-logs',
      href: '/security/audit-logs',
    },
    flagEnabled(irisCtx, 'securityCenterTaggingAndRecovery') && {
      displayName: translate.instant('settings'),
      icon: 'settings!outline',
      href: '/security/settings',
    },
  ].filter(Boolean) as NavItem[];

  const navItems = getConfigByKey(irisCtx, 'securityCenterNavItems', defaultNavItems);

  const filteredNavItems: NavItem[] = [
    !isDmsOnlyUser(irisCtx) && {
      displayName: translate.instant('dashboard'),
      icon: 'dashboard!outline',
      href: '/security-center',
    },
    ...(navItems.includes('antiransomwareItems') ? antiransomwareItems : []),
    ...(navItems.includes('datahawkItems') ? datahawkItems : []),
    ...(navItems.includes('securityItems') ? securityItems : []),
    ...(navItems.includes('miscItems') ? miscItems : [])
  ];

  return filteredNavItems;
};
