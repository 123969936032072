/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CohesityV2ApiConfiguration as __Configuration } from '../cohesity-v2api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ChassisList } from '../models/chassis-list';
import { Chassis } from '../models/chassis';
import { UpgradeChecksResults } from '../models/upgrade-checks-results';
import { UpgradeCheckRunTestsResult } from '../models/upgrade-check-run-tests-result';
import { UpgradeCheckRunTestsRequest } from '../models/upgrade-check-run-tests-request';
import { Cluster } from '../models/cluster';
import { CreateClusterParams } from '../models/create-cluster-params';
import { ClusterOperationResponseParams } from '../models/cluster-operation-response-params';
import { AirgapConfig } from '../models/airgap-config';
import { ClusterAMQPTargetConfig } from '../models/cluster-amqptarget-config';
import { ApiBasedFetchInfo } from '../models/api-based-fetch-info';
import { MarkBaseosUpgradeInfo } from '../models/mark-baseos-upgrade-info';
import { McmRigelClaimResponseParams } from '../models/mcm-rigel-claim-response-params';
import { FeatureFlagList } from '../models/feature-flag-list';
import { UpdateFeatureFlagParams } from '../models/update-feature-flag-params';
import { HostMappings } from '../models/host-mappings';
import { HostMappingsParameters } from '../models/host-mappings-parameters';
import { DeleteHostsParameters } from '../models/delete-hosts-parameters';
import { DMaaSInfo } from '../models/dmaa-sinfo';
import { ClusterLocalDomainSID } from '../models/cluster-local-domain-sid';
import { Node } from '../models/node';
import { ClusterExpandParams } from '../models/cluster-expand-params';
import { FreeNodes } from '../models/free-nodes';
import { ClusterOperationListResponse } from '../models/cluster-operation-list-response';
import { ClusterPackages } from '../models/cluster-packages';
import { SMTPConfiguration } from '../models/smtpconfiguration';
import { UpdateSMTPParams } from '../models/update-smtpparams';
import { TestSMTPConfig } from '../models/test-smtpconfig';
import { SoftwareComponents } from '../models/software-components';
import { GetClusterSWUpdateHistoryResponseParams } from '../models/get-cluster-swupdate-history-response-params';
import { ClusterSWUpdateResponseParams } from '../models/cluster-swupdate-response-params';
import { ClusterSWUpdateParams } from '../models/cluster-swupdate-params';
import { PublicKeyResponse } from '../models/public-key-response';
import { PublicKeyRequest } from '../models/public-key-request';
import { ClusterStateParams } from '../models/cluster-state-params';
import { ClusterUiConfig } from '../models/cluster-ui-config';
import { ClusterFreeDisks } from '../models/cluster-free-disks';
import { DiskIdentify } from '../models/disk-identify';
import { DisksList } from '../models/disks-list';
import { RemoteDisks } from '../models/remote-disks';
import { AddRemoteDiskResponseBody } from '../models/add-remote-disk-response-body';
import { RemoveDisk } from '../models/remove-disk';
import { DiskRemovalParams } from '../models/disk-removal-params';
import { ClusterInterfaces } from '../models/cluster-interfaces';
import { InterfaceGroups } from '../models/interface-groups';
import { InterfaceGroup } from '../models/interface-group';
import { InterfaceGroupParams } from '../models/interface-group-params';
import { NetworkInterfaceParams } from '../models/network-interface-params';
import { InterfaceParams } from '../models/interface-params';
import { IpmiLanParams } from '../models/ipmi-lan-params';
import { IpmiUsers } from '../models/ipmi-users';
import { UpdateIpmiUsers } from '../models/update-ipmi-users';
import { NodeBondInterfaceParams } from '../models/node-bond-interface-params';
import { UpdateNodeBondInterfaceParams } from '../models/update-node-bond-interface-params';
import { ClusterVlans } from '../models/cluster-vlans';
import { ClusterVlanParams } from '../models/cluster-vlan-params';
import { CreateClusterVlanParams } from '../models/create-cluster-vlan-params';
import { UpdateClusterVlanParams } from '../models/update-cluster-vlan-params';
import { NodePowerOperation } from '../models/node-power-operation';
import { NodeIdentifyParams } from '../models/node-identify-params';
import { RemoveNode } from '../models/remove-node';
import { NodeRemovalParams } from '../models/node-removal-params';
import { Racks } from '../models/racks';
import { Rack } from '../models/rack';
import { SupportChannelConfig } from '../models/support-channel-config';
import { GetKubernetesStatusResponse } from '../models/get-kubernetes-status-response';
@Injectable({
  providedIn: 'root',
})
class PlatformService extends __BaseService {
  static readonly GetChassisPath = '/chassis';
  static readonly GetChassisByIdPath = '/chassis/{id}';
  static readonly UpdateChassisByIdPath = '/chassis/{id}';
  static readonly UpgradeCheckGetResultsPath = '/clusters/upgrade-checks/{testRunInstanceId}';
  static readonly UpgradeCheckRunTestsPath = '/cluster/upgrade-checks';
  static readonly GetClusterPath = '/clusters';
  static readonly UpdateClusterPath = '/clusters';
  static readonly CreateClusterPath = '/clusters';
  static readonly DestroyClusterPath = '/clusters';
  static readonly UpdateAirgapConfigPath = '/clusters/airgap';
  static readonly GetAMQPTargetConfigPath = '/clusters/amqp-target-config';
  static readonly UpdateAMQPTargetConfigPath = '/clusters/amqp-target-config';
  static readonly DeleteAMQPTargetConfigPath = '/clusters/amqp-target-config';
  static readonly GetClusterDestroyHmacPath = '/clusters/api-based-fetch-info';
  static readonly MarkBaseosUpgradePath = '/clusters/baseos-upgrade';
  static readonly UpdateClusterBifrostConfigPath = '/clusters/bifrost-config';
  static readonly CleanupTenantMigrationPath = '/clusters/cleanup-tenant-migration';
  static readonly ListFeatureFlagPath = '/clusters/feature-flag';
  static readonly UpdateFeatureFlagPath = '/clusters/feature-flag';
  static readonly ListHostsPath = '/clusters/host-mappings';
  static readonly UpdateHostsPath = '/clusters/host-mappings';
  static readonly AddHostsPath = '/clusters/host-mappings';
  static readonly DeleteHostsPath = '/clusters/host-mappings/delete';
  static readonly GetIsDMaaSClusterPath = '/clusters/is-dmaas';
  static readonly UpdateIsDMaaSClusterPath = '/clusters/is-dmaas';
  static readonly ImportCrlFilePath = '/clusters/import-crl-file';
  static readonly GetClusterLocalDomainSIDPath = '/clusters/local-domain-sid';
  static readonly GetNodesPath = '/clusters/nodes';
  static readonly ExpandClusterNodesPath = '/clusters/nodes';
  static readonly ListFreeNodesPath = '/clusters/nodes/free';
  static readonly RemoveClusterNodePath = '/clusters/nodes/{id}';
  static readonly GetClusterOperationStatusListPath = '/clusters/operation-status';
  static readonly GetClusterPackagesPath = '/clusters/packages';
  static readonly UploadFilePackagePath = '/clusters/packages/file';
  static readonly DeleteClusterPackagePath = '/clusters/packages/{versionName}';
  static readonly GetSMTPConfigurationPath = '/clusters/smtp';
  static readonly UpdateSMTPConfigurationPath = '/clusters/smtp';
  static readonly ClearSMTPConfigurationPath = '/clusters/smtp';
  static readonly ValidateSMTPConfigurationPath = '/clusters/smtp/validate';
  static readonly GetSoftwareComponentsPath = '/clusters/software-components';
  static readonly GetSWUpdateHistoryPath = '/clusters/softwares';
  static readonly UpdateClusterSoftwarePath = '/clusters/softwares';
  static readonly PublicKeyRequestPath = '/clusters/ssh-public-key';
  static readonly GetClusterStatePath = '/clusters/state';
  static readonly GetClusterUiConfigPath = '/clusters/ui-config';
  static readonly UpdateClusterUiConfigPath = '/clusters/ui-config';
  static readonly DisksAssimilatePath = '/disks/assimilate';
  static readonly DiscoverDisksPath = '/disks/discover';
  static readonly DiskIdentifyPath = '/disks/identify';
  static readonly ListDisksPath = '/disks/local';
  static readonly GetRemoteDisksPath = '/disks/remote';
  static readonly AddRemoteDiskPath = '/disks/remote';
  static readonly RemoveRemoteDiskPath = '/disks/remote/{id}';
  static readonly MarkDiskRemovalPath = '/disks/{id}/remove';
  static readonly GetNetworkInterfacesPath = '/network-interfaces';
  static readonly GetInterfaceGroupsPath = '/network/interface-groups';
  static readonly CreateInterfaceGroupPath = '/network/interface-groups';
  static readonly UpdateInterfaceGroupPath = '/network/interface-groups/{id}';
  static readonly DeleteInterfaceGroupPath = '/network/interface-groups/{id}';
  static readonly GetInterfacesPath = '/network/interfaces';
  static readonly UpdateInterfacePath = '/network/interfaces/{id}';
  static readonly GetIpmiLanConfigPath = '/network/ipmi/lan';
  static readonly UpdateIpmiLanConfigPath = '/network/ipmi/lan';
  static readonly GetIpmiUsersPath = '/network/ipmi/users';
  static readonly UpdateIpmiUsersPath = '/network/ipmi/users';
  static readonly CreateNodeBondInterfacePath = '/network/nodes/bonds';
  static readonly UpdateNodeBondInterfacePath = '/network/nodes/bonds/{name}';
  static readonly DeleteNodeBondInterfacePath = '/network/nodes/bonds/{name}';
  static readonly GetClusterVlansPath = '/network/vlans';
  static readonly CreateClusterVlanPath = '/network/vlans';
  static readonly UpdateClusterVlanPath = '/network/vlans/{interfaceName}';
  static readonly DeleteClusterVlanPath = '/network/vlans/{interfaceName}';
  static readonly SetNodePowerPath = '/node-power';
  static readonly IdentifyNodePath = '/nodes/{id}/identify';
  static readonly MarkNodeRemovalPath = '/nodes/{id}/remove';
  static readonly GetRacksPath = '/racks';
  static readonly CreateRacksPath = '/racks';
  static readonly DeleteRacksPath = '/racks';
  static readonly UpdateRacksPath = '/racks';
  static readonly GetRackByIdPath = '/racks/{id}';
  static readonly DeleteRackByIdPath = '/racks/{id}';
  static readonly UpdateRackByIdPath = '/racks/{id}';
  static readonly GetSupportChannelConfigPath = '/support-channel-config';
  static readonly UpdateSupportChannelConfigPath = '/support-channel-config';
  static readonly GetKubernetesInfraHealthStatusPath = '/kubernetes/status';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get list of chassis
   *
   * Get list of all chassis info that are part of cluster.
   * @param params The `PlatformService.GetChassisParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `noRackAssigned`: Filters chassis that have no rack assigned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetChassisResponse(params: PlatformService.GetChassisParams): __Observable<__StrictHttpResponse<ChassisList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.noRackAssigned != null) __params = __params.set('noRackAssigned', params.noRackAssigned.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/chassis`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ChassisList>;
      })
    );
  }
  /**
   * Get list of chassis
   *
   * Get list of all chassis info that are part of cluster.
   * @param params The `PlatformService.GetChassisParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `noRackAssigned`: Filters chassis that have no rack assigned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetChassis(params: PlatformService.GetChassisParams): __Observable<ChassisList> {
    return this.GetChassisResponse(params).pipe(
      __map(_r => _r.body as ChassisList)
    );
  }

  /**
   * Get a chassis by chassis id.
   *
   * Get a chassis info by id.
   * @param params The `PlatformService.GetChassisByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of chassis.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetChassisByIdResponse(params: PlatformService.GetChassisByIdParams): __Observable<__StrictHttpResponse<Chassis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/chassis/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Chassis>;
      })
    );
  }
  /**
   * Get a chassis by chassis id.
   *
   * Get a chassis info by id.
   * @param params The `PlatformService.GetChassisByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of chassis.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetChassisById(params: PlatformService.GetChassisByIdParams): __Observable<Chassis> {
    return this.GetChassisByIdResponse(params).pipe(
      __map(_r => _r.body as Chassis)
    );
  }

  /**
   * Update a chassis by chassis id.
   *
   * Update selected properties of chassis info by id.
   * @param params The `PlatformService.UpdateChassisByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of chassis.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the parameters to update chassis.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateChassisByIdResponse(params: PlatformService.UpdateChassisByIdParams): __Observable<__StrictHttpResponse<Chassis>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/chassis/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Chassis>;
      })
    );
  }
  /**
   * Update a chassis by chassis id.
   *
   * Update selected properties of chassis info by id.
   * @param params The `PlatformService.UpdateChassisByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of chassis.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the parameters to update chassis.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateChassisById(params: PlatformService.UpdateChassisByIdParams): __Observable<Chassis> {
    return this.UpdateChassisByIdResponse(params).pipe(
      __map(_r => _r.body as Chassis)
    );
  }

  /**
   * Get upgrade checks results.
   *
   * Get upgrade checks results.
   * @param testRunInstanceId Specifies test run instance for which to fetch results
   * @return Success
   */
  UpgradeCheckGetResultsResponse(testRunInstanceId: number): __Observable<__StrictHttpResponse<UpgradeChecksResults>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/upgrade-checks/${encodeURIComponent(testRunInstanceId)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpgradeChecksResults>;
      })
    );
  }
  /**
   * Get upgrade checks results.
   *
   * Get upgrade checks results.
   * @param testRunInstanceId Specifies test run instance for which to fetch results
   * @return Success
   */
  UpgradeCheckGetResults(testRunInstanceId: number): __Observable<UpgradeChecksResults> {
    return this.UpgradeCheckGetResultsResponse(testRunInstanceId).pipe(
      __map(_r => _r.body as UpgradeChecksResults)
    );
  }

  /**
   * Run upgrade checks on cluster.
   *
   * Run upgrade checks on cluster.
   * @param params The `PlatformService.UpgradeCheckRunTestsParams` containing the following parameters:
   *
   * - `body`: Run upgrade checks on cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpgradeCheckRunTestsResponse(params: PlatformService.UpgradeCheckRunTestsParams): __Observable<__StrictHttpResponse<UpgradeCheckRunTestsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/cluster/upgrade-checks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UpgradeCheckRunTestsResult>;
      })
    );
  }
  /**
   * Run upgrade checks on cluster.
   *
   * Run upgrade checks on cluster.
   * @param params The `PlatformService.UpgradeCheckRunTestsParams` containing the following parameters:
   *
   * - `body`: Run upgrade checks on cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpgradeCheckRunTests(params: PlatformService.UpgradeCheckRunTestsParams): __Observable<UpgradeCheckRunTestsResult> {
    return this.UpgradeCheckRunTestsResponse(params).pipe(
      __map(_r => _r.body as UpgradeCheckRunTestsResult)
    );
  }

  /**
   * Retrieve Cluster Configuration
   *
   * Retrieve some summary information about the Cluster Configuration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Cluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cluster>;
      })
    );
  }
  /**
   * Retrieve Cluster Configuration
   *
   * Retrieve some summary information about the Cluster Configuration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetCluster(regionId?: string,
    accessClusterId?: number): __Observable<Cluster> {
    return this.GetClusterResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Cluster)
    );
  }

  /**
   * Update a cluster.
   *
   * Update the Cluster with the given configuration.
   * @param params The `PlatformService.UpdateClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateClusterResponse(params: PlatformService.UpdateClusterParams): __Observable<__StrictHttpResponse<Cluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cluster>;
      })
    );
  }
  /**
   * Update a cluster.
   *
   * Update the Cluster with the given configuration.
   * @param params The `PlatformService.UpdateClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateCluster(params: PlatformService.UpdateClusterParams): __Observable<Cluster> {
    return this.UpdateClusterResponse(params).pipe(
      __map(_r => _r.body as Cluster)
    );
  }

  /**
   * Create a cluster.
   *
   * Create a cluster with given network and cluster configuration.
   * @param params The `PlatformService.CreateClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateClusterResponse(params: PlatformService.CreateClusterParams): __Observable<__StrictHttpResponse<Cluster>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Cluster>;
      })
    );
  }
  /**
   * Create a cluster.
   *
   * Create a cluster with given network and cluster configuration.
   * @param params The `PlatformService.CreateClusterParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateCluster(params: PlatformService.CreateClusterParams): __Observable<Cluster> {
    return this.CreateClusterResponse(params).pipe(
      __map(_r => _r.body as Cluster)
    );
  }

  /**
   * Destroy a cluster.
   *
   * Destroy a cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  DestroyClusterResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ClusterOperationResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/clusters`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterOperationResponseParams>;
      })
    );
  }
  /**
   * Destroy a cluster.
   *
   * Destroy a cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  DestroyCluster(regionId?: string,
    accessClusterId?: number): __Observable<ClusterOperationResponseParams> {
    return this.DestroyClusterResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ClusterOperationResponseParams)
    );
  }

  /**
   * Update Airgap config
   *
   * Enable or Disable Airgap on the cluster.
   * @param params The `PlatformService.UpdateAirgapConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update airgap config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateAirgapConfigResponse(params: PlatformService.UpdateAirgapConfigParams): __Observable<__StrictHttpResponse<AirgapConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clusters/airgap`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AirgapConfig>;
      })
    );
  }
  /**
   * Update Airgap config
   *
   * Enable or Disable Airgap on the cluster.
   * @param params The `PlatformService.UpdateAirgapConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update airgap config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateAirgapConfig(params: PlatformService.UpdateAirgapConfigParams): __Observable<AirgapConfig> {
    return this.UpdateAirgapConfigResponse(params).pipe(
      __map(_r => _r.body as AirgapConfig)
    );
  }

  /**
   * Get AMQP Target Config
   *
   * Fetch AMQP target config on the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAMQPTargetConfigResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ClusterAMQPTargetConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/amqp-target-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterAMQPTargetConfig>;
      })
    );
  }
  /**
   * Get AMQP Target Config
   *
   * Fetch AMQP target config on the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetAMQPTargetConfig(regionId?: string,
    accessClusterId?: number): __Observable<ClusterAMQPTargetConfig> {
    return this.GetAMQPTargetConfigResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ClusterAMQPTargetConfig)
    );
  }

  /**
   * Update AMQP Target Config
   *
   * Updates AMQP target config on the cluster.
   * @param params The `PlatformService.UpdateAMQPTargetConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update cluster AMQP target config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateAMQPTargetConfigResponse(params: PlatformService.UpdateAMQPTargetConfigParams): __Observable<__StrictHttpResponse<ClusterAMQPTargetConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clusters/amqp-target-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterAMQPTargetConfig>;
      })
    );
  }
  /**
   * Update AMQP Target Config
   *
   * Updates AMQP target config on the cluster.
   * @param params The `PlatformService.UpdateAMQPTargetConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update cluster AMQP target config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateAMQPTargetConfig(params: PlatformService.UpdateAMQPTargetConfigParams): __Observable<ClusterAMQPTargetConfig> {
    return this.UpdateAMQPTargetConfigResponse(params).pipe(
      __map(_r => _r.body as ClusterAMQPTargetConfig)
    );
  }

  /**
   * Delete AMQP Target Config
   *
   * Delete AMQP target config on the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteAMQPTargetConfigResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/clusters/amqp-target-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete AMQP Target Config
   *
   * Delete AMQP target config on the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteAMQPTargetConfig(regionId?: string,
    accessClusterId?: number): __Observable<null> {
    return this.DeleteAMQPTargetConfigResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Retrieve specific cluster information.
   *
   * Fetch info regarding cluster to perform certain api based operations.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterDestroyHmacResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ApiBasedFetchInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/api-based-fetch-info`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ApiBasedFetchInfo>;
      })
    );
  }
  /**
   * Retrieve specific cluster information.
   *
   * Fetch info regarding cluster to perform certain api based operations.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterDestroyHmac(regionId?: string,
    accessClusterId?: number): __Observable<ApiBasedFetchInfo> {
    return this.GetClusterDestroyHmacResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ApiBasedFetchInfo)
    );
  }

  /**
   * Sets/clears the BaseOS upgrade cluster operation.
   *
   * Sets/clears the BaseOS upgrade cluster operation.
   * @param params The `PlatformService.MarkBaseosUpgradeParams` containing the following parameters:
   *
   * - `body`: Param to whether set/clear BaseOS uprgade  operation.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  MarkBaseosUpgradeResponse(params: PlatformService.MarkBaseosUpgradeParams): __Observable<__StrictHttpResponse<MarkBaseosUpgradeInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clusters/baseos-upgrade`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<MarkBaseosUpgradeInfo>;
      })
    );
  }
  /**
   * Sets/clears the BaseOS upgrade cluster operation.
   *
   * Sets/clears the BaseOS upgrade cluster operation.
   * @param params The `PlatformService.MarkBaseosUpgradeParams` containing the following parameters:
   *
   * - `body`: Param to whether set/clear BaseOS uprgade  operation.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  MarkBaseosUpgrade(params: PlatformService.MarkBaseosUpgradeParams): __Observable<MarkBaseosUpgradeInfo> {
    return this.MarkBaseosUpgradeResponse(params).pipe(
      __map(_r => _r.body as MarkBaseosUpgradeInfo)
    );
  }

  /**
   * Update cluster Bifrost config.
   *
   * Update cluster Bifrost config.
   * @param params The `PlatformService.UpdateClusterBifrostConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the request to update Bifrost config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateClusterBifrostConfigResponse(params: PlatformService.UpdateClusterBifrostConfigParams): __Observable<__StrictHttpResponse<McmRigelClaimResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clusters/bifrost-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<McmRigelClaimResponseParams>;
      })
    );
  }
  /**
   * Update cluster Bifrost config.
   *
   * Update cluster Bifrost config.
   * @param params The `PlatformService.UpdateClusterBifrostConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the request to update Bifrost config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateClusterBifrostConfig(params: PlatformService.UpdateClusterBifrostConfigParams): __Observable<McmRigelClaimResponseParams> {
    return this.UpdateClusterBifrostConfigResponse(params).pipe(
      __map(_r => _r.body as McmRigelClaimResponseParams)
    );
  }

  /**
   * Cleanup Tenant Migration.
   *
   * Cleanup in cluster config for tenant migration on rigel.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CleanupTenantMigrationResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clusters/cleanup-tenant-migration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Cleanup Tenant Migration.
   *
   * Cleanup in cluster config for tenant migration on rigel.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  CleanupTenantMigration(regionId?: string,
    accessClusterId?: number): __Observable<null> {
    return this.CleanupTenantMigrationResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get feature flag overrides list.
   *
   * Get the list of feature flag overrides defined on cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListFeatureFlagResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<FeatureFlagList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/feature-flag`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeatureFlagList>;
      })
    );
  }
  /**
   * Get feature flag overrides list.
   *
   * Get the list of feature flag overrides defined on cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListFeatureFlag(regionId?: string,
    accessClusterId?: number): __Observable<FeatureFlagList> {
    return this.ListFeatureFlagResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as FeatureFlagList)
    );
  }

  /**
   * Update feature flag override status.
   *
   * Update a feature flag override status to cluster.
   * @param params The `PlatformService.UpdateFeatureFlagParams` containing the following parameters:
   *
   * - `body`: Param for feature flag override request.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFeatureFlagResponse(params: PlatformService.UpdateFeatureFlagParams): __Observable<__StrictHttpResponse<FeatureFlagList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clusters/feature-flag`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FeatureFlagList>;
      })
    );
  }
  /**
   * Update feature flag override status.
   *
   * Update a feature flag override status to cluster.
   * @param params The `PlatformService.UpdateFeatureFlagParams` containing the following parameters:
   *
   * - `body`: Param for feature flag override request.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateFeatureFlag(params: PlatformService.UpdateFeatureFlagParams): __Observable<FeatureFlagList> {
    return this.UpdateFeatureFlagResponse(params).pipe(
      __map(_r => _r.body as FeatureFlagList)
    );
  }

  /**
   * List Host Mappings
   *
   * Lists the host mappings in /etc/hosts of the nodes in a cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListHostsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<HostMappings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/host-mappings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HostMappings>;
      })
    );
  }
  /**
   * List Host Mappings
   *
   * Lists the host mappings in /etc/hosts of the nodes in a cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  ListHosts(regionId?: string,
    accessClusterId?: number): __Observable<HostMappings> {
    return this.ListHostsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as HostMappings)
    );
  }

  /**
   * Update Host Mappings
   *
   * Updates Host Mapping on the Cluster.
   * @param params The `PlatformService.UpdateHostsParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateHostsResponse(params: PlatformService.UpdateHostsParams): __Observable<__StrictHttpResponse<HostMappings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clusters/host-mappings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HostMappings>;
      })
    );
  }
  /**
   * Update Host Mappings
   *
   * Updates Host Mapping on the Cluster.
   * @param params The `PlatformService.UpdateHostsParams` containing the following parameters:
   *
   * - `body`:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateHosts(params: PlatformService.UpdateHostsParams): __Observable<HostMappings> {
    return this.UpdateHostsResponse(params).pipe(
      __map(_r => _r.body as HostMappings)
    );
  }

  /**
   * Create Cluster Host Mappings
   *
   * Sends a request to add one or more new entries to the Cluster's /etc/hosts
   * @param params The `PlatformService.AddHostsParams` containing the following parameters:
   *
   * - `body`: Specifies the request to add entries to /etc/hosts
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddHostsResponse(params: PlatformService.AddHostsParams): __Observable<__StrictHttpResponse<HostMappings>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clusters/host-mappings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<HostMappings>;
      })
    );
  }
  /**
   * Create Cluster Host Mappings
   *
   * Sends a request to add one or more new entries to the Cluster's /etc/hosts
   * @param params The `PlatformService.AddHostsParams` containing the following parameters:
   *
   * - `body`: Specifies the request to add entries to /etc/hosts
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddHosts(params: PlatformService.AddHostsParams): __Observable<HostMappings> {
    return this.AddHostsResponse(params).pipe(
      __map(_r => _r.body as HostMappings)
    );
  }

  /**
   * Deletes multiple Host Mappings within the cluster
   *
   * Delete one or more Host Mappings within the cluster.
   * @param params The `PlatformService.DeleteHostsParams` containing the following parameters:
   *
   * - `body`: Specifies the params to delete host mappings
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteHostsResponse(params: PlatformService.DeleteHostsParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clusters/host-mappings/delete`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Deletes multiple Host Mappings within the cluster
   *
   * Delete one or more Host Mappings within the cluster.
   * @param params The `PlatformService.DeleteHostsParams` containing the following parameters:
   *
   * - `body`: Specifies the params to delete host mappings
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteHosts(params: PlatformService.DeleteHostsParams): __Observable<null> {
    return this.DeleteHostsResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get whether the cluster is a DMaaS cluster.
   *
   * Get whether the cluster is a DMaaS cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetIsDMaaSClusterResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<DMaaSInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/is-dmaas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DMaaSInfo>;
      })
    );
  }
  /**
   * Get whether the cluster is a DMaaS cluster.
   *
   * Get whether the cluster is a DMaaS cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetIsDMaaSCluster(regionId?: string,
    accessClusterId?: number): __Observable<DMaaSInfo> {
    return this.GetIsDMaaSClusterResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as DMaaSInfo)
    );
  }

  /**
   * Update whether the cluster is a DMaaS cluster.
   *
   * Update whether the cluster is a DMaaS cluster.
   * @param params The `PlatformService.UpdateIsDMaaSClusterParams` containing the following parameters:
   *
   * - `body`: Param to update whether the cluster is a DMaaS cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIsDMaaSClusterResponse(params: PlatformService.UpdateIsDMaaSClusterParams): __Observable<__StrictHttpResponse<DMaaSInfo>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clusters/is-dmaas`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DMaaSInfo>;
      })
    );
  }
  /**
   * Update whether the cluster is a DMaaS cluster.
   *
   * Update whether the cluster is a DMaaS cluster.
   * @param params The `PlatformService.UpdateIsDMaaSClusterParams` containing the following parameters:
   *
   * - `body`: Param to update whether the cluster is a DMaaS cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIsDMaaSCluster(params: PlatformService.UpdateIsDMaaSClusterParams): __Observable<DMaaSInfo> {
    return this.UpdateIsDMaaSClusterResponse(params).pipe(
      __map(_r => _r.body as DMaaSInfo)
    );
  }

  /**
   * Import Crl File
   *
   * Import a Crl file into the cluster.
   * @param fileName undefined
   * @param crlfile undefined
   */
  ImportCrlFileResponse(fileName: string,
    crlfile: Blob): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (fileName != null) { __formData.append('fileName', fileName as string | Blob);}
    if (crlfile != null) { __formData.append('crlfile', crlfile as string | Blob);}
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clusters/import-crl-file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Import Crl File
   *
   * Import a Crl file into the cluster.
   * @param fileName undefined
   * @param crlfile undefined
   */
  ImportCrlFile(fileName: string,
    crlfile: Blob): __Observable<null> {
    return this.ImportCrlFileResponse(fileName, crlfile).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Cluster Local Domain SID
   *
   * Fetch SID of cluster local domain.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterLocalDomainSIDResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ClusterLocalDomainSID>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/local-domain-sid`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterLocalDomainSID>;
      })
    );
  }
  /**
   * Get Cluster Local Domain SID
   *
   * Fetch SID of cluster local domain.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterLocalDomainSID(regionId?: string,
    accessClusterId?: number): __Observable<ClusterLocalDomainSID> {
    return this.GetClusterLocalDomainSIDResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ClusterLocalDomainSID)
    );
  }

  /**
   * List Nodes of the cluster.
   *
   * Gets the list of Nodes in a cluster.
   * @param params The `PlatformService.GetNodesParams` containing the following parameters:
   *
   * - `showSystemDisks`: ShowSystemdisks is used to specify whether to display system disks for the nodes. Not populated by default.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeOnlyUnassignedNodes`: IncludeOnlyUnassignedNodes will return nodes that are not yet assigned to
   *   any cluster partition. If this parameter is specified as true and
   *   ClusterPartitionIdList is also non-empty, then no nodes will be returned.
   *
   * - `includeMarkedForRemoval`: IncludeMarkedForRemoval is used to specify whether to include nodes
   *   marked for removal.
   *
   * - `ids`: "List of IDs to be returned. If empty, all nodes are returned."
   *
   * - `fetchStats`: FetchStats is used to specify whether to call Stats service to fetch the
   *   stats for the nodes. Stats not displayed by default
   *
   * - `clusterPartitionIds`: ClusterPartitionIdList specifies the list of Ids used to filter the
   *   nodes by specified cluster partition.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNodesResponse(params: PlatformService.GetNodesParams): __Observable<__StrictHttpResponse<Array<Node>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.showSystemDisks != null) __params = __params.set('showSystemDisks', params.showSystemDisks.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.includeOnlyUnassignedNodes != null) __params = __params.set('includeOnlyUnassignedNodes', params.includeOnlyUnassignedNodes.toString());
    if (params.includeMarkedForRemoval != null) __params = __params.set('includeMarkedForRemoval', params.includeMarkedForRemoval.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.fetchStats != null) __params = __params.set('fetchStats', params.fetchStats.toString());
    (params.clusterPartitionIds || []).forEach(val => {if (val != null) __params = __params.append('clusterPartitionIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Node>>;
      })
    );
  }
  /**
   * List Nodes of the cluster.
   *
   * Gets the list of Nodes in a cluster.
   * @param params The `PlatformService.GetNodesParams` containing the following parameters:
   *
   * - `showSystemDisks`: ShowSystemdisks is used to specify whether to display system disks for the nodes. Not populated by default.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `includeOnlyUnassignedNodes`: IncludeOnlyUnassignedNodes will return nodes that are not yet assigned to
   *   any cluster partition. If this parameter is specified as true and
   *   ClusterPartitionIdList is also non-empty, then no nodes will be returned.
   *
   * - `includeMarkedForRemoval`: IncludeMarkedForRemoval is used to specify whether to include nodes
   *   marked for removal.
   *
   * - `ids`: "List of IDs to be returned. If empty, all nodes are returned."
   *
   * - `fetchStats`: FetchStats is used to specify whether to call Stats service to fetch the
   *   stats for the nodes. Stats not displayed by default
   *
   * - `clusterPartitionIds`: ClusterPartitionIdList specifies the list of Ids used to filter the
   *   nodes by specified cluster partition.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetNodes(params: PlatformService.GetNodesParams): __Observable<Array<Node>> {
    return this.GetNodesResponse(params).pipe(
      __map(_r => _r.body as Array<Node>)
    );
  }

  /**
   * Expand the cluster.
   *
   * Expand the cluster by adding new nodes.
   * @param params The `PlatformService.ExpandClusterNodesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to expand the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ExpandClusterNodesResponse(params: PlatformService.ExpandClusterNodesParams): __Observable<__StrictHttpResponse<ClusterOperationResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clusters/nodes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterOperationResponseParams>;
      })
    );
  }
  /**
   * Expand the cluster.
   *
   * Expand the cluster by adding new nodes.
   * @param params The `PlatformService.ExpandClusterNodesParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to expand the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ExpandClusterNodes(params: PlatformService.ExpandClusterNodesParams): __Observable<ClusterOperationResponseParams> {
    return this.ExpandClusterNodesResponse(params).pipe(
      __map(_r => _r.body as ClusterOperationResponseParams)
    );
  }

  /**
   * List the free Cohesity Nodes present on a network.
   *
   * Sends a request to any Node to list all of the free Nodes that are present on the network.
   * @param params The `PlatformService.ListFreeNodesParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ipAddresses`: Specify IP addresses to use for free node search if auto discovery is disabled in the network.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListFreeNodesResponse(params: PlatformService.ListFreeNodesParams): __Observable<__StrictHttpResponse<FreeNodes>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ipAddresses || []).forEach(val => {if (val != null) __params = __params.append('ipAddresses', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/nodes/free`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<FreeNodes>;
      })
    );
  }
  /**
   * List the free Cohesity Nodes present on a network.
   *
   * Sends a request to any Node to list all of the free Nodes that are present on the network.
   * @param params The `PlatformService.ListFreeNodesParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ipAddresses`: Specify IP addresses to use for free node search if auto discovery is disabled in the network.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListFreeNodes(params: PlatformService.ListFreeNodesParams): __Observable<FreeNodes> {
    return this.ListFreeNodesResponse(params).pipe(
      __map(_r => _r.body as FreeNodes)
    );
  }

  /**
   * Remove node
   *
   * Remove a node from the cluster.
   * @param params The `PlatformService.RemoveClusterNodeParams` containing the following parameters:
   *
   * - `id`: Id of the node.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RemoveClusterNodeResponse(params: PlatformService.RemoveClusterNodeParams): __Observable<__StrictHttpResponse<ClusterOperationResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/clusters/nodes/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterOperationResponseParams>;
      })
    );
  }
  /**
   * Remove node
   *
   * Remove a node from the cluster.
   * @param params The `PlatformService.RemoveClusterNodeParams` containing the following parameters:
   *
   * - `id`: Id of the node.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  RemoveClusterNode(params: PlatformService.RemoveClusterNodeParams): __Observable<ClusterOperationResponseParams> {
    return this.RemoveClusterNodeResponse(params).pipe(
      __map(_r => _r.body as ClusterOperationResponseParams)
    );
  }

  /**
   * Get cluster operations status.
   *
   * Get list of cluster operations status information.
   * @param params The `PlatformService.GetClusterOperationStatusListParams` containing the following parameters:
   *
   * - `startTime`: Filters operations that started after the specified time. Applicable only for patch apply, revert, and upgrade operations
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `operationTypes`: One or more operation types to query for
   *
   * - `operationIds`: One or more operation ids to query for
   *
   * - `includeFinishedOperations`: Controls whether finished operations should be included in the query results. The default value is false. Applicable only for patch apply, revert, and upgrade operations
   *
   * - `includeEventLogs`: Controls whether event logs should be included in the query results. If set to true, 'operationIds' becomes mandatory. The default value is false. Applicable only for patch apply, revert, and upgrade operations
   *
   * - `endTime`: Filters operations that ended before the specified time. Applicable only for patch apply, revert, and upgrade operations
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetClusterOperationStatusListResponse(params: PlatformService.GetClusterOperationStatusListParams): __Observable<__StrictHttpResponse<ClusterOperationListResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.startTime != null) __params = __params.set('startTime', params.startTime.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.operationTypes || []).forEach(val => {if (val != null) __params = __params.append('operationTypes', val.toString())});
    (params.operationIds || []).forEach(val => {if (val != null) __params = __params.append('operationIds', val.toString())});
    if (params.includeFinishedOperations != null) __params = __params.set('includeFinishedOperations', params.includeFinishedOperations.toString());
    if (params.includeEventLogs != null) __params = __params.set('includeEventLogs', params.includeEventLogs.toString());
    if (params.endTime != null) __params = __params.set('endTime', params.endTime.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/operation-status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterOperationListResponse>;
      })
    );
  }
  /**
   * Get cluster operations status.
   *
   * Get list of cluster operations status information.
   * @param params The `PlatformService.GetClusterOperationStatusListParams` containing the following parameters:
   *
   * - `startTime`: Filters operations that started after the specified time. Applicable only for patch apply, revert, and upgrade operations
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `operationTypes`: One or more operation types to query for
   *
   * - `operationIds`: One or more operation ids to query for
   *
   * - `includeFinishedOperations`: Controls whether finished operations should be included in the query results. The default value is false. Applicable only for patch apply, revert, and upgrade operations
   *
   * - `includeEventLogs`: Controls whether event logs should be included in the query results. If set to true, 'operationIds' becomes mandatory. The default value is false. Applicable only for patch apply, revert, and upgrade operations
   *
   * - `endTime`: Filters operations that ended before the specified time. Applicable only for patch apply, revert, and upgrade operations
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetClusterOperationStatusList(params: PlatformService.GetClusterOperationStatusListParams): __Observable<ClusterOperationListResponse> {
    return this.GetClusterOperationStatusListResponse(params).pipe(
      __map(_r => _r.body as ClusterOperationListResponse)
    );
  }

  /**
   * Get packages
   *
   * Get software packages on the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterPackagesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ClusterPackages>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/packages`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterPackages>;
      })
    );
  }
  /**
   * Get packages
   *
   * Get software packages on the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterPackages(regionId?: string,
    accessClusterId?: number): __Observable<ClusterPackages> {
    return this.GetClusterPackagesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ClusterPackages)
    );
  }

  /**
   * Upload package by files
   *
   * Upload upgrade/patch package.
   * @param params The `PlatformService.UploadFilePackageParams` containing the following parameters:
   *
   * - `packageFile`: Binary content of the file.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `packageType`: Package Type.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UploadFilePackageResponse(params: PlatformService.UploadFilePackageParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.packageFile != null) { __formData.append('packageFile', params.packageFile as string | Blob);}
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.packageType != null) __params = __params.set('packageType', params.packageType.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clusters/packages/file`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Upload package by files
   *
   * Upload upgrade/patch package.
   * @param params The `PlatformService.UploadFilePackageParams` containing the following parameters:
   *
   * - `packageFile`: Binary content of the file.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `packageType`: Package Type.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  UploadFilePackage(params: PlatformService.UploadFilePackageParams): __Observable<null> {
    return this.UploadFilePackageResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete package
   *
   * Delete a software package on the cluster.
   * @param params The `PlatformService.DeleteClusterPackageParams` containing the following parameters:
   *
   * - `versionName`: Specifies the version name of the package. Example: 6.3.1h_release-20210714_0fad884e
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `packageType`: Specifies the type of the package.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DeleteClusterPackageResponse(params: PlatformService.DeleteClusterPackageParams): __Observable<__StrictHttpResponse<ClusterOperationResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.packageType != null) __params = __params.set('packageType', params.packageType.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/clusters/packages/${encodeURIComponent(params.versionName)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterOperationResponseParams>;
      })
    );
  }
  /**
   * Delete package
   *
   * Delete a software package on the cluster.
   * @param params The `PlatformService.DeleteClusterPackageParams` containing the following parameters:
   *
   * - `versionName`: Specifies the version name of the package. Example: 6.3.1h_release-20210714_0fad884e
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `packageType`: Specifies the type of the package.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DeleteClusterPackage(params: PlatformService.DeleteClusterPackageParams): __Observable<ClusterOperationResponseParams> {
    return this.DeleteClusterPackageResponse(params).pipe(
      __map(_r => _r.body as ClusterOperationResponseParams)
    );
  }

  /**
   * Get SMTP configuration.
   *
   * Get the SMTP cluster configuration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSMTPConfigurationResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<SMTPConfiguration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/smtp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SMTPConfiguration>;
      })
    );
  }
  /**
   * Get SMTP configuration.
   *
   * Get the SMTP cluster configuration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSMTPConfiguration(regionId?: string,
    accessClusterId?: number): __Observable<SMTPConfiguration> {
    return this.GetSMTPConfigurationResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as SMTPConfiguration)
    );
  }

  /**
   * Update SMTP configuration.
   *
   * Update SMTP configuration.
   * @param params The `PlatformService.UpdateSMTPConfigurationParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update cluster SMTP configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSMTPConfigurationResponse(params: PlatformService.UpdateSMTPConfigurationParams): __Observable<__StrictHttpResponse<SMTPConfiguration>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clusters/smtp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SMTPConfiguration>;
      })
    );
  }
  /**
   * Update SMTP configuration.
   *
   * Update SMTP configuration.
   * @param params The `PlatformService.UpdateSMTPConfigurationParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update cluster SMTP configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSMTPConfiguration(params: PlatformService.UpdateSMTPConfigurationParams): __Observable<SMTPConfiguration> {
    return this.UpdateSMTPConfigurationResponse(params).pipe(
      __map(_r => _r.body as SMTPConfiguration)
    );
  }

  /**
   * Clear SMTP configuration.
   *
   * Clear cluster SMTP configuration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ClearSMTPConfigurationResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/clusters/smtp`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Clear SMTP configuration.
   *
   * Clear cluster SMTP configuration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ClearSMTPConfiguration(regionId?: string,
    accessClusterId?: number): __Observable<null> {
    return this.ClearSMTPConfigurationResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Validate SMTP configuration.
   *
   * Validate SMTP configuration by sending a test email.
   * @param params The `PlatformService.ValidateSMTPConfigurationParams` containing the following parameters:
   *
   * - `body`: Specifies the request parameters to validate SMTP configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ValidateSMTPConfigurationResponse(params: PlatformService.ValidateSMTPConfigurationParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clusters/smtp/validate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Validate SMTP configuration.
   *
   * Validate SMTP configuration by sending a test email.
   * @param params The `PlatformService.ValidateSMTPConfigurationParams` containing the following parameters:
   *
   * - `body`: Specifies the request parameters to validate SMTP configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  ValidateSMTPConfiguration(params: PlatformService.ValidateSMTPConfigurationParams): __Observable<null> {
    return this.ValidateSMTPConfigurationResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Software Components
   *
   * Get software components versions on the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSoftwareComponentsResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<SoftwareComponents>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/software-components`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SoftwareComponents>;
      })
    );
  }
  /**
   * Get Software Components
   *
   * Get software components versions on the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSoftwareComponents(regionId?: string,
    accessClusterId?: number): __Observable<SoftwareComponents> {
    return this.GetSoftwareComponentsResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as SoftwareComponents)
    );
  }

  /**
   * Get cluster software history
   *
   * Get upgrade and patch history of the cluster.
   * @param params The `PlatformService.GetSWUpdateHistoryParams` containing the following parameters:
   *
   * - `includeNodeHistory`: Flag to specify whether to fetch data from current node
   *   or all the nodes.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSWUpdateHistoryResponse(params: PlatformService.GetSWUpdateHistoryParams): __Observable<__StrictHttpResponse<GetClusterSWUpdateHistoryResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.includeNodeHistory != null) __params = __params.set('includeNodeHistory', params.includeNodeHistory.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/softwares`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetClusterSWUpdateHistoryResponseParams>;
      })
    );
  }
  /**
   * Get cluster software history
   *
   * Get upgrade and patch history of the cluster.
   * @param params The `PlatformService.GetSWUpdateHistoryParams` containing the following parameters:
   *
   * - `includeNodeHistory`: Flag to specify whether to fetch data from current node
   *   or all the nodes.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetSWUpdateHistory(params: PlatformService.GetSWUpdateHistoryParams): __Observable<GetClusterSWUpdateHistoryResponseParams> {
    return this.GetSWUpdateHistoryResponse(params).pipe(
      __map(_r => _r.body as GetClusterSWUpdateHistoryResponseParams)
    );
  }

  /**
   * Update cluster software
   *
   * Update the software on the cluster through upgrade and/or patch.
   * @param params The `PlatformService.UpdateClusterSoftwareParams` containing the following parameters:
   *
   * - `body`: The parameters to update the software on the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateClusterSoftwareResponse(params: PlatformService.UpdateClusterSoftwareParams): __Observable<__StrictHttpResponse<ClusterSWUpdateResponseParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clusters/softwares`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterSWUpdateResponseParams>;
      })
    );
  }
  /**
   * Update cluster software
   *
   * Update the software on the cluster through upgrade and/or patch.
   * @param params The `PlatformService.UpdateClusterSoftwareParams` containing the following parameters:
   *
   * - `body`: The parameters to update the software on the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateClusterSoftware(params: PlatformService.UpdateClusterSoftwareParams): __Observable<ClusterSWUpdateResponseParams> {
    return this.UpdateClusterSoftwareResponse(params).pipe(
      __map(_r => _r.body as ClusterSWUpdateResponseParams)
    );
  }

  /**
   * Get the SSH public key.
   *
   * Get the SSH public key corresponding to the private key used by workloads. For example, users may specify multiple scripts which are supposed to be executed on a remote machine at different progress states of a protection group run (for instance - running a script before the run starts and another after the run completes). The public key returned as part of this response should be added on the remote server where the script is to be executed as there is a specific private key used by the workload for remote login.
   * @param params The `PlatformService.PublicKeyRequestParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters required to retrieve SSH public key
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Describes the structure of SSH public key.
   */
  PublicKeyRequestResponse(params: PlatformService.PublicKeyRequestParams): __Observable<__StrictHttpResponse<PublicKeyResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/clusters/ssh-public-key`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<PublicKeyResponse>;
      })
    );
  }
  /**
   * Get the SSH public key.
   *
   * Get the SSH public key corresponding to the private key used by workloads. For example, users may specify multiple scripts which are supposed to be executed on a remote machine at different progress states of a protection group run (for instance - running a script before the run starts and another after the run completes). The public key returned as part of this response should be added on the remote server where the script is to be executed as there is a specific private key used by the workload for remote login.
   * @param params The `PlatformService.PublicKeyRequestParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters required to retrieve SSH public key
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Describes the structure of SSH public key.
   */
  PublicKeyRequest(params: PlatformService.PublicKeyRequestParams): __Observable<PublicKeyResponse> {
    return this.PublicKeyRequestResponse(params).pipe(
      __map(_r => _r.body as PublicKeyResponse)
    );
  }

  /**
   * Get cluster state
   *
   * Get the current state of the cluster.
   * @param params The `PlatformService.GetClusterStateParams` containing the following parameters:
   *
   * - `systemApps`: The filter whether or not to get the system apps state details.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetClusterStateResponse(params: PlatformService.GetClusterStateParams): __Observable<__StrictHttpResponse<ClusterStateParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.systemApps != null) __params = __params.set('systemApps', params.systemApps.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/state`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterStateParams>;
      })
    );
  }
  /**
   * Get cluster state
   *
   * Get the current state of the cluster.
   * @param params The `PlatformService.GetClusterStateParams` containing the following parameters:
   *
   * - `systemApps`: The filter whether or not to get the system apps state details.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetClusterState(params: PlatformService.GetClusterStateParams): __Observable<ClusterStateParams> {
    return this.GetClusterStateResponse(params).pipe(
      __map(_r => _r.body as ClusterStateParams)
    );
  }

  /**
   * Get cluster UI Config.
   *
   * Get customized UI config for the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterUiConfigResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ClusterUiConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/clusters/ui-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterUiConfig>;
      })
    );
  }
  /**
   * Get cluster UI Config.
   *
   * Get customized UI config for the cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetClusterUiConfig(regionId?: string,
    accessClusterId?: number): __Observable<ClusterUiConfig> {
    return this.GetClusterUiConfigResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ClusterUiConfig)
    );
  }

  /**
   * Update cluster UI Config.
   *
   * Update customized UI config for the cluster.
   * @param params The `PlatformService.UpdateClusterUiConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the UI config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateClusterUiConfigResponse(params: PlatformService.UpdateClusterUiConfigParams): __Observable<__StrictHttpResponse<ClusterUiConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/clusters/ui-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterUiConfig>;
      })
    );
  }
  /**
   * Update cluster UI Config.
   *
   * Update customized UI config for the cluster.
   * @param params The `PlatformService.UpdateClusterUiConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the UI config.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateClusterUiConfig(params: PlatformService.UpdateClusterUiConfigParams): __Observable<ClusterUiConfig> {
    return this.UpdateClusterUiConfigResponse(params).pipe(
      __map(_r => _r.body as ClusterUiConfig)
    );
  }

  /**
   * Assimilate disks.
   *
   * Assimilate list of disks from one or more nodes of cluster.
   * @param params The `PlatformService.DisksAssimilateParams` containing the following parameters:
   *
   * - `body`: Specifies the parameter to assimilate disks.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DisksAssimilateResponse(params: PlatformService.DisksAssimilateParams): __Observable<__StrictHttpResponse<ClusterFreeDisks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/disks/assimilate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterFreeDisks>;
      })
    );
  }
  /**
   * Assimilate disks.
   *
   * Assimilate list of disks from one or more nodes of cluster.
   * @param params The `PlatformService.DisksAssimilateParams` containing the following parameters:
   *
   * - `body`: Specifies the parameter to assimilate disks.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DisksAssimilate(params: PlatformService.DisksAssimilateParams): __Observable<ClusterFreeDisks> {
    return this.DisksAssimilateResponse(params).pipe(
      __map(_r => _r.body as ClusterFreeDisks)
    );
  }

  /**
   * Discover new disks
   *
   * Discover disks that are ready for activation
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  DiscoverDisksResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ClusterFreeDisks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/disks/discover`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterFreeDisks>;
      })
    );
  }
  /**
   * Discover new disks
   *
   * Discover disks that are ready for activation
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  DiscoverDisks(regionId?: string,
    accessClusterId?: number): __Observable<ClusterFreeDisks> {
    return this.DiscoverDisksResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ClusterFreeDisks)
    );
  }

  /**
   * Identify a disk
   *
   * Turn on/off led light of a disk.
   * @param params The `PlatformService.DiskIdentifyParams` containing the following parameters:
   *
   * - `body`: Specifies the parameter to identify disk.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DiskIdentifyResponse(params: PlatformService.DiskIdentifyParams): __Observable<__StrictHttpResponse<DiskIdentify>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/disks/identify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DiskIdentify>;
      })
    );
  }
  /**
   * Identify a disk
   *
   * Turn on/off led light of a disk.
   * @param params The `PlatformService.DiskIdentifyParams` containing the following parameters:
   *
   * - `body`: Specifies the parameter to identify disk.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  DiskIdentify(params: PlatformService.DiskIdentifyParams): __Observable<DiskIdentify> {
    return this.DiskIdentifyResponse(params).pipe(
      __map(_r => _r.body as DiskIdentify)
    );
  }

  /**
   * Get list of disks
   *
   * Get list of local disks.
   * @param params The `PlatformService.ListDisksParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `nodeId`: Specifies node id of the node to get list of disks
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListDisksResponse(params: PlatformService.ListDisksParams): __Observable<__StrictHttpResponse<DisksList>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.nodeId != null) __params = __params.set('nodeId', params.nodeId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/disks/local`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<DisksList>;
      })
    );
  }
  /**
   * Get list of disks
   *
   * Get list of local disks.
   * @param params The `PlatformService.ListDisksParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `nodeId`: Specifies node id of the node to get list of disks
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  ListDisks(params: PlatformService.ListDisksParams): __Observable<DisksList> {
    return this.ListDisksResponse(params).pipe(
      __map(_r => _r.body as DisksList)
    );
  }

  /**
   * Get remote disks
   *
   * Get remote disks.
   * @param params The `PlatformService.GetRemoteDisksParams` containing the following parameters:
   *
   * - `tiers`: Specifies a list of disk tiers, only disks with given tiers will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `nodeIds`: Specifies a list of node ids, only disks in these nodes will be returned.
   *
   * - `mountPath`: This field is deprecated. Providing this queryparam will not have any impact. Please use fileSystem query param to filter instead.
   *
   * - `fileSystem`: Specified file system name to search. only disks with file system name that partially matches the specified name will be returned.
   *
   * - `diskIds`: Specifies a list of disk ids, only disks having these ids will be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteDisksResponse(params: PlatformService.GetRemoteDisksParams): __Observable<__StrictHttpResponse<RemoteDisks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tiers || []).forEach(val => {if (val != null) __params = __params.append('tiers', val.toString())});
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.nodeIds || []).forEach(val => {if (val != null) __params = __params.append('nodeIds', val.toString())});
    if (params.mountPath != null) __params = __params.set('mountPath', params.mountPath.toString());
    if (params.fileSystem != null) __params = __params.set('fileSystem', params.fileSystem.toString());
    (params.diskIds || []).forEach(val => {if (val != null) __params = __params.append('diskIds', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/disks/remote`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoteDisks>;
      })
    );
  }
  /**
   * Get remote disks
   *
   * Get remote disks.
   * @param params The `PlatformService.GetRemoteDisksParams` containing the following parameters:
   *
   * - `tiers`: Specifies a list of disk tiers, only disks with given tiers will be returned.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `nodeIds`: Specifies a list of node ids, only disks in these nodes will be returned.
   *
   * - `mountPath`: This field is deprecated. Providing this queryparam will not have any impact. Please use fileSystem query param to filter instead.
   *
   * - `fileSystem`: Specified file system name to search. only disks with file system name that partially matches the specified name will be returned.
   *
   * - `diskIds`: Specifies a list of disk ids, only disks having these ids will be returned.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRemoteDisks(params: PlatformService.GetRemoteDisksParams): __Observable<RemoteDisks> {
    return this.GetRemoteDisksResponse(params).pipe(
      __map(_r => _r.body as RemoteDisks)
    );
  }

  /**
   * Add remote disk
   *
   * Add a remote disk.
   * @param params The `PlatformService.AddRemoteDiskParams` containing the following parameters:
   *
   * - `body`: Specifies the remote disk configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddRemoteDiskResponse(params: PlatformService.AddRemoteDiskParams): __Observable<__StrictHttpResponse<AddRemoteDiskResponseBody>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/disks/remote`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AddRemoteDiskResponseBody>;
      })
    );
  }
  /**
   * Add remote disk
   *
   * Add a remote disk.
   * @param params The `PlatformService.AddRemoteDiskParams` containing the following parameters:
   *
   * - `body`: Specifies the remote disk configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  AddRemoteDisk(params: PlatformService.AddRemoteDiskParams): __Observable<AddRemoteDiskResponseBody> {
    return this.AddRemoteDiskResponse(params).pipe(
      __map(_r => _r.body as AddRemoteDiskResponseBody)
    );
  }

  /**
   * Remove remote disk
   *
   * Remove a remote disk.
   * @param params The `PlatformService.RemoveRemoteDiskParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the remote disk to remove.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  RemoveRemoteDiskResponse(params: PlatformService.RemoveRemoteDiskParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/disks/remote/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Remove remote disk
   *
   * Remove a remote disk.
   * @param params The `PlatformService.RemoveRemoteDiskParams` containing the following parameters:
   *
   * - `id`: Specifies the id of the remote disk to remove.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  RemoveRemoteDisk(params: PlatformService.RemoveRemoteDiskParams): __Observable<null> {
    return this.RemoveRemoteDiskResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Mark Disk for removal
   *
   * Mark disk for removal or cancel removal if a disk is already marked for removal.
   * @param params The `PlatformService.MarkDiskRemovalParams` containing the following parameters:
   *
   * - `id`: Specifies unique id of the disk to mark for removal.
   *
   * - `body`: Specifies parameters to mark/cancel disk removal.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  MarkDiskRemovalResponse(params: PlatformService.MarkDiskRemovalParams): __Observable<__StrictHttpResponse<RemoveDisk>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/disks/${encodeURIComponent(params.id)}/remove`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoveDisk>;
      })
    );
  }
  /**
   * Mark Disk for removal
   *
   * Mark disk for removal or cancel removal if a disk is already marked for removal.
   * @param params The `PlatformService.MarkDiskRemovalParams` containing the following parameters:
   *
   * - `id`: Specifies unique id of the disk to mark for removal.
   *
   * - `body`: Specifies parameters to mark/cancel disk removal.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  MarkDiskRemoval(params: PlatformService.MarkDiskRemovalParams): __Observable<RemoveDisk> {
    return this.MarkDiskRemovalResponse(params).pipe(
      __map(_r => _r.body as RemoveDisk)
    );
  }

  /**
   * Get list of interfaces
   *
   * Get a list of interfaces present on the node or cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetNetworkInterfacesResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<ClusterInterfaces>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/network-interfaces`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterInterfaces>;
      })
    );
  }
  /**
   * Get list of interfaces
   *
   * Get a list of interfaces present on the node or cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetNetworkInterfaces(regionId?: string,
    accessClusterId?: number): __Observable<ClusterInterfaces> {
    return this.GetNetworkInterfacesResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as ClusterInterfaces)
    );
  }

  /**
   * Get interface groups
   *
   * Get a list of interface groups configured on the cluster.
   * @param params The `PlatformService.GetInterfaceGroupsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: Ids of the interface groups.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetInterfaceGroupsResponse(params: PlatformService.GetInterfaceGroupsParams): __Observable<__StrictHttpResponse<InterfaceGroups>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/network/interface-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InterfaceGroups>;
      })
    );
  }
  /**
   * Get interface groups
   *
   * Get a list of interface groups configured on the cluster.
   * @param params The `PlatformService.GetInterfaceGroupsParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: Ids of the interface groups.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetInterfaceGroups(params: PlatformService.GetInterfaceGroupsParams): __Observable<InterfaceGroups> {
    return this.GetInterfaceGroupsResponse(params).pipe(
      __map(_r => _r.body as InterfaceGroups)
    );
  }

  /**
   * Create interface group
   *
   * Create an interface group on the cluster.
   * @param params The `PlatformService.CreateInterfaceGroupParams` containing the following parameters:
   *
   * - `body`: Parameters to create an interface group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateInterfaceGroupResponse(params: PlatformService.CreateInterfaceGroupParams): __Observable<__StrictHttpResponse<InterfaceGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/network/interface-groups`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InterfaceGroup>;
      })
    );
  }
  /**
   * Create interface group
   *
   * Create an interface group on the cluster.
   * @param params The `PlatformService.CreateInterfaceGroupParams` containing the following parameters:
   *
   * - `body`: Parameters to create an interface group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateInterfaceGroup(params: PlatformService.CreateInterfaceGroupParams): __Observable<InterfaceGroup> {
    return this.CreateInterfaceGroupResponse(params).pipe(
      __map(_r => _r.body as InterfaceGroup)
    );
  }

  /**
   * Update interface group
   *
   * Update an interface group on the cluster.
   * @param params The `PlatformService.UpdateInterfaceGroupParams` containing the following parameters:
   *
   * - `id`: Id of the interface group.
   *
   * - `body`: Parameters to update an interface group on the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateInterfaceGroupResponse(params: PlatformService.UpdateInterfaceGroupParams): __Observable<__StrictHttpResponse<InterfaceGroup>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/network/interface-groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InterfaceGroup>;
      })
    );
  }
  /**
   * Update interface group
   *
   * Update an interface group on the cluster.
   * @param params The `PlatformService.UpdateInterfaceGroupParams` containing the following parameters:
   *
   * - `id`: Id of the interface group.
   *
   * - `body`: Parameters to update an interface group on the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateInterfaceGroup(params: PlatformService.UpdateInterfaceGroupParams): __Observable<InterfaceGroup> {
    return this.UpdateInterfaceGroupResponse(params).pipe(
      __map(_r => _r.body as InterfaceGroup)
    );
  }

  /**
   * Delete interface group
   *
   * Delete an interface group on the cluster.
   * @param params The `PlatformService.DeleteInterfaceGroupParams` containing the following parameters:
   *
   * - `id`: Id of the interface group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteInterfaceGroupResponse(params: PlatformService.DeleteInterfaceGroupParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/network/interface-groups/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete interface group
   *
   * Delete an interface group on the cluster.
   * @param params The `PlatformService.DeleteInterfaceGroupParams` containing the following parameters:
   *
   * - `id`: Id of the interface group.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteInterfaceGroup(params: PlatformService.DeleteInterfaceGroupParams): __Observable<null> {
    return this.DeleteInterfaceGroupResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get interfaces
   *
   * Get interfaces on a cluster or free node.
   * @param params The `PlatformService.GetInterfacesParams` containing the following parameters:
   *
   * - `uplinkSwitch`: Include uplink switch information.
   *
   * - `stats`: Include interface stats.
   *
   * - `slot`: Slot number, used to get interfaces on a slot.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `nodeId`: Node id, used to get interfaces on a particular node.
   *
   * - `interfaceGroup`: Get interfaces assigned to a interface group only.
   *
   * - `chassisSerial`: Chassis serial number, used to get interfaces on a chassis.
   *
   * - `cache`: Get interfaces information from cache.
   *
   * - `bondMember`: Include bond member information for bond interfaces.
   *
   * - `bondInterfaces`: Get bond interfaces only.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetInterfacesResponse(params: PlatformService.GetInterfacesParams): __Observable<__StrictHttpResponse<NetworkInterfaceParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.uplinkSwitch != null) __params = __params.set('uplinkSwitch', params.uplinkSwitch.toString());
    if (params.stats != null) __params = __params.set('stats', params.stats.toString());
    if (params.slot != null) __params = __params.set('slot', params.slot.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.nodeId != null) __params = __params.set('nodeId', params.nodeId.toString());
    if (params.interfaceGroup != null) __params = __params.set('interfaceGroup', params.interfaceGroup.toString());
    if (params.chassisSerial != null) __params = __params.set('chassisSerial', params.chassisSerial.toString());
    if (params.cache != null) __params = __params.set('cache', params.cache.toString());
    if (params.bondMember != null) __params = __params.set('bondMember', params.bondMember.toString());
    if (params.bondInterfaces != null) __params = __params.set('bondInterfaces', params.bondInterfaces.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/network/interfaces`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NetworkInterfaceParams>;
      })
    );
  }
  /**
   * Get interfaces
   *
   * Get interfaces on a cluster or free node.
   * @param params The `PlatformService.GetInterfacesParams` containing the following parameters:
   *
   * - `uplinkSwitch`: Include uplink switch information.
   *
   * - `stats`: Include interface stats.
   *
   * - `slot`: Slot number, used to get interfaces on a slot.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `nodeId`: Node id, used to get interfaces on a particular node.
   *
   * - `interfaceGroup`: Get interfaces assigned to a interface group only.
   *
   * - `chassisSerial`: Chassis serial number, used to get interfaces on a chassis.
   *
   * - `cache`: Get interfaces information from cache.
   *
   * - `bondMember`: Include bond member information for bond interfaces.
   *
   * - `bondInterfaces`: Get bond interfaces only.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetInterfaces(params: PlatformService.GetInterfacesParams): __Observable<NetworkInterfaceParams> {
    return this.GetInterfacesResponse(params).pipe(
      __map(_r => _r.body as NetworkInterfaceParams)
    );
  }

  /**
   * Update interface
   *
   * Update network interface on a free node.
   * @param params The `PlatformService.UpdateInterfaceParams` containing the following parameters:
   *
   * - `id`: Id of the interface.
   *
   * - `body`: Parameters to update an interface on a node or cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateInterfaceResponse(params: PlatformService.UpdateInterfaceParams): __Observable<__StrictHttpResponse<InterfaceParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/network/interfaces/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<InterfaceParams>;
      })
    );
  }
  /**
   * Update interface
   *
   * Update network interface on a free node.
   * @param params The `PlatformService.UpdateInterfaceParams` containing the following parameters:
   *
   * - `id`: Id of the interface.
   *
   * - `body`: Parameters to update an interface on a node or cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateInterface(params: PlatformService.UpdateInterfaceParams): __Observable<InterfaceParams> {
    return this.UpdateInterfaceResponse(params).pipe(
      __map(_r => _r.body as InterfaceParams)
    );
  }

  /**
   * Get IPMI LAN configuration
   *
   * Get cluster and node level IPMI LAN configuration.
   * @param params The `PlatformService.GetIpmiLanConfigParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: Node ids to filter node IPMI LAN configuration.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIpmiLanConfigResponse(params: PlatformService.GetIpmiLanConfigParams): __Observable<__StrictHttpResponse<IpmiLanParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/network/ipmi/lan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IpmiLanParams>;
      })
    );
  }
  /**
   * Get IPMI LAN configuration
   *
   * Get cluster and node level IPMI LAN configuration.
   * @param params The `PlatformService.GetIpmiLanConfigParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: Node ids to filter node IPMI LAN configuration.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIpmiLanConfig(params: PlatformService.GetIpmiLanConfigParams): __Observable<IpmiLanParams> {
    return this.GetIpmiLanConfigResponse(params).pipe(
      __map(_r => _r.body as IpmiLanParams)
    );
  }

  /**
   * Update IPMI LAN configuration
   *
   * Update cluster and node level IPMI LAN configuration.
   * @param params The `PlatformService.UpdateIpmiLanConfigParams` containing the following parameters:
   *
   * - `body`: Parameters to update cluster and node level IPMI LAN configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIpmiLanConfigResponse(params: PlatformService.UpdateIpmiLanConfigParams): __Observable<__StrictHttpResponse<IpmiLanParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/network/ipmi/lan`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IpmiLanParams>;
      })
    );
  }
  /**
   * Update IPMI LAN configuration
   *
   * Update cluster and node level IPMI LAN configuration.
   * @param params The `PlatformService.UpdateIpmiLanConfigParams` containing the following parameters:
   *
   * - `body`: Parameters to update cluster and node level IPMI LAN configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIpmiLanConfig(params: PlatformService.UpdateIpmiLanConfigParams): __Observable<IpmiLanParams> {
    return this.UpdateIpmiLanConfigResponse(params).pipe(
      __map(_r => _r.body as IpmiLanParams)
    );
  }

  /**
   * Get IPMI users
   *
   * Get cluster and node level IPMI users.
   * @param params The `PlatformService.GetIpmiUsersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: Node ids to filter node IPMI users.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIpmiUsersResponse(params: PlatformService.GetIpmiUsersParams): __Observable<__StrictHttpResponse<IpmiUsers>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.ids || []).forEach(val => {if (val != null) __params = __params.append('ids', val.toString())});
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/network/ipmi/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IpmiUsers>;
      })
    );
  }
  /**
   * Get IPMI users
   *
   * Get cluster and node level IPMI users.
   * @param params The `PlatformService.GetIpmiUsersParams` containing the following parameters:
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `ids`: Node ids to filter node IPMI users.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetIpmiUsers(params: PlatformService.GetIpmiUsersParams): __Observable<IpmiUsers> {
    return this.GetIpmiUsersResponse(params).pipe(
      __map(_r => _r.body as IpmiUsers)
    );
  }

  /**
   * Update IPMI users
   *
   * Update cluster and node level IPMI users.
   * @param params The `PlatformService.UpdateIpmiUsersParams` containing the following parameters:
   *
   * - `body`: Parameters to update cluster and node level IPMI users.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIpmiUsersResponse(params: PlatformService.UpdateIpmiUsersParams): __Observable<__StrictHttpResponse<IpmiUsers>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/network/ipmi/users`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IpmiUsers>;
      })
    );
  }
  /**
   * Update IPMI users
   *
   * Update cluster and node level IPMI users.
   * @param params The `PlatformService.UpdateIpmiUsersParams` containing the following parameters:
   *
   * - `body`: Parameters to update cluster and node level IPMI users.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateIpmiUsers(params: PlatformService.UpdateIpmiUsersParams): __Observable<IpmiUsers> {
    return this.UpdateIpmiUsersResponse(params).pipe(
      __map(_r => _r.body as IpmiUsers)
    );
  }

  /**
   * Create bond interface
   *
   * Create a bond interface on a free node or cluster node.
   * @param params The `PlatformService.CreateNodeBondInterfaceParams` containing the following parameters:
   *
   * - `body`: Parameters to create bond interface.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateNodeBondInterfaceResponse(params: PlatformService.CreateNodeBondInterfaceParams): __Observable<__StrictHttpResponse<NodeBondInterfaceParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/network/nodes/bonds`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NodeBondInterfaceParams>;
      })
    );
  }
  /**
   * Create bond interface
   *
   * Create a bond interface on a free node or cluster node.
   * @param params The `PlatformService.CreateNodeBondInterfaceParams` containing the following parameters:
   *
   * - `body`: Parameters to create bond interface.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateNodeBondInterface(params: PlatformService.CreateNodeBondInterfaceParams): __Observable<NodeBondInterfaceParams> {
    return this.CreateNodeBondInterfaceResponse(params).pipe(
      __map(_r => _r.body as NodeBondInterfaceParams)
    );
  }

  /**
   * Update bond interface
   *
   * Update the bond interface on a free node or cluster node.
   * @param params The `PlatformService.UpdateNodeBondInterfaceParams` containing the following parameters:
   *
   * - `name`: Name of the bond interface.
   *
   * - `body`: Parameters to update bond interface.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateNodeBondInterfaceResponse(params: PlatformService.UpdateNodeBondInterfaceParams): __Observable<__StrictHttpResponse<NodeBondInterfaceParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/network/nodes/bonds/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NodeBondInterfaceParams>;
      })
    );
  }
  /**
   * Update bond interface
   *
   * Update the bond interface on a free node or cluster node.
   * @param params The `PlatformService.UpdateNodeBondInterfaceParams` containing the following parameters:
   *
   * - `name`: Name of the bond interface.
   *
   * - `body`: Parameters to update bond interface.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateNodeBondInterface(params: PlatformService.UpdateNodeBondInterfaceParams): __Observable<NodeBondInterfaceParams> {
    return this.UpdateNodeBondInterfaceResponse(params).pipe(
      __map(_r => _r.body as NodeBondInterfaceParams)
    );
  }

  /**
   * Delete bond interface
   *
   * Delete the bond interface on a free or cluster node.
   * @param params The `PlatformService.DeleteNodeBondInterfaceParams` containing the following parameters:
   *
   * - `name`: Name of the bond interface.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `nodeId`: Id of the node, this is required when node is part of a cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteNodeBondInterfaceResponse(params: PlatformService.DeleteNodeBondInterfaceParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.nodeId != null) __params = __params.set('nodeId', params.nodeId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/network/nodes/bonds/${encodeURIComponent(params.name)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete bond interface
   *
   * Delete the bond interface on a free or cluster node.
   * @param params The `PlatformService.DeleteNodeBondInterfaceParams` containing the following parameters:
   *
   * - `name`: Name of the bond interface.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `nodeId`: Id of the node, this is required when node is part of a cluster.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteNodeBondInterface(params: PlatformService.DeleteNodeBondInterfaceParams): __Observable<null> {
    return this.DeleteNodeBondInterfaceResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get vlans
   *
   * Get vlans on the cluster.
   * @param params The `PlatformService.GetClusterVlansParams` containing the following parameters:
   *
   * - `tenantIds`: Ids of the tenants, used to get vlans assigned to tenants.
   *
   * - `skipPrimaryAndBondIface`: If true, vlan primary and bond interfaces are not returned in the response.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `interfaceNames`: Vlan interface names, it should be in interface_group_name.vlan_id format.
   *
   * - `includeTenants`: If true, the response includes vlans which belongs to all the tenants the current user has permissions to see.
   *
   * - `compressIpsToRanges`: Compress vlan IPs to list of contigous IP ranges with startIp and endIp.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetClusterVlansResponse(params: PlatformService.GetClusterVlansParams): __Observable<__StrictHttpResponse<ClusterVlans>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (params.tenantIds || []).forEach(val => {if (val != null) __params = __params.append('tenantIds', val.toString())});
    if (params.skipPrimaryAndBondIface != null) __params = __params.set('skipPrimaryAndBondIface', params.skipPrimaryAndBondIface.toString());
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    (params.interfaceNames || []).forEach(val => {if (val != null) __params = __params.append('interfaceNames', val.toString())});
    if (params.includeTenants != null) __params = __params.set('includeTenants', params.includeTenants.toString());
    if (params.compressIpsToRanges != null) __params = __params.set('compressIpsToRanges', params.compressIpsToRanges.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/network/vlans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterVlans>;
      })
    );
  }
  /**
   * Get vlans
   *
   * Get vlans on the cluster.
   * @param params The `PlatformService.GetClusterVlansParams` containing the following parameters:
   *
   * - `tenantIds`: Ids of the tenants, used to get vlans assigned to tenants.
   *
   * - `skipPrimaryAndBondIface`: If true, vlan primary and bond interfaces are not returned in the response.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `interfaceNames`: Vlan interface names, it should be in interface_group_name.vlan_id format.
   *
   * - `includeTenants`: If true, the response includes vlans which belongs to all the tenants the current user has permissions to see.
   *
   * - `compressIpsToRanges`: Compress vlan IPs to list of contigous IP ranges with startIp and endIp.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetClusterVlans(params: PlatformService.GetClusterVlansParams): __Observable<ClusterVlans> {
    return this.GetClusterVlansResponse(params).pipe(
      __map(_r => _r.body as ClusterVlans)
    );
  }

  /**
   * Create vlan
   *
   * Create a vlan on the cluster.
   * @param params The `PlatformService.CreateClusterVlanParams` containing the following parameters:
   *
   * - `body`: Parameters to create a vlan.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateClusterVlanResponse(params: PlatformService.CreateClusterVlanParams): __Observable<__StrictHttpResponse<ClusterVlanParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/network/vlans`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterVlanParams>;
      })
    );
  }
  /**
   * Create vlan
   *
   * Create a vlan on the cluster.
   * @param params The `PlatformService.CreateClusterVlanParams` containing the following parameters:
   *
   * - `body`: Parameters to create a vlan.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateClusterVlan(params: PlatformService.CreateClusterVlanParams): __Observable<ClusterVlanParams> {
    return this.CreateClusterVlanResponse(params).pipe(
      __map(_r => _r.body as ClusterVlanParams)
    );
  }

  /**
   * Update vlan
   *
   * Update a vlan on the cluster.
   * @param params The `PlatformService.UpdateClusterVlanParams` containing the following parameters:
   *
   * - `interfaceName`: Vlan interface name, it should be in interface_group_name.vlan_id format.
   *
   * - `body`: Parameters to update vlan on the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateClusterVlanResponse(params: PlatformService.UpdateClusterVlanParams): __Observable<__StrictHttpResponse<ClusterVlanParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/network/vlans/${encodeURIComponent(params.interfaceName)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ClusterVlanParams>;
      })
    );
  }
  /**
   * Update vlan
   *
   * Update a vlan on the cluster.
   * @param params The `PlatformService.UpdateClusterVlanParams` containing the following parameters:
   *
   * - `interfaceName`: Vlan interface name, it should be in interface_group_name.vlan_id format.
   *
   * - `body`: Parameters to update vlan on the cluster.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateClusterVlan(params: PlatformService.UpdateClusterVlanParams): __Observable<ClusterVlanParams> {
    return this.UpdateClusterVlanResponse(params).pipe(
      __map(_r => _r.body as ClusterVlanParams)
    );
  }

  /**
   * Delete vlan
   *
   * Delete a vlan on the cluster.
   * @param params The `PlatformService.DeleteClusterVlanParams` containing the following parameters:
   *
   * - `interfaceName`: Vlan interface name, it should be in interface_group_name.vlan_id format.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteClusterVlanResponse(params: PlatformService.DeleteClusterVlanParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/network/vlans/${encodeURIComponent(params.interfaceName)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete vlan
   *
   * Delete a vlan on the cluster.
   * @param params The `PlatformService.DeleteClusterVlanParams` containing the following parameters:
   *
   * - `interfaceName`: Vlan interface name, it should be in interface_group_name.vlan_id format.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteClusterVlan(params: PlatformService.DeleteClusterVlanParams): __Observable<null> {
    return this.DeleteClusterVlanResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Reboot or shutdown nodes in cluster.
   *
   * Reboot or shutdown nodes in cluster.
   * @param params The `PlatformService.SetNodePowerParams` containing the following parameters:
   *
   * - `body`: Specifies the reboot or shutdown operation.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  SetNodePowerResponse(params: PlatformService.SetNodePowerParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/node-power`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Reboot or shutdown nodes in cluster.
   *
   * Reboot or shutdown nodes in cluster.
   * @param params The `PlatformService.SetNodePowerParams` containing the following parameters:
   *
   * - `body`: Specifies the reboot or shutdown operation.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  SetNodePower(params: PlatformService.SetNodePowerParams): __Observable<null> {
    return this.SetNodePowerResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Identify node
   *
   * Turn on/off LED light of a node to identify.
   * @param params The `PlatformService.IdentifyNodeParams` containing the following parameters:
   *
   * - `id`: Specifies id of node to identify.
   *
   * - `body`: Specifies the parameter to identify node.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  IdentifyNodeResponse(params: PlatformService.IdentifyNodeParams): __Observable<__StrictHttpResponse<NodeIdentifyParams>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/nodes/${encodeURIComponent(params.id)}/identify`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<NodeIdentifyParams>;
      })
    );
  }
  /**
   * Identify node
   *
   * Turn on/off LED light of a node to identify.
   * @param params The `PlatformService.IdentifyNodeParams` containing the following parameters:
   *
   * - `id`: Specifies id of node to identify.
   *
   * - `body`: Specifies the parameter to identify node.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  IdentifyNode(params: PlatformService.IdentifyNodeParams): __Observable<NodeIdentifyParams> {
    return this.IdentifyNodeResponse(params).pipe(
      __map(_r => _r.body as NodeIdentifyParams)
    );
  }

  /**
   * Mark Node for removal
   *
   * Mark node for removal or Cancel if a node is already marked for removal.
   * @param params The `PlatformService.MarkNodeRemovalParams` containing the following parameters:
   *
   * - `id`: Specifies id of node to cancel removal.
   *
   * - `body`: Specifies parameters to initiate/cancel node removal .
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  MarkNodeRemovalResponse(params: PlatformService.MarkNodeRemovalParams): __Observable<__StrictHttpResponse<RemoveNode>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/nodes/${encodeURIComponent(params.id)}/remove`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<RemoveNode>;
      })
    );
  }
  /**
   * Mark Node for removal
   *
   * Mark node for removal or Cancel if a node is already marked for removal.
   * @param params The `PlatformService.MarkNodeRemovalParams` containing the following parameters:
   *
   * - `id`: Specifies id of node to cancel removal.
   *
   * - `body`: Specifies parameters to initiate/cancel node removal .
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  MarkNodeRemoval(params: PlatformService.MarkNodeRemovalParams): __Observable<RemoveNode> {
    return this.MarkNodeRemovalResponse(params).pipe(
      __map(_r => _r.body as RemoveNode)
    );
  }

  /**
   * Get list of racks
   *
   * Get list of all racks that are part of cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetRacksResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<Racks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/racks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Racks>;
      })
    );
  }
  /**
   * Get list of racks
   *
   * Get list of all racks that are part of cluster.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetRacks(regionId?: string,
    accessClusterId?: number): __Observable<Racks> {
    return this.GetRacksResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as Racks)
    );
  }

  /**
   * Create racks
   *
   * Create list of racks and optionally also assign list of chassis to each rack
   * @param params The `PlatformService.CreateRacksParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create racks.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRacksResponse(params: PlatformService.CreateRacksParams): __Observable<__StrictHttpResponse<Racks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/racks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Racks>;
      })
    );
  }
  /**
   * Create racks
   *
   * Create list of racks and optionally also assign list of chassis to each rack
   * @param params The `PlatformService.CreateRacksParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to create racks.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  CreateRacks(params: PlatformService.CreateRacksParams): __Observable<Racks> {
    return this.CreateRacksResponse(params).pipe(
      __map(_r => _r.body as Racks)
    );
  }

  /**
   * Delete all the racks.
   *
   * Delete all the racks.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRacksResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/racks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete all the racks.
   *
   * Delete all the racks.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRacks(regionId?: string,
    accessClusterId?: number): __Observable<null> {
    return this.DeleteRacksResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update racks
   *
   * Updates list of racks with name, chassis list or/and location
   * @param params The `PlatformService.UpdateRacksParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update racks.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRacksResponse(params: PlatformService.UpdateRacksParams): __Observable<__StrictHttpResponse<Racks>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/racks`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Racks>;
      })
    );
  }
  /**
   * Update racks
   *
   * Updates list of racks with name, chassis list or/and location
   * @param params The `PlatformService.UpdateRacksParams` containing the following parameters:
   *
   * - `body`: Specifies the parameters to update racks.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRacks(params: PlatformService.UpdateRacksParams): __Observable<Racks> {
    return this.UpdateRacksResponse(params).pipe(
      __map(_r => _r.body as Racks)
    );
  }

  /**
   * Get a rack by rack id.
   *
   * Get a rack info by id.
   * @param params The `PlatformService.GetRackByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of rack.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRackByIdResponse(params: PlatformService.GetRackByIdParams): __Observable<__StrictHttpResponse<Rack>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/racks/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Rack>;
      })
    );
  }
  /**
   * Get a rack by rack id.
   *
   * Get a rack info by id.
   * @param params The `PlatformService.GetRackByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of rack.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  GetRackById(params: PlatformService.GetRackByIdParams): __Observable<Rack> {
    return this.GetRackByIdResponse(params).pipe(
      __map(_r => _r.body as Rack)
    );
  }

  /**
   * Delete a rack by id.
   *
   * Delete a given rack by id.
   * @param params The `PlatformService.DeleteRackByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the rack.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRackByIdResponse(params: PlatformService.DeleteRackByIdParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/racks/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete a rack by id.
   *
   * Delete a given rack by id.
   * @param params The `PlatformService.DeleteRackByIdParams` containing the following parameters:
   *
   * - `id`: Specifies a unique id of the rack.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   */
  DeleteRackById(params: PlatformService.DeleteRackByIdParams): __Observable<null> {
    return this.DeleteRackByIdResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Update selected properties of a rack given by id.
   * @param params The `PlatformService.UpdateRackByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of rack.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the parameters to update rack.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRackByIdResponse(params: PlatformService.UpdateRackByIdParams): __Observable<__StrictHttpResponse<Rack>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    __body = params.body;
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/racks/${encodeURIComponent(params.id)}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Rack>;
      })
    );
  }
  /**
   * Update selected properties of a rack given by id.
   * @param params The `PlatformService.UpdateRackByIdParams` containing the following parameters:
   *
   * - `id`: Specifies the id of rack.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `body`: Specifies the parameters to update rack.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateRackById(params: PlatformService.UpdateRackByIdParams): __Observable<Rack> {
    return this.UpdateRackByIdResponse(params).pipe(
      __map(_r => _r.body as Rack)
    );
  }

  /**
   * Get support channel configuration.
   *
   * Get support channel configuration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSupportChannelConfigResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<SupportChannelConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/support-channel-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SupportChannelConfig>;
      })
    );
  }
  /**
   * Get support channel configuration.
   *
   * Get support channel configuration.
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Success
   */
  GetSupportChannelConfig(regionId?: string,
    accessClusterId?: number): __Observable<SupportChannelConfig> {
    return this.GetSupportChannelConfigResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as SupportChannelConfig)
    );
  }

  /**
   * Update support channel configuration.
   *
   * Update support channel configuration.
   * @param params The `PlatformService.UpdateSupportChannelConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the support channel configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSupportChannelConfigResponse(params: PlatformService.UpdateSupportChannelConfigParams): __Observable<__StrictHttpResponse<SupportChannelConfig>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.body;
    if (params.regionId != null) __headers = __headers.set('regionId', params.regionId.toString());
    if (params.accessClusterId != null) __headers = __headers.set('accessClusterId', params.accessClusterId.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/support-channel-config`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<SupportChannelConfig>;
      })
    );
  }
  /**
   * Update support channel configuration.
   *
   * Update support channel configuration.
   * @param params The `PlatformService.UpdateSupportChannelConfigParams` containing the following parameters:
   *
   * - `body`: Specifies the support channel configuration.
   *
   * - `regionId`: This field uniquely represents a region and is used for making Helios calls to a specific region.
   *
   * - `accessClusterId`: This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   *
   * @return Success
   */
  UpdateSupportChannelConfig(params: PlatformService.UpdateSupportChannelConfigParams): __Observable<SupportChannelConfig> {
    return this.UpdateSupportChannelConfigResponse(params).pipe(
      __map(_r => _r.body as SupportChannelConfig)
    );
  }

  /**
   * Get Kubernetes Infra Health Status
   *
   * Fetches the Kubernetes Infra Health status
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Successful response
   */
  GetKubernetesInfraHealthStatusResponse(regionId?: string,
    accessClusterId?: number): __Observable<__StrictHttpResponse<GetKubernetesStatusResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (regionId != null) __headers = __headers.set('regionId', regionId.toString());
    if (accessClusterId != null) __headers = __headers.set('accessClusterId', accessClusterId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/kubernetes/status`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetKubernetesStatusResponse>;
      })
    );
  }
  /**
   * Get Kubernetes Infra Health Status
   *
   * Fetches the Kubernetes Infra Health status
   * @param regionId This field uniquely represents a region and is used for making Helios calls to a specific region.
   * @param accessClusterId This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
   * @return Successful response
   */
  GetKubernetesInfraHealthStatus(regionId?: string,
    accessClusterId?: number): __Observable<GetKubernetesStatusResponse> {
    return this.GetKubernetesInfraHealthStatusResponse(regionId, accessClusterId).pipe(
      __map(_r => _r.body as GetKubernetesStatusResponse)
    );
  }
}

module PlatformService {

  /**
   * Parameters for GetChassis
   */
  export interface GetChassisParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Filters chassis that have no rack assigned.
     */
    noRackAssigned?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetChassisById
   */
  export interface GetChassisByIdParams {

    /**
     * Specifies the id of chassis.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateChassisById
   */
  export interface UpdateChassisByIdParams {

    /**
     * Specifies the id of chassis.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the parameters to update chassis.
     */
    body?: Chassis;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpgradeCheckRunTests
   */
  export interface UpgradeCheckRunTestsParams {

    /**
     * Run upgrade checks on cluster.
     */
    body: UpgradeCheckRunTestsRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateCluster
   */
  export interface UpdateClusterParams {

    /**
     * Specifies the parameters to update cluster.
     */
    body: Cluster;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateCluster
   */
  export interface CreateClusterParams {

    /**
     * Specifies the parameters to create cluster.
     */
    body: CreateClusterParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateAirgapConfig
   */
  export interface UpdateAirgapConfigParams {

    /**
     * Specifies the parameters to update airgap config.
     */
    body: AirgapConfig;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateAMQPTargetConfig
   */
  export interface UpdateAMQPTargetConfigParams {

    /**
     * Specifies the parameters to update cluster AMQP target config.
     */
    body: ClusterAMQPTargetConfig;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for MarkBaseosUpgrade
   */
  export interface MarkBaseosUpgradeParams {

    /**
     * Param to whether set/clear BaseOS uprgade  operation.
     */
    body: MarkBaseosUpgradeInfo;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateClusterBifrostConfig
   */
  export interface UpdateClusterBifrostConfigParams {

    /**
     * Specifies the request to update Bifrost config.
     */
    body: McmRigelClaimResponseParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateFeatureFlag
   */
  export interface UpdateFeatureFlagParams {

    /**
     * Param for feature flag override request.
     */
    body: UpdateFeatureFlagParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateHosts
   */
  export interface UpdateHostsParams {
    body: HostMappingsParameters;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for AddHosts
   */
  export interface AddHostsParams {

    /**
     * Specifies the request to add entries to /etc/hosts
     */
    body: HostMappingsParameters;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteHosts
   */
  export interface DeleteHostsParams {

    /**
     * Specifies the params to delete host mappings
     */
    body: DeleteHostsParameters;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateIsDMaaSCluster
   */
  export interface UpdateIsDMaaSClusterParams {

    /**
     * Param to update whether the cluster is a DMaaS cluster.
     */
    body: DMaaSInfo;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetNodes
   */
  export interface GetNodesParams {

    /**
     * ShowSystemdisks is used to specify whether to display system disks for the nodes. Not populated by default.
     */
    showSystemDisks?: boolean;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * IncludeOnlyUnassignedNodes will return nodes that are not yet assigned to
     * any cluster partition. If this parameter is specified as true and
     * ClusterPartitionIdList is also non-empty, then no nodes will be returned.
     */
    includeOnlyUnassignedNodes?: boolean;

    /**
     * IncludeMarkedForRemoval is used to specify whether to include nodes
     * marked for removal.
     */
    includeMarkedForRemoval?: boolean;

    /**
     * "List of IDs to be returned. If empty, all nodes are returned."
     */
    ids?: Array<number>;

    /**
     * FetchStats is used to specify whether to call Stats service to fetch the
     * stats for the nodes. Stats not displayed by default
     */
    fetchStats?: boolean;

    /**
     * ClusterPartitionIdList specifies the list of Ids used to filter the
     * nodes by specified cluster partition.
     */
    clusterPartitionIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ExpandClusterNodes
   */
  export interface ExpandClusterNodesParams {

    /**
     * Specifies the parameters to expand the cluster.
     */
    body: ClusterExpandParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListFreeNodes
   */
  export interface ListFreeNodesParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specify IP addresses to use for free node search if auto discovery is disabled in the network.
     */
    ipAddresses?: Array<string>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RemoveClusterNode
   */
  export interface RemoveClusterNodeParams {

    /**
     * Id of the node.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetClusterOperationStatusList
   */
  export interface GetClusterOperationStatusListParams {

    /**
     * Filters operations that started after the specified time. Applicable only for patch apply, revert, and upgrade operations
     */
    startTime?: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * One or more operation types to query for
     */
    operationTypes?: Array<'Destroy' | 'Create' | 'NodeAddition' | 'NodeRemoval' | 'DownloadUpgradePackage' | 'DownloadPatchPackage' | 'DownloadUpgradeAndPatchPackages' | 'DownloadAndUpgrade' | 'DownloadAndApplyPatch' | 'DownloadAndUpgradeWithPatch' | 'Upgrade' | 'ApplyPatch' | 'RevertPatch' | 'UpgradeAndPatch' | 'AssessSoftwareUpdate' | 'AbortApplyPatch' | 'AbortUpgrade'>;

    /**
     * One or more operation ids to query for
     */
    operationIds?: Array<string>;

    /**
     * Controls whether finished operations should be included in the query results. The default value is false. Applicable only for patch apply, revert, and upgrade operations
     */
    includeFinishedOperations?: boolean;

    /**
     * Controls whether event logs should be included in the query results. If set to true, 'operationIds' becomes mandatory. The default value is false. Applicable only for patch apply, revert, and upgrade operations
     */
    includeEventLogs?: boolean;

    /**
     * Filters operations that ended before the specified time. Applicable only for patch apply, revert, and upgrade operations
     */
    endTime?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UploadFilePackage
   */
  export interface UploadFilePackageParams {

    /**
     * Binary content of the file.
     */
    packageFile: Blob;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Package Type.
     */
    packageType?: 'Upgrade' | 'Patch';

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteClusterPackage
   */
  export interface DeleteClusterPackageParams {

    /**
     * Specifies the version name of the package. Example: 6.3.1h_release-20210714_0fad884e
     */
    versionName: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the type of the package.
     */
    packageType?: 'Upgrade' | 'Patch';

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateSMTPConfiguration
   */
  export interface UpdateSMTPConfigurationParams {

    /**
     * Specifies the parameters to update cluster SMTP configuration.
     */
    body: UpdateSMTPParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ValidateSMTPConfiguration
   */
  export interface ValidateSMTPConfigurationParams {

    /**
     * Specifies the request parameters to validate SMTP configuration.
     */
    body: TestSMTPConfig;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetSWUpdateHistory
   */
  export interface GetSWUpdateHistoryParams {

    /**
     * Flag to specify whether to fetch data from current node
     * or all the nodes.
     */
    includeNodeHistory: boolean;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateClusterSoftware
   */
  export interface UpdateClusterSoftwareParams {

    /**
     * The parameters to update the software on the cluster.
     */
    body: ClusterSWUpdateParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for PublicKeyRequest
   */
  export interface PublicKeyRequestParams {

    /**
     * Specifies the parameters required to retrieve SSH public key
     */
    body: PublicKeyRequest;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetClusterState
   */
  export interface GetClusterStateParams {

    /**
     * The filter whether or not to get the system apps state details.
     */
    systemApps?: boolean;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateClusterUiConfig
   */
  export interface UpdateClusterUiConfigParams {

    /**
     * Specifies the UI config.
     */
    body: ClusterUiConfig;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DisksAssimilate
   */
  export interface DisksAssimilateParams {

    /**
     * Specifies the parameter to assimilate disks.
     */
    body: ClusterFreeDisks;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DiskIdentify
   */
  export interface DiskIdentifyParams {

    /**
     * Specifies the parameter to identify disk.
     */
    body: DiskIdentify;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for ListDisks
   */
  export interface ListDisksParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies node id of the node to get list of disks
     */
    nodeId?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRemoteDisks
   */
  export interface GetRemoteDisksParams {

    /**
     * Specifies a list of disk tiers, only disks with given tiers will be returned.
     */
    tiers?: Array<'PCIeSSD' | 'SATA-SSD' | 'SATA-HDD' | 'CLOUD'>;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies a list of node ids, only disks in these nodes will be returned.
     */
    nodeIds?: Array<number>;

    /**
     * This field is deprecated. Providing this queryparam will not have any impact. Please use fileSystem query param to filter instead.
     */
    mountPath?: string;

    /**
     * Specified file system name to search. only disks with file system name that partially matches the specified name will be returned.
     */
    fileSystem?: string;

    /**
     * Specifies a list of disk ids, only disks having these ids will be returned.
     */
    diskIds?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for AddRemoteDisk
   */
  export interface AddRemoteDiskParams {

    /**
     * Specifies the remote disk configuration.
     */
    body: RemoteDisks;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for RemoveRemoteDisk
   */
  export interface RemoveRemoteDiskParams {

    /**
     * Specifies the id of the remote disk to remove.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for MarkDiskRemoval
   */
  export interface MarkDiskRemovalParams {

    /**
     * Specifies unique id of the disk to mark for removal.
     */
    id: number;

    /**
     * Specifies parameters to mark/cancel disk removal.
     */
    body: DiskRemovalParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetInterfaceGroups
   */
  export interface GetInterfaceGroupsParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Ids of the interface groups.
     */
    ids?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateInterfaceGroup
   */
  export interface CreateInterfaceGroupParams {

    /**
     * Parameters to create an interface group.
     */
    body: InterfaceGroupParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateInterfaceGroup
   */
  export interface UpdateInterfaceGroupParams {

    /**
     * Id of the interface group.
     */
    id: number;

    /**
     * Parameters to update an interface group on the cluster.
     */
    body: InterfaceGroupParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteInterfaceGroup
   */
  export interface DeleteInterfaceGroupParams {

    /**
     * Id of the interface group.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetInterfaces
   */
  export interface GetInterfacesParams {

    /**
     * Include uplink switch information.
     */
    uplinkSwitch?: boolean;

    /**
     * Include interface stats.
     */
    stats?: boolean;

    /**
     * Slot number, used to get interfaces on a slot.
     */
    slot?: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Node id, used to get interfaces on a particular node.
     */
    nodeId?: number;

    /**
     * Get interfaces assigned to a interface group only.
     */
    interfaceGroup?: boolean;

    /**
     * Chassis serial number, used to get interfaces on a chassis.
     */
    chassisSerial?: string;

    /**
     * Get interfaces information from cache.
     */
    cache?: boolean;

    /**
     * Include bond member information for bond interfaces.
     */
    bondMember?: boolean;

    /**
     * Get bond interfaces only.
     */
    bondInterfaces?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateInterface
   */
  export interface UpdateInterfaceParams {

    /**
     * Id of the interface.
     */
    id: number;

    /**
     * Parameters to update an interface on a node or cluster.
     */
    body: InterfaceParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetIpmiLanConfig
   */
  export interface GetIpmiLanConfigParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Node ids to filter node IPMI LAN configuration.
     */
    ids?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateIpmiLanConfig
   */
  export interface UpdateIpmiLanConfigParams {

    /**
     * Parameters to update cluster and node level IPMI LAN configuration.
     */
    body: IpmiLanParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetIpmiUsers
   */
  export interface GetIpmiUsersParams {

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Node ids to filter node IPMI users.
     */
    ids?: Array<number>;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateIpmiUsers
   */
  export interface UpdateIpmiUsersParams {

    /**
     * Parameters to update cluster and node level IPMI users.
     */
    body: UpdateIpmiUsers;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateNodeBondInterface
   */
  export interface CreateNodeBondInterfaceParams {

    /**
     * Parameters to create bond interface.
     */
    body: NodeBondInterfaceParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateNodeBondInterface
   */
  export interface UpdateNodeBondInterfaceParams {

    /**
     * Name of the bond interface.
     */
    name: string;

    /**
     * Parameters to update bond interface.
     */
    body: UpdateNodeBondInterfaceParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteNodeBondInterface
   */
  export interface DeleteNodeBondInterfaceParams {

    /**
     * Name of the bond interface.
     */
    name: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Id of the node, this is required when node is part of a cluster.
     */
    nodeId?: number;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetClusterVlans
   */
  export interface GetClusterVlansParams {

    /**
     * Ids of the tenants, used to get vlans assigned to tenants.
     */
    tenantIds?: Array<string>;

    /**
     * If true, vlan primary and bond interfaces are not returned in the response.
     */
    skipPrimaryAndBondIface?: boolean;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Vlan interface names, it should be in interface_group_name.vlan_id format.
     */
    interfaceNames?: Array<string>;

    /**
     * If true, the response includes vlans which belongs to all the tenants the current user has permissions to see.
     */
    includeTenants?: boolean;

    /**
     * Compress vlan IPs to list of contigous IP ranges with startIp and endIp.
     */
    compressIpsToRanges?: boolean;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateClusterVlan
   */
  export interface CreateClusterVlanParams {

    /**
     * Parameters to create a vlan.
     */
    body: CreateClusterVlanParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateClusterVlan
   */
  export interface UpdateClusterVlanParams {

    /**
     * Vlan interface name, it should be in interface_group_name.vlan_id format.
     */
    interfaceName: string;

    /**
     * Parameters to update vlan on the cluster.
     */
    body: UpdateClusterVlanParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteClusterVlan
   */
  export interface DeleteClusterVlanParams {

    /**
     * Vlan interface name, it should be in interface_group_name.vlan_id format.
     */
    interfaceName: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for SetNodePower
   */
  export interface SetNodePowerParams {

    /**
     * Specifies the reboot or shutdown operation.
     */
    body: NodePowerOperation;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for IdentifyNode
   */
  export interface IdentifyNodeParams {

    /**
     * Specifies id of node to identify.
     */
    id: number;

    /**
     * Specifies the parameter to identify node.
     */
    body: NodeIdentifyParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for MarkNodeRemoval
   */
  export interface MarkNodeRemovalParams {

    /**
     * Specifies id of node to cancel removal.
     */
    id: number;

    /**
     * Specifies parameters to initiate/cancel node removal .
     */
    body: NodeRemovalParams;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for CreateRacks
   */
  export interface CreateRacksParams {

    /**
     * Specifies the parameters to create racks.
     */
    body: Racks;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateRacks
   */
  export interface UpdateRacksParams {

    /**
     * Specifies the parameters to update racks.
     */
    body: Racks;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for GetRackById
   */
  export interface GetRackByIdParams {

    /**
     * Specifies the id of rack.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for DeleteRackById
   */
  export interface DeleteRackByIdParams {

    /**
     * Specifies a unique id of the rack.
     */
    id: string;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateRackById
   */
  export interface UpdateRackByIdParams {

    /**
     * Specifies the id of rack.
     */
    id: number;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * Specifies the parameters to update rack.
     */
    body?: Rack;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }

  /**
   * Parameters for UpdateSupportChannelConfig
   */
  export interface UpdateSupportChannelConfigParams {

    /**
     * Specifies the support channel configuration.
     */
    body: SupportChannelConfig;

    /**
     * This field uniquely represents a region and is used for making Helios calls to a specific region.
     */
    regionId?: string;

    /**
     * This field uniquely represents a Cohesity Cluster and is used for making on-prem calls from Helios.
     */
    accessClusterId?: number;
  }
}

export { PlatformService }
