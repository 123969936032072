import { Injectable } from '@angular/core';
import { ProtectedObject } from '@cohesity/api/v2';
import { NavItem } from '@cohesity/helix';
import { flagEnabled, IrisContextService } from '@cohesity/iris-core';
import { SourceSelection } from '@cohesity/iris-source-tree';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import {
  Environment,
  Office365BackupType,
  Office365ContainerNodeType,
  Office365CSMBackupTypes,
  Office365DownloadItemType,
  Office365LeafNodeType,
  PassthroughOptions,
  RecoveryAction,
} from 'src/app/shared';
import { Office365UtilityService } from 'src/app/shared/office365/services/office365-utility.service';

import { ProtectionGroupService } from '../protection-group-shared';
import { RestorePointSelection } from '../restore/restore-shared';
import { Office365SourceDetailsService } from '../sources/office365/services/office365-source-details.service';
import { ObjectActionCreator } from './object-action-creator';
import { SimpleObjectInfo } from './object-menu-provider';
import { ProtectedObjectsService } from './protected-objects.service';

/**
 * Type of office 365 object.
 */
type Office365ObjectType = Office365LeafNodeType | Office365ContainerNodeType;

/**
 * Helper class for creating object action items for Office 365.
 */
@Injectable({
  providedIn: 'root',
})
export class O365ObjectActionCreator extends ObjectActionCreator {
  constructor(
    objectsService: ProtectedObjectsService,
    protectionGroupService: ProtectionGroupService,
    stateService: StateService,
    private o365SourceDetailsService: Office365SourceDetailsService,
    private office365UtilityService: Office365UtilityService,
    private irisContextService: IrisContextService,
    private translateService: TranslateService,
  ) {
    super(objectsService, protectionGroupService, stateService);
  }

  /**
   * Creates protect object nav items for Office365 object
   *
   * @param objects          The objects being protected
   * @param selection        Most of the time, we can easily determine the selection based on
   *                         list of objects.
   * @param isGlobalSearch   Whether the object is selected via global search.
   *                         If true, do not lock the workload.
   * @returns                The array of protect NavItem for Office365
   */
  createO365ProtectActions(
    objects: SimpleObjectInfo[],
    selection?: SourceSelection,
    isGlobalSearch?: boolean,
  ): NavItem[] {
    if (!objects || !objects.length) {
      return [];
    }

    const baseNavItem = super.createProtectAction(objects, selection);
    const { icon, state, stateParams } = baseNavItem;

    // Check if CSM workloads are to be included.
    const shouldIncludeCSMWorkload = flagEnabled(
      this.irisContextService.irisContext, 'office365CsmBasedBackupEnabled');

    // Get the compatible workloads based on selected leaf entity.
    const compatibleWorkloads =
      this.office365UtilityService.getWorkloadFromEntity(
        objects[0].objectType as Office365ObjectType,
        objects[0].v1Object,
        shouldIncludeCSMWorkload) ?? [];
    const currentWorkload = this.o365SourceDetailsService.getWorkload();

    // If not in global search context, only show the protect action for the current workload.
    if (!isGlobalSearch && compatibleWorkloads.includes(currentWorkload)) {
      return [{
        ...baseNavItem,
        stateParams: {
          ...stateParams,
          office365WorkloadType: currentWorkload,
        }
      }];
    } else {
      // Create protect action for each of the compatible workloads.
      return compatibleWorkloads.map((workload: Office365BackupType) => ({
        displayName: `protect.office365.${workload}`,
        icon,
        state,
        stateParams: {
          ...stateParams,
          office365WorkloadType: workload,
        },
      }));
    }
  }

  /**
   * Creates and returns a recovery action for the O365 protected object.
   *
   * @param objects The protected objects.
   * @param type The type of the recovery.
   * @param office365WorkloadType The O365 workload type.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options.
   * @returns The recovery NavItem object.
   */
  createO365RecoverAction(
    objects: ProtectedObject[],
    type: RecoveryAction,
    office365WorkloadType = this.o365SourceDetailsService.getWorkload(),
    restorePointSelection?: RestorePointSelection,
    objectOptions?: PassthroughOptions,
    isGlobalSearchContext?: boolean
  ): NavItem {
    const restorePoints = super.getRestorePointSelection(objects, restorePointSelection);

    if (!restorePoints) {
      return;
    }

    let office365DownloadItemType: Office365DownloadItemType;
    let isGranular: boolean;
    let icon: string;
    let displayName: string;
    let objectId: number = null;
    let objectName: string = null;

    switch (type) {
      // Granular Mails recovery
      case RecoveryAction.RecoverMails:
        displayName = this.translateService.instant(
          this.office365UtilityService.isMailboxNonEmailItemsRecoveryEnabled() ?
          'office365Restore.recover.RecoverMailboxItems' :
          'office365Restore.recover.RecoverMails'),
        office365WorkloadType = Office365BackupType.kMailbox;
        icon = 'note!left';
        isGranular = true;

        // Populate object Id and name since Recover Mails is not a bulk op.
        objectId = objects[0]?.id;
        objectName = objects[0]?.name;
        break;

      // Download Private chats
      case RecoveryAction.DownloadChats:
        displayName = this.translateService.instant('office365Restore.recover.downloadChats');
        office365WorkloadType = Office365BackupType.kMailbox;
        office365DownloadItemType = Office365DownloadItemType.kUserPrivateChats;
        icon = 'note!left';
        isGranular = false;

        // Populate object Id and name since download chats is not a bulk op.
        objectId = objects[0]?.id;
        objectName = objects[0]?.name;
        break;

      // Granular SharePoint recovery
      case RecoveryAction.RecoverSharePointDocuments:
        displayName = this.translateService.instant('office365Restore.recover.RecoverSharePointDocuments'),
        office365WorkloadType = Office365BackupType.kSharePoint;
        icon = 'note!left';
        isGranular = true;

        // Populate object Id and name since Recover SharePoint is not a bulk op.
        objectId = objects[0]?.id;
        objectName = objects[0]?.name;
        break;

      // Granular OneDrive recovery
      case RecoveryAction.RecoverOneDriveDocuments:
        displayName = this.translateService.instant('office365Restore.recover.RecoverOneDriveDocuments'),
        office365WorkloadType = Office365BackupType.kOneDrive;
        icon = 'note!left';
        isGranular = true;

        // Populate object Id and name since Recover Onedrive is not a bulk op.
        objectId = objects[0]?.id;
        objectName = objects[0]?.name;
        break;

      // Granular Teams recovery
      case RecoveryAction.RecoverTeamsDocuments:
        displayName = this.translateService.instant('office365Restore.recover.RecoverTeamsDocuments'),
        office365WorkloadType = Office365BackupType.kTeams;
        icon = 'note!left';
        isGranular = true;

        // Populate object Id and name since Recover Teams is not a bulk op.
        objectId = objects[0]?.id;
        objectName = objects[0]?.name;
        break;

      // Download Team Posts
      case RecoveryAction.DownloadTeamsPosts:
        displayName = this.translateService.instant('office365Restore.recover.downloadTeamPosts'),
        office365WorkloadType = Office365BackupType.kTeams;
        office365DownloadItemType = Office365DownloadItemType.kTeamPosts;
        icon = 'note!left';
        isGranular = false;

        // Populate object Id and name since Recover Teams is not a bulk op.
        objectId = objects[0]?.id;
        objectName = objects[0]?.name;
        break;

      // Granular Groups Recovery
      case RecoveryAction.RecoverMsGroupDocuments:
        displayName = this.translateService.instant('office365Restore.recover.RecoverMsGroupsDocuments'),
        office365WorkloadType = Office365BackupType.kGroups;
        icon = 'note!left';
        isGranular = true;

        // Populate object Id and name since Recover Groups Documents is not a
        // bulk op.
        objectId = objects[0]?.id;
        objectName = objects[0]?.name;
        break;

      // Object recovery including kMailbox, kSharePoint, kOneDrive, kTeams...
      default:
        if (Office365CSMBackupTypes.includes(office365WorkloadType) && isGlobalSearchContext) {
          displayName = this.translateService.instant(
            `office365Restore.recover.globalSearch.${office365WorkloadType}`);
        } else {
          displayName = this.translateService.instant(
            `office365Restore.recover.${office365WorkloadType}`);
        }

        icon = 'restore';
        isGranular = false;
    }

    return {
      displayName,
      icon,
      state: 'recover-office365-ng',
      stateParams: {
        restorePoints: !isGranular ? restorePoints : null,
        isGranular,
        objectId,
        objectName,
        office365WorkloadType,
        office365DownloadItemType,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
      },
      options: {
        // Object recovery take higher priority than granular recovery
        priority: !isGranular ? 1 : -1
      },
    };
  }

  /**
   * Creates and returns a bulk recovery action for the O365 protected objects.
   *
   * @param objects The protected objects.
   * @param office365WorkloadType The O365 workload type.
   * @param restorePointSelection The Restore point selection of the selected object.
   * @param objectOptions The object's passthrough options.
   * @returns The recovery NavItem object.
   */
  createO365BulkRecoverAction(
    objects: ProtectedObject[],
    office365WorkloadType = this.o365SourceDetailsService.getWorkload(),
    restorePointSelection?: RestorePointSelection,
    objectOptions?: PassthroughOptions
  ): NavItem {
    const restorePoints = super.getRestorePointSelection(objects, restorePointSelection);

    if (!restorePoints) {
      return;
    }

    return {
      displayName: this.translateService.instant(`office365Restore.recover.${office365WorkloadType}`),
      icon: 'restore',
      state: 'recover-office365-ng',
      stateParams: {
        restorePoints,
        office365WorkloadType,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
      },
    };
  }

  /**
   * Creates an action to edit a protected O365object.
   *
   * @param   workloadType     The object's backup type.
   * @param   id               The object's id.
   * @param   objectOptions    The object's passthrough options.
   * @param   isAutoProtection True if this is for a parent's auto protection
   * @returns The edit protected O365 object action.
   */
  createEditO365ObjectProtectionAction(
    workloadType: Office365BackupType,
    id: number,
    objectOptions: PassthroughOptions = {},
    isAutoProtection: boolean = false
  ): NavItem {
    return {
      displayName: isAutoProtection ? 'editAutoProtection' : 'editProtection',
      icon: 'edit!outline',
      state: 'protection-builder',
      stateParams: {
        environments: [Environment.kO365],
        objectId: id,
        cid: objectOptions.accessClusterId,
        regionId: objectOptions.regionId,
        office365WorkloadType: workloadType,
      },
    } as any;
  }
}
